import { EnumCodRelatorios, EnumRelatorioOrigem, EnumTipoImpressaoRelatorio, RelatoriosAvancadosModel, TipoRelatorio } from "model/api/gestao/relatorios-avancados/relatorios-avancados-model";
import { useCallback, useEffect, useState } from "react";
import { useToastSaurus } from "services/app";
import { useConfiguracoesRelatorios } from "services/app/hooks/configuracoes-relatorios";
import { useExportarRelatorio } from "services/app/hooks/exportar-relatorio"
import { CircularLoading } from "views/components";
import { ExportarExcelIcon } from "views/components/icons/export-excel-icon"
import { ExportarPDFIcon } from "views/components/icons/export-pdf-icon";
import { PaginacaoButton } from "views/components/paginacao/components/paginacao-button"

interface Props {
    cod: EnumCodRelatorios,
}

export const RelatorioExportar = (props: Props) => {

    const { exportarRelatorio, carregando } = useExportarRelatorio();
    const { getConfigRelatorioByCod } = useConfiguracoesRelatorios();
    const [config, setConfig] = useState<RelatoriosAvancadosModel | null>(null);
    const { showToast } = useToastSaurus();

    const exportarArquivo = useCallback(async (tp: TipoRelatorio) => {
        try {
            await exportarRelatorio({
                filtros: [],
                relatorio: props.cod,
            }, tp)
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [exportarRelatorio, props.cod, showToast])

    useEffect(() => {
        (async () => {
            try {
                const model = getConfigRelatorioByCod(props.cod);

                if (model) {
                    setConfig(model)
                }
            } catch {

            }
        })()
    }, [getConfigRelatorioByCod, props.cod])

    const podePDF = (config && [EnumTipoImpressaoRelatorio.Ambos, EnumTipoImpressaoRelatorio.PDF].includes(config.tipoImpressao))
    const podeExcel = (config && [EnumTipoImpressaoRelatorio.Ambos, EnumTipoImpressaoRelatorio.Excel].includes(config.tipoImpressao))

    return (
        <>
            {carregando && <CircularLoading tipo='FULLSIZED' />}
            {config && config.origem === EnumRelatorioOrigem.Carga && (
                <>
                    {podeExcel && <PaginacaoButton
                        icon={<ExportarExcelIcon />}
                        onClick={() => exportarArquivo(TipoRelatorio.EXCEL)}
                        tooltip="Download Relatório XLS"
                    />}
                    {podePDF && <PaginacaoButton
                        icon={<ExportarPDFIcon />}
                        onClick={() => exportarArquivo(TipoRelatorio.PDF)}
                        tooltip='Download Relatório PDF'
                    />}
                </>
            )}
        </>
    )
}