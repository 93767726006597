import { useCallback } from 'react';
import { useStyles } from './swipe-saurus-styles';
import Swipe from 'react-easy-swipe';
import { SwipeSaurusProps } from './swipe-saurus-props';

export const SwipeSaurus = ({
  children,
  disabled,
  selectedTabIndex,
  tabsLabel,
  onChange,
  index
}: SwipeSaurusProps) => {
  const classes = useStyles();

  const swipeRight = useCallback(() => {
    const min = tabsLabel.reduce(function (prev, curr) {
      return prev.index < curr.index ? prev : curr;
    }).index;
    const valor = selectedTabIndex > min ? selectedTabIndex - 1 : min;
    if (onChange) {
      onChange(valor);
    }
  }, [onChange, selectedTabIndex, tabsLabel]);

  const swipeLeft = useCallback(() => {
    const max = tabsLabel.reduce(function (prev, curr) {
      return prev.index > curr.index ? prev : curr;
    }).index;
    const valor = selectedTabIndex > max ? selectedTabIndex + 1 : max;
    if (onChange) {
      onChange(valor);
    }
  }, [onChange, selectedTabIndex, tabsLabel]);

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Swipe
      onSwipeRight={swipeRight}
      onSwipeLeft={swipeLeft}
      className={classes.swipeDiv}
      key={index}
      tolerance={150}
    >
      {children}
    </Swipe>
  );
};
