import { useCallback, useEffect, useState, useRef } from 'react';
import { VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useToastSaurus, useCadastros } from 'services/app';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import classNames from 'classnames';
import {
  EnumTipoImpressaoRelatorio,
  RelatoriosAvancadosModel,
  TipoRelatorio
} from 'model/api/gestao/relatorios-avancados/relatorios-avancados-model';
import {
  FormRelatoriosAvancadosEdit,
  FormRelatoriosAvancadosPost
} from 'views/components/form/relatorios-avancados/form-relatorios-avancados-edit/form-relatorios-avancados-edit';
import { ExportarExcelIcon } from 'views/components/icons/export-excel-icon';
import { PDFIcon } from 'views/components/icons/pdf-icon';
import { Button, Grid } from 'views/design-system';
import { useExportarRelatorio } from 'services/app/hooks/exportar-relatorio';

export const RalatoriosAvancadosEdit = (props: {
  relatorio: RelatoriosAvancadosModel;
}) => {
  const { showToast } = useToastSaurus();
  const [preenchendoTela, setPreenchendoTela] = useState(true);

  const classes = useModalStyles();

  const { fecharRelatoriosAvancados } = useCadastros();

  const { exportarRelatorio, carregando: carregandoPostJson } = useExportarRelatorio();
  const carregando = carregandoPostJson || preenchendoTela;

  const [relatorioAvancadoFormState, setRelatorioAvancadoForm] =
    useState<RelatoriosAvancadosModel>(new RelatoriosAvancadosModel());
  const refEditForm = useRef<DefaultFormRefs<RelatoriosAvancadosModel>>(null);

  const tipoRelatorio = useRef<TipoRelatorio>(TipoRelatorio.PDF);

  const recarregarForm = useCallback((model: RelatoriosAvancadosModel) => {
    refEditForm.current?.fillForm(model);
  }, []);

  useEffect(() => {
    recarregarForm(relatorioAvancadoFormState);
  }, [relatorioAvancadoFormState, recarregarForm]);

  useEffect(() => {
    (async () => {
      try {
        setPreenchendoTela(true);
        if (props.relatorio.codigo > 0) {
          setRelatorioAvancadoForm(props.relatorio);
        }
      } catch (e: any) {
        showToast('error', e.message);
      } finally {
        setPreenchendoTela(false);
      }
    })();
    return () => {
      //funcao de limpeza
      setRelatorioAvancadoForm(new RelatoriosAvancadosModel());
    };
  }, [showToast, props.relatorio]);

  const onCloseClick = useCallback(() => {
    fecharRelatoriosAvancados();
  }, [fecharRelatoriosAvancados]);


  const handleSubmit = useCallback(
    async (model: FormRelatoriosAvancadosPost) => {
      try {
        await exportarRelatorio(model, tipoRelatorio.current);
      } catch (e: any) {
        showToast('error', e.message);
        refEditForm.current?.resetForm();
      }
    },
    [exportarRelatorio, showToast]
  );

  const podePDF = [EnumTipoImpressaoRelatorio.Ambos, EnumTipoImpressaoRelatorio.PDF].includes(relatorioAvancadoFormState.tipoImpressao)
  const podeExcel = [EnumTipoImpressaoRelatorio.Ambos, EnumTipoImpressaoRelatorio.Excel].includes(relatorioAvancadoFormState.tipoImpressao)

  const gridSize = podePDF && podeExcel ? 6 : 12;
  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={
          props.relatorio.nome.length > 0 ? props.relatorio.nome : 'Relatório'
        }
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            preenchendoTela ? classes.contentFormsLoading : undefined
          )}
        >
          <FormRelatoriosAvancadosEdit
            ref={refEditForm}
            onSubmit={handleSubmit}
            showLoading={false}
            loading={carregando}
            formFiltros={relatorioAvancadoFormState.filtros}
            ordenadores={relatorioAvancadoFormState.ordenadores}
            origem={relatorioAvancadoFormState.origem}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            {podePDF && <Grid item xs={gridSize} md={gridSize}>
              <Button
                disabled={carregando}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                onClick={() => {
                  tipoRelatorio.current = TipoRelatorio.PDF;
                  refEditForm.current?.submitForm();
                }}
              >
                <PDFIcon tipo="BUTTON" />
                PDF
              </Button>
            </Grid>}

            {podeExcel && <Grid item xs={gridSize} md={gridSize}>
              <Button
                onClick={() => {
                  tipoRelatorio.current = TipoRelatorio.EXCEL;
                  refEditForm.current?.submitForm();
                }}
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
              >
                <ExportarExcelIcon tipo="BUTTON_PRIMARY" />
                Excel
              </Button>
            </Grid>}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RalatoriosAvancadosEdit;
