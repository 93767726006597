import { useGetMedicamentoPrecoByMedId } from "data/api/gestao/medicamento-preco/get-medicamento-by-med-id";
import { useGetMedicamentoByCod } from "data/api/gestao/medicamentos/get-medicamento-by-cod";
import { useGetMedicamentos } from "data/api/gestao/medicamentos/get-medicamentos";
import { usePutMedicamento } from "data/api/gestao/medicamentos/put-medicamento";
import { useGetNcms, usePostNcm } from "data/api/gestao/ncm";
import { useDeleteProdutoImagem } from "data/api/gestao/produto/produto-imagem/delete-produto-imagem";
import { usePostProdutoImagem } from "data/api/gestao/produto/produto-imagem/post-produto-imagem";
import { usePutProdutoImagem } from "data/api/gestao/produto/produto-imagem/put-produto-imagem";
import { useDeleteProdutoVariacao } from "data/api/gestao/produto/produto-variacao/delete-produto-variacao";
import { usePutProdutoVariacao } from "data/api/gestao/produto/produto-variacao/put-produto-variacao";
import { useGetProdutoById } from "data/api/gestao/produto/produto/get-produto-by-id";
import { usePutProduto } from "data/api/gestao/produto/produto/put-produto";
import { usePutProdutoVincularFiscal } from "data/api/gestao/produto/produto/put-produto-vincular-fiscal";
import { usePostImagemBase64 } from "data/api/imagem/post-imagem";
import { useGetNcmsMaster } from "data/api/ncm-master/get-ncms-master";
import { isEmpty, isEqual } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { MedicamentoModel } from "model/api/gestao/medicamento/medicamento-model";
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { ProdutoImagemModel } from "model/api/gestao/produto/produto-imagem/produto-imagem";
import { ProdutoImpostoModel } from "model/api/gestao/produto/produto-imposto/produto-imposto-model";
import { ProdutoPrecoModel } from "model/api/gestao/produto/produto-preco/produto-preco-model";
import { ProdutoAtualizarVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-atualizar-variacao-model";
import { ProdutoVariacaoModel } from "model/api/gestao/produto/produto-variacao/produto-variacao";
import { ProdutoAtualizarModel } from "model/api/gestao/produto/produto/produto-atualizar-model";
import { ProdutoMedicamentoPrecoFormModel } from "model/app/forms/produto/medicamento/medicamento-preco-form-model";
import { ProdutoPrincipalEditFormModel } from "model/app/forms/produto/produto-edit/produto-principal-edit-form-model";
import { ProdutoCodigoPreCadastroFormModel } from "model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-codigo-form-model";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCadastros, useSessaoAtual, useToastSaurus } from "services/app";
import { useContratoAtual } from "services/app/hooks/contrato-atual";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";
import { useShowAviso } from "services/app/hooks/show-aviso";
import { picker } from "utils/picker";
import { ProdutoSubItensRefs } from "../components/produto-subitens/produto-subitens";
import { MedicamentoPrecoModel } from "model/api/gestao/medicamento/medicamento-preco-model";
import { DefaultFormRefs } from "views/components/form/utils";
import { FormProdutoCodigoRefs } from "views/components/form/produto/form-produto-edit/produto-edit-codigos/form-produto-codigos-edit";
import { ProdutoResumoEditFormModel } from "model/app/forms/produto/produto-edit/produto-resumo-edit-form-model";
import { ProdutoPrincipalEditRefs } from "views/components/form/produto/form-produto-edit/produto-edit-principal/form-produto-principal-edit";
import { ProdutoCompletoModel } from "model/api/gestao/produto/produto/produto-completo-model";
import { ContratoConfigCompletoModel } from "model/api/gestao/master/contrato-completo-config";
import { guidEmpty } from "utils/guid-empty";
import { EnumContratoConfig } from "model/enums/enum-contrato-config";
import { MedicamentoEditFormModel } from "views/components/form/produto/form-produto-edit/medicameto-edit-principal/medicamento-edit-principal";
import { toDecimal, toDecimalString } from "utils/to-decimal";
import { PutConfigContratoProps } from "data/api/gestao/contrato/put-contrato-configuracao";
import { produtoPageNome } from "views/pages/private/cadastros/produto/produto-page";
import { NcmModel } from "model/api/gestao/ncm/ncm-model";
import { ProdutoVincularFiscalModel } from "model/api/gestao/produto/produto-imposto/produto-vincular-fiscal";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useLocation } from "react-router-dom";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { NcmMasterSummaryModel } from "model/api/ncm-master/ncm-master-model";
import { newGuid } from "utils/new-guid";
import { imagemForUpload } from "utils/imagem-for-upload";
import { EnumRetornoApiBase } from "data/api/base/api-base-response";
import { isPlanoFarmaceutico } from "utils/plano-utils";

interface ProdutoEditHookProps {
    id: string;
    empresaId: string;
    contratoId: string;
    tipo: EnumTipoProduto;
    handleAtualizarLista: (value: boolean) => void
    atualizarLista: boolean
}

export const useProdutoEdit = ({ ...props }: ProdutoEditHookProps) => {

    // REQUESTS
    const { getProdutoById, carregando: carregandoGetProduto } =
        useGetProdutoById();
    const { putProduto, carregando: carregandoPutProduto } = usePutProduto();
    const { putProdutoVariacao, carregando: carregandoPutVariacao } =
        usePutProdutoVariacao();
    const { postImagemBase64, carregando: carregandoPostImagemBase64 } =
        usePostImagemBase64();
    const { postProdutoImagem, carregando: carregandoPostProdutoImagem } =
        usePostProdutoImagem();
    const { putProdutoImagem, carregando: carregandoPutProdutoImagem } =
        usePutProdutoImagem();
    const { deleteProdutoImagem, carregando: carregandoDeleteProdutoImagem } =
        useDeleteProdutoImagem();
    const { deleteProdutoVariacao, carregando: carregandoDelete } =
        useDeleteProdutoVariacao();
    const { putVincularFiscal, carregando: carregandoProdutoVincular } =
        usePutProdutoVincularFiscal();
    const { getNcms, carregando: carregandoNCMS } = useGetNcms();
    const { postNcm, carregando: carregandoPost } = usePostNcm();
    const { getNcmsMaster, carregando: carregandoNCMMaster } = useGetNcmsMaster();
    const { getMedicamentoByCod, carregando: carregandoMedicamentoByCod } = useGetMedicamentoByCod();
    const { putMedicamento, carregando: carregandoPutMedicamento } = usePutMedicamento();
    const { getMedicamentoPrecoByMedId, carregando: carregandoMedPreco } = useGetMedicamentoPrecoByMedId();
    const { getMedicamentos, carregando: carregandoGetMedicamentos } = useGetMedicamentos();

    // HOOKS
    const { fecharCadastroProduto, abrirCadastroProduto } = useCadastros();
    const { getEmpresaAtual, carregando: carregandoEmpresaAtual } = useEmpresaAtual();
    const { getEmpresaSelecionada, plano } = useSessaoAtual();
    const location = useLocation();
    const confirm = useConfirm();
    const {
        getConfigCompleto,
        putContratoConfig,
        carregando: carregandoContrato
    } = useContratoAtual();
    const { showToast } = useToastSaurus();
    const { showAviso } = useShowAviso();
    const { callEvent, addHandler, removeHandler } = useEventTools()
    const isPlanoFarma = useMemo(() =>
        isPlanoFarmaceutico(plano?.plano)
        , [plano?.plano])

    // STATES
    const [preenchendoTela, setPreenchendoTela] = useState(true);
    const [abaSelecionada, setAbaSelecionada] = useState<number>(0);
    const [showOptions, setShowOptions] = useState(true);
    const [contratoCompleto, setContratoCompleto] =
        useState<ContratoConfigCompletoModel>(new ContratoConfigCompletoModel());
    const [produtoFormPrincipalState, setProdutoFormPrincipalState] =
        useState<ProdutoPrincipalEditFormModel>(
            new ProdutoPrincipalEditFormModel()
        );
    const [produtoFormResumoState, setProdutoFormResumoState] =
        useState<ProdutoResumoEditFormModel>(new ProdutoResumoEditFormModel());
    const [produtoCompleto, setProdutoCompleto] = useState<ProdutoCompletoModel>(
        new ProdutoCompletoModel()
    );
    const [changeTipo, setChangeTipo] = useState<boolean>(false);
    const [irParaTelaDeCodigoAnvisa, setIrParaTelaDeCodigoAnvisa] = useState<boolean>(false);


    //REFS
    const refEditPrincipalForm = useRef<ProdutoPrincipalEditRefs>(null);
    const refEditResumoForm = useRef<DefaultFormRefs<ProdutoResumoEditFormModel>>(null);
    const codigoProdutoRefs = useRef<FormProdutoCodigoRefs>(null);
    const refEditMedicamentoForm = useRef<DefaultFormRefs<MedicamentoModel>>(null);
    const refSubitens = useRef<ProdutoSubItensRefs>(null);
    const tituloRef = useRef<HTMLHeadingElement>(null);
    const medicamentoRef = useRef<MedicamentoModel | null>(null);
    const medicamentoPrecoRef = useRef<MedicamentoPrecoModel | null>(null);
    const refPreCadastroCodigo = useRef<DefaultFormRefs<ProdutoCodigoPreCadastroFormModel>>(null)
    const refProdutoModel = useRef<ProdutoAtualizarModel>(
        new ProdutoAtualizarModel()
    );
    const refProdutoVariacaoPadrao = useRef<ProdutoVariacaoModel>(
        new ProdutoVariacaoModel()
    );
    const refProdutoPrecoPadrao = useRef<ProdutoPrecoModel>(
        new ProdutoPrecoModel()
    );
    const refProdutoImagemPadrao = useRef<ProdutoImagemModel>(
        new ProdutoImagemModel()
    );
    const refProdutoCodigoPadrao = useRef<ProdutoCodigoModel>(
        new ProdutoCodigoModel()
    );
    const refProdutoCodigos = useRef<Array<ProdutoCodigoModel>>(
        new Array<ProdutoCodigoModel>()
    );
    const refProdutoImposto = useRef<ProdutoImpostoModel>(
        new ProdutoImpostoModel()
    );
    const refMedicamentoPrecoModelForm = useRef<ProdutoMedicamentoPrecoFormModel>(
        new ProdutoMedicamentoPrecoFormModel()
    );
    const refMedicamentoModelForm = useRef<MedicamentoModel>(
        new MedicamentoModel()
    );
    const refCodigoModelForm = useRef<ProdutoCodigoPreCadastroFormModel>(
        new ProdutoCodigoPreCadastroFormModel()
    );
    const refBuscouMedicamento = useRef<boolean>(false);
    const buscouProduto = useRef<boolean>(false);
    const medicamentoEncontrado = useRef<boolean>(false);
    const precoEncontrado = useRef<boolean>(false);
    const refProdutoSaldo = useRef<number>(0);
    const refNCMId = useRef<string>('');

    const isMedicamento = produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento;
    const produtoModelCompleto = refProdutoModel.current as ProdutoCompletoModel;

    const carregando =
        carregandoGetProduto ||
        preenchendoTela ||
        carregandoPutProduto ||
        carregandoPutVariacao ||
        carregandoPostImagemBase64 ||
        carregandoPostProdutoImagem ||
        carregandoPutProdutoImagem ||
        carregandoDeleteProdutoImagem ||
        carregandoNCMS ||
        carregandoPost ||
        carregandoNCMMaster ||
        carregandoProdutoVincular ||
        carregandoEmpresaAtual ||
        carregandoContrato ||
        carregandoDelete ||
        carregandoMedicamentoByCod ||
        carregandoPutMedicamento ||
        carregandoMedPreco ||
        carregandoGetMedicamentos;

    const atualizarContrato = useCallback((obj: any) => {
        setContratoCompleto(obj);
    }, []);

    const produtoServico =
        contratoCompleto &&
        contratoCompleto.configuracoes.find(
            (x) => x.cod === EnumContratoConfig.ProdutoServico
        );
    const produtoGenerico =
        contratoCompleto &&
        contratoCompleto.configuracoes.find(
            (x) => x.cod === EnumContratoConfig.ProdutoGenerico
        );
    const produtoEntrega = contratoCompleto.configuracoes.find(
        (x) => x.cod === EnumContratoConfig.ProdutoEntrega
    );


    // MARK: recarregarFormMedicamento
    const recarregarFormMedicamento = useCallback((model: MedicamentoEditFormModel) => {
        refEditMedicamentoForm.current?.fillForm(model);
    }, []);

    //MARK: recarregarFormPrincipal
    const recarregarFormPrincipal = useCallback(
        (model: ProdutoPrincipalEditFormModel) => {
            refEditPrincipalForm.current?.fillForm(model);
        },
        []
    );

    //MARK: recarregarFormResumo
    const recarregarFormResumo = useCallback(
        (model: ProdutoResumoEditFormModel) => {
            refEditResumoForm.current?.fillForm(model);
        },
        []
    );

    // MARK: putConfigContratoWrapper
    const putConfigContratoWrapper = useCallback(
        async (cod: number, value: string) => {
            try {
                const getConfig = getConfigCompleto()?.configuracoes.find(
                    (x) => x.cod === cod
                );

                if (isEmpty(getConfig)) {
                    throw new Error(
                        'Não foi possível atualizar... Tente novamente mais tarde.'
                    );
                }

                getConfig.vConfig = value;

                await putContratoConfig([
                    new PutConfigContratoProps(null, getConfig.cod, getConfig.vConfig)
                ]);

                showToast(
                    'success',
                    `${produtoPageNome(
                        produtoFormPrincipalState.tipo,
                        false
                    )} atualizado!`
                );
            } catch (err: any) {
                showToast('error', err.message);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getEmpresaAtual, getEmpresaSelecionada, putContratoConfig, showToast]
    );

    // MARK: getProdutoVariacaoWrapper
    const getProdutoVariacaoWrapper = useCallback(
        async (variacao: ProdutoVariacaoModel) => {

            refProdutoCodigos.current = variacao.codigos;

            const imagemPadrao = variacao.imagens.find((x) => x.padrao === true);
            if (imagemPadrao) refProdutoImagemPadrao.current = imagemPadrao;

            const precoPadrao = variacao.precos.find((x) => true);
            if (precoPadrao) refProdutoPrecoPadrao.current = precoPadrao;

            const codigoPadrao = variacao.codigos.find((x) => true);
            if (codigoPadrao) refProdutoCodigoPadrao.current = codigoPadrao;

            const imposto = variacao.impostos.find((x) => true);
            if (imposto) refProdutoImposto.current = imposto;

            const saldo = variacao.qSaldoTotal;
            if (saldo !== null && saldo !== undefined)
                refProdutoSaldo.current = saldo;

            refProdutoVariacaoPadrao.current = variacao;
        },
        []
    );

    // MARK: getProdutoByIdWrapper
    const getProdutoByIdWrapper = useCallback(async () => {
        const res = await getProdutoById(props.empresaId, props.id);
        if (res.erro) {
            throw res.erro;
        }
        const produtoData = res.resultado?.data as ProdutoCompletoModel;

        setProdutoCompleto(produtoData);

        refProdutoModel.current = produtoData;

        let produto = picker<ProdutoPrincipalEditFormModel>(
            produtoData,
            new ProdutoPrincipalEditFormModel()
        );

        const variacao = produtoData.grades[0]

        await getProdutoVariacaoWrapper(variacao);
        produto = picker<ProdutoPrincipalEditFormModel>(variacao, produto);
        try {
            produto.imagemUrl = atob(refProdutoImagemPadrao.current.imagemUrl);
        } catch {
            produto.imagemUrl = refProdutoImagemPadrao.current.imagemUrl;
        }
        produto.vPreco = toDecimalString(refProdutoPrecoPadrao.current.vPreco)
        produto.qSaldoTotal = refProdutoSaldo.current;
        produto.nomeCategoria = produtoData.categoria ?? '';
        produto.nomeSetor = produtoData.setor ?? '';
        produto.codigoNcm = produtoData.ncm ?? ''
        produto.marca = produtoData.marca ?? ''

        //se tiver imposto eu busco
        if (refProdutoImposto.current.grupoImpostoId) {
            //buscando a descrição do imposto caso o produto tenha um grupoImpostoId
            produto.imposto = refProdutoImposto.current.descricao;
            produto.impostoId = refProdutoImposto.current.grupoImpostoId ?? '';
        }

        produto.pLucro = toDecimalString(
            (toDecimal(produto.vPreco) / toDecimal(produto.vCompra)) * 100 - 100,
            2
        );

        return produto;
    }, [getProdutoById, getProdutoVariacaoWrapper, props.empresaId, props.id]);

    // MARK: getMedicamentoPrecoWrapper
    const getMedicamentoPrecoWrapper = useCallback(
        async (medicamentoId: string) => {
            try {
                const res = await getMedicamentoPrecoByMedId(medicamentoId);
                if (res.erro) throw res.erro

                if (isEmpty(res.resultado?.data.precos)) return

                const preco = res.resultado?.data.precos[0] as MedicamentoPrecoModel;

                medicamentoPrecoRef.current = preco;

            } catch (e: any) {
                showToast('error', e.message)
            }
        }, [getMedicamentoPrecoByMedId, showToast]
    )

    // MARK: getMedicamentoWrapper
    const getMedicamentoWrapper = useCallback(
        async (cod: string) => {
            try {
                const res = await getMedicamentoByCod(cod);

                if (res.erro) throw res.erro;

                if (res.resultado?.data.list.length < 1) {
                    throw new Error('Não foi encontrado um medicamento com código ANVISA cadastrado no produto.')
                }

                const ret = res.resultado?.data.list[0] as MedicamentoModel;

                medicamentoRef.current = ret;

                await getMedicamentoPrecoWrapper(ret.id);
            } catch (e: any) {
                showAviso('error', e.message, 5000, true, true);
            }
        },
        [getMedicamentoByCod, getMedicamentoPrecoWrapper, showAviso]
    );

    // MARK: preencherTela
    const preencherTela = useCallback(async () => {
        try {
            setPreenchendoTela(true);
            const produtoPrincipal = await getProdutoByIdWrapper();

            //CASO SEJA MEDICAMENTO, PEGAR INFOS DO MEDICAMENTO

            if (
                !refBuscouMedicamento.current &&
                produtoPrincipal.tipo === EnumTipoProduto.Medicamento &&
                produtoPrincipal.codigoAnvisa
            ) {
                await getMedicamentoWrapper(produtoPrincipal.codigoAnvisa);
                refBuscouMedicamento.current = true;
            }
            const produtoResumido = picker<ProdutoResumoEditFormModel>(
                produtoPrincipal,
                new ProdutoResumoEditFormModel()
            );
            if (medicamentoPrecoRef.current) {
                produtoPrincipal.precoFabricacao = medicamentoPrecoRef.current.precoFabricacao
                produtoPrincipal.precoMaximoConsumidor = medicamentoPrecoRef.current.precoMaximoConsumidor

                if (medicamentoRef.current) {
                }
            }
            setProdutoFormPrincipalState(produtoPrincipal);
            setProdutoFormResumoState(produtoResumido);

            if (
                produtoPrincipal.tipo === EnumTipoProduto.Medicamento &&
                medicamentoRef.current
            ) {
                recarregarFormMedicamento(new MedicamentoEditFormModel(medicamentoRef.current, medicamentoPrecoRef.current?.precoFabricacao, medicamentoPrecoRef.current?.precoMaximoConsumidor));
            }
            recarregarFormPrincipal(produtoPrincipal);

        } catch (e: any) {
            showToast('error', e.message);
        } finally {
            setPreenchendoTela(false);
        }
    }, [
        getMedicamentoWrapper,
        getProdutoByIdWrapper,
        recarregarFormMedicamento,
        recarregarFormPrincipal,
        showToast
    ]);

    // MARK: buscarMedicamento
    const buscarMedicamento = async (
        codigo: string,
        modelo: ProdutoCodigoPreCadastroFormModel,
    ) => {
        refCodigoModelForm.current = { ...refCodigoModelForm.current, codigo };
        if (codigo.length > 0) {
            setPreenchendoTela(true)
            //SETO ESSE BUSCOU PRODUTO PARA QUE A PESSOA INFORME O CÓDIGO DA ANVISA CASO NÃO IDENTIFIQUE

            const res = await getMedicamentos(`codigoAnvisa=${codigo}`);
            if (res.erro) throw res.erro
            const list = res.resultado?.data.list as MedicamentoModel[]

            if (list && list.length > 0) {
                const ret = picker<MedicamentoModel>(list[0], new MedicamentoModel());

                medicamentoEncontrado.current = true;

                const retPreco = await getMedicamentoPrecoByMedId(ret.id);

                if (
                    retPreco.erro ||
                    !retPreco.resultado?.data ||
                    retPreco.resultado.data.precos.length === 0
                ) {

                    precoEncontrado.current = false;
                    refMedicamentoPrecoModelForm.current =
                        new ProdutoMedicamentoPrecoFormModel();
                    throw retPreco.erro
                } else {
                    precoEncontrado.current = true;
                    const preco = retPreco.resultado.data
                        .precos[0] as MedicamentoPrecoModel;

                    refMedicamentoPrecoModelForm.current = {
                        ...refMedicamentoPrecoModelForm.current,
                        dataFinal: preco.dataFinal,
                        dataInicial: preco.dataInicial,
                        precoFabricacao: preco.precoFabricacao,
                        precoMaximoConsumidor: preco.precoMaximoConsumidor
                    };
                }

                refMedicamentoModelForm.current = { ...ret };
                modelo.codigo = ret.codigoAnvisa;
                return;
            }

            medicamentoEncontrado.current = false;
            precoEncontrado.current = false;
            setPreenchendoTela(false)
            throw new Error();

        }
    };

    // MARK: handleSubmitFormPreCadastroCodigo
    const handleSubmitFormPreCadastroCodigo = async (
        modelo: ProdutoCodigoPreCadastroFormModel
    ) => {
        try {
            ;
            if (!buscouProduto.current) {
                await buscarMedicamento(modelo.codigo, modelo);
            }

            await handleSubmit(
                {
                    ...produtoFormPrincipalState, tipo: EnumTipoProduto.Medicamento,
                    codigoAnvisa: refMedicamentoModelForm.current.codigoAnvisa,

                },

                produtoFormPrincipalState
            );
            setIrParaTelaDeCodigoAnvisa(false)
            setChangeTipo(false)
            buscouProduto.current = false;
            refCodigoModelForm.current = modelo;
            await preencherTela()

        } catch (e: any) {
            showToast('error', 'Medicamento não encontrado. Detalhes: ' + e.message)
            refPreCadastroCodigo.current?.fillForm(
                new ProdutoCodigoPreCadastroFormModel()
            );
            return;
        }
    }

    // MARK: onCloseClick
    const onCloseClick = useCallback(() => {
        const isFormOpen = codigoProdutoRefs.current?.isOpenned();
        const isEstoqueOpen = refEditPrincipalForm.current?.isAberto();
        const isSubitensOpen = refSubitens.current?.isOpenned();

        if (isFormOpen) {
            codigoProdutoRefs.current?.goBack();
            return;
        }
        if (isEstoqueOpen) {
            refEditPrincipalForm.current?.fecharEstoque();
            return;
        }
        if (isSubitensOpen) {
            refSubitens.current?.goBack();
            return;
        }
        if (irParaTelaDeCodigoAnvisa) {
            setIrParaTelaDeCodigoAnvisa(false);
            callEvent(AppEventEnum.AttTituloEdicaoProduto, { titulo: '' })
            return;
        }
        if (changeTipo) {
            setChangeTipo(false);
            return;
        }
        const url = location.pathname.split('/')[1];
        fecharCadastroProduto('/' + url, props.atualizarLista);
        props.handleAtualizarLista(false)
    }, [irParaTelaDeCodigoAnvisa, changeTipo, location.pathname, fecharCadastroProduto, props, callEvent]);

    // MARK: searchNcm
    const searchNcm = useCallback(
        async (modelo: ProdutoAtualizarModel) => {
            try {
                //primeiro vou buscar na minha base
                const retNcmGestao = await getNcms(
                    `Codigo=${modelo.codigoNcm}&TemFiltro=true`
                );
                if (retNcmGestao.erro) throw retNcmGestao.erro;
                if (retNcmGestao.resultado?.data.list.length > 0) {
                    const ncm = retNcmGestao.resultado?.data?.list[0] as NcmModel;
                    modelo.codigoNcm = ncm.codigo;
                    modelo.ncmId = ncm.id;
                    refNCMId.current = ncm.id;
                }
            } catch (e: any) { }
            //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado

            if (modelo.ncmId === guidEmpty()) {
                //entao busco na api de ncms master
                const retNcmGestao = await getNcmsMaster(modelo.codigoNcm);
                if (retNcmGestao.erro) throw retNcmGestao.erro;
                if (retNcmGestao.resultado?.data.list.length > 0) {
                    const ncm = retNcmGestao.resultado?.data
                        ?.list[0] as NcmMasterSummaryModel;
                    const ncmModel = new NcmModel(
                        guidEmpty(),
                        props.contratoId,
                        ncm.id,
                        ncm.codigo,
                        ncm.descricao,
                        ncm.cest
                    );
                    //cadastro em nossa base
                    const ret = await postNcm(ncmModel);

                    if (ret.erro) {
                        throw ret.erro;
                    }
                    const novoNcm = ret.resultado?.data as NcmModel;
                    modelo.codigoNcm = novoNcm.codigo;
                    modelo.ncmId = novoNcm.id;
                    refNCMId.current = novoNcm.id;
                }
            }
        },
        [getNcms, getNcmsMaster, postNcm, props.contratoId]
    );

    // MARK: saveChangesProduto
    const saveChangesProduto = useCallback(
        async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
            const produto = picker<ProdutoAtualizarModel>(
                produtoModelForm,
                refProdutoModel.current
            );
            produto.codigoNcm = produtoModelForm.codigoNcm;
            produto.setorId = produtoModelForm.setorId;
            produto.categoriaId = produtoModelForm.categoriaId;

            try {
                if (produtoModelForm.codigoNcm) {
                    await searchNcm(produto);
                }
                const ret = await putProduto(produto, props.empresaId);
                if (ret.erro) {
                    throw ret.erro;
                }

                const labelToast = produtoPageNome(
                    produtoModelForm.tipo,
                    false
                ).toLowerCase()

                showToast(
                    'success',
                    `${labelToast.charAt(0).toUpperCase() + labelToast.slice(1)} atualizado com Sucesso!`
                );
            } catch (e: any) {
                showToast('error', e.message);
                refEditPrincipalForm.current?.resetForm();
            }
        },
        [props.empresaId, putProduto, searchNcm, showToast]
    );

    // MARK: saveChangesProdutoVariacao
    const saveChangesProdutoVariacao = useCallback(
        async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
            /*
            estou fazendo isso senão ele mandava uns dados que nao precisava,
            aí dou um cast na referencia para produto ProdutoAtualizarVariacaoModel primeiro
          */
            const prodRef = picker<ProdutoAtualizarVariacaoModel>(
                refProdutoVariacaoPadrao.current,
                new ProdutoAtualizarVariacaoModel()
            );
            prodRef.tabelaPrecoId = refProdutoPrecoPadrao.current.tabelaPrecoId;
            const produto = picker<ProdutoAtualizarVariacaoModel>(
                produtoModelForm,
                prodRef
            );
            if (!produto.codigo) {
                produto.codigo = null;
            }
            try {
                const ret = await putProdutoVariacao(produto.produtoId, produto);

                if (ret.erro) {
                    throw ret.erro;
                }

                setProdutoFormPrincipalState(produtoModelForm);
                showToast(
                    'success',
                    `${produtoPageNome(
                        produtoModelForm.tipo,
                        false
                    )} atualizado com Sucesso!`
                );
            } catch (e: any) {
                showToast('error', e.message);
                refEditPrincipalForm.current?.resetForm();
            }
        },
        [putProdutoVariacao, showToast]
    );

    // MARK: sendImage
    const sendImage = useCallback(
        async (imagemUrl: string) => {
            let imagem = '';

            const imgUpload = imagemForUpload(imagemUrl);
            if (imgUpload.length > 0) {
                const retImagem = await postImagemBase64(
                    imgUpload,
                    `${produtoPageNome(produtoFormPrincipalState.tipo).toLowerCase()}/${props.contratoId
                    }/`,
                    newGuid()
                );
                if (retImagem.tipoRetorno !== EnumRetornoApiBase.Sucesso) {
                    throw new Error('Erro ao processar  a Imagem selecionada.');
                }
                if (retImagem.resultado?.data.data.status === 2) {
                    throw new Error(
                        'Erro ao processar a Imagem selecionada.Detalhe: ' +
                        retImagem.resultado?.data?.data?.retorno
                    );
                }
                imagem =
                    retImagem.resultado?.data?.data?.url_blob +
                    '?timestamp=' +
                    new Date().getTime();
            }

            return imagem.length > 0 ? btoa(imagem) : imagem;
        },
        [postImagemBase64, produtoFormPrincipalState.tipo, props.contratoId]
    );

    // MARK: saveChangesProdutoImagem
    const saveChangesProdutoImagem = useCallback(
        async (produtoModelForm: ProdutoPrincipalEditFormModel) => {
            const produtoImagem = picker<ProdutoImagemModel>(
                produtoModelForm,
                refProdutoImagemPadrao.current
            );

            let prodRef = picker<ProdutoAtualizarVariacaoModel>(
                refProdutoVariacaoPadrao.current,
                new ProdutoAtualizarVariacaoModel()
            );

            produtoImagem.produtoGradeId = prodRef.id;
            produtoImagem.produtoId = prodRef.produtoId;

            try {
                if (
                    refProdutoImagemPadrao.current.id === guidEmpty() &&
                    isEmpty(produtoImagem.imagemUrl)
                ) {
                    return;
                }

                produtoImagem.imagemUrl = await sendImage(produtoImagem.imagemUrl);

                const ret =
                    produtoImagem.id !== guidEmpty()
                        ? produtoImagem.imagemUrl.length > 0
                            ? await putProdutoImagem(
                                props.id,
                                refProdutoVariacaoPadrao.current.id,
                                produtoImagem
                            )
                            : await deleteProdutoImagem(
                                props.id,
                                refProdutoVariacaoPadrao.current.id,
                                produtoImagem.id
                            )
                        : await postProdutoImagem(
                            props.id,
                            refProdutoVariacaoPadrao.current.id,
                            produtoImagem
                        );

                if (ret.erro) {
                    throw ret.erro;
                }

                showToast('success', 'Imagem atualizada com sucesso!');
            } catch (e: any) {
                showToast(
                    'error',
                    `Não foi possível alterar a imagem do produto. Detalhe: ${e.message}`
                );
                refEditPrincipalForm.current?.resetForm();
            }
        },
        [
            deleteProdutoImagem,
            postProdutoImagem,
            props.id,
            putProdutoImagem,
            sendImage,
            showToast
        ]
    );


    // MARK: alterarProdutoFiscal
    const alterarProdutoFiscal = useCallback(
        async (
            informacaoFiscal: ProdutoVincularFiscalModel,
            empresaId: string,
            produtoId: string
        ): Promise<void> => {
            setPreenchendoTela(true)
            const res = await putVincularFiscal(
                informacaoFiscal,
                empresaId,
                produtoId
            );

            if (res.erro) throw res.erro;
        },
        [putVincularFiscal]
    );

    // MARK: submitMedicamento
    const submitMedicamento = async (model: MedicamentoModel) => {
        try {
            if (isEqual(model, medicamentoRef.current)) {
                return;
            } setPreenchendoTela(true)
            const res = await putMedicamento(model);
            if (res.erro) throw res.erro;
            props.handleAtualizarLista(true)

        } catch (e: any) {
            showToast(
                'error',
                `Não foi possível atualizar o Medicamento. Detalhe: ${e.message}`
            );
        }
    };

    // MARK: handleSubmit
    const handleSubmit = useCallback(
        async (
            model: ProdutoPrincipalEditFormModel,
            beforeModel: ProdutoPrincipalEditFormModel
        ) => {
            try {
                model = picker<ProdutoPrincipalEditFormModel>(
                    produtoFormResumoState,
                    model
                );
                //LEMBRAR ARRUMAR ESSA COISA
                const produtoEqual = isEqual(
                    picker<ProdutoAtualizarModel>(model, new ProdutoAtualizarModel()),
                    picker<ProdutoAtualizarModel>(
                        beforeModel,
                        new ProdutoAtualizarModel()
                    )
                );
                if (
                    (produtoServico?.vConfig === props.id ||
                        produtoGenerico?.vConfig === props.id) &&
                    [EnumTipoProduto.Insumo, EnumTipoProduto.Combo].includes(model.tipo)
                ) {
                    showToast(
                        'error',
                        'Produtos do tipo insumo ou combo não podem ser utilizados como produto genérico e nem produto de serviço (Taxa).'
                    );
                    return;
                }
                if (!produtoEqual) {
                    await saveChangesProduto({
                        ...model,
                        categoriaId:
                            model.categoriaId === guidEmpty() ? null : model.categoriaId
                    });
                }

                const produtoGradeEqual = isEqual(
                    picker<ProdutoAtualizarVariacaoModel>(
                        model,
                        new ProdutoAtualizarVariacaoModel()
                    ),
                    picker<ProdutoAtualizarVariacaoModel>(
                        beforeModel,
                        new ProdutoAtualizarVariacaoModel()
                    )
                );

                if (!produtoGradeEqual) {
                    await saveChangesProdutoVariacao(model);
                }

                const ncmVazio = refNCMId.current.length < 1 || isEmpty(model.ncmId)
                const produtoImposto =
                    model.imposto === beforeModel.imposto &&
                    model.impostoId === beforeModel.impostoId

                if (!produtoImposto && !ncmVazio) {

                    const attProdutoFiscal: ProdutoVincularFiscalModel = {
                        ...new ProdutoVincularFiscalModel(),
                        ncmId: refNCMId.current ?? model.ncmId ?? null,
                        grupoImpostoId: model.impostoId ?? '',
                        produtoGradeId: refProdutoVariacaoPadrao.current.id,
                    }

                    await alterarProdutoFiscal(
                        attProdutoFiscal,
                        props.empresaId,
                        props.id
                    );
                }

                const produtoImagemEqual = model.imagemUrl === beforeModel.imagemUrl;
                if (!produtoImagemEqual) {
                    await saveChangesProdutoImagem(model);
                }

                // if (model.tipo === EnumTipoProduto.Medicamento) {
                //   refEditMedicamentoForm.current?.submitForm();
                // } 
                if (
                    produtoEqual &&
                    produtoGradeEqual &&
                    produtoImagemEqual
                ) {
                    showToast('info', 'Nenhuma informação foi alterada');
                    return
                } else {
                    props.handleAtualizarLista(true)
                }

                refEditPrincipalForm.current?.fillForm(model);

            } catch (err: any) {
                showToast('error', err.message);
            } finally {
                setPreenchendoTela(false)
            }
        },
        [alterarProdutoFiscal, produtoFormResumoState, produtoGenerico?.vConfig, produtoServico?.vConfig, props, saveChangesProduto, saveChangesProdutoImagem, saveChangesProdutoVariacao, showToast]
    );

    // MARK: submitProduto
    const submitProduto = async (
        model: ProdutoPrincipalEditFormModel,
        beforeModel: ProdutoPrincipalEditFormModel
    ) => {
        handleSubmit(model, beforeModel);
    };

    // MARK: trocarTipoProduto
    const trocarTipoProduto = useCallback(
        (produtoId: string, tpProdutoAtual: number) => {
            showToast('info', 'Alteração desativada temporariamente.');
        },
        [showToast]
    );

    // MARK: handleSubmitResumo
    const handleSubmitResumo = useCallback(
        async (
            model: ProdutoResumoEditFormModel,
            beforeModel: ProdutoResumoEditFormModel
        ) => {
            const produtoEqual = isEqual(model, beforeModel);
            if (!produtoEqual) setProdutoFormResumoState(model);

            refEditPrincipalForm.current?.submitForm();
        },
        []
    );

    // MARK: changeStatus
    const changeStatus = useCallback(async () => {
        let produtoChangedStatus = { ...produtoFormPrincipalState };
        produtoChangedStatus.ativo = !produtoChangedStatus.ativo;
        handleSubmit(produtoChangedStatus, produtoFormPrincipalState);
    }, [handleSubmit, produtoFormPrincipalState]);

    // MARK: handleDelete
    const handleDelete = useCallback(async () => {
        try {
            const res = await deleteProdutoVariacao(
                getEmpresaAtual()?.id || '',
                refProdutoVariacaoPadrao.current.produtoId,
                refProdutoVariacaoPadrao.current.id
            );

            if (res.erro) throw res.erro;
            if (res.statusCode !== 204)
                throw new Error('Erro ao excluir produto. Tente Novamente');

            showToast('success', 'Produto Excluído com sucesso.');

            const url = location.pathname.split('/')[1];
            fecharCadastroProduto('/' + url, props.atualizarLista);
            props.handleAtualizarLista(true)
        } catch (e: any) {
            showToast('error', e.message);
        }
    }, [deleteProdutoVariacao, fecharCadastroProduto, getEmpresaAtual, location.pathname, props, showToast]);

    // MARK: excluirProduto
    const excluirProduto = useCallback(() => {
        confirm({
            title: 'Tem certeza?',
            description:
                'Deseja excluir este produto? Esta ação não poderá ser desfeita futuramente.',
            cancellationText: 'Cancelar',
            confirmationText: 'Excluir'
        }).then(() => {
            handleDelete();
        });
    }, [confirm, handleDelete]);

    // MARK: atualizarTitulo
    const atualizarTitulo = useCallback((obj: any) => {
        if (tituloRef.current) {
            if (!isEmpty(obj.titulo)) {
                tituloRef.current.innerText = obj.titulo;

                if (obj.titulo === 'Editar Código' || obj.titulo === 'Adicionar Código' || obj.titulo === 'Depósitos') {
                    setShowOptions(false)
                }
                return;
            }
            tituloRef.current.innerText = `Edição de ${produtoPageNome(
                props.tipo,
                false
            )}`;
            setShowOptions(true)
        }
    }, [props.tipo]);

    // MARK: atualizarVariacao
    const atualizarVariacao = useCallback(async () => {
        try {
            setPreenchendoTela(true);
            await getProdutoByIdWrapper();

            setProdutoFormPrincipalState((prev) => {
                return {
                    ...prev,
                    qSaldoTotal: refProdutoSaldo.current
                };
            });
            recarregarFormPrincipal({
                ...produtoFormPrincipalState,
                qSaldoTotal: refProdutoSaldo.current
            });
        } catch (e: any) {
            showToast('error', e.message);
        } finally {
            setPreenchendoTela(false);
        }
    }, [getProdutoByIdWrapper, produtoFormPrincipalState, recarregarFormPrincipal, showToast])

    // MARK: atualizarVariacao
    const alterarTipoProduto = useCallback(async (tipo) => {

        const prodAtualizado = { ...produtoFormPrincipalState };

        if (isPlanoFarma && tipo === EnumTipoProduto.Medicamento) {
            callEvent(AppEventEnum.AttTituloEdicaoProduto, { titulo: 'Conversão de Produto para Medicamento' })
            setIrParaTelaDeCodigoAnvisa(true)
            return
        }

        if (prodAtualizado.tipo === EnumTipoProduto.Medicamento && tipo !== EnumTipoProduto.Medicamento) {
            prodAtualizado.codigoAnvisa = '';
        }

        callEvent(AppEventEnum.AttTituloEdicaoProduto, {
            titulo: ''
        });
        setChangeTipo(false);
        await handleSubmit(
            {
                ...prodAtualizado,
                tipo
            },
            produtoFormPrincipalState
        );

        await preencherTela();
    }, [callEvent, handleSubmit, isPlanoFarma, preencherTela, produtoFormPrincipalState])

    useEffect(() => {
        atualizarContrato(getConfigCompleto());
        addHandler(AppEventEnum.ContratoAtualizado, atualizarContrato);
        return () =>
            removeHandler(AppEventEnum.ContratoAtualizado, atualizarContrato);
    }, [addHandler, atualizarContrato, getConfigCompleto, removeHandler]);

    useEffect(() => {
        recarregarFormResumo(produtoFormResumoState);
    }, [produtoFormResumoState, recarregarFormResumo]);

    useEffect(() => {
        (async () => {
            await preencherTela();
        })();
        return () => {
            //funcao de limpeza
            setProdutoFormPrincipalState(new ProdutoPrincipalEditFormModel());
        };
    }, [preencherTela, setProdutoFormPrincipalState]);

    useEffect(() => {
        addHandler(AppEventEnum.AttTituloEdicaoProduto, atualizarTitulo);
        return () => {
            removeHandler(AppEventEnum.AttTituloEdicaoProduto, atualizarTitulo);
        };
    }, [addHandler, atualizarTitulo, props.tipo, removeHandler, setShowOptions, tituloRef]);

    return {
        excluirProduto,
        handleSubmit,
        atualizarContrato,
        getConfigCompleto,
        changeStatus,
        submitProduto,
        trocarTipoProduto,
        handleSubmitResumo,
        submitMedicamento,
        handleSubmitFormPreCadastroCodigo,
        onCloseClick,
        atualizarTitulo,
        recarregarFormResumo,
        preencherTela,
        setProdutoFormPrincipalState,
        recarregarFormPrincipal,
        abrirCadastroProduto,
        putConfigContratoWrapper,
        atualizarVariacao,
        alterarTipoProduto,
        showToast,

        carregando,

        plano,
        produtoCompleto,
        produtoFormResumoState,
        refProdutoModel,
        produtoFormPrincipalState,
        produtoEntrega,
        produtoGenerico,
        produtoServico,
        refEditResumoForm,
        tituloRef,
        isMedicamento,
        refEditMedicamentoForm,
        refSubitens,
        refProdutoVariacaoPadrao,
        refProdutoSaldo,
        refPreCadastroCodigo,
        refEditPrincipalForm,
        codigoProdutoRefs,
        produtoModelCompleto,
        isPlanoFarma,

        // STATES
        changeTipo, setChangeTipo,
        irParaTelaDeCodigoAnvisa, setIrParaTelaDeCodigoAnvisa,
        abaSelecionada, setAbaSelecionada,
        showOptions, setShowOptions,
        preenchendoTela, setPreenchendoTela,
    }
}