import { Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-transferir-item-styles';
import { OkIcon, VoltarIcon } from '../../icons';
import { CircularLoading } from '../../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useHistory } from 'react-router-dom';
import { usePutPedidoProdutoTransferir } from 'data/api/gestao/pedido-produto/put-pedido-produto-transferir';
import { PedidoTransferirProduto } from 'model/api/gestao/pedido/pedido-transferir-produto';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { usePutPedidoProdutoAlterarVendedor } from 'data/api/gestao/pedido-produto/put-pedido-produto-alterar-vendedor';
import { PedidoAlterarVendedorProduto } from 'model/api/gestao/pedido/pedido-alterar-vendedor';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { EnumMesas } from 'model/enums/enum-mesas';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { TabelaSaloes } from 'database/interfaces/interface-tabela-saloes';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaMesas } from 'database/interfaces/interface-tabela-mesas';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { EnumStatusComanda } from 'model/enums/enum-status-comanda';

interface DialogTransferirItemProps {
  close: () => void;
  aberto: boolean;
  pedido: PedidoModel;
  idsProdutos: Array<string>;
  vendedor: PessoaModel | null;
  dialogTransferirCam: (valor: boolean) => void;
}

export const DialogTransferirItem = ({
  close,
  aberto,
  pedido,
  idsProdutos,
  vendedor,
  dialogTransferirCam
}: DialogTransferirItemProps) => {
  // STATES E REFS
  const [cod, setCod] = useState('');
  const [saloesList, setSaloesList] = useState<TabelaSaloes[]>([]);
  const [salaoId, setSalaoId] = useState<string | undefined>(undefined);

  // CHAMADAS API
  const { putPedidoProdutoTransferir, carregando: PutTransferirProdutos } =
    usePutPedidoProdutoTransferir();
  const {
    putPedidoProdutoAlterarVendedor,
    carregando: PutAlterarVendedorProdutos
  } = usePutPedidoProdutoAlterarVendedor();

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { get, getFirst } = useBancoLocal();

  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const submitRef = useRef<HTMLButtonElement>(null);
  const history = useHistory();
  const { redirectVisualizarPedido } = useMovRota();
  const carregando = PutTransferirProdutos || PutAlterarVendedorProdutos;
  const tipoTrabalho = getConfiguracoesMesaEComanda()?.tipoTrabalho

  const onCloseClick = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    const getSaloes = async () => {
      try {
        const res = await get<TabelaSaloes>({
          nomeTabela: EnumTableNames.SALOES,
        });

        const saloes = res.filter(x => x.status?.codigo === EnumStatusSalao.ATIVO)

        if (saloes.length > 1) {
          setSaloesList(saloes);
        } else if (saloes.length === 1) {
          setSaloesList(saloes)
          setSalaoId(saloes[0].id ?? undefined);
        }
      } catch (e: any) {
        showToast(
          'error',
          `Não foi possível buscar os salões, Detalhes: ${e.message}`
        );
      }
    };

    getSaloes();
  }, [get, showToast]);

  const handleClickTransferirItens = useCallback(
    async (
      codigo: string,
      idsProdutos: string[],
      pedido: PedidoModel,
      salaoId?: string
    ) => {
      try {
        let comanda = null;
        let mesa = null;

        // valido o tipo de trabalho
        if (
          tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          mesa = await getFirst<TabelaMesas>({
            nomeTabela: EnumTableNames.MESAS,
            where: ['codigo', 'salaoId'],
            value: [codigo, salaoId],
          });

          if (!mesa) {
            throw new Error(`Mesa com o código ${codigo} não encontrada.`);
          }

          if (mesa && mesa.status?.codigo !== EnumMesas.ATIVO) {
            throw new Error(
              `A mesa com o código ${codigo} está com status de INATIVA.`
            );
          }
        } else if (tipoTrabalho === EnumTipoTrabalho.COMANDA) {
          comanda = await getFirst<TabelaComandas>({
            nomeTabela: EnumTableNames.COMANDAS,
            where: 'codigoComanda',
            value: codigo,
          });

          if (!comanda) {
            throw new Error(`Comanda com o código ${codigo} não encontrada.`);
          }

          if (comanda && comanda?.status?.codigo !== EnumStatusComanda.ATIVO) {
            throw new Error(
              `Comanda com o código ${codigo} está com status de inativo.`
            );
          }
        }

        const produtosParaTransferencia = new PedidoTransferirProduto();
        produtosParaTransferencia.produtosIds = idsProdutos;


        if (
          tipoTrabalho === EnumTipoTrabalho.MESA
        ) {
          produtosParaTransferencia.destino.mesaId = mesa!.id;
        } else if (tipoTrabalho === EnumTipoTrabalho.COMANDA) {
          produtosParaTransferencia.destino.comandaId = comanda!.id;
        } else {
          if (pedido.identificador) {
            produtosParaTransferencia.destino.identificador = codigo
          }
        }

        const respostaPut = await putPedidoProdutoTransferir(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          produtosParaTransferencia
        );
        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast(
          'success',
          `Os produtos selecionados foram transferidos para ${tipoTrabalho ===
            EnumTipoTrabalho.MESA
            ? 'a mesa'
            : tipoTrabalho === EnumTipoTrabalho.COMANDA ? 'a comanda' : pedido.identificador ? 'o identificador' : ''
          } com o código: ${codigo} com sucesso.`
        );

        close()
        history.goBack()
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [close, getEmpresaSelecionada, getFirst, history, putPedidoProdutoTransferir, showToast, tipoTrabalho]
  );

  const handleClickAlterarVendedor = useCallback(async () => {
    try {
      if (!vendedor) {
        throw new Error('O vendedor não foi identificado.');
      }

      const produtosAlterarVendedor = new PedidoAlterarVendedorProduto(
        idsProdutos,
        vendedor.id,
        vendedor.nome
      );

      const respostaPut = await putPedidoProdutoAlterarVendedor(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        produtosAlterarVendedor
      );

      if (respostaPut.erro) {
        throw new Error(respostaPut.erro);
      }
      showToast(
        'success',
        `Os produtos selecionados foram transferidos para o ${vendedor.nome} com sucesso.`
      );
      redirectVisualizarPedido(pedido);
      close()

    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [vendedor, idsProdutos, putPedidoProdutoAlterarVendedor, getEmpresaSelecionada, pedido, showToast, redirectVisualizarPedido, close]);

  const buttonClick = useCallback(async () => {
    if (vendedor) {
      await handleClickAlterarVendedor();
      return
    } else {
      await handleClickTransferirItens(cod, idsProdutos, pedido, salaoId);
    }
  }, [vendedor, handleClickAlterarVendedor, handleClickTransferirItens, cod, idsProdutos, pedido, salaoId]);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      await buttonClick();
    },
    [buttonClick]
  );

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo={vendedor ? `Alterar Vendedor` : `Transferir Itens`}
      tamanho="xs"
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        {!vendedor && (
          <Typography className={classes.descricao}>
            {`Digite o código ${tipoTrabalho ===
              EnumTipoTrabalho.MESA
              ? 'da mesa'
              : tipoTrabalho === EnumTipoTrabalho.COMANDA
                ? 'da comanda'
                : pedido.identificador
                  ? 'do identificador'
                  : ''
              } para qual deseja transferir os itens.`}
          </Typography>
        )}

        {vendedor && (
          <Typography className={classes.descricao}>
            Ao clicar em confirmar, o vendedor:{' '}
            <strong style={{ color: '#666666' }}>{vendedor.nome}</strong> será
            relacionado aos produtos selecionados.
          </Typography>
        )}
        <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit}>
            {!vendedor && (
              <>
                {getConfiguracoesMesaEComanda()?.tipoTrabalho ===
                  EnumTipoTrabalho.MESA && saloesList.length > 1 && (
                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <SelectSaurus
                        label="Selecione o Salão"
                        conteudo={saloesList.map((valor: any) => {
                          return new KeyValueModel(
                            valor.id.toString(),
                            valor.descricao
                          );
                        })}
                        fullWidth={true}
                        value={salaoId}
                        variant="outlined"
                        disabled={saloesList.length <= 1}
                        onChange={(event) => {
                          if (event) {
                            setSalaoId(event.target.value);
                          }
                        }}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={carregando}
                    fullWidth
                    variant="outlined"
                    allowSubmit
                    label="Código"
                    InputLabelProps={{
                      shrink: true
                    }}
                    endAdornmentButton={
                      [EnumTipoTrabalho.COMANDA, EnumTipoTrabalho.MESA].includes(tipoTrabalho ?? EnumTipoTrabalho.BALCAO)
                        ? (
                          <BarcodeScanIcon
                            tipo="INPUT"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              dialogTransferirCam(true);
                              onCloseClick();
                            }}
                          />
                        ) : undefined
                    }
                    placeholder={[EnumTipoTrabalho.COMANDA, EnumTipoTrabalho.MESA].includes(tipoTrabalho ?? EnumTipoTrabalho.BALCAO) ? 'EX: 1' : "EX: gabriel"}
                    value={cod}
                    onChange={(e) => setCod(e.target.value)}
                  />
                </Grid>
              </>
            )}
            <Grid container className={classes.acoes} spacing={2}>
              <Grid item xs={5}>
                <Button
                  disabled={carregando}
                  onClick={onCloseClick}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  <VoltarIcon tipo="BUTTON" />
                  Voltar
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button
                  disabled={
                    carregando ||
                    (!vendedor && tipoTrabalho ===
                      EnumTipoTrabalho.MESA &&
                      !salaoId)
                  }
                  variant="contained"
                  ref={submitRef}
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
