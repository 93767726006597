import { guidEmpty } from 'utils/guid-empty';
import { useCallback, useRef } from 'react';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { newGuid } from 'utils/new-guid';
import { useMovProd } from './mov-prod';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { MovSimplesModificadoresProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-modificadores-produto-model';
import { EnumTpCalculoModificador } from 'model/enums/enum-tpcalculo-modificador';
import { roundTo } from 'utils/round-to';
import { usePromocoes } from './promocoes';
import { DescontoItemModel, VendaDescontoModel } from 'model/api/gestao/venda/venda-desconto-model';
import { useGestaoToken } from '../providers';
import { isEmpty } from 'lodash';
import { useBancoLocal } from './banco-local';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaModificadores } from 'database/interfaces/interface-tabela-modificadores';

const useSubItens = () => {
  // STATES E REFS
  const allProducts = useRef<MovSimplesProdutoModel[]>([]);
  const adicionais = useRef<MovSimplesProdutoModel[]>([]);

  // PROVIDERS
  const { retornaModelMovSimples } = useMovProd();
  const { retornarPromocaoDePor } = usePromocoes();
  const { getTokenFromStorage } = useGestaoToken();
  const { getFirst } = useBancoLocal();

  const getFullSubItens = useCallback(
    async (product: MovSimplesProdutoModel, idGroup: string) => {
      const { subItens } = product;
      let productsSubItens: MovSimplesProdutoModel[] = [];
      let productsModifier: MovSimplesModificadoresProdutoModel[] = [];
      let p: MovSimplesProdutoModel | undefined = undefined;

      if (subItens?.length && subItens.length > 0) {
        for (let item of subItens) {
          if (item.modificadorId) {

            const isModifier = await getFirst<TabelaModificadores>({
              nomeTabela: EnumTableNames.MODIFICADORES,
              where: 'id',
              value: item.modificadorId,
            })

            const newIdModificador = newGuid();

            if (isModifier) {
              const produtos: MovSimplesProdutoModel[] = [];
              for (let prod of isModifier.produtos) {
                const prodFromIndexDB = (await getFirst({
                  nomeTabela: EnumTableNames.PRODUTOS,
                  where: 'produtoGradeId',
                  value: prod.produtoGradeId,
                })) as unknown as ProdutoResumidoModel;
                if (!prodFromIndexDB) {
                  continue
                }
                const promo = retornarPromocaoDePor(prodFromIndexDB);
                let produto: MovSimplesProdutoModel | undefined =
                  await retornaModelMovSimples(prodFromIndexDB, promo, '');

                const isProductDefault =
                  item.produtoSubGradeId === produto.produtoGradeId;

                const valorProduto = prod.valor > 0 ? prod.valor : produto.vUnCom;

                produto = {
                  ...produto,
                  vUnComOrig: valorProduto,
                  vUnCom: valorProduto,
                  qCom: isProductDefault ? item.qPadrao : 0,
                  vProd: (isProductDefault ? item.qPadrao : 0) * valorProduto,
                  vFinal: (isProductDefault ? item.qPadrao : 0) * valorProduto,
                  id: newGuid(),
                  infoSubItem: { ...item, qPadrao: isProductDefault ? item.qPadrao : 0, modificadorTipoCalculo: isModifier.tpCalculo as EnumTpCalculoModificador },
                  idDoProdutoPaiInfoSubItem: product.id,
                  produtoPaiId: product.produtoId,
                  idGroup: idGroup,
                  nivel: product.nivel + 1,
                  tpProduto:
                    (isProductDefault ? item.qPadrao : 0) <= 0
                      ? EnumTpProduto.Opcional
                      : EnumTpProduto.Insumo,
                  modificadorId: isModifier.id,
                  indFin: isProductDefault && item.qPadrao > 0,
                  ordem: prod.ordem,
                  modificadorUnicoId: newIdModificador,
                };

                if (produto?.subItens && produto?.subItens?.length > 0 && produto.produtoId !== product.produtoId) {
                  p = await getFullSubItens(produto, idGroup);
                }

                allProducts.current = [...allProducts.current, p ?? produto];

                productsSubItens.push(p ?? produto);

                p = undefined;
                produto = undefined;
              }

              let m: MovSimplesModificadoresProdutoModel | undefined = {
                id: isModifier.id ?? '',
                empresaId: isModifier?.empresaId ?? '',
                contratoId: isModifier?.contratoId ?? '',
                descricao: isModifier?.descricao ?? '',
                nome: isModifier?.nome ?? '',
                fator: item.fator,
                qMax: item.qMax,
                qMin: item.qMin,
                qPadrao: item.qPadrao,
                produtoSubGradeIdPadrao: item.produtoSubGradeId,
                produtos: produtos,
                qAtual: item.qPadrao,
                tpCalculo: isModifier.tpCalculo,
                valorItemPadrao: 0,
                descontoPadrao: 0,
                unicoId: newIdModificador,
              };

              productsModifier.push(m);

              m = undefined;
            }
          } else if (item.produtoSubGradeId) {
            const prodFromIndexDB = (await getFirst({
              nomeTabela: EnumTableNames.PRODUTOS,
              where: 'produtoGradeId',
              value: item.produtoSubGradeId,
            })) as unknown as ProdutoResumidoModel;

            if (!prodFromIndexDB) {
              continue
            }

            const promo = retornarPromocaoDePor(prodFromIndexDB);

            let prod: MovSimplesProdutoModel | undefined =
              await retornaModelMovSimples(prodFromIndexDB, promo, '');

            const newId = newGuid();
            prod = {
              ...prod,
              vUnComOrig: prod.vUnCom,
              qCom: item.qPadrao,
              vProd: item.qPadrao * prod.vUnCom,
              vFinal: item.qPadrao * prod.vUnCom,
              id: newId,
              infoSubItem: { ...item, modificadorTipoCalculo: 0 as EnumTpCalculoModificador },
              idDoProdutoPaiInfoSubItem: product.id,
              produtoPaiId: product.produtoId,
              idGroup: idGroup,
              nivel: product.nivel + 1,
              tpProduto:
                item.qPadrao <= 0
                  ? EnumTpProduto.Opcional
                  : EnumTpProduto.Insumo,
            };

            if (prod?.subItens && prod?.subItens?.length > 0) {
              p = await getFullSubItens(prod, idGroup);
            }

            allProducts.current = [...allProducts.current, prod];
            productsSubItens.push(p ?? prod);
            p = undefined;
            prod = undefined;
          }
        }
      }

      const verificarItens = productsModifier.filter(m => !m.produtoSubGradeIdPadrao)

      const verificarDescontoModificador = verificarItens.map(m => {
        return productsSubItens.filter(p => p.modificadorId === m.id).reduce((atual, current) => {
          if (atual?.vUnCom > 0) {
            if (atual.vUnCom < current.vUnCom) {
              return atual
            } else {
              return current
            }
          }

          if (Object.keys(atual).length === 0) {
            return current
          }

          return atual;
        }, {} as MovSimplesProdutoModel);
      })

      const valuePai = product.vUnCom;
      const valueTotalSubItens = productsSubItens.filter(p => p.qCom > 0).reduce(
        (acc, cur) => acc + cur.vProd,
        0
      ) + verificarDescontoModificador.reduce(
        (acc, cur) => acc + cur.vUnCom,
        0
      )

      let descontoTotal = 0;

      if (valueTotalSubItens > valuePai && valuePai > 0) {
        descontoTotal = Number((roundTo(valueTotalSubItens - valuePai, 2)));
      }

      let produtosComDesconto = productsSubItens.map((item) => {


        const descontoNoMod = verificarDescontoModificador.find((p) => p.id === item.id)
        const descontoPonderadoDoProduto = () => {
          let valorDoItem: number = 0
          if (!isEmpty(descontoNoMod)) {
            valorDoItem = item.vUnCom
          } else {
            valorDoItem = item.vProd
          }
          const total = (valueTotalSubItens !== 0 && descontoTotal !== 0)
            ? (valorDoItem / valueTotalSubItens) * descontoTotal
            : 0;
          return roundTo(total, 3)
        }
          ;
        const descontoPonderado = descontoPonderadoDoProduto()

        let desc = !isEmpty(descontoNoMod)
          ? descontoPonderado
          : item.qCom === 0 || item.vFinal === 0
            ? 0
            : descontoPonderado

        const token = getTokenFromStorage()
        //Fiz essa validação pois mesmo sem desc estava setando um desconto no item com valores vazios. 
        if (desc > 0) {
          item.descontos.push({
            ...new VendaDescontoModel(),
            item: {
              ...new DescontoItemModel(),
              valorDesconto: desc,
              usuarioId: token?.usuarioId ?? '',
              usuarioNome: token?.saudacao ?? ''
            }
          })
        }
        const prod = {
          ...item,
          vDesc: desc
        };

        return prod;
      })

      const valorDosProdutosComDesconto = + roundTo(produtosComDesconto.filter(p => p.qCom > 0).reduce((acc, current) => acc + (current.vProd), 0), 2)

      if (valorDosProdutosComDesconto > product.vFinal) {
        const diferenca = +(roundTo(valorDosProdutosComDesconto - product.vFinal, 2));



        const produtoCommaiorpreco = produtosComDesconto.filter(p => p.qCom > 0).reduce((acc, current) => {
          if (acc?.qCom > 0) {
            if (acc.vFinal > current.vFinal) {
              return acc
            } else {
              return current
            }
          }

          if (Object.keys(acc).length === 0) {
            return current
          }

          return acc;
        }, {} as MovSimplesProdutoModel);

        produtosComDesconto = produtosComDesconto.map(p => {
          if (p.id === produtoCommaiorpreco.id) {
            const valorAtt = diferenca

            return { ...produtoCommaiorpreco, vDesc: valorAtt }
          }

          return p
        });

      }

      const produtoComSubItem = {
        ...product,
        idGroup: idGroup,
        modificadores: descontoTotal <= 0 ? productsModifier : productsModifier.map(m => {

          const produtosDoModificador = produtosComDesconto.filter(p => p.modificadorId === m.id && (p.qCom > 0 || verificarDescontoModificador.find(prod => prod.id === p.id)));

          if (produtosDoModificador.length > 0) {
            const descontoDoItemModificador = produtosDoModificador.reduce((acc, current) => acc + current.vDesc, 0);

            return {
              ...m,
              descontoPadrao: descontoDoItemModificador,
              valorItemPadrao: verificarDescontoModificador.find(prod => prod.modificadorId === m.id) ? verificarDescontoModificador.find(prod => prod.modificadorId === m.id)?.vUnCom ?? 0 : produtosDoModificador[0]?.vProd ?? 0
            }
          }

          return m
        }),
        prodSubItem:
          descontoTotal <= 0
            ? productsSubItens
            : produtosComDesconto
      };

      produtosComDesconto = [];

      return produtoComSubItem;
    },
    [getFirst, getTokenFromStorage, retornaModelMovSimples, retornarPromocaoDePor]
  );

  const getProductForEditingWithAdditional = useCallback(
    async (
      product: MovSimplesProdutoModel,
      idGroup: string,
      adicionais: MovSimplesProdutoModel[],
      quantidadePai: number
    ) => {
      const { prodSubItem } = product;
      let productsSubItens: MovSimplesProdutoModel[] = [];
      let p: MovSimplesProdutoModel | undefined = undefined;

      if (prodSubItem?.length && prodSubItem.length > 0) {
        for (let item of prodSubItem) {
          const temoAdicional = adicionais.find(
            (p) => p.idAdicional === item.id && p.idGroup === idGroup
          );

          const qtdAdicional = temoAdicional?.qCom ? temoAdicional.qCom : 0;

          const qtdPadao =
            item.qCom < item.infoSubItem!.qPadrao
              ? item.qCom
              : item.infoSubItem!.qPadrao;

          let prod = {
            ...item,
            qCom: qtdPadao + qtdAdicional,
            vProd: (qtdPadao + qtdAdicional) * item.vUnCom,
            vFinal: (qtdPadao + qtdAdicional) * item.vUnCom,
            id: item.id !== guidEmpty() ? item.id : newGuid(),
            infoSubItem: item.infoSubItem,
            idDoProdutoPaiInfoSubItem: product.id,
            produtoPaiId: product.produtoId,
            idGroup: idGroup,
            nivel: product.nivel + 1,
            qComModificador: temoAdicional?.qComModificador ?? 0
          };

          if (prod?.subItens && prod?.subItens?.length > 0) {
            p = await getProductForEditingWithAdditional(
              prod,
              idGroup,
              adicionais,
              quantidadePai
            );
          }

          allProducts.current = [...allProducts.current, prod];
          productsSubItens.push(p ?? prod);
          p = undefined;
        }
      }

      const valuePai = product.vProd;
      const valueTotalSubItens = productsSubItens.reduce(
        (acc, cur) => acc + cur.vProd,
        0
      );

      let descontoTotal = 0;

      if (valueTotalSubItens > valuePai) {
        descontoTotal = Number(roundTo(valueTotalSubItens - valuePai, 2));
      }

      const produtoComSubItem = {
        ...product,
        prodSubItem:
          descontoTotal <= 0
            ? productsSubItens
            : productsSubItens.map((item) => {
              const descontoPonderadoDoProduto = Number(
                roundTo((item.vProd / valueTotalSubItens) * descontoTotal, 2)
              );

              return {
                ...item,
                vDesc: descontoPonderadoDoProduto
              };
            })
      };

      return produtoComSubItem;
    },
    []
  );

  return {
    getFullSubItens,
    getProductForEditingWithAdditional,
    allProducts,
    adicionais
  };
};

export default useSubItens;
