import { Button, Tooltip, Typography } from "views/design-system";
import { isEmpty } from "lodash";
import { EditarIcon } from "views/components/icons";
import { useDefaultCardStyles } from "views/components/cards/components";
import { toDecimalString } from "utils/to-decimal";
import { useStyles } from "../../card-operadores-styles";
import { ImpressoraIcon } from "views/components/icons/impressora-icon";
import { CardOperadoresSituacaoProps } from "../../card-operadores";
import classNames from "classnames";

export const CardOperadoresDesktop = ({
    model,
    pontoDeVenda,
    dataAbertura,
    dataConferencia,
    dataFechamento,
    handleImpressaoClick
}: CardOperadoresSituacaoProps) => {

    const cardClasses = useDefaultCardStyles()
    const classes = useStyles()

    return (
        <>
            <div className={cardClasses.cardContent}>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Caixa
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {pontoDeVenda.numCaixa}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Data de abertura
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {dataAbertura}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Data de Fechamento
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {isEmpty(model?.dFechamento) ? '-' : dataFechamento}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Data de Conferência
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {isEmpty(model?.dConferencia) ? '-' : dataConferencia}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Vendas
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdVendas}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Itens
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdItens}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Cancelados
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdCancelamentos}
                    </Typography>
                </div>
                <div className={cardClasses.celulaGrid}>
                    <Typography variant="caption" color="textPrimary">
                        Valor Total
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {`R$ ${toDecimalString(model.valorVendido, 2)}`}
                    </Typography>
                </div>
            </div>
            <Tooltip arrow title="Imprimir Sessão">
                <div className={cardClasses.cardRightButton}>
                    <Button
                        variant="text"
                        size="small"
                        color="primary"
                        className={classes.buttons}
                        onClick={handleImpressaoClick}
                    >
                        <ImpressoraIcon tipo="BUTTON" />
                    </Button>
                </div>
            </Tooltip>
            <Tooltip arrow title="Editar Sessão">
                <div className={classNames(cardClasses.cardRightButton)}>
                    <EditarIcon tipo="BUTTON" />
                </div>
            </Tooltip>
        </>
    )
}