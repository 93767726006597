export enum EnumSincronizacao {
    PRODUTOS = 0,
    CLIENTES = 1,
    FINALIZADORAS = 2,
    CATEGORIAS = 3,
    NCMS = 4,
    IMPOSTOS = 5,
    MESAS = 6,
    COMANDAS = 7,
    SALOES = 8,
    MODIFICADORES = 9,
    MEDICAMENTOS = 10,
    PROMOCOES_DEPOR = 11,
    CONFIGURACOES = 12,

    //ENUMS DA SINCRONIZAÇÃO DE CARGA
    CARGA = 20,
    MARCA = 21,
    MEDIDAS = 22,
    CONFIGURACOES_PDV = 23,
    ENTIDADES_PROMOCOES = 24,
    REGRAS_PROMOCOES = 25,
    ENTIDADES_PRODUTOS = 26,
    ENTIDADES_PESSOAS = 27,
    ENTIDADES_CATEGORIAS = 28,
    ENTIDADES_MODIFICADORES = 29,
}