import { VariaveisAmbiente } from "config";
import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePutPedidoAlterarStatus() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPedidoAlterarStatus = useCallback(
        (empresaId: string, pedidoId: string, statusPedido: EnumStatusPedido) => {
            return invocarApi({
                url: `/v2/empresa/${empresaId}/pedido-salao/${pedidoId}/alterar-status/${statusPedido}`,
                method: "PUT",
                enviarTokenUsuario: true,
                baseURL: `${VariaveisAmbiente.apiUrlPedidos}/api`,
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        putPedidoAlterarStatus,
    };
}
