import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react"
import { TextFieldSaurus } from "views/components/controles/inputs"
import { useForm, Controller } from 'react-hook-form';
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { CircularLoading } from "views/components/utils";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { AppEventEnum } from "model/enums/enum-app-event";
import { validaEan } from "utils/valida-ean";
import { toDecimal } from 'utils/to-decimal';
import { DefaultFormProps, DefaultFormRefs } from "views/components/form/utils";
import { SelectSaurus } from "views/components/controles/selects/select-saurus/select-saurus";
import { KeyValueModel } from "model";
import { Grid } from "views/design-system";
import { useCadastros } from "services/app";

export enum EnumTpDescontoCodigo {
    NAO_ALTERA,
    PRECO_FIXO,
    DESCONTO
}

interface Props extends DefaultFormProps<ProdutoCodigoModel> {
    carregando: boolean
    edit: boolean;
}

export const FormCodigoEdit = forwardRef<DefaultFormRefs<ProdutoCodigoModel>, Props>
    (({ carregando = false, edit, ...props }: Props, ref) => {
        const [tipoPreco, setTipoPreco] = useState<EnumTpDescontoCodigo>(EnumTpDescontoCodigo.NAO_ALTERA);
        const { addHandler, removeHandler } = useEventTools()
        const { abrirCadastroBarCodeCam } = useCadastros()

        const [model, setModel] = useState<ProdutoCodigoModel>(new ProdutoCodigoModel());

        const {
            handleSubmit,
            control,
            setValue,
            getValues,
            reset,
            formState: { errors },
            setError,
        } = useForm<ProdutoCodigoModel>({
            defaultValues: { ...model },
            criteriaMode: 'all',
            mode: 'onChange',
        });

        useEffect(() => {
            if (model.precoFixo !== null && model.precoFixo !== 0) {
                setTipoPreco(1)
            }
            if (model.percTabela !== null && model.percTabela !== 0) {
                setTipoPreco(2)
            }
        }, [getValues, model.percTabela, model.precoFixo])

        const onSubmit = useCallback(async (values: ProdutoCodigoModel) => {
            const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
            if (regexCaracEspeciais.test(values.codigo)) {
                setError('codigo', { type: "error", message: 'Não pode conter caracteres especiais.' })
                return
            }
            const strNum = String(values.precoFixo).replace(/[^0-9]/g, '')

            values.precoFixo = strNum.length > 2 ? (Number(strNum) / 100) : Number(strNum)
            if (values.percTabela && values.percTabela > 0) {
                values.percTabela = -Math.abs(values.percTabela)
            }

            if (!validaEan(values.codigo)) {
                values.isEanTrib = false
            }
            if (values.fator) {
                values.fator = toDecimal(values.fator)
            }

            props.onSubmit(values);
        }, [props, setError]);

        const pegarValorCam = useCallback((valor: string) => {
            setValue('codigo', valor)
        }, [setValue])

        useEffect(() => {
            addHandler(AppEventEnum.SetValueCam, pegarValorCam)

            return () => {
                removeHandler(AppEventEnum.SetValueCam, pegarValorCam)
            }
        }, [addHandler, pegarValorCam, removeHandler])

        const validarCodigo = useCallback((ev: any) => {
            const nome: string = ev.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

            const regexCaracEspeciais = /[^a-zA-Z0-9À-ÖØ-öø-ÿ\s,.\\-]/
            if (regexCaracEspeciais.test(nome)) {
                setError('codigo', { type: "error", message: 'Não pode conter caracteres especiais.' })
            }

            setValue('codigo', nome)
        }, [setError, setValue]);


        useImperativeHandle(ref, () => ({
            fillForm: (model) => {
                reset({ ...model })
                if (model) {
                    setModel({ ...model });
                }
            },
            resetForm: () => { },
            submitForm: async () => {
                await handleSubmit(onSubmit)();
            },
        }))

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                {carregando && (
                    <CircularLoading tipo="FULLSIZED" />
                )}
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            name="codigo"
                            render={({ field }) => (
                                <TextFieldSaurus
                                    {...field}
                                    showCamBarcode
                                    handleShowBarCam={() => abrirCadastroBarCodeCam()}
                                    autoComplete='off'
                                    error={Boolean(
                                        errors.codigo && errors.codigo.message,
                                    )}
                                    helperText={
                                        errors.codigo
                                            ? errors.codigo?.message
                                            : undefined
                                    }
                                    label="Código"
                                    onBlur={validarCodigo}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            control={control}
                            name="fator"
                            render={({ field }) => (
                                <TextFieldSaurus
                                    tipo='DECIMAL'
                                    manterMascara
                                    {...field}
                                    autoComplete='off'
                                    error={Boolean(
                                        errors.fator && errors.fator.message,
                                    )}
                                    helperText={
                                        errors.fator
                                            ? errors.fator?.message
                                            : undefined
                                    }
                                    label="Fator"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectSaurus
                            conteudo={[
                                new KeyValueModel(EnumTpDescontoCodigo.NAO_ALTERA, 'Preço de Cadastro'),
                                new KeyValueModel(EnumTpDescontoCodigo.PRECO_FIXO, 'Preço Fixo'),
                                new KeyValueModel(EnumTpDescontoCodigo.DESCONTO, '% de Desconto'),
                            ]}
                            label='Tipo de Desconto'
                            value={tipoPreco}
                            onChange={(e) => {
                                const value = Number(e.target.value) as EnumTpDescontoCodigo;
                                setTipoPreco(value)
                                switch (value) {
                                    case EnumTpDescontoCodigo.NAO_ALTERA:
                                        setValue('precoFixo', 0)
                                        setValue('percTabela', 0)
                                        break;
                                    case EnumTpDescontoCodigo.DESCONTO:
                                        setValue('percTabela', 0)
                                        break;
                                    default:
                                        setValue('precoFixo', 0)
                                }
                            }}
                        />
                    </Grid>
                    {tipoPreco !== EnumTpDescontoCodigo.NAO_ALTERA && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name={tipoPreco === 2 ? "percTabela" : "precoFixo"}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        autoComplete='new-password'
                                        tipo={tipoPreco === EnumTpDescontoCodigo.DESCONTO ? "NUMERO" : "DECIMAL"}
                                        label={tipoPreco === EnumTpDescontoCodigo.DESCONTO ? "% Desconto" : "Preço Fixo"}
                                        casasDecimais={tipoPreco === EnumTpDescontoCodigo.DESCONTO ? 0 : 2}
                                        {...field}
                                        error={Boolean(
                                            errors.percTabela || errors.precoFixo,
                                        )}
                                        placeholder="Adicione um valor"
                                        helperText={
                                            errors.percTabela
                                                ? errors.percTabela?.message
                                                : undefined
                                        }
                                    />

                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </form >
        )
    })