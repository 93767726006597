import { useCallback } from "react";
import { useApiBase } from "../../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetProdutoVariacaoDepositoById() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getProdutoVariacaoDepositoById = useCallback(
    (produtoId: string, variacaoId: string, depositoId: string) =>
      invocarApi({
        url: `/${VariaveisAmbiente.gatewayVersion}/produto/${produtoId}/variacao/${variacaoId}/deposito/${depositoId}`,
        method: "GET",
        enviarTokenUsuario: true,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getProdutoVariacaoDepositoById,
  };
}
