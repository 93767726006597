import { Dialog, Fade } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { forwardRef, useCallback } from 'react';
import Scanner from 'views/pages/private/movimentacao/mov-prod-leitor/components/scanner/scanner';
import { useStyles } from './dialog-barcode-cam-comanda-styles';
import CloseIcon from '@material-ui/icons/Close';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoDadosMesa } from 'data/api/gestao/pedido-dados/put-pedido-dados-mesas';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CircularLoading } from 'views/components/utils';
import { Button, Typography } from 'views/design-system';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { EnumTableNames } from 'database/touchone-carga-database';
import { EnumStatusComanda } from 'model/enums/enum-status-comanda';

interface DialogBarCodeProps {
  openned?: boolean;
  mesaId: string;
  tamanho?: DialogProps['maxWidth'];
  zIndex?: string;
}

export const DialogBarCodeCamComanda = ({
  mesaId,
  ...props
}: DialogBarCodeProps) => {
  const classes = useStyles();

  const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Fade timeout={500} ref={ref} {...props} />;
  });

  const { callEvent } = useEventTools();
  const { getPedidoSalao, carregando: carregandoGet } = useGetPedidoSalao();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { putPedidoDadosMesa, carregando: carregandoPut } =
    usePutPedidoDadosMesa();
  const { showToast } = useToastSaurus();
  const { abrirVincularComandaDialog, fecharDialogBarCodeCamComanda } = useCadastros()
  const { getFirst } = useBancoLocal();

  const carregando = carregandoGet || carregandoPut;

  const handleConfirme = useCallback(
    async (codigo: string) => {
      try {
        if (codigo.length > 3) {
          codigo = codigo.slice(codigo.length - 3, codigo.length)

          if (codigo[0] === '0' && codigo[1] === '0') {
            codigo = codigo.slice(codigo.length - 1, codigo.length)
          }
          else if (codigo[0] === '0') {
            codigo = codigo.slice(codigo.length - 2, codigo.length)
          }
        } else {
          if (codigo[0] === '0' && codigo[1] === '0') {
            codigo = codigo.slice(codigo.length - 1, codigo.length)
          }
          else if (codigo[0] === '0') {
            codigo = codigo.slice(codigo.length - 2, codigo.length)
          }
        }

        const comanda = await getFirst<TabelaComandas>({
          nomeTabela: EnumTableNames.COMANDAS,
          where: 'codigoComanda',
          value: codigo,
        })

        if (!comanda) {
          throw new Error(`Comanda com o codigo ${codigo} não encontrada.`);
        }

        if (comanda && comanda.status?.codigo !== EnumStatusComanda.ATIVO) {
          throw new Error(
            `Comanda com o codigo ${codigo} está com status de Inativa.`,
          );
        }

        let query = `comandaId=${comanda.id}`;

        const responsePedidos = await getPedidoSalao(
          getEmpresaSelecionada()!.Id,
          query,
        );

        if (responsePedidos.erro) {
          throw new Error(responsePedidos.erro);
        }

        const pedidos =
          (responsePedidos?.resultado?.data.list as PedidoModel[]) ?? [];

        if (pedidos.length === 0) {
          throw new Error(
            `Nenhum pedido foi localizado com a comanda ${codigo}.`,
          );
        }

        const respostaPut = await putPedidoDadosMesa(
          getEmpresaSelecionada()?.Id ?? '',
          pedidos[0].id,
          mesaId,
        );

        if (respostaPut.erro) {
          throw new Error(respostaPut.erro);
        }

        showToast('success', `A comanda ${codigo} foi vinculada com sucesso`);
        fecharDialogBarCodeCamComanda();
        callEvent(AppEventEnum.ComandaVinculada, true);
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [callEvent, fecharDialogBarCodeCamComanda, getEmpresaSelecionada, getFirst, getPedidoSalao, mesaId, putPedidoDadosMesa, showToast],
  );

  return (
    <Dialog
      maxWidth={props.tamanho || 'md'}
      open={props.openned || false}
      fullWidth
      keepMounted
      className={classes.root}
      disableEscapeKeyDown
      TransitionComponent={Transition}
      ref={(el: any) => {
        if (el && (props.zIndex || '').length > 0) {
          el.style.setProperty('z-index', props.zIndex, 'important');
        }
      }}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <div style={{ padding: 8 }}>
        <Typography
          style={{
            textAlign: 'center',
            margin: '0 0 24px 0',
            fontWeight: '600',
            background: 'rgb(0 0 0 / 50%)',
            padding: 12,
            color: '#FFF',
            borderRadius: 8,
          }}
        >
          Posicione o código de barras na marcação
        </Typography>
        <div style={{ borderRadius: 8, overflowY: 'hidden' }}>
          {props.openned && (
            <Scanner onScan={handleConfirme} inicialOrSecundario={false} />
          )}
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            padding: '8px 0',
          }}
        >
          <Button
            variant="text"
            style={{
              fontWeight: '600',
              background: 'rgb(0 0 0 / 50%)',
              color: '#FFF',
            }}
            onClick={() => {
              abrirVincularComandaDialog(mesaId);
              fecharDialogBarCodeCamComanda();
            }}
          >
            Digite o código da comanda
          </Button>
        </div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <CloseIcon
            onClick={() => {
              fecharDialogBarCodeCamComanda();
            }}
            style={{ fontSize: 50, marginTop: 16, cursor: 'pointer' }}
          />
        </div>
      </div>
    </Dialog>
  );
};
