import { Grid, Typography } from 'views/design-system';
import { useStyles } from './mov-finalizar-page-styles';
import { useThemeQueries } from 'views';
import { useCallback, useEffect, useState } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { EnumMovModelo } from 'model';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useHistory } from 'react-router-dom';
import { DialogNotaFiscal } from 'views/components/dialog/dialog-nota-fiscal/dialog-nota-fiscal';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { guidEmpty } from 'utils/guid-empty';

const MovFinalizarPage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { getMov } = useMovAtual();
  const [dialogNF, setDialogNF] = useState<boolean>(false);
  const { showToast } = useToastSaurus();
  const { finalizarMov } = useMovAtual();
  const history = useHistory();
  const { abrirSolicitarPermissao, fecharSolicitarPermissao } = useCadastros();
  const { getPermissaoBoolean } = useSessaoAtual();

  const [mov, setMov] = useState(getMov());
  const [statusAtualMov, setStatusAtualMov] = useState(
    mov?.retornoFinalizacao?.status,
  );

  const { addHandler, removeHandler, callEvent } = useEventTools();
  const { redirectVendaFinalizadaComprovante, avancarFluxoMov, redirectCarrinho } = useMovRota();

  useEffect(() => {
    //SE NAO TEM MOV, ELA JA FOI FINALIZADA PROVAVELMENTE
    if (!mov) {
      redirectVendaFinalizadaComprovante().catch(() => { });
    }

    //SE FOR PEDIDO, REDIRECIONA PARA O CARRINHO
    if (mov?.mod === EnumMovModelo.PEDIDO || mov?.mod === EnumMovModelo.DELIVERY) {
      avancarFluxoMov().catch(() => { });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalizarVenda = useCallback(
    async (mod: number | null = null, checked: boolean = false) => {
      try {
        if (
          !getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_VENDAS) &&
          !checked
        ) {
          abrirSolicitarPermissao(
            async () => {
              fecharSolicitarPermissao();
              setTimeout(async () => {
                await finalizarVenda(mod, true);
              }, 300)
            },
            EnumCodigosPermissoes.REALIZAR_VENDAS,
            'Realizar Venda',
            undefined,
            undefined,
            async () => {
              try {
                await redirectCarrinho();
              } catch (e: any) {
                showToast('error', e.message);
              }
            }
          );
          return;
        }
        const res = await finalizarMov(mod);
        // se o retorno for sucesso redireciona para comprovante
        if (res.error) {
          if ((res.error?.message?.length || 0) < 200) {
            if (res.error.message !== 'Cliente não identificado!') {
              showToast('error', res.error, 3000);
            }
          }

          if (res.url.length > 0) {
            return history.push(`${res.url}`);
          }
        }

        return history.push({
          pathname: `${res.url}`
        });

      } catch (e: any) {
        showToast(
          'error',
          e.message ? e.message : e,
          3000
        );
      }
    },
    [abrirSolicitarPermissao, fecharSolicitarPermissao, finalizarMov, getPermissaoBoolean, history, redirectCarrinho, showToast]
  );


  const movAlterada = useCallback((novaMov: MovSimplesModel) => {
    setMov((previous) => {
      if (previous?.id !== novaMov?.id && previous?.id != null && previous?.id !== guidEmpty()) return previous;
      return novaMov;
    });

    setStatusAtualMov((previous) => {
      if ((novaMov?.retornoFinalizacao?.status || '') === '') return previous;
      if ((novaMov?.retornoFinalizacao?.status || '') !== (previous || '')) {
        return novaMov?.retornoFinalizacao?.status;
      }
      return previous;
    });
  }, []);

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    return () => {
      removeHandler(AppEventEnum.MovAtualAlterada, movAlterada);
    };
  }, [addHandler, movAlterada, removeHandler]);

  const isVendaFiscal =
    mov?.mod === EnumMovModelo.NFCE ||
    mov?.mod === EnumMovModelo.NFE ||
    mov?.mod === EnumMovModelo.SAT;
  const nnf = mov?.nnf || 0;
  const serie = mov?.serie || 0;
  const vNF = mov?.vNF || 0;
  const selecionarMod = useCallback(
    async (mod: number) => {
      setDialogNF(false);
      await finalizarVenda(mod);
    },
    [finalizarVenda],
  );

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);

  //EFFECT INICIAL
  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);
    setHeaderProps({
      title: 'Finalizando Venda',
    })

    finalizarVenda(getMov()?.mod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        {!dialogNF && (
          <Grid className={classes.content}>
            <Grid
              className={
                isMobile ? classes.infoContainer : classes.infoContainerPC
              }
            >
              <Typography className={classes.textTitle}>
                {
                  (statusAtualMov || '') === 'salvo'
                    ? !isVendaFiscal
                      ? 'Finalizando Venda'
                      : 'Emitindo Nota Fiscal'
                    : 'Processando Venda'}
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 32,
                }}
              >
                <LoadingFinalizacao />
              </Grid>

              <Grid
                className={
                  !isMobile ? classes.infoCardContent : classes.cardInfo
                }
              >
                <Grid container justifyContent="space-between">
                  <Typography className={classes.label}>
                    Número / Série
                  </Typography>
                  <Typography className={classes.label}>Valor</Typography>
                </Grid>
                <Grid
                  container
                  style={{ marginBottom: 16 }}
                  justifyContent="space-between"
                >
                  <Typography className={classes.infoTotal}>
                    {nnf !== 0 && serie !== 0
                      ? nnf + ' / ' + serie
                      : 'Gerando...'}
                  </Typography>
                  <Typography className={classes.infoTotal}>
                    R$ {toDecimalString(vNF)}
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="subtitle1" className={classes.textSubtitle}>
                {statusAtualMov === 'emitindo' && isVendaFiscal
                  ? isVendaFiscal
                    ? 'Aguarde mais alguns instantes enquanto emitimos a nota fiscal. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'
                    : 'Aguarde mais alguns instantes enquanto finalizamos a venda. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'
                  : 'Aguarde mais alguns instantes enquanto salvamos a venda. Não realize nenhum procedimento no sistema enquanto realizamos esta operação.'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DialogNotaFiscal
        openned={dialogNF}
        onClickNF={async () => await selecionarMod(65)}
        onClickManual={async () => await selecionarMod(1)}
      />
    </>
  );
};

export default MovFinalizarPage;
