import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";

export function useGetHistoricoVendaCompleta() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getHistoricoVendaCompleta = useCallback(
        (id: string) => {
            return invocarApi({
                url: `api/v3/Movimento/${id}/completo`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: VariaveisAmbiente.apiUrlMovs
            });
        },
        [invocarApi]
    );
    return {
        ...outrasPropriedades,
        getHistoricoVendaCompleta,
    };
}
