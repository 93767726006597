import { EnumStatusSituacaoPedido } from './../../../enums/enum-status-situacao-pedido';
import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { EnumTipoPedido } from "model/enums/enum-tipo-pedido";
import { guidEmpty } from "utils/guid-empty";
import { PedidoClienteModel } from "./pedido-cliente-model";
import { PedidoDadosIntegracaoModel } from "./pedido-integracao-model";
import { PedidoPagamentoModel } from "./pedido-pagamento-model";
import { PedidoProdutoModel } from "./pedido-produto-model";
import { ReceitaMedicaModel } from '../venda/venda-completa-model';
import { PedidoEnderecoEntregaModel } from './pedido-model';

class TipoPedido {
    constructor(
        public codigo: EnumTipoPedido = EnumTipoPedido.LOCAL,
        public descricao: string = 'Local'
    ) { }
}

class StatusPedido {
    constructor(
        public codigo: EnumStatusPedido = EnumStatusPedido.CONFIRMADO,
        public descricao: string = 'Confirmado'
    ) { }
}

class StatusSituacao {
    constructor(
        public codigo: EnumStatusSituacaoPedido = EnumStatusSituacaoPedido.ABERTO,
        public descricao: string = 'Aberto'
    ) { }
}

export class PedidoModelComanda {
    constructor(
        public id: string = guidEmpty(),
        public dataCriacao: string | Date = '',
        public systemUpdateDate: string | Date = '',
        public dataAgendamentoFinal: string | Date = '',
        public dataAgendamentoInicial: string | Date = '',
        public mesaId: string = guidEmpty(),
        public salaoId: string = guidEmpty(),
        public comandaId: string = guidEmpty(),
        public tipoPedido: TipoPedido = new TipoPedido(),
        public statusPedido: StatusPedido = new StatusPedido(),
        public statusAutomacao: StatusSituacao = new StatusSituacao(),
        public codigoPedido: string = '',
        public codigoReferencia: string = '',
        public posicaoMesa: string = '',
        public mesaCodigo: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public informacaoAdicional: string = '',
        public aplicarDescontoEcommerce: boolean = false,
        public valorTroco: number = 0,
        public taxaServico: number = 0,
        public taxaDeServico: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalAcrescimo: number = 0,
        public valorTotalAcrescimoEmbutido: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalDescontoEmbutido: number = 0,
        public valorTotalFrete: number = 0,
        public descontoEcommerce: number = 0,
        public cliente: PedidoClienteModel = new PedidoClienteModel(),
        public produtos: PedidoProdutoModel[] = [],
        public pagamentos: PedidoPagamentoModel[] = [],
        public dadosIntegracao: PedidoDadosIntegracaoModel = new PedidoDadosIntegracaoModel(),
        public enderecoEntrega: PedidoEnderecoEntregaModel = new PedidoEnderecoEntregaModel(),
        public identificador: string = '',

        //uso essa prop pra saber se é um pedido que pertence há uma comanda/mesa que foi reconfigurada
        public isDeprecated: boolean = false,

        // prop para saber se está ocioso
        public isOciosa: boolean = false,
        public valorTotalPedido: number = 0,
        public codigoComanda: string = '',
        public nomeSalao: string = '',
        public salao?: string,
        public receitasMedicas: ReceitaMedicaModel[] = []
    ) { }
}
