import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";

export function useGetHistoricoVendasPendentes() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { getEmpresaAtual } = useEmpresaAtual()

    const getHistoricoVendasPendentes = useCallback(
        (queryBusca: string, page?: number) => {
            let queryPage = "";
            if (page) {
                queryPage = "page=" + page;
            }
            let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
                }${queryPage}${queryPage.length > 0 ? "&" : ""}${queryBusca}`;

            return invocarApi({
                url: `api/v3/empresa/${getEmpresaAtual()?.id ?? ''}/Movimento/pendencias${queryFinal}`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: VariaveisAmbiente.apiUrlMovs
            });
        },
        [getEmpresaAtual, invocarApi]
    );
    return {
        ...outrasPropriedades,
        getHistoricoVendasPendentes,
    };
}
