import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { CargaModel } from "model/api/gestao/carga/carga-model";

export function useGetCargaTouchone() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getCargaTouchone = useCallback(
    (chavePDV: string, dtBase?: string) => {
      return invocarApi<CargaModel>({
        url: `/v1/Carga/touchone/${chavePDV}/json${dtBase ? `?dtBase=${dtBase}` : ''} `,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiCarga}/api`,
        timeout: 200000,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getCargaTouchone,
  };
}