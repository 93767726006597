import { usePostEquipamentoImpressaoLocal } from 'data/api/gestao/equipamento/post-equipamento-impressao-local';
import {
  ImpressaoLocalModel,
  Equipamento,
  DadosImpressao,
  DadosImpressaoAPI
} from 'model/api/gestao/impressora/impressao-local-model';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import React from 'react';
import { usePDV } from './pdv';
import { useToastSaurus } from './toast-saurus';
import { useSessaoAtual } from '../providers';
import { picker } from 'utils/picker';
import { newGuid } from 'utils/new-guid';
import { usePostEquipamentoImpressao } from 'data/api/gestao/equipamento/post-equipamento-impressao';

export interface ImpressaoLocalProps {
  externalId: string
  externalCode: string
  tipo: string
  qtdColunas?: number | null
}

export interface EnviarImpressaoLocalProps extends ImpressaoLocalProps {
  cupomTraduzido: string | string[]
}

export interface ImprimirPorStringProps extends ImpressaoLocalProps {
  cupomTraduzido: string
}

export const useImpressaoLocal = () => {
  // HOOKS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getImpressoraPdv } = usePDV();
  const { showToast } = useToastSaurus();
  const { postEquipamentoImpressao } = usePostEquipamentoImpressao();
  // CALL API
  const {
    postEquipamentoImpressaoLocal,
    carregando: carregandoImpressaoLocal
  } = usePostEquipamentoImpressaoLocal();

  const impressaoLocalDataWrapper = (
    externalId: string,
    externalCode: string,
    conteudoImpressao: string,
    tipoImpressao: string,
    impressaoModel: ImpressoraModel
  ) => {

    const equipamento = picker<Equipamento>(impressaoModel, new Equipamento());

    const impressao = new DadosImpressao(
      newGuid(),
      equipamento.id,
      conteudoImpressao,
      tipoImpressao,
      externalCode,
      externalId,
      equipamento.quantidadeVias
    );

    if (tipoImpressao === 'Gaveta') {
      impressao.impressao = '<gaveta>'
    }

    const modelImpressor = new ImpressaoLocalModel(
      impressao,
      equipamento
    );

    return modelImpressor;
  };

  const imprimirCupomTraduzido = React.useCallback(async ({ cupomTraduzido, externalCode, externalId, tipo, qtdColunas }: ImprimirPorStringProps) => {

    const impressora = getImpressoraPdv();

    if (!impressora) {
      return;
    }

    const modelImpressor = impressaoLocalDataWrapper(
      externalId,
      externalCode,
      cupomTraduzido,
      tipo,
      { ...impressora, colunas: qtdColunas ? qtdColunas : impressora.colunas },
    );

    const res = await postEquipamentoImpressaoLocal(
      getEmpresaSelecionada()?.Id ?? '',
      modelImpressor
    );

    if (res.erro && res.statusCode > 0) {
      throw new Error(res.erro);
    }

    if (res.erro && res.statusCode === 0) {
      const resImp = await postEquipamentoImpressao(
        new DadosImpressaoAPI(newGuid(), cupomTraduzido, false, externalId,
          externalCode, impressora.quantidadeVias ?? 1, impressora.id
        ), getEmpresaSelecionada()?.Id ?? ''
      );
      if (resImp.erro) {
        throw new Error(resImp.erro);
      }
    }
  }, [getEmpresaSelecionada, getImpressoraPdv, postEquipamentoImpressao, postEquipamentoImpressaoLocal])

  const enviarImpressaoLocal = React.useCallback(
    async ({ cupomTraduzido, ...props }: EnviarImpressaoLocalProps) => {
      if (Array.isArray(cupomTraduzido)) {
        for (let impressao of cupomTraduzido)
          await imprimirCupomTraduzido({ ...props, cupomTraduzido: impressao })
      } else {
        await imprimirCupomTraduzido({ ...props, cupomTraduzido })
      }
      showToast('success', 'Impressão enviada');
    },
    [imprimirCupomTraduzido, showToast]
  );

  return {
    carregandoImpressaoLocal,
    enviarImpressaoLocal,
  };
};
