import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MesasListData } from './components/list/mesa-list/mesas-list-data';
import { useHistory } from 'react-router';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { CircularLoading } from 'views';
import { useStyles } from './components/list/list-styles';
import { ComandasListData } from './components/list/comanda-list/comandas-list-data';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import { BalcaoListData } from './components/list/balcao-list/balcao-list-data';
import { PedidoMesaProcessadaModel } from 'model/api/gestao/pedido/pedido-mesa-processada';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import FooterMesaList from './components/footer/footer-mesa/footer-mesa-list';
import FooterComandaList from './components/footer/footer-comanda/footer-comanda-list';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { FiltroMesa } from 'model/api/gestao/mesa/mesa-filtro';
import { FiltroComanda } from 'model/api/gestao/pedido/pedido-comanda-filtro';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { usePutPedidoStatus } from 'data/api/gestao/pedido-dados/put-pedido-status';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { isEmpty } from 'lodash';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { BalcaoIcon } from 'views/components/icons/balcao-icon';
import { ComandasIcon } from 'views/components/icons/comanda-icon';
import { DeliveryIcon } from 'views/components/icons/delivery-icon';
import { DeliveryListData } from './components/list/delivery-list/delivery-list-data';
import { isPlanoDeliveryProprio, isPlanoEntregaDelivery, isPlanoFarmaceutico } from 'utils/plano-utils';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { Grid } from 'views/design-system';
import { getModoPedidoIdentificador } from 'config/environment-variables';
import { EnumConfigIdentificadorMesa } from 'model/enums/enum-config-identificador-mesa';

interface AtendimentoTabsProps {
  salaoAtualId: string;
  saloes: SaloesModel[];
  temMesaEmAlgumSalao: boolean;
  pesquisaPedido: string;
}

export const AtendimentoTabs = ({
  salaoAtualId,
  saloes,
  temMesaEmAlgumSalao,
  pesquisaPedido,
  ...props
}: AtendimentoTabsProps) => {

  // PROVIDERS
  const {
    getMesaComandaEPedidos,
    getBalcoesEPedidos,
    getComandasEPedidos,
    getConfiguracoesMesaEComanda,
    getPedidoDelivery,
    carregando: carregandoHook
  } = usePedidoLocal();
  const {
    getPessoa,
    getEmpresaSelecionada,
    plano
  } = useSessaoAtual();
  const { replace, location } = useHistory();
  const { transformarPedidosEmVendaSimples } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = usePDV();
  const { abrirImportarPedidorDialog } = useCadastros();
  const planoDeliveryProprio = isPlanoDeliveryProprio(plano?.plano)
  const planoEntregaDelivery = isPlanoEntregaDelivery(plano?.plano)
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)

  const hasPlanoDelivery = planoDeliveryProprio || planoEntregaDelivery

  // CHAMADAS API
  const { putPedidoStatus, carregando: carregandoPutPedidoStatus } =
    usePutPedidoStatus();

  // rotas
  const rotas = useMemo(
    () => [
      MovRotasMock.pedidosMesas,
      MovRotasMock.pedidosBalcao,
      MovRotasMock.pedidosComandas,
      MovRotasMock.pedidosDeliveryRota
    ],
    []
  );

  const retornaAba = useCallback(
    (path: string) => {
      const rotaAtual = rotas.indexOf(path);
      if (rotaAtual > -1) {
        return rotaAtual;
      }
      const temBalcao = saloes.find(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA);
      if (temBalcao) {
        return 1
      }

      if (
        getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.MESA &&
        (getConfiguracoesMesaEComanda()?.qtdeMesa || 0) > 0
      ) {
        return 0;
      }

      if (
        getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
        (getConfiguracoesMesaEComanda()?.qtdeComandas || 0) > 0
      ) {
        return 2;
      }

      return 1;
    },
    [getConfiguracoesMesaEComanda, rotas, saloes]
  );

  // STATES E REFS
  const pedidoParaImportar = useRef<PedidoModel | undefined>(undefined);
  const [temMesas, setTemMesas] = useState<boolean>(false);
  const buscouMesa = useRef<boolean>(false);
  const [mesasEPedidos, setMesasEPedidos] = useState<
    PedidoMesaProcessadaModel[]
  >([]);
  const [balcoesEPedidos, setBalcoesEPedidos] = useState<PedidoModelComanda[]>(
    []
  );
  const [pedidosDelivery, setPedidosDelivery] = useState<PedidoModel[]>([])
  const [comandasEPedidos, setComandasEPedidos] = useState<
    PedidoModelComanda[]
  >([]);

  const [abaSelecionada, setAbaSelecionada] = useState(
    retornaAba(location.pathname)
  );

  // STATES DE FILTRO MESA
  const [filtroMesa, setFiltroMesa] = useState<FiltroMesa>({
    semFiltro: true,
    meusAtendimentos: false,
    masasVazias: false,
    mesasOciosas: false
  });

  // STATES DE FILTRO COMANDA e BALCAO
  const [filtroComanda, setFiltroComanda] = useState<FiltroComanda>({
    semFiltro: true,
    meusAtendimentos: false,
    comandasOciosas: false
  });

  const [filtroComandaBalcao, setFiltroComandaBalcao] = useState<FiltroComanda>(
    {
      semFiltro: true,
      meusAtendimentos: false,
      comandasOciosas: false
    }
  );

  // AUX
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const carregando = carregandoHook || carregandoPutPedidoStatus;
  const { push: historyPush } = useHistory();
  const modeloTrabalho = getConfigByCod(101);

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS,
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const importarPedido = useCallback(
    async (pedido: PedidoModel) => {
      try {
        if (pedido.statusPedido.codigo === EnumStatusPedido.IMPORTADO) {
          throw new Error(`O pedido já foi importado.`);
        }

        const respostaPutStatus = await putPedidoStatus(
          getEmpresaSelecionada()?.Id ?? '',
          pedido.id,
          EnumStatusPedido.IMPORTADO
        );

        if (respostaPutStatus.erro) {
          throw new Error(
            `Erro ao alterar status do pedido para Importado. Detalhe: ${respostaPutStatus.erro}`
          );
        }

        await transformarPedidosEmVendaSimples([pedido]);
      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [
      getEmpresaSelecionada,
      putPedidoStatus,
      showToast,
      transformarPedidosEmVendaSimples
    ]
  );

  // Mesas
  const handleMesas = useCallback(async () => {
    try {
      if (salaoAtualId === '') return;

      const responseMesa = await getMesaComandaEPedidos(salaoAtualId);

      setMesasEPedidos(responseMesa ?? []);
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getMesaComandaEPedidos, salaoAtualId, showToast]);

  const handleDeliverys = useCallback(async () => {
    try {
      if (salaoAtualId === '') return;
      const response = await getPedidoDelivery();
      setPedidosDelivery(response ?? []);
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getPedidoDelivery, salaoAtualId, showToast]);

  const onClickCardMesa = useCallback((mesa: PedidoMesaProcessadaModel) => {
    const tipoTrabalho = getConfiguracoesMesaEComanda()?.tipoTrabalho;
    if (
      tipoTrabalho === EnumTipoTrabalho.COMANDA ||
      (tipoTrabalho === EnumTipoTrabalho.MESA && getModoPedidoIdentificador() === EnumConfigIdentificadorMesa.PEDIR_IDENTIFICADOR)
    ) {
      return historyPush({
        pathname: `${MovRotasMock.mesaEComandaRota}/${mesa.idMesa}`,
        state: { mesa },
        search: '?modo=mesa'
      });
    }
    return historyPush({
      pathname: `/venda-simples/visualizar-pedido/${mesa.idMesa}`,
      state: { mesa },
      search: '?modo=mesa'
    });
  }, [getConfiguracoesMesaEComanda, historyPush]);

  const onHoldedCard = useCallback((pedido: PedidoModel) => {
    pedidoParaImportar.current = pedido;
    if (isSomentePedido) return;
    abrirImportarPedidorDialog(pedidoParaImportar.current, importarPedido, AppEventEnum.RecarregarPedidos);
  }, [abrirImportarPedidorDialog, importarPedido, isSomentePedido]);

  const temMesa = useCallback(async () => {

    const db = getConfiguracoesMesaEComanda()?.qtdeMesa ?? 0;
    setTemMesas(db > 1);
  }, [getConfiguracoesMesaEComanda]);

  const mesasEPedidosFiltrados = useCallback(() => {
    let mesasFiltradas: PedidoMesaProcessadaModel[] = mesasEPedidos;

    if (filtroMesa.mesasOciosas) {
      return mesasFiltradas.filter(
        (pedido) => pedido.ociosa === filtroMesa.mesasOciosas
      );
    }

    if (filtroMesa.masasVazias) {
      return mesasFiltradas.filter((pedido) => pedido.pedidos.length === 0);
    }

    if (filtroMesa.meusAtendimentos) {
      return mesasFiltradas.filter((mesa) => {
        const pedidosNaMesa = mesa.pedidos;

        const meuPedidoNaMesa = pedidosNaMesa.filter((pedido) =>
          pedido.produtos.find(
            (produto) => produto.vendedorId === getPessoa()?.pessoa?.id
          )
        );

        if (meuPedidoNaMesa.length > 0) {
          return mesa;
        }
        return false;
      });
    }

    return mesasFiltradas;
  }, [
    filtroMesa.masasVazias,
    filtroMesa.mesasOciosas,
    filtroMesa.meusAtendimentos,
    getPessoa,
    mesasEPedidos
  ]);

  // Balcoes
  const handleBalcoes = useCallback(async () => {
    try {
      if (salaoAtualId === '') return;
      const responsebalcao = await getBalcoesEPedidos(salaoAtualId);
      setBalcoesEPedidos(responsebalcao);
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [salaoAtualId, getBalcoesEPedidos, showToast]);

  const onCardBalcaoSelected = useCallback((balcao: PedidoModelComanda) => {
    if (isEmpty(balcao.comandaId)) {
      return historyPush({
        pathname: `/venda-simples/visualizar-pedido/${balcao.id}`,
        state: { balcao },
        search: '?modo=balcao'
      })
    }
    return historyPush({
      pathname: `/venda-simples/visualizar-pedido/${balcao.comandaId}`,
      state: { balcao },
      search: '?modo=comanda'
    });
  }, [historyPush]);

  const balcoesEPedidosFiltrados = useCallback(() => {
    let comandasFiltradas: PedidoModelComanda[] = balcoesEPedidos;
    if (filtroComandaBalcao.comandasOciosas) {
      return comandasFiltradas.filter(
        (pedido) => pedido.isOciosa === filtroComandaBalcao.comandasOciosas
      );
    }

    if (filtroComandaBalcao.meusAtendimentos) {
      return comandasFiltradas.filter((pedido) => {
        const tenhoProdutoNoPedido = pedido.produtos.find(
          (produto) => produto.vendedorId === getPessoa()?.pessoa?.id
        );

        if (tenhoProdutoNoPedido) {
          return pedido;
        }
        return false;
      });
    }

    return comandasFiltradas;
  }, [
    balcoesEPedidos,
    filtroComandaBalcao.comandasOciosas,
    filtroComandaBalcao.meusAtendimentos,
    getPessoa
  ]);

  // Comandas
  const handleComandas = useCallback(async () => {
    try {
      if (salaoAtualId === '') return;
      const responseComandas = await getComandasEPedidos(salaoAtualId);
      setComandasEPedidos(responseComandas);
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [getComandasEPedidos, salaoAtualId, showToast]);

  const onCardComandaSelected = useCallback((comanda: PedidoModelComanda) => {
    return historyPush({
      pathname: `/venda-simples/visualizar-pedido/${comanda.comandaId}`,
      state: { comanda },
      search: '?modo=comanda'
    });
  }, [historyPush]);

  const onCardDeliverySelected = useCallback((pedido: PedidoModel) => {
    return historyPush({
      pathname: `/venda-simples/visualizar-pedido/${pedido.id}`,
      state: { pedido }
    });
  }, [historyPush]);

  const comandasEPedidosFiltrados = useCallback(() => {
    let comandasFiltradas: PedidoModelComanda[] = comandasEPedidos;

    if (filtroComanda.comandasOciosas) {
      return comandasFiltradas.filter(
        (pedido) => pedido.isOciosa === filtroComanda.comandasOciosas
      );
    }

    if (filtroComanda.meusAtendimentos) {
      return comandasFiltradas.filter((pedido) => {
        const tenhoProdutoNoPedido = pedido.produtos.find(
          (produto) => produto.vendedorId === getPessoa()?.pessoa?.id
        );

        if (tenhoProdutoNoPedido) {
          return pedido;
        }
        return false;
      });
    }

    return comandasFiltradas;
  }, [
    comandasEPedidos,
    filtroComanda.comandasOciosas,
    filtroComanda.meusAtendimentos,
    getPessoa
  ]);

  const tabChange = (index: number) => {
    replace(rotas[index]);
  };

  const atualizarStatus = useCallback(
    (idPedido: string) => {
      const aba = retornaAba(location.pathname);

      if (aba === 0) {
        setMesasEPedidos((prev) => {
          return prev.map((mesa) => {
            return {
              ...mesa,
              pedidos: mesa.pedidos.map((pedido) => {
                if (pedido.id === idPedido) {
                  return {
                    ...pedido,
                    statusPedido: {
                      codigo: EnumStatusPedido.IMPORTADO,
                      descricao: 'Importado'
                    }
                  };
                }
                return pedido;
              })
            };
          });
        });
      }

      if (aba === 1) {
        setBalcoesEPedidos((prev) =>
          prev.map((comanda) => {
            if (comanda.id === idPedido) {
              return {
                ...comanda,
                statusPedido: {
                  codigo: EnumStatusPedido.IMPORTADO,
                  descricao: 'Importado'
                }
              };
            }
            return comanda;
          })
        );
      }

      if (aba === 2) {
        setComandasEPedidos((prev) =>
          prev.map((comanda) => {
            if (comanda.id === idPedido) {
              return {
                ...comanda,
                statusPedido: {
                  codigo: EnumStatusPedido.IMPORTADO,
                  descricao: 'Importado'
                }
              };
            }
            return comanda;
          })
        );
      }
    },
    [location.pathname, retornaAba]
  );

  useEffect(() => {
    addHandler(AppEventEnum.RecarregarPedidos, atualizarStatus);
    return () => {
      removeHandler(AppEventEnum.RecarregarPedidos, atualizarStatus);
    };
  }, [addHandler, removeHandler, atualizarStatus]);

  useEffect(() => {
    const aba = retornaAba(location.pathname);
    setAbaSelecionada(aba);
  }, [location.pathname, retornaAba]);

  useEffect(() => {
    const handleSelectedAba = async () => {
      if (!buscouMesa.current) {
        await temMesa();
        buscouMesa.current = true;
      }

      if (abaSelecionada === 0) {
        await handleMesas();
      }
      if (abaSelecionada === 1) {
        await handleBalcoes();
      }
      if (abaSelecionada === 2) {
        await handleComandas();
      }
      if (abaSelecionada === 3) {
        await handleDeliverys();
      }
    };

    handleSelectedAba();
  }, [abaSelecionada, handleBalcoes, handleComandas, handleDeliverys, handleMesas, temMesa]);

  const tabsSaurusLabel = useMemo(() => {
    let tabs: TabSaurusLabel[] = []
    const temBalcao = saloes.find(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA);

    const tabBalcao = (label?: string) => new TabSaurusLabel(label ?? 'Balcão ', 1, <BalcaoIcon tipo='INPUT' class={classes.icon} />)
    const tabDelivery = () => new TabSaurusLabel('Delivery ', 3, <DeliveryIcon tipo='INPUT' class={classes.icon} />)
    const tabComanda = () => new TabSaurusLabel('Comandas ', 2, <ComandasIcon tipo='INPUT' class={classes.icon} />)
    const tabMesa = () => new TabSaurusLabel('Mesas ', 0, <MesaIcon tipo='INPUT' class={classes.icon} />)

    if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA) {
      if (isFarmaceutico) {
        if (temBalcao) {
          tabs.push(tabBalcao())
        }
        if (hasPlanoDelivery) {
          if (!temBalcao) {
            tabs.push(tabBalcao('Pedidos'))
          }
          tabs.push(tabDelivery())
        }
        return [
          ...tabs,
          tabComanda()
        ]
      } else {
        tabs.push(tabComanda())
      }
    }

    if (temMesas) {
      tabs.push(tabMesa())
    }
    let incluiuBalcao = false;

    const pedirIdentificadoMesa = getModoPedidoIdentificador() === EnumConfigIdentificadorMesa.PEDIR_IDENTIFICADOR;

    if (temBalcao) {
      tabs.push(tabBalcao(
        (pedirIdentificadoMesa && !temMesas) ?
          'Pedidos' : 'Balcão'
      ))
    } else if (pedirIdentificadoMesa) {
      tabs.push(tabBalcao('Pedidos'))
      incluiuBalcao = true;
    }

    if (hasPlanoDelivery) {
      if (!temBalcao && !incluiuBalcao) {
        tabs.push(tabBalcao('Pedidos'))
      }
      tabs.push(tabDelivery())
    }

    return tabs.sort((a, b) => a.index - b.index)
  }, [classes.icon, getConfiguracoesMesaEComanda, hasPlanoDelivery, isFarmaceutico, saloes, temMesas])

  const tabsSaurusContent = useCallback(() => {
    let tabs: TabSaurusContent[] = [new TabSaurusContent(
      0,
      (
        <div className={classes.defaultContainer}>
          <Grid className={classes.containerListCard}>
            <MesasListData
              carregando={carregando}
              list={mesasEPedidosFiltrados()}
              onCardSelected={onClickCardMesa}
              onCardHolded={onHoldedCard}
              salaoAtualId={salaoAtualId}
              saloes={saloes}
            />
          </Grid>
        </div>
      )
    ),
    new TabSaurusContent(
      1,
      (
        <div className={classes.defaultContainer}>
          <Grid className={classes.containerListCard}>
            <BalcaoListData
              carregando={carregando}
              list={balcoesEPedidosFiltrados()}
              onCardSelected={onCardBalcaoSelected}
              salaoAtualId={salaoAtualId}
              saloes={saloes}
              onCardHolded={onHoldedCard}
            />
          </Grid>
        </div>
      )
    ),
    new TabSaurusContent(
      2,
      (
        <div className={classes.defaultContainer}>
          <Grid className={classes.containerListCard}>
            <ComandasListData
              carregando={carregando}
              comandas={comandasEPedidosFiltrados()}
              onCardSelected={onCardComandaSelected}
              salaoAtualId={salaoAtualId}
              saloes={saloes}
              temMesaEmAlgumSalao={temMesaEmAlgumSalao}
              onCardHolded={onHoldedCard}
            />
          </Grid>
        </div>
      )
    )]
    if (hasPlanoDelivery) {
      tabs.push(
        new TabSaurusContent(
          3,
          (
            <div className={classes.defaultContainer}>
              <Grid className={classes.containerListCard}>
                <DeliveryListData
                  carregando={carregando}
                  pedidos={pedidosDelivery}
                  onCardSelected={onCardDeliverySelected}
                  onCardHolded={onHoldedCard}
                  atualizarLista={handleDeliverys}
                  pesquisaPedido={pesquisaPedido}
                />
              </Grid>
            </div>
          )
        ))
    }

    return tabs
  }, [balcoesEPedidosFiltrados, carregando, classes.containerListCard, classes.defaultContainer, comandasEPedidosFiltrados, handleDeliverys, hasPlanoDelivery, mesasEPedidosFiltrados, onCardBalcaoSelected, onCardComandaSelected, onCardDeliverySelected, onClickCardMesa, onHoldedCard, pedidosDelivery, pesquisaPedido, salaoAtualId, saloes, temMesaEmAlgumSalao])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid item xs={12} className={classes.listContainer}>
        <TabsSaurus
          selectedTabIndex={abaSelecionada}
          onChange={tabChange}
          tabsLabel={tabsSaurusLabel}
          hideTabs={tabsSaurusLabel.length === 0}
          tabsContent={tabsSaurusContent()}
        />
      </Grid>

      {abaSelecionada === 0 && (
        <FooterMesaList
          emAtendimento={
            mesasEPedidos.filter((mesa) => mesa.pedidos.length > 0).length
          }
          ociosas={
            mesasEPedidos.filter(
              (mesa) => mesa.pedidos.length > 0 && mesa.ociosa
            ).length
          }
          vazias={
            mesasEPedidos.filter((mesa) => mesa.pedidos.length === 0).length
          }
          tooltipFiltro="Filtro Mesa"
          filtroMesa={filtroMesa}
          setFiltroMesa={setFiltroMesa}
        />
      )}

      {abaSelecionada === 1 && (
        <FooterComandaList
          emAtendimento={balcoesEPedidos.length}
          ociosas={balcoesEPedidos.filter((balcao) => balcao.isOciosa).length}
          clientes={balcoesEPedidos.reduce(
            (acc, current) => acc + current.quantidadePessoas,
            0
          )}
          tooltipFiltro="Filtro Balcão"
          setFiltroComanda={setFiltroComandaBalcao}
          filtroComanda={filtroComandaBalcao}
        />
      )}

      {abaSelecionada === 2 && (
        <FooterComandaList
          emAtendimento={comandasEPedidos.length}
          ociosas={
            comandasEPedidos.filter((comanda) => comanda.isOciosa).length
          }
          clientes={comandasEPedidos.reduce(
            (acc, current) => acc + current.quantidadePessoas,
            0
          )}
          tooltipFiltro="Filtro Comanda"
          setFiltroComanda={setFiltroComanda}
          filtroComanda={filtroComanda}
        />
      )}
    </>
  );
};
