export enum EnumEmpresaConfig {
    TipoSincronizacao = 5,
    TempoSincronizacao = 6,
    SelecaoClienteVenda = 20,
    TaxaServicoRecomendada = 800,
    PrefixoDeComanda = 804,
    ValorBandeira = 8002,
    MaximoKM = 8003,
    ValorPorKM = 8004,
    TaxaServicoMinima = 830,
    TaxaServicoMaxima = 831,
    AlertaClienteInativo = 812,
    SelecaoInfoPedido = 8020,
    CamposSolicitarCliente = 8022,
    TipoFinalizacaoVendaPDV = 8023,
    LimiteCreditoCartao = 8024,
    SenhaPadrao = 8025,
    PermiteConfigurarDiaFechamentoPagamento = 8026,
    DiaFechamentoPadrao = 8027,
    DiasPagamentoPadrao = 8028,
    ModeloAutenticacao = 8029,
    AguardarIniciarVenda = 924,
    NroSequencialMovimentoValores = 930,
    AceitarSomenteClienteCadastrado = 1806,
    GerarSenha = 10001,
    QuandoResetarSenha = 10003,
    QuantidadeDigitosSenha = 10002,
    AgrupamentoItensVenda = 123,
    ModeloInsercaoVenda = 8019,
    ModeloSincronizacao = 10202,
    TipoControleSessao = 8032
}
