import { Button, Grid, Typography } from 'views/design-system';
import { isEmpty } from 'lodash';
import { ConfiguracaoMesaEComanda } from 'model/app/mov-pedido-local/configuracao-mesa-e-comanda';
import { EnumComandas } from 'model/enums/enum-comandas';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useToastSaurus
} from 'services/app';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { LoadingFinalizacao } from 'views';
import { DialogAlteracaoCadastros } from 'views/components/dialog/dialog-alteracao-cadastros/dialog-alteracao-cadastros';
import { AtualizarIcon, CancelarIcon } from 'views/components/icons';
import { useDefaultMovimentacaoStyles } from '../components/styles/default-movimentacao-styles';
import { PDVDiferenteConta } from './components/pdv-diferente-conta';
import { PDVDiferenteEmpresa } from './components/pdv-diferente-empresa';
import { useStyles } from './mov-simples-landing-page-styles';
import { useSessaoAtual } from 'services/app';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { useGetConfigContratoByCod } from 'data/api/gestao/contrato/get-contrato-config-by-cod';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { consoleDev } from 'utils/console-dev';
import { validaGuid } from 'utils/valida-guid';
import { serializarGuids } from 'utils/serialize-guid';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaSaloes } from 'database/interfaces/interface-tabela-saloes';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { TabelaMesas } from 'database/interfaces/interface-tabela-mesas';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumTpControleSessao } from 'model/enums/enum-tp-controle-sessao';

enum EnumPDVDiferente {
  Igual = 0,
  ContaDiferente = 1,
  EmpresaDiferente = 2,
}

enum EnumFasesIniciarLoading {
  IniciandoOperacao,
  VerificandoPdv,
  LocalizandoClientes,
  ProcurandoAtualizacoes,
  VerificandoSincronizacao,
  VerificandoSessao,
  IniciandoVenda,
}

interface AlteracaoCadastrosProps {
  cadastrosAlterados: boolean,
  ultimaAlteracaoLocal: Date | undefined,
  ultimaAlteracaoAPI: Date | undefined
}

export const MovSimplesLandingPage = () => {
  const { getSessao } = useSessaoPDV();
  const { getUltimaSincronizacao } = useSincronizacaoCadastros();
  const {
    restaurarMov,
    getMov,
    iniciarMov,
    alterarSessaoId,
    carregando: carregandoProvider
  } = useMovAtual();
  const { getConsumidor, carregando: carregandoCadastros } =
    useCadastroPadrao();
  const { push } = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const movStyles = useDefaultMovimentacaoStyles();
  const { showToast } = useToastSaurus();
  const {
    procurarMeuPDV,
    getPDV,
    getConfigByCod,
    getInicializacaoVendaRealizada,
    setInicializacaoVendaRealizada,
    carregando: carregandoPDV,
  } = usePDV();
  const { getConfigByCod: getConfigByCodEmp } = useEmpresaAtual()
  const { setConfiguracaoMesaEComanda, getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { usuario, getEmpresaSelecionada, getPessoa } = useSessaoAtual();
  const { getConfigContratoByCod, carregando: carregandoContrato } = useGetConfigContratoByCod()
  const { get } = useBancoLocal();


  const [alteracaoCadastros, setAlteracaoCadastros] = useState<AlteracaoCadastrosProps>({
    cadastrosAlterados: false,
    ultimaAlteracaoAPI: undefined,
    ultimaAlteracaoLocal: undefined
  });
  const [pdvDiff, setPdvDiff] = useState<EnumPDVDiferente>(EnumPDVDiferente.Igual);
  const [situacao, setSituacao] = useState<EnumFasesIniciarLoading>(EnumFasesIniciarLoading.IniciandoOperacao);
  const [error, setError] = useState<Error>();

  const carregando = [
    carregandoProvider,
    carregandoPDV,
    carregandoCadastros,
    carregandoContrato
  ].includes(true);

  const verificarCadastros = useMemo(() => {
    const urlQuery = new URLSearchParams(location.search);
    return urlQuery.get('verificarCadastros')
  }, [location.search])

  const urlRedirect = useMemo(() => {
    const urlQuery = new URLSearchParams(location.search);
    return urlQuery.get('redirect')
  }, [location.search])
  const configSessao = useMemo(() => getConfigByCodEmp<EnumTpControleSessao>(EnumEmpresaConfig.TipoControleSessao), [getConfigByCodEmp])

  const { avancarFluxoMov } = useMovRota();

  const tentarAbrirMov = React.useCallback(async () => {
    try {
      let mov = getMov();
      //RESILIENCIA APÓS A SINCRONIZACAO SE A VENDA TIVER VAZIA, RESETO ELA PARA PODER APLICAR AS NOVAS CONFIGURACOES
      if (mov?.vNF === 0) {
        await iniciarMov();
        mov = getMov();
      }

      if (!mov) {
        await restaurarMov();
        mov = getMov();

        try {
          if (!mov) {
            await iniciarMov(true);
          }
        } catch (e: any) {
          showToast('error', e.message);
        }
      } else {
        await alterarSessaoId();
      }
      setInicializacaoVendaRealizada(true);

      if (urlRedirect) {
        push(urlRedirect)
        return
      }

      await avancarFluxoMov();

    } catch (e: any) {
      showToast('error', e.message);
      setError(e);
    }
  }, [alterarSessaoId, avancarFluxoMov, getMov, iniciarMov, push, restaurarMov, setInicializacaoVendaRealizada, showToast, urlRedirect]);


  const verificarSincronizacao = useCallback(() => {
    if (getUltimaSincronizacao()?.dataSucesso) {
      setSituacao(3);
      return;
    } else if (getUltimaSincronizacao()?.dataUltima) {
      return push(PdvRotasMock.sincronizarResumoRota);
    }
    return push(PdvRotasMock.sincronizarDadosRota);
  }, [getUltimaSincronizacao, push]);

  const verificarAlteracoesCadastrais = useCallback(async () => {
    const tipoConfig = getConfigByCod(15) || '2';
    const ignoraVerificacao = tipoConfig === '0';

    if (verificarCadastros && !ignoraVerificacao) {
      let res = undefined;
      if (navigator.onLine) {
        res = await getConfigContratoByCod(getPessoa().pessoa?.contratoId || '', EnumContratoConfig.DataUltimoCadastro);

        if (res.erro) {
          verificarSincronizacao();
          return;
        }
      }

      const ultimaSincronizacao = getUltimaSincronizacao()?.dataSucesso;
      const dataAlteracao = res?.resultado?.data?.valor ?? undefined;

      if (!dataAlteracao || !ultimaSincronizacao) {
        verificarSincronizacao();
        return;
      }

      const dAlteracao = new Date(dataAlteracao).getTime();
      const uSincronizacao = new Date(ultimaSincronizacao).getTime();

      if (dAlteracao > uSincronizacao) {
        if (tipoConfig === '2') {
          setAlteracaoCadastros({
            cadastrosAlterados: true,
            ultimaAlteracaoAPI: new Date(dataAlteracao),
            ultimaAlteracaoLocal: new Date(ultimaSincronizacao)
          });
        } else {
          return push(PdvRotasMock.sincronizarDadosRota);
        }
        return;
      }
    }

    setSituacao(EnumFasesIniciarLoading.ProcurandoAtualizacoes);
  }, [getConfigByCod, getConfigContratoByCod, getPessoa, getUltimaSincronizacao, push, verificarCadastros, verificarSincronizacao]);

  const iniciarLoading = useCallback(async () => {
    consoleDev('Iniciando Loading', situacao);
    const sessao = await getSessao()
    switch (situacao) {
      case EnumFasesIniciarLoading.IniciandoOperacao:
        if (getInicializacaoVendaRealizada()) {
          if (configSessao === EnumTpControleSessao.Controla && !sessao) {
            setSituacao(EnumFasesIniciarLoading.VerificandoSessao)
          } else {
            setSituacao(EnumFasesIniciarLoading.IniciandoVenda)
          }
          return
        }
        setSituacao(EnumFasesIniciarLoading.VerificandoPdv)
        break;
      case EnumFasesIniciarLoading.VerificandoPdv:
        try {
          const acheiPdv = await procurarMeuPDV(false);
          if (!acheiPdv) {
            push(MovRotasMock.selecionarPDVRota);
            return;
          }
          setSituacao(EnumFasesIniciarLoading.LocalizandoClientes);
        } catch (e: any) {
          const pdv = getPDV();
          if (!pdv && e.message !== undefined) {
            setError(e);
            return;
          }
          setSituacao(EnumFasesIniciarLoading.LocalizandoClientes);
        }
        break;
      case EnumFasesIniciarLoading.LocalizandoClientes:
        const cli = await getConsumidor();
        if (!cli) {
          setError(new Error('Erro ao Identificar o Cliente Padrão.'));
          return;
        }
        setSituacao(EnumFasesIniciarLoading.ProcurandoAtualizacoes);
        break;
      case EnumFasesIniciarLoading.ProcurandoAtualizacoes:
        await verificarAlteracoesCadastrais();
        setSituacao(EnumFasesIniciarLoading.VerificandoSincronizacao)
        break;
      case EnumFasesIniciarLoading.VerificandoSincronizacao:
        const configMesaEComanda = getConfiguracoesMesaEComanda();
        if (!configMesaEComanda) {
          const saloesCadastrados = await get<TabelaSaloes>({
            nomeTabela: EnumTableNames.SALOES,
            filterFn: (salao) => salao.status?.codigo === EnumStatusSalao.ATIVO,
          });
          const comandas = await get<TabelaComandas>({
            nomeTabela: EnumTableNames.COMANDAS,
            filterFn: (comanda) => comanda.status?.codigo === EnumComandas.ATIVO
          });

          const comandasCadastradas = comandas.length
          const rfidIguais = comandas.filter(comanda => comanda.rfId === comanda.codigoComanda).length === comandas.length
          const temRFID = comandas.filter(comanda => !isEmpty(comanda.rfId)).length > 0

          const tipoTrabalhoComanda = rfidIguais ? EnumTipoTrabalhoComanda.APENAS_RFID : temRFID ? EnumTipoTrabalhoComanda.HIBRIDO : EnumTipoTrabalhoComanda.APENAS_CODIGO
          const mesasCadastradas = (await get<TabelaMesas>({
            nomeTabela: EnumTableNames.MESAS,
            filterFn: (mesa) => {
              if (
                saloesCadastrados.filter((x) => x.id === mesa.salaoId).length >
                0
              ) {
                if (mesa.status?.codigo === EnumMesas.ATIVO) {
                  return true;
                }
              }
              return false;
            }
          })).length;

          const configuracaoMesaComanda = new ConfiguracaoMesaEComanda(
            undefined,
            saloesCadastrados.length,
            mesasCadastradas,
            comandasCadastradas,
            tipoTrabalhoComanda
          );
          if (comandasCadastradas > 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.COMANDA
            });
          } else if (mesasCadastradas > 0 && comandasCadastradas < 1) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.MESA
            });
          }
          else if (comandasCadastradas === 0 && mesasCadastradas === 0) {
            setConfiguracaoMesaEComanda({
              ...configuracaoMesaComanda,
              tipoTrabalho: EnumTipoTrabalho.BALCAO
            });
          }
        }

        setSituacao(EnumFasesIniciarLoading.VerificandoSessao);
        break;
      case EnumFasesIniciarLoading.VerificandoSessao:

        if (!sessao) {
          if (configSessao === EnumTpControleSessao.Controla) {
            return push(MovRotasMock.abrirCaixaRota);
          }
        }

        setSituacao(EnumFasesIniciarLoading.IniciandoVenda);
        break;
      case EnumFasesIniciarLoading.IniciandoVenda:
        await tentarAbrirMov();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConfiguracoesMesaEComanda, getConsumidor, getPDV, getSessao, procurarMeuPDV, push, setConfiguracaoMesaEComanda, situacao, tentarAbrirMov, verificarAlteracoesCadastrais, getConfigByCodEmp]);

  const pdvDiferente = useCallback(async () => {
    const empresas = usuario?.empresa;
    const pdv = getPDV();
    const hasPDV = empresas?.filter((item) => item.Id === pdv?.empresaId);
    const isDeviceMobile = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS;
    if (!isEmpty(pdv)) {
      if (!isDeviceMobile) {
        let serial = validaGuid(pdv.chave) ? getPessoa().pessoa?.id : serializarGuids([getPessoa().pessoa?.id, getEmpresaSelecionada()!.Id]);

        if (serial !== pdv.chave && (!hasPDV || hasPDV.length < 1)) {
          setPdvDiff(EnumPDVDiferente.ContaDiferente)
          return;
        }
      }
      if ((hasPDV && hasPDV.length > 0) && pdv?.empresaId !== getEmpresaSelecionada()?.Id) {
        setPdvDiff(EnumPDVDiferente.EmpresaDiferente);
        return
      }
    }
    await iniciarLoading();
  }, [getEmpresaSelecionada, getPDV, getPessoa, iniciarLoading, usuario?.empresa]);

  const { callEvent } = useEventTools();
  const setHideHeader = useVendaHeaderStore((state) => state.setHideHeader);

  useEffect(() => {
    setHideHeader(true);
    callEvent(AppEventEnum.AlterarMenuPDV, false);
    (async () => {
      await pdvDiferente();
    })();

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true);
    }

  }, [pdvDiferente, callEvent, setHideHeader]);

  return (
    <>
      <Grid className={movStyles.root}>
        {!error && (
          <>
            {pdvDiff === EnumPDVDiferente.Igual ? (
              <Grid className={classes.content}>
                <Grid className={classes.infoContainer}>
                  <Grid className={classes.containerLoader}>
                    <LoadingFinalizacao />
                  </Grid>
                  <Typography className={classes.textTitle}>Preparando...</Typography>
                  <Typography variant="subtitle1" className={classes.textSubtitle}>
                    Preparando a aplicação para iniciar a venda.
                  </Typography>
                  <Grid className={classes.containerSincAtual}>
                    <Grid className={classes.textInfo}>
                      {situacao === EnumFasesIniciarLoading.IniciandoOperacao && <>Iniciando Operação</>}
                      {situacao === EnumFasesIniciarLoading.VerificandoPdv && <>Identificando PDV</>}
                      {situacao === EnumFasesIniciarLoading.LocalizandoClientes && <>Localizando Clientes</>}
                      {situacao === EnumFasesIniciarLoading.ProcurandoAtualizacoes && <>Procurando Atualizações</>}
                      {situacao === EnumFasesIniciarLoading.VerificandoSincronizacao && <>Verificando Sincronização</>}
                      {situacao === EnumFasesIniciarLoading.VerificandoSessao && <>Verificando Sessão</>}
                      {situacao === EnumFasesIniciarLoading.IniciandoVenda && <>Iniciando Venda</>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


            ) : pdvDiff === EnumPDVDiferente.ContaDiferente ? (
              <>
                <PDVDiferenteConta
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              </>
            ) : (
              pdvDiff === EnumPDVDiferente.EmpresaDiferente && (
                <PDVDiferenteEmpresa
                  onClick={() => {
                    setPdvDiff(0);
                    iniciarLoading();
                  }}
                />
              )
            )}
          </>
        )}
      </Grid>
      {!carregando && error && (
        <Grid
          container
          className={classes.containerError}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={10} sm={8} md={4}>
            <Grid className={classes.imageError}>
              <CancelarIcon tipo="GERAL" fill="#D22" />
            </Grid>
            <Typography variant="h5" align="center">
              {error.message}
            </Typography>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              disabled={carregando}
              className={classNames(classes.btnTentarNovamente, 'round')}
              onClick={() => {
                iniciarLoading();
              }}
            >
              <AtualizarIcon tipo="BUTTON_PRIMARY" />
              Tentar Novamente
            </Button>
          </Grid>
        </Grid>
      )}

      {alteracaoCadastros && (
        <DialogAlteracaoCadastros
          openned={alteracaoCadastros.cadastrosAlterados}
          ultimaAlteracaoAPI={alteracaoCadastros.ultimaAlteracaoAPI}
          ultimaAlteracaoLocal={alteracaoCadastros.ultimaAlteracaoLocal}

          closeModal={() => {
            setAlteracaoCadastros(
              { cadastrosAlterados: false, ultimaAlteracaoAPI: undefined, ultimaAlteracaoLocal: undefined }
            );
            verificarSincronizacao();
          }}
          handleClick={() => {
            return push(PdvRotasMock.sincronizarDadosRota);
          }}
        />
      )
      }
    </>
  );
};

export default MovSimplesLandingPage;
