import { useCallback } from 'react';
import * as Yup from 'yup';

export const useDataYup = (required: boolean = false)=>{
   const dataYup = useCallback(() => {
        return (
          Yup.string().max(10, 'Data Inválida').test({
            message: 'Informe uma Data válida.',
            test: (value) => {
                if(!value && !required) return true
                if(!value){
                    return false
                }
                return (
                    value.length === 10
                )
            }
          }).nullable(true)
        )
    }, [required])
    return{
        dataYup,
    }
}
