import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => ({
    cardContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        transition: "0.3s ease-in-out border",
        width: '180px',
        minHeight: '90px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    cardContent: {
        display: "flex",
        flexDirection: 'column',
        "& span": {
            fontSize: "0.7em",
        },
        width: '100%',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        margin: '2px 0'
    }
}))