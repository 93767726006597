import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import { CardCarrinhoPedidoProps } from './card-carrinho-pedido-props';
import { useStyles } from './card-carrinho-pedido-styles';
import { toDecimalString } from 'utils/to-decimal';
import React from 'react';
import { DefaultCard, useDefaultCardStyles } from '../components';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';

const CardCarrinhoPedido = ({
  model,
  inativarProduto,
  reativarProduto,
}: CardCarrinhoPedidoProps) => {
  const classes = useStyles();
  const classesCard = useDefaultCardStyles();

  return (
    <DefaultCard hasTagStatus={false}>
      <Grid container p={1} xs={12} alignItems='center' className={model.ativo ? '' : classes.cardinativo}>
        <Box flex alignItems='center' className={classes.containerInternoCard}>
          <Grid item xs={6} className={classesCard.celulaGrid} m={0}>
            <Typography
              className={'celula-grid-value'}
              color="primary"
              variant="body1"
            >
              <strong>{model.xProd}</strong>
            </Typography>
          </Grid>
          <Grid item className={classesCard.celulaGridFull}>
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              {toDecimalString(model.qCom, 3)} {model.uCom}
            </Typography>
          </Grid>
          <Grid item className={classesCard.celulaGrid} mr={1} >
            <Typography
              className={'celula-grid-value'}
              color="textPrimary"
              variant="body1"
            >
              <strong>R$ {toDecimalString((![EnumTpProduto.Combo, EnumTpProduto.ProdutoComSubItem].includes(model.tpProduto) ? model.vFinal : 0) + model.adicionais.reduce((acc, current) => acc + current.vFinal, 0), 2)}</strong>
            </Typography>
          </Grid>
          |
          {model.ativo ? (
            <Button
              onClick={async () => await inativarProduto(model)}
              className={classes.btnInativar}
            >
              <CloseIcon />
            </Button>
          ) : (
            <Button
              onClick={async () => await reativarProduto(model)}
              className={classes.btnRetornar}
            >
              <ReplyIcon />
            </Button>
          )}
        </Box>
        {model?.adicionais?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="caption" >Adicionais</Typography>
            <Divider className={classes.divider} />
          </Grid>
        )}
        {model?.adicionais?.length > 0 &&
          <Grid item >
            {model.adicionais.map((p, index) => (
              <Grid flex item key={index}>
                <Typography variant='body2'>{p.qCom}X {p.xProd}</Typography>
              </Grid>
            ))}
          </Grid>
        }
      </Grid>
    </DefaultCard>
  );
};
export default React.memo(CardCarrinhoPedido);
