import { EnumTipoExibicao } from "model/enums/enum-tipo-exibicao";

export interface TabelaCategorias {
    idIndexed?: number;
    id: string | null
    contratoId: string | null
    categoriaPaiId: string | null
    nome: string | null
    descricao: string | null
    cor: string | null
    ativo: boolean
    foto: string
    breadCrumbs: string | null
    temProdutos: boolean | null
    ordem: number
    tipoExibicao: EnumTipoExibicao
}

export class TabelaCategoriasModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public categoriaPaiId: string = '',
        public nome: string = '',
        public descricao: string = '',
        public cor: string = '',
        public ativo: boolean = false,
        public foto: string = '',
        public breadCrumbs: string = '',
        public temProdutos: boolean = false,
        public ordem: number = 0,
        public tipoExibicao: EnumTipoExibicao = EnumTipoExibicao.Catalogo,
        public idIndexed?: number,
    ) {}
}
