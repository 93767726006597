import { DefaultCard } from "../components"
import { CardEdicaoGerenciamentoSessaoValorProps } from "./card-edicao-gerenciamento-sessao-valor-props"
import { toDateString, toDateStringApi } from "utils/to-date"
import { useCallback, useEffect, useRef, useState } from "react"
import { toCurrency } from "utils/to-decimal"
import { useStyles } from "./card-edicao-gerenciamento-sessao-valor-styles"
import { useThemeQueries } from "views/theme"
import { DeletarProcessoIcon } from "views/components/icons/deletar-processo-icon"
import { isEmpty } from "lodash"
import { useConfirm } from "material-ui-confirm"
import { FormGerenciamentoSessaoValor } from "views/components/form/gerenciamento-sessoes-valor/form-gerenciamento-sessao-valor"
import { DefaultFormRefs } from "views/components/form/utils"
import { SessaoValorPostModel } from "model/api/gestao/sessao/sessao-valor-post-model"
import { CancelarIcon, EditarIcon, SalvarIcon } from "views/components/icons"
import { useSessaoAtual, useToastSaurus } from "services/app"
import { usePutSessaoValor } from "data/api/gestao/sessao/put-sessao-valor"
import { useDeleteSessaoValor } from "data/api/gestao/sessao/delete-sessao-valor"
import { CircularLoading } from "views/components/utils"
import { picker } from "utils/picker"
import { SessaoValoresModel } from "model/api/gestao/sessao/sessao-gerenciamento-model"
import { TabelaFinalizadoras } from "database/interfaces/interface-tabela-finalizadoras"
import { TpLancamentoSessaoValorMock } from "data/mocks/tp-lancamento-sessao-valor-mock"
import { EnumTpLancamentoSessaoValor } from "model/enums/enum-tipo-lancamento-sessao-valor"
import { Button, Divider, Grid, Tooltip, Typography } from "views/design-system"
import { useBancoLocal } from "services/app/hooks/banco-local"
import { EnumTableNames } from "database/touchone-carga-database"

export const CardEdicaoGerenciamentoSessaoValor = ({
    model,
    sessao,
    getSessaoValorWrapper,
}: CardEdicaoGerenciamentoSessaoValorProps) => {

    //AUX
    const classes = useStyles();
    const { theme, isMobile } = useThemeQueries();
    const confirm = useConfirm();
    const { showToast } = useToastSaurus();

    //STATES E REFS
    const [editar, setEditar] = useState<boolean>(false);
    const [finalizadoras, setFinalizadoras] = useState<TabelaFinalizadoras[]>([]);
    const refEditForm = useRef<DefaultFormRefs<SessaoValorPostModel>>(null);

    //HOOKS
    const { getEmpresaSelecionada } = useSessaoAtual();
    const { get } = useBancoLocal();

    //CHAMADAS DA API
    const { putSessaoValor, carregando: carregandoPut } = usePutSessaoValor();
    const { deleteSessaoValor, carregando: carregandoDelete } = useDeleteSessaoValor();

    const carregando = carregandoDelete || carregandoPut;

    const handleSubmit = useCallback(async (modelForm: SessaoValorPostModel) => {
        try {
            const modelPicker = picker<SessaoValoresModel>(model, modelForm);

            modelPicker.tpLancamento = modelForm.tpLancamento;
            modelPicker.valor = modelForm.valor;
            modelPicker.dLancamento = toDateStringApi(new Date());

            const res = await putSessaoValor(getEmpresaSelecionada()!.Id, sessao?.caixaId, sessao?.id, modelPicker);

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'Correção alterada com sucesso.');
            await getSessaoValorWrapper();
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [getEmpresaSelecionada, getSessaoValorWrapper, model, putSessaoValor, sessao?.caixaId, sessao?.id, showToast])

    const deletarCorrecao = useCallback(async () => {
        try {
            const res = await deleteSessaoValor(getEmpresaSelecionada()!.Id, sessao?.caixaId, sessao?.id, model.id);

            if (res.erro) {
                throw res.erro
            }

            showToast('success', 'A correção foi removida com sucesso.')
            await getSessaoValorWrapper();
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [deleteSessaoValor, getEmpresaSelecionada, getSessaoValorWrapper, model.id, sessao?.caixaId, sessao?.id, showToast])

    const retornaFinalizadoras = useCallback(async () => {
        try {
            const finalizadorasDB = await get<TabelaFinalizadoras>({ nomeTabela: EnumTableNames.FINALIZADORAS });

            setFinalizadoras(finalizadorasDB);
        } catch (err: any) {
            showToast('error', err.message);
        }
    }, [get, showToast])

    useEffect(() => {
        retornaFinalizadoras();
    }, [retornaFinalizadoras])

    const statusSessao = useCallback(() => {
        if (isEmpty(sessao.dFechamento) || !isEmpty(sessao.dConferencia)) {
            return true;
        }

        return false;
    }, [sessao.dConferencia, sessao.dFechamento])

    const isConferida = !isEmpty(sessao.dConferencia);

    return (
        <>
            <DefaultCard
                className={statusSessao() ? classes.root : ''}
                onClick={async () => {
                    if (!editar && !statusSessao()) {
                        setEditar(true);
                    }
                }}
            >
                {carregando && <CircularLoading tipo="FULLSIZED" />}
                <Grid container p={1} spacing={1} flex justifyContent="space-between">
                    {!editar ? (
                        <>
                            <Grid item xs={4} flex alignItems="center">
                                <Typography
                                    variant='body1'
                                    color="textPrimary"
                                    weight={700}
                                >
                                    {TpLancamentoSessaoValorMock.filter(x => x.Key === model.tpLancamento)[0].Value}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} flex alignItems="center" justifyContent="center">
                                <Typography
                                    variant={isMobile ? "caption" : "body2"}
                                    color="textPrimary"
                                >
                                    {toDateString(model.dLancamento, 'DD/MM/yyyy HH:mm')}
                                </Typography>
                            </Grid>
                            {!isConferida && (
                                <Grid item xs={4} flex alignItems="center" justifyContent="flex-end">
                                    {!statusSessao() && (
                                        <Tooltip arrow title="Editar">
                                            <div>
                                                <Button
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={() => {
                                                        if (!editar) {
                                                            setEditar(true);
                                                        }
                                                    }}
                                                >
                                                    <EditarIcon tipo="BUTTON" fill={theme.palette.text.primary} />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    )}
                                    {model.tpLancamento === EnumTpLancamentoSessaoValor.ABERTURA ? null :
                                        <Tooltip arrow title='Remover'>
                                            <div>
                                                <Button
                                                    size="small"
                                                    className={classes.button}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        confirm({
                                                            title: 'Remover Correção',
                                                            description: 'Deseja realmente remover a correção?',
                                                        }).then(async () => {
                                                            await deletarCorrecao();
                                                        })
                                                    }}
                                                >
                                                    <DeletarProcessoIcon tipo="BUTTON" fill={theme.palette.error.main} />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    }
                                </Grid>
                            )}
                            <Grid item xs={12} py={1}>
                                <Divider variant="fullWidth" />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Forma de Pagamento
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.pagamentoNome) ? model.pagamentoNome : 'Sem Pagamento'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Responsável
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.responsavelNome) ? model.responsavelNome : 'Sem Responsável'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Operador
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.operadorNome) ? model.operadorNome : 'Sem Operador'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Supervisor
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {!isEmpty(model.portadorNome) ? model.portadorNome : 'Sem Supervisor'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    Valor Movimentado
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {toCurrency(model.valor)}
                                </Typography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                    weight={600}
                                >
                                    Editar Correção
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGerenciamentoSessaoValor
                                    ref={refEditForm}
                                    onSubmit={handleSubmit}
                                    showLoading={false}
                                    loading={false}
                                    model={model}
                                    editar={true}
                                    finalizadoras={finalizadoras}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={() => setEditar(false)}
                                >
                                    <CancelarIcon tipo="BUTTON" />
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    onClick={() => refEditForm.current?.submitForm()}
                                >
                                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                                    Salvar
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DefaultCard >
        </>
    )
}