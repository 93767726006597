import { KeyValueModel } from 'model';
import { validaGuid } from './valida-guid'

export const chaveCaracteresParaSubstituir = [
    new KeyValueModel('/', '_b-_'),
]

// Function to remove hyphens from GUID and convert to byte array
function guidToBytes(guid: string): number[] {
    const hex = guid.replace(/-/g, '');
    const bytes: number[] = [];
    for (let i = 0; i < hex.length; i += 2) {
        bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return bytes;
}

// Convert byte array to Base64 string
function bytesToBase64(bytes: number[]): string {
    const binary = String.fromCharCode.apply(null, bytes as unknown as number[]);
    return btoa(binary);
}

// Function to decode Base64 string back to byte array
function base64ToBytes(base64: string): number[] {
    const binary = atob(base64);
    const bytes: number[] = [];
    for (let i = 0; i < binary.length; i++) {
        bytes.push(binary.charCodeAt(i));
    }
    return bytes;
}

// Function to convert byte array back to GUID
function bytesToGuid(bytes: number[]): string {
    let hex = '';
    for (const byte of bytes) {
        hex += ('0' + byte.toString(16)).slice(-2);
    }
    return [
        hex.substr(0, 8),
        hex.substr(8, 4),
        hex.substr(12, 4),
        hex.substr(16, 4),
        hex.substr(20)
    ].join('-');
}

export function serializarGuids(guids: string[], limiteCaracteres: number = 60, replace: boolean = true): string {
    let bytes: number[] = [];
    guids.forEach((guid, index) => {

        if (!validaGuid(guid)) {
            throw new Error(`Guid no index ${index} é inválida.`);
        }

        const byte = guidToBytes(guid);

        bytes = [...bytes, ...byte]
    })

    let serializedGuids = bytesToBase64(bytes);

    if (replace) {
        chaveCaracteresParaSubstituir.forEach((x) => {
            if (serializedGuids.includes(x.Key)) {
                serializedGuids = serializedGuids.replaceAll(x.Key, x.Value);
            }
        })
    }

    serializedGuids = serializedGuids.length > limiteCaracteres ? serializedGuids.substring(0, limiteCaracteres) : serializedGuids;

    return serializedGuids
}

export function decodificarGuidsSerializadas(serializedGuid: string): string[] {
    chaveCaracteresParaSubstituir.forEach((x) => {
        if (serializedGuid.includes(x.Value)) {
            serializedGuid = serializedGuid.replaceAll(x.Value, x.Key);
        }
    })
    let decodedBytes = base64ToBytes(serializedGuid);
    let guids: string[] = [];

    while (decodedBytes.length >= 16) {
        const byteGuid = decodedBytes.splice(0, 16)
        let decodedGuid = bytesToGuid(byteGuid);

        guids.push(decodedGuid)
    }

    return guids;
}

//passa um array de guids pra cá pra verificar se são iguais a da serializada
export function isSerializedGuidEqual(serializedGuid: string, guids: string[]): boolean {
    const decodedGuids = decodificarGuidsSerializadas(serializedGuid);

    let equal: boolean = true;

    for (let guid of guids) {
        if (!decodedGuids.includes(guid)) {
            equal = false;
            break;
        }
    }

    return equal;
}