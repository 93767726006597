import { FormControlLabel, Grow } from '@material-ui/core';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { SaurusRadio } from 'views/components/controles/radio/saurus-radio';
import { OkIcon, VoltarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { VendedorObj } from '../dialog-taxa-servico';
import { useStyles } from '../dialog-taxa-servico-styles'
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoProduto } from 'data/api/gestao/pedido-produto/put-pedido-dados-produtos';
import { CircularLoading } from 'views/components/utils';
import { PedidoProdutoModelPost } from 'model/api/gestao/pedido/pedido-produto-model';
import { roundTo } from 'utils/round-to';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isEmpty } from 'lodash';
import { calcPercent } from 'utils/calc-percent';
import { useControleMedicamento } from 'services/app/hooks/controle-medicamento';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { Button, Divider, Grid, Typography } from 'views/design-system';

type Props = {
    closeModal: () => void
    vendedor: VendedorObj
    voltar: () => void;
    pedidoModel: PedidoModel;
    carregarInfoPedido?: () => void;
    setPedido: React.Dispatch<React.SetStateAction<PedidoModel>>
}
export const AlterarTaxa = ({ closeModal, vendedor, setPedido, ...props }: Props) => {

    const classes = useStyles();
    const { showToast } = useToastSaurus()
    const { theme, isMobile } = useThemeQueries()
    const inputRef = useRef<HTMLInputElement>()
    const [option, setOption] = useState<1 | 2>(vendedor.taxa > 0 ? 2 : 1)
    const [value, setValue] = useState<string>(
        vendedor.taxa > 0 ? vendedor.taxa.toString() : ''
    )
    const [error, setError] = useState<string>('')
    const [carregando, setCarregando] = useState<boolean>(false)
    const { getPermissaoBoolean } = useSessaoAtual()
    const { abrirSolicitarPermissao } = useCadastros()
    const { putPedidoProdutos } = usePutPedidoProduto()
    const { getConfigByCod } = useEmpresaAtual()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { retornarInfoMedicamento } = useControleMedicamento();

    useEffect(() => {
        !isMobile && inputRef.current?.focus()
    }, [isMobile])

    const minTaxa = getConfigByCod(EnumEmpresaConfig.TaxaServicoMinima)
    const maxTaxa = getConfigByCod(EnumEmpresaConfig.TaxaServicoMaxima)
    const podeAlterarTaxa = getPermissaoBoolean(EnumCodigosPermissoes.ALTERAR_TAXA_SERVICO)

    const handlePutPedidos = useCallback(async () => {
        setCarregando(true)
        try {
            if (option === 2) {
                if (error.length > 0) {
                    throw new Error(error)
                }
                if ((value === '0' || isEmpty(value))) {
                    throw new Error('Precisa ter adicionar um valor.')
                }
            }
            const produtos = props.pedidoModel.produtos.filter(x => x.vendedorId === vendedor.vendedorId && x.calculaTaxaServico)

            for await (const produto of produtos) {
                const vFinal = (produto.valorUnitario * produto.quantidade) -
                    produto.valorTotalDesconto +
                    produto.valorTotalAdicional

                const taxaServicoProd = roundTo(calcPercent(roundTo(vFinal), parseInt(value)) ?? 0)

                setPedido(ped => {
                    let produtosPed = ped.produtos
                    produtosPed = produtosPed.map(x => {
                        if (x.id === produto.id) {
                            return {
                                ...x,
                                taxaServico: option === 1 ? 0 : parseInt(value),
                                valorTotalServico: option === 1 ? 0 : taxaServicoProd
                            }
                        }
                        return x
                    })

                    return {
                        ...ped,
                        produtos: produtosPed
                    }
                })
                const retMed = await retornarInfoMedicamento(produto.codigoAnvisa, produto.id);

                const produtoPostModel = {
                    ...new PedidoProdutoModelPost(),
                    id: produto.id,
                    adicionalId: produto.adicionalId,
                    codigoBarra: produto.codigoBarra,
                    codigoReferencia: produto.codigoReferencia,
                    descricao: produto.descricao,
                    groupId: produto.groupId,
                    grupoImpostoId: produto.grupoImpostoId,
                    indDesperdicio: produto.indDesperdicio.codigo,
                    indFin: produto.indFin,
                    ncm: produto.ncm,
                    ncmId: produto.ncmId,
                    nItem: produto.nItem,
                    observacao: produto.observacao,
                    status: produto.status.codigo,
                    taxaServico: option === 1 ? 0 : parseInt(value),
                    valorTotalServico: option === 1 ? 0 : taxaServicoProd,
                    produtoGradeId: produto.produtoGradeId,
                    valorTotalAdicional: produto.valorTotalAdicional,
                    valorTotalAdicionalEmbutido: produto.valorTotalAdicionalEmbutido,
                    valorTotal: produto.valorTotal,
                    valorTotalDesconto: produto.valorTotalDesconto,
                    valorTotalDescontoEmbutido: produto.valorTotalDescontoEmbutido,

                    cProdANVISA: retMed?.cProdANVISA,
                    nSeqReceitaMedica: produto.numeroSequenciaReceitaMedica,
                    vendaControlada: retMed?.vendaControlada,
                    vPMC: retMed?.vPMC,
                    xMotivoIsencao: retMed?.xMotivoIsencao,

                    descontos: produto.descontos,
                    acrescimos: produto.acrescimos,
                    pesoVariavel: produto.pesoVariavel,
                    plu: produto.plu,
                    posicaoMesa: produto.posicaoMesa,
                    produtoIdReferencia: produto.produtoIdReferencia,
                    produtoPai: produto.produtoPai,
                    quantidade: produto.quantidade,
                    quantidadeMax: produto.quantidadeMax,
                    salaoId: produto.salaoId,
                    setorEmpresaId: produto.setorEmpresaId,
                    setorId: produto.setorId,
                    subItens: produto.subItens,
                    tabelaPrecoId: produto.tabelaPrecoId,
                    temImposto: produto.temImposto,
                    tpProduto: produto.tpProduto.codigo,
                    unidadeComercial: produto.unidadeComercial,
                    valorTotalFrete: produto.valorTotalFrete,
                    valorUnitario: produto.valorUnitario,
                    vendedor: produto.vendedor,
                    vendedorId: produto.vendedorId,
                    calculaTaxaServico: produto.calculaTaxaServico
                } as PedidoProdutoModelPost
                const res = await putPedidoProdutos(getEmpresaAtual()?.id ?? '', props.pedidoModel.id, produto.id, produtoPostModel)

                if (res.erro) {
                    throw res.erro
                }
            }

            props.carregarInfoPedido && props.carregarInfoPedido()
            showToast('success', 'Taxa Atualizada!')
            props.voltar()
        } catch (err: any) {
            showToast('error', err.message)
        } finally {
            setCarregando(false)
        }
    }, [error, getEmpresaAtual, option, props, putPedidoProdutos, retornarInfoMedicamento, setPedido, showToast, value, vendedor.vendedorId])

    const permissaoAlterarTaxa = useCallback(async (callback: () => void | Promise<void>) => {
        if (!podeAlterarTaxa) {
            abrirSolicitarPermissao(async () => await callback(), EnumCodigosPermissoes.ALTERAR_TAXA_SERVICO, 'alterar a taxa')
            return
        }
        await callback()
    }, [abrirSolicitarPermissao, podeAlterarTaxa])

    const onSubmit = useCallback(async (e: React.FormEvent) => {
        e.preventDefault()

        permissaoAlterarTaxa(handlePutPedidos)

    }, [handlePutPedidos, permissaoAlterarTaxa])

    const alterarValue = useCallback((value: string) => {
        setError('')
        if (option === 2) {
            if (Number(value) < Number(minTaxa)) {
                setError('O valor é menor que o permitido.')
            } else if (Number(value) > Number(maxTaxa)) {
                setError('O valor é maior que o permitido.')
            }
        }
        setValue(value)
    }, [maxTaxa, minTaxa, option])

    return (<>
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        <Grow in={true}>
            <form onSubmit={onSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='body2' style={{ display: 'flex' }}>
                            Vendedor: <Typography color="primary" variant="body2" style={{
                                paddingLeft: 4
                            }}>{vendedor?.vendedorNome || '-'}</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' style={{ textAlign: 'initial' }}>
                            Digite a baixo o valor da porcentagem (%) que vai ser cobrado
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Não receber taxa"
                            checked={option === 1}
                            onClick={() => {
                                setOption(1)
                            }}
                            control={<SaurusRadio />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Porcentagem"
                            checked={option === 2}
                            onClick={() => {
                                setValue((vendedor.taxa ?? 0).toString())
                                setOption(2)
                            }}
                            control={<SaurusRadio />}
                        />
                    </Grid>
                    {option !== 1 && (
                        <>
                            <Grid item xs={12}>
                                <TextFieldSaurus
                                    fullWidth
                                    manterMascara
                                    title="Valor da Taxa"
                                    tipo={"NUMERO"}
                                    className={classes.inputValorCenter}
                                    onChange={e => alterarValue(e.target.value)}
                                    variant="outlined"
                                    maxTexto={10}
                                    placeholder='0'
                                    allowSubmit
                                    error={error.length > 0}
                                    helperText={
                                        error.length > 0 && error
                                    }
                                    inputRef={inputRef}
                                    value={value}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent='space-between' style={{
                                marginTop: '-8px',
                                height: 29
                            }}>
                                {option === 2 && (
                                    <>
                                        <Typography variant='body2' style={{ fontWeight: 500 }}>
                                            <span style={{ color: theme.palette.primary.main }}>Min:</span> {minTaxa}
                                        </Typography>
                                        <Typography variant='body2' style={{ fontWeight: 500 }}>
                                            <span style={{ color: theme.palette.primary.main }}>Max:</span> {maxTaxa}
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            color="primary"
                            onClick={props.voltar}
                        >
                            <VoltarIcon tipo="BUTTON" />
                            Voltar
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="primary"
                            type="submit"
                        >
                            <OkIcon tipo="BUTTON_PRIMARY" />
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grow>
    </>
    )
}