import { useCallback, FocusEvent, useState } from 'react';

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { EnumCadastroTipo } from 'model';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaClientes } from 'database/interfaces/interface-tabela-clientes';
import { EnumTableNames } from 'database/touchone-carga-database';

export interface AutocompleteResponsaveisProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.Ref<any>;
  pessoaId?: string;
  allowSubmit?: boolean;
  modo: 'nome';
  disabled?: boolean;
}
interface ResponsaveisPesquisa extends PessoaModel {
  campoFormatado?: string;
}

export const AutocompleteResponsaveis = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteResponsaveisProps) => {
  const { get } = useBancoLocal();

  const [loading, setLoading] = useState<boolean>(false);

  const [responsaveisState, setResponsaveisState] = useState<
    Array<ResponsaveisPesquisa>
  >(new Array<ResponsaveisPesquisa>());

  const getResponsaveisWrapper = useCallback(async (termo: string) => {
    try {
      setLoading(true);
      const ret = await get<TabelaClientes>({
        nomeTabela: EnumTableNames.CLIENTES,
        filterFn: (pessoa) =>
          (pessoa.tpCadastro === EnumCadastroTipo.FUNCIONARIO ||
            pessoa.tpCadastro === EnumCadastroTipo.REPRESENTANTE) &&
          (pessoa.nome!.toLowerCase().includes(termo.toLocaleLowerCase()) ||
            pessoa
              .cpfcnpj!.toLowerCase()
              .includes(termo.toLocaleLowerCase())),
      });

      let responsaveis: Array<ResponsaveisPesquisa> =
        new Array<ResponsaveisPesquisa>();

      if (ret.length > 0) {
        responsaveis = ret as ResponsaveisPesquisa[];
      }
      responsaveis.forEach((item) => {
        item.campoFormatado = `${item.nome}`;
      });

      setResponsaveisState(responsaveis);
    } catch (e: any) {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  }, [get]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const onPesquisa = useCallback(
    (termo: string) => {
      let termoPesq = termo.length > 0 ? termo.split('-')[0].trim() : termo;
      getResponsaveisWrapper(termoPesq);
    },
    [getResponsaveisWrapper],
  );

  return (
    <AutocompleteSaurus
      disabled={props.disabled}
      inputRef={props.inputRef}
      loading={loading}
      tipoTextField="TEXTO"
      onPesquisa={onPesquisa}
      onChange={onChangeWrapper}
      opcoes={responsaveisState}
      optionLabelKey={props.modo}
      optionValueKey="codigo"
      disabledValueKey="id"
      disabledValues={[props.pessoaId]}
      onBlur={props.onBlur}
      value={props.value}
      allowSubmit={props.allowSubmit}
      name={props.name}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      placeholder={props.placeholder}
    />
  );
};
