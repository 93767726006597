import React from 'react';
import { useStyles } from '../paginacao-styles';
import classNames from 'classnames';
import { ButtonIcon } from 'views/components/controles';
import { OptionalTooltip } from 'views/design-system/tooltip/components/optional-tooltip';

interface Props {
    onClick: () => any;
    icon: React.ReactElement;
    tooltip?: string;
}

export const PaginacaoButton = ({ icon, onClick, tooltip }: Props) => {
    const classes = useStyles();
    return (
        <OptionalTooltip title={tooltip} >
            <div className={classNames(classes.sumaryContainer, classes.paginacaoButton)}>
                <ButtonIcon
                    icon={React.cloneElement(icon, {
                        tipo: 'INPUT'
                    })}
                    onClick={onClick}
                />
            </div>
        </OptionalTooltip>
    )
}