import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
    },
    containerInternoCard: {
        width: '100%'
    },
    btnInativar: {
        color: theme.palette.error.main,
        height: '18px',
        width: '18px',
    },
    btnRetornar: {
        cursor: 'pointer',
        width: '18px',
        color: theme.palette.primary.main
    },
    divider: {
        marginTop: '4px',
        marginBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.secondary}`,
    },
}));
