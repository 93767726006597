import { Container } from '@material-ui/core';
import { useStyles } from './taxa-entrega-content-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { useThemeQueries } from 'views/theme';
import { useToastSaurus } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import classNames from 'classnames';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { FormTaxaEntrega } from 'views/components/form/taxa-entrega/form-taxa-entrega/form-taxa-entrega';
import { TaxaEntregaFormModel } from 'model/app/forms/taxa-entrega/taxa-entrega-form-model';
import { toDecimal } from 'utils/to-decimal';
import { GeolocalizacaoEnderecoFreteModel, GeolocalizacaoFreteModel } from 'model/api/gestao/geolocalizacao/geolocalizacao-frete-model';
import { stringNumeros } from 'utils/string-numeros';
import { usePostGeolocalizacaoFrete } from 'data/api/gestao/geolocalizacao/post-geolocalizacao-comparar-preco';
import { GeolocalizacaoFreteResponseModel } from 'model/api/gestao/geolocalizacao/geolocalizacao-frete-response-model';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { VariaveisAmbiente } from 'config';
import { completarEndereco } from 'utils/completar-endereco';
import { usePostLocalizacaoObterCoordenadas } from 'data/api/gestao/geolocalizacao/post-obter-coordenadas';
import { AvisoIcon } from 'views/components/icons';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePutPedidoProduto } from 'data/api/gestao/pedido-produto/put-pedido-dados-produtos';
import { picker } from 'utils/picker';
import { PedidoProdutoModelPost } from 'model/api/gestao/pedido/pedido-produto-model';
import MarkerMarket from '../../../../../../assets/img/map-pointer-market.png'
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { EnumMovModelo } from 'model';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { DefaultCard } from 'views/components/cards/components';

interface TaxaEntregaContentProps {
}

interface PedidoLocationProps {
    pedido: PedidoModel
}

export const TaxaEntregaContent = ({ ...props }: TaxaEntregaContentProps) => {
    const classes = useStyles();
    const { isMobile } = useThemeQueries();
    const { calcularTaxaEntrega, getMov } = useMovAtual()
    const { showToast } = useToastSaurus()
    const { getEmpresaAtual, getConfigByCod: getConfigEmp } = useEmpresaAtual()
    const { getConfigByCod: getConfigContrato } = useContratoAtual();
    const { redirectLanding, redirectSelecionarCliente, redirectCarrinho } = useMovRota();
    const { avancarFluxoMov } = useMovRota();
    const valorKM = getConfigEmp(EnumEmpresaConfig.ValorPorKM)
    const maximoKM = getConfigEmp(EnumEmpresaConfig.MaximoKM)
    const valorBandeira = getConfigEmp(EnumEmpresaConfig.ValorBandeira)

    const { postGeolocalizacaoFrete, carregando: carregandoPostComparar } = usePostGeolocalizacaoFrete()
    const { postLocalizacaoObterCoordenadas, carregando: carregandoPostObterCoor } = usePostLocalizacaoObterCoordenadas()
    const { putPedidoProdutos, carregando: carregandoPutProduto } = usePutPedidoProduto()
    const [vCalculado, setVCalculado] = useState<GeolocalizacaoFreteResponseModel>({
        ...new GeolocalizacaoFreteResponseModel(),
        valorFrete: toDecimal(valorKM || '0')
    })
    const produtoEntrega = getConfigContrato(EnumContratoConfig.ProdutoEntrega)
    const [estaFora, setEstaFora] = useState<boolean>(false)
    const history = useHistory()
    const location = useLocation<PedidoLocationProps>()
    const [center, setCenter] = useState({
        lat: getEmpresaAtual()?.latitude || 0,
        lng: getEmpresaAtual()?.longitude || 0,
    })
    const movEnd = getMov()?.cliente?.endereco
    const carregando = [carregandoPostComparar, carregandoPostObterCoor, carregandoPutProduto].includes(true)
    const pedidoState = location.state?.pedido

    const formRef = useRef<DefaultFormRefs<TaxaEntregaFormModel>>(null);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.fillForm({
                tipoEntrega: 'propria',
                valor: vCalculado.valorFrete + toDecimal(valorBandeira)
            })
        }
    }, [vCalculado.valorFrete, valorBandeira])

    const retornaEndereco = useCallback((tipo: 'empresa' | 'clienteMov' | 'clientePedido') => {
        const mov = getMov()
        const clienteMov = mov?.cliente?.endereco
        let endereco: GeolocalizacaoEnderecoFreteModel = new GeolocalizacaoEnderecoFreteModel()
        const empresa = getEmpresaAtual()
        const clientePedido = pedidoState?.enderecoEntrega
        switch (tipo) {
            case 'empresa':
                endereco = {
                    bairro: empresa?.bairro || "",
                    cidade: empresa?.uf || '',
                    logradouro: empresa?.logradouro || '',
                    numero: stringNumeros(empresa?.numero || ''),
                    pais: 'Brasil',
                    estado: empresa?.uf || '',
                    cep: empresa?.cep || '',
                    codigoMunicipio: (empresa?.cMunicipio || '').toString(),
                    codigoUF: ''
                } as GeolocalizacaoEnderecoFreteModel
                break;
            case 'clienteMov':
                endereco = {
                    bairro: clienteMov?.bairro || '',
                    logradouro: clienteMov?.logradouro || '',
                    numero: stringNumeros(clienteMov?.numero || ''),
                    cidade: clienteMov?.xMun || '',
                    pais: 'Brasil',
                    estado: clienteMov?.uf || '',
                    cep: clienteMov?.cep || '',
                    codigoMunicipio: clienteMov?.cMun || '',
                    codigoUF: (clienteMov?.cuf || 0).toString()
                }
                break;
            case 'clientePedido':
                endereco = {
                    bairro: clientePedido?.bairro || '',
                    cep: clientePedido?.cep || '',
                    cidade: clientePedido?.municipio || '',
                    codigoMunicipio: clientePedido?.codigoMunicipio || '',
                    codigoUF: '',
                    estado: clientePedido?.uf || '',
                    logradouro: clientePedido?.logradouro || '',
                    numero: clientePedido?.numero || '',
                    pais: 'Brasil',
                }
                break;
        }

        return endereco
    }, [getEmpresaAtual, getMov, pedidoState?.enderecoEntrega])

    const getCoordenadas = useCallback(async () => {
        try {
            const data: GeolocalizacaoEnderecoFreteModel = pedidoState ?
                retornaEndereco('clientePedido') :
                retornaEndereco('clienteMov')

            const res = await postLocalizacaoObterCoordenadas(data)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data as { latitude: number, longitude: number }

            setCenter({
                lat: resultado.latitude,
                lng: resultado.longitude
            })
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [pedidoState, postLocalizacaoObterCoordenadas, retornaEndereco, showToast])

    const getCompararPrecoWrapper = useCallback(async () => {
        try {
            const data: GeolocalizacaoFreteModel = {
                destinatario: pedidoState ? retornaEndereco('clientePedido') : retornaEndereco('clienteMov'),
                distanciaMaximaMetros: toDecimal(stringNumeros(maximoKM || '')) * 1000,
                origem: retornaEndereco('empresa'),
                valorKM: toDecimal(valorKM)
            }
            const res = await postGeolocalizacaoFrete(data)

            if (res.erro) throw res.erro

            setVCalculado(res.resultado?.data)
            const resultado = res.resultado?.data as GeolocalizacaoFreteResponseModel
            if (!resultado.dentro) {
                setEstaFora(true)
            }
            formRef.current?.fillForm({ tipoEntrega: 'propria', valor: resultado.valorFrete + toDecimal(valorBandeira) })
        } catch (err: any) {
            showToast('error', err.message)
        }
    }, [maximoKM, pedidoState, postGeolocalizacaoFrete, retornaEndereco, showToast, valorBandeira, valorKM])

    useEffect(() => {
        (async () => {
            const mov = getMov()
            if (!pedidoState) {
                if (mov?.mod !== EnumMovModelo.DELIVERY) {
                    await redirectLanding();
                    return;
                }
                if (mov?.cliente && isEmpty(mov?.cliente.endereco)) {
                    showToast('error', 'Você precisa ter o endereço do cliente para poder calcular a taxa de entrega.')
                    await redirectSelecionarCliente();
                    return;
                }
            }
            await getCoordenadas()
            await getCompararPrecoWrapper()
        })()
    }, [getCompararPrecoWrapper, getCoordenadas, getMov, history, pedidoState, redirectLanding, redirectSelecionarCliente, showToast])

    const atualizarProdutoEntrega = async (value: TaxaEntregaFormModel) => {
        if (!pedidoState) return;
        const prodPedido = pedidoState.produtos.find(p => p.produtoIdReferencia === produtoEntrega)
        if (!prodPedido) return;
        const prodPut: PedidoProdutoModelPost = picker<PedidoProdutoModelPost>(prodPedido, new PedidoProdutoModelPost())
        prodPut.status = prodPedido.status.codigo
        prodPut.indDesperdicio = prodPedido.indDesperdicio.codigo
        prodPut.tpProduto = prodPedido.tpProduto.codigo
        prodPut.subItens = []
        prodPut.rastros = []

        prodPut.observacao = value.tipoEntrega === "propria" ? 'Entrega Própria' : 'Entrega Terceirizada'
        prodPut.valorTotal = toDecimal(value.valor);
        prodPut.valorUnitario = toDecimal(value.valor);
        prodPut.valorTotalFrete = toDecimal(value.valor);


        const res = await putPedidoProdutos(getEmpresaAtual()?.id || '', pedidoState.id, prodPedido?.id || '', prodPut)

        if (res.erro) throw res.erro
    }

    const handleSubmit = async (value: TaxaEntregaFormModel) => {
        try {
            if (pedidoState) {
                await atualizarProdutoEntrega(value)

                history.push({
                    pathname: `${MovRotasMock.visualizarPedidoRota}/${pedidoState.id}`,
                    state: {
                        pedido: pedidoState
                    }
                })
                showToast('success', 'Frete atualizado!')
                return;

            }
            await calcularTaxaEntrega(toDecimal(value.valor), value.tipoEntrega)
            showToast('success', 'Frete registrado!')
            await avancarFluxoMov();
        } catch (err: any) {
            showToast('error', err.message)
        }
    }

    const handleVoltar = async () => {
        if (pedidoState) {
            history.push({
                pathname: `${MovRotasMock.visualizarPedidoRota}/${pedidoState.id}`,
                state: {
                    pedido: pedidoState
                }
            })
            return
        }
        await redirectCarrinho();
    }

    const enderecoEntrega = useMemo(() => {
        if (pedidoState) {
            const endPedido = pedidoState.enderecoEntrega
            return {
                bairro: endPedido.bairro,
                rua: endPedido.logradouro,
                cep: endPedido.cep,
                municipio: endPedido.municipio,
                uf: endPedido.uf,
                complemento: endPedido.complemento,
                numero: endPedido.numero
            }
        }
        return {
            bairro: movEnd?.bairro || '',
            rua: movEnd?.logradouro || '',
            cep: movEnd?.cep || '',
            municipio: movEnd?.xMun || '',
            uf: movEnd?.uf || '',
            complemento: movEnd?.complemento || '',
            numero: movEnd?.numero || ''
        }
    }, [movEnd?.bairro, movEnd?.cep, movEnd?.complemento, movEnd?.logradouro, movEnd?.numero, movEnd?.uf, movEnd?.xMun, pedidoState])

    const coordenadasEmpresa = useMemo(() => {
        const empresa = getEmpresaAtual()
        if (isEmpty(empresa)) return undefined
        return {
            lat: empresa?.latitude || 0,
            lng: empresa?.longitude || 0
        }
    }, [getEmpresaAtual])

    return (
        <Container maxWidth="md" className={classes.containerPai}>
            <Box className={classes.container}>
                <Box className={classes.content} >
                    <DefaultCard hasTagStatus={false} className={classes.cardContainer}>
                        <Box className={classes.cardContent} p={2}>
                            {estaFora ? (
                                <Box flex flexDirection="column" justifyContent="center" >
                                    <div className={classes.iconAviso}>
                                        <AvisoIcon tipo="GERAL" />
                                    </div>
                                    <Box mb={3}>
                                        <Typography variant='h6' align='center'className={classes.textWarning}>
                                            Limite de KM atingido!
                                        </Typography>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography variant="body1" align='center'>
                                            O endereço do cliente está fora do KM permitido para entrega, cancele a entrega ou altere o endereço para outra rota(isso irá atualizar o cadastro do usuário).
                                        </Typography>
                                    </Box>
                                    <Button variant='contained' fullWidth color="primary" className={classNames('round')} onClick={handleVoltar}>
                                        Voltar
                                    </Button>
                                </Box>
                            ) : (
                                <Grid container>
                                    <Grid item sm={12} md={6} className={classes.gridFull}>
                                        <Box className={classes.boxCustom} p={isMobile ? 2 : 0} pb={0}>
                                            {isMobile && (
                                                <Box className={classes.enderecoEntregaMobile}
                                                    p={1}
                                                    mb={2}
                                                >
                                                    <Typography variant="body2" weight={600} >
                                                        <Box mb={1}>
                                                            Endereço de Entrega
                                                        </Box>
                                                    </Typography>
                                                    <Typography>
                                                        {completarEndereco(enderecoEntrega)}
                                                    </Typography>
                                                    <Typography>
                                                        Distância: {
                                                            vCalculado.distanciaMetros > 0
                                                                ? `${vCalculado.distanciaFormatada}${vCalculado.distanciaFormatada.includes('km') ? ` (${vCalculado.distanciaMetros}m)` : ''}`
                                                                : 'Informação não obtida'
                                                        }
                                                    </Typography>
                                                </Box>
                                            )}
                                            <LoadScript
                                                googleMapsApiKey={VariaveisAmbiente.googleMapsKey}
                                                nonce='printWindow'
                                            >
                                                <GoogleMap
                                                    mapContainerClassName={classes.mapContainer}
                                                    center={center}
                                                    zoom={10}
                                                    options={{
                                                        clickableIcons: false,
                                                        disableDefaultUI: true,
                                                        streetViewControl: false,
                                                    }}
                                                    onLoad={(map) => {
                                                        const bounds = new window.google.maps.LatLngBounds()
                                                        bounds.extend({ lat: center.lat, lng: center.lng })
                                                        bounds.extend({ lat: coordenadasEmpresa?.lat || 0, lng: coordenadasEmpresa?.lng || 0 })
                                                        if (isEmpty(coordenadasEmpresa))
                                                            map.fitBounds(bounds)
                                                    }}
                                                >
                                                    <Marker position={center} />
                                                    {!isEmpty(coordenadasEmpresa) && (
                                                        <Marker
                                                            position={coordenadasEmpresa}
                                                            icon={{
                                                                url: MarkerMarket || '',
                                                                scaledSize: {
                                                                    height: 40,
                                                                    width: 40,
                                                                    equals: () => true
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </GoogleMap>
                                            </LoadScript>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6} className={classes.gridFullP}>
                                        {!isMobile && (
                                            <Box className={classes.enderecoEntregaMobile}
                                                p={1}
                                                mb={2}
                                            >
                                                <Typography variant="body2" weight={600}>
                                                    <Box mb={1}>
                                                        Endereço de Entrega
                                                    </Box>
                                                </Typography>
                                                <Typography>
                                                    {completarEndereco(enderecoEntrega)}
                                                </Typography>
                                                <Typography>
                                                    Distância: {
                                                        vCalculado.distanciaMetros > 0
                                                            ? `${vCalculado.distanciaFormatada}${vCalculado.distanciaFormatada.includes('km') ? ` (${vCalculado.distanciaMetros}m)` : ''}`
                                                            : 'Informação não obtida'
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                        <FormTaxaEntrega
                                            loading={carregando}
                                            onSubmit={handleSubmit}
                                            showLoading={carregando}
                                            pedidoState={pedidoState}
                                            ref={formRef}
                                            vCalculado={vCalculado}
                                        />
                                        <Button
                                            fullWidth
                                            color='primary'
                                            variant='contained'
                                            onClick={() => {
                                                formRef.current?.submitForm()
                                            }}
                                            className={classNames(classes.submitButton, 'round')}
                                        >
                                            <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                                            Confirmar Dados
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </DefaultCard>
                </Box>
            </Box>
        </Container>
    );
};