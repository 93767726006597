import { Box, Grid, Typography } from 'views/design-system';
import { useGetHistoricoVendaCompleta } from 'data/api/gestao/historico-venda/get-historico-venda-completa';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularLoading } from 'views/components';
import { CardVendaDados } from 'views/components/cards/card-venda-dados/card-venda-dados';
import { ButtonFabMenu } from 'views/components/controles/buttons/button-fab-menu';
import { ButtonFabMenuModel } from 'model/app/components/button-fab-menu/button-fab-menu';
import { MenuVerticalIcon } from 'views/components/icons/menu-vertical-icon';
import { ImpressoraIcon } from 'views/components/icons/impressora-icon';
import { useThemeQueries } from 'views/theme';
import { CancelarIcon } from 'views/components/icons';
import { SMSIcon } from 'views/components/icons/sms-icon';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { useCadastros, useToastSaurus } from 'services/app';
import { useMovSimples } from 'services/app/hooks/mov-simples';
import { DialogEnviarCupomEmail } from 'views/components/dialog/dialog-enviar-cupom-venda-por-email/dialog-enviar-cupom-venda-por-email';
import { DialogEnviarCupomSMS } from 'views/components/dialog/dialog-enviar-cupom-venda-por-sms/dialog-enviar-cupom-venda-por-sms';
import { useHistory, useLocation } from 'react-router-dom';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useVenda } from 'services/app/hooks/gerenciar-venda';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { VendaOffModel } from 'model/api/gestao/venda/venda-off-model';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { isEmpty } from 'lodash';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import {
  EmitModel,
  PagsModel,
  ProdsModel,
  TotalModel,
  VendaCompletaModel
} from 'model/api/gestao/venda/venda-completa-model';
import { EnumMovModelo, RetornoApiModel } from 'model';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { EnumTpStatusMov } from 'model/enums/enum-tp-status-mov';
import { usePutFinalizarVenda } from 'data/api/gestao/venda/put-finalizar-venda';
import { VendaIcon } from 'views/components/icons/venda-icon';
import { useGetHistoricoVendasPendentesById } from 'data/api/gestao/historico-venda/get-historico-vendas-pendentes-by-id';
import { useStyles } from './detalhes-venda-data-styles';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';

interface DetalhesVendaDataProps {
  id: string;
}

export const DetalhesVendaData = (props: DetalhesVendaDataProps) => {
  //AUX
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles()
  const { alterarModo } = useMenuPrincipal()

  const urlParams = new URLSearchParams(history.location.search);
  const myParam = urlParams.get('offline');
  const estaSincronizado = {
    sincronizado: myParam ? false : true
  };

  //STATES
  const [email, setEmail] = useState<string[]>([]);
  const [numero, setNumero] = useState<string>('');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isModalSMS, setIsModalSMS] = useState<boolean>(false);
  const [isModalEmail, setIsModalEmail] = useState<boolean>(false);
  const [carregandoMenus, setCarregandoMenus] = useState<boolean>();
  const [vendaOff, setVendaOff] = useState<VendaOffModel>(new VendaOffModel());
  const [vendaCompleta, setVendaCompleta] = useState<VendaCompletaModel>(
    new VendaCompletaModel()
  );
  const { redirectCancelamento } = useMovRota();
  // CHAMADAS DA API
  const { getHistoricoVendaCompleta, carregando: carregandoVendaCompleta } =
    useGetHistoricoVendaCompleta();
  const { getHistoricoVendasPendentesById, carregando: carregandoVendaCompletaPendente } = useGetHistoricoVendasPendentesById()
  const { putFinalizarVenda, carregando: carregandoFinalizarVenda } = usePutFinalizarVenda()

  const carregando = carregandoVendaCompleta || carregandoMenus || carregandoFinalizarVenda || carregandoVendaCompletaPendente;
  // HOOKS
  const { getVenda, cancelarVenda } = useVenda();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { abrirDialogImpressaoNfe } = useCadastros();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const {
    enviarCupomPorEmail,
    enviarCupomPorSMS,
    imprimirCupom,
    enviarCupomVendaOffline
  } = useMovSimples();

  //BUTTON FAB
  const handleOpen = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const isPendenciaFiscal = location.pathname.includes('pendencias-fiscais')

  //SETANDO VALORES
  const valor = useCallback(() => {
    if (!estaSincronizado.sincronizado) {
      try {
        let email = '';
        let telefone = '';

        if (!isEmpty(vendaOff.cliente?.contatos)) {
          email = vendaOff.cliente?.contatos?.filter(
            (x) => x.tipo === EnumTipoPessoaContato.EMAIL
          )[0]?.valor!;

          if (isEmpty(email)) {
            email = '';
          }
          telefone = vendaOff.cliente?.contatos?.filter(
            (x) => x.tipo === EnumTipoPessoaContato.TELEFONE
          )[0]?.valor!;

          if (isEmpty(telefone)) {
            telefone = '';
          }
        }

        const icmsTotCompleto = new TotalModel();
        const icmsTot: TotalModel = {
          ...icmsTotCompleto,
          vnf: vendaOff.vNF
        };

        const venda = new EmitModel();
        const emit: EmitModel = {
          ...venda,
          xNome: getEmpresaAtual()?.nomeFantasia!,
          doc: getEmpresaAtual()?.cpfcnpj!,
          fone: getEmpresaAtual()?.fone!,
          cep: getEmpresaAtual()?.cep!,
          xLgr: getEmpresaAtual()?.logradouro!,
          nro: getEmpresaAtual()?.numero!,
          xBairro: getEmpresaAtual()?.bairro!,
          xMun: getEmpresaAtual()?.municipio!,
          uf: getEmpresaAtual()?.uf!,
          xCpl: getEmpresaAtual()?.complemento!
        };

        const produtos: ProdsModel[] = vendaOff.produtos.map((produtos) => {
          const venda = new ProdsModel();
          return {
            ...venda,
            xProd: produtos.xProd,
            cProd: produtos.cProd,
            nItem: produtos.qCom,
            vUnCom: produtos.vUnCom,
            vFinal: this
          };
        });

        const pagamentos: PagsModel[] = vendaOff.pags.map((pagamentos) => {
          const venda = new PagsModel();
          return {
            ...venda,
            tPag: pagamentos.modPagamento,
            vTroco: pagamentos.vTroco,
            vPag: pagamentos.vPag
          };
        });

        const vendaDetalheOff: VendaCompletaModel = {
          ...vendaCompleta,
          infMov: {
            ...vendaCompleta.infMov,
            dest: {
              ...vendaCompleta.infMov.dest,
              documento: vendaOff?.cliente?.cpfcnpj!,
              id: vendaOff?.cliente?.id!,
              pessoaId: vendaOff?.pessoaId!,
              xNome: vendaOff?.cliente?.nome!,
              email: email,
              fone: telefone
            },
            prod: produtos,
            mod: vendaOff.mod,
            tpEmis: vendaOff.tpEmis,
            pag: pagamentos,
            emit: emit,
            total: icmsTot,
            nnf: vendaOff.nnf,
            serie: vendaOff.serie
          },
          status: vendaOff.status
        };

        return vendaDetalheOff;
      } catch (err: any) {
        return showToast('error', err.message);
      }
    } else {
      return vendaCompleta;
    }
  }, [
    estaSincronizado.sincronizado,
    getEmpresaAtual,
    showToast,
    vendaCompleta,
    vendaOff.cliente?.contatos,
    vendaOff.cliente?.cpfcnpj,
    vendaOff.cliente?.id,
    vendaOff.cliente?.nome,
    vendaOff.mod,
    vendaOff.nnf,
    vendaOff.pags,
    vendaOff?.pessoaId,
    vendaOff.produtos,
    vendaOff.serie,
    vendaOff.status,
    vendaOff.tpEmis,
    vendaOff.vNF
  ]);

  const detalheVenda = useCallback(() => {
    const venda = valor();

    if (isEmpty(venda)) {
      return vendaCompleta;
    }

    return venda as VendaCompletaModel;
  }, [valor, vendaCompleta]);

  // VENDA ONLINE
  const getHistoricoVendaCompletaWrapper = useCallback(async () => {
    try {
      let res: RetornoApiModel<any>
      if (isPendenciaFiscal) {
        res = await getHistoricoVendasPendentesById(props.id)
      } else {
        res = await getHistoricoVendaCompleta(props.id);
      }

      if (res.erro) throw res.erro;

      const historico = res.resultado?.data as VendaCompletaModel;

      return setVendaCompleta(historico);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getHistoricoVendaCompleta, getHistoricoVendasPendentesById, isPendenciaFiscal, props.id, showToast]);

  const handleClickImprimirComprovante = useCallback(async () => {
    try {
      setCarregandoMenus(true);
      const venda = detalheVenda()
      if (venda.infMov.mod === EnumMovModelo.NFE) {
        const email = venda.infMov.dest.email
        abrirDialogImpressaoNfe({ movId: venda.id, emails: email ? [email] : [] });
        return;
      }
      await imprimirCupom({
        idVenda: props.id,
        tpEmis: venda.infMov.tpEmis,
        mod: venda.infMov.mod,
        checkTipoPdv: true,
        viaAPI: true,
      });
    } catch (err: any) {
      showToast(
        'error',
        `Não foi possível realizar a impressão. Detalhe: ${err.message}`
      );
    } finally {
      setCarregandoMenus(false);
    }
  }, [
    abrirDialogImpressaoNfe,
    detalheVenda,
    imprimirCupom,
    props.id,
    showToast
  ]);

  const handleClickEnviarComprovantePorEmail = useCallback(async (emails?: string[]) => {
    try {
      const getEmails = emails ?? email
      setCarregandoMenus(true);
      await enviarCupomPorEmail(props.id, getEmails.join(';'));
      setCarregandoMenus(false);
      showToast('success', `Cupom enviado para o E-mail: ${getEmails}`);
      setIsModalEmail(false);
      setEmail([]);
    } catch (err: any) {
      showToast('error', err.message);
      setCarregandoMenus(false);
    }
  }, [email, enviarCupomPorEmail, props.id, showToast]);

  const handleClickEnviarComprovantePorSMS = useCallback(async () => {
    try {
      setCarregandoMenus(true);
      await enviarCupomPorSMS(props.id, numero);
      setCarregandoMenus(false);
      showToast('success', `Cupom enviado para o Número: ${numero}`);
      setIsModalSMS(false);
      setNumero('');
    } catch (err: any) {
      showToast('error', err.message);
      setCarregandoMenus(false);
    }
  }, [enviarCupomPorSMS, numero, props.id, showToast]);

  const handleOpenOrCloseEmail = useCallback(() => {
    setIsModalEmail(!isModalEmail);
  }, [isModalEmail]);

  const handleOpenOrCloseSMS = useCallback(() => {
    setIsModalSMS(!isModalSMS);
  }, [isModalSMS]);

  const handleCancelarVenda = useCallback(async () => {
    const rotaAtual = location.pathname;

    try {
      await redirectCancelamento(
        props.id,
        {
          urlCalback: rotaAtual
        });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [location.pathname, props.id, redirectCancelamento, showToast]);

  // VENDA OFF
  const handleVendaOFF = useCallback(async () => {
    try {
      const res = await getVenda(props.id);
      if (!res) {
        setVendaOff(new VendaOffModel());
        return;
      }
      setVendaOff(res);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getVenda, props.id, showToast]);

  const handleClickImprimirComprovanteOFF = useCallback(async () => {
    try {
      setCarregandoMenus(true);
      const venda = detalheVenda()
      await imprimirCupom({
        idVenda: props.id,
        mod: venda.infMov.tpEmis,
        tpEmis: venda.infMov.tpEmis,
        checkTipoPdv: true,
        viaAPI: false,
      });
      setCarregandoMenus(false);
    } catch (err: any) {
      showToast(
        'error',
        `Não foi possível realizar a impressão. Detalhe: ${err.message}`
      );
      setCarregandoMenus(false);
    }
  }, [detalheVenda, imprimirCupom, props.id, showToast]);

  const handleClickEnviarComprovantePorEmailOFF = useCallback(async (emails?: string[]) => {
    try {
      const getEmails = emails ?? email
      setCarregandoMenus(true);
      await enviarCupomVendaOffline(props.id, getEmails.join(';'));
      setCarregandoMenus(false);
      showToast('success', `Cupom enviado para o E-mail: ${getEmails}`);
      setIsModalEmail(false);
      setEmail([]);
    } catch (err: any) {
      showToast('error', err.message);
      setCarregandoMenus(false);
    }
  }, [email, enviarCupomVendaOffline, props.id, showToast]);

  const handleClickEnviarComprovantePorSMSOFF = useCallback(async () => {
    try {
      setCarregandoMenus(true);
      await enviarCupomPorSMS(props.id, numero, detalheVenda().infMov.mod);
      setCarregandoMenus(false);
      showToast('success', `Cupom enviado para o Número: ${numero}`);
      setIsModalSMS(false);
      setNumero('');
    } catch (err: any) {
      showToast('error', err.message);
      setCarregandoMenus(false);
    }
  }, [detalheVenda, enviarCupomPorSMS, numero, props.id, showToast]);

  const handleOpenOrCloseEmailOFF = useCallback(() => {
    setIsModalEmail(!isModalEmail);
  }, [isModalEmail]);

  const handleOpenOrCloseSMSOFF = useCallback(() => {
    setIsModalSMS(!isModalSMS);
  }, [isModalSMS]);

  const handleCancelarVendaOFF = useCallback(async () => {
    const cancelada = await cancelarVenda(props.id);

    if (cancelada) {
      showToast('success', 'Venda cancelada');
      callEvent(AppEventEnum.AtualizarVendaOffiline, 1);
    }
  }, [callEvent, cancelarVenda, props.id, showToast]);

  //EFFECTS
  useEffect(() => {
    addHandler(AppEventEnum.AtualizarVendaOffiline, handleVendaOFF);

    return () =>
      removeHandler(AppEventEnum.AtualizarVendaOffiline, handleVendaOFF);
  }, [addHandler, handleVendaOFF, removeHandler]);

  useEffect(() => {
    if (estaSincronizado.sincronizado) {
      getHistoricoVendaCompletaWrapper();
    } else {
      handleVendaOFF();
    }
  }, [
    estaSincronizado.sincronizado,
    getHistoricoVendaCompletaWrapper,
    handleVendaOFF
  ]);

  const handleFinalizarVenda = useCallback(async () => {
    try {
      let conteudoVenda: VendaCompletaModel | undefined
      const res = await putFinalizarVenda(props.id)
      if (res.erro) throw res.erro

      const resData = res.resultado?.data
      conteudoVenda = resData

      await imprimirCupom({
        idVenda: props.id!,
        tpEmis: vendaCompleta.infMov.tpEmis,
        mod: vendaCompleta.infMov.mod,
        checkTipoPdv: true,
        conteudoMov: conteudoVenda
      });

      history.push(RetaguardaRotasMock.detalhesVendaRota.path.replace(':id', props.id))
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [history, imprimirCupom, props.id, putFinalizarVenda, showToast, vendaCompleta.infMov.mod, vendaCompleta.infMov.tpEmis])

  //ITENS BUTTON FAB
  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();
    if (vendaCompleta.status === EnumTpStatusMov.Finalizado) {
      itens.push(
        new ButtonFabMenuModel(
          <CancelarIcon tipo="BUTTON_FAB" />,
          'Cancelar Venda',
          async () => {
            if (estaSincronizado.sincronizado) {
              await alterarModo(EnumMenuPrincipalModo.PDV)
              return handleCancelarVenda();
            } else {
              return handleCancelarVendaOFF();
            }
          }
        )
      );
    }
    if (vendaCompleta.infMov.mod === EnumMovModelo.NFCE && vendaCompleta.statusFiscal !== EnumTpStatusMov.Finalizado) {
      itens.push(
        new ButtonFabMenuModel(
          <VendaIcon tipo="BUTTON_FAB" />,
          'Tentar Finalizar Venda',
          handleFinalizarVenda
        )
      )
    }
    itens.push(
      new ButtonFabMenuModel(
        <SMSIcon tipo="BUTTON_FAB" />,
        'Enviar por SMS',
        () => {
          if (estaSincronizado.sincronizado) {
            return handleOpenOrCloseSMS();
          } else {
            return handleOpenOrCloseSMSOFF();
          }
        }
      )
    );

    itens.push(
      new ButtonFabMenuModel(
        <EnviarEmailIcon tipo="BUTTON_FAB" />,
        'Enviar por e-mail',
        () => {
          if (estaSincronizado.sincronizado) {
            return handleOpenOrCloseEmail();
          } else {
            return handleOpenOrCloseEmailOFF();
          }
        }
      )
    );

    itens.push(
      new ButtonFabMenuModel(
        <ImpressoraIcon tipo="BUTTON_FAB" />,
        'Reimprimir',
        () => {
          if (estaSincronizado.sincronizado) {
            return handleClickImprimirComprovante();
          } else {
            return handleClickImprimirComprovanteOFF();
          }
        }
      )
    );

    return itens;
  }, [alterarModo, estaSincronizado.sincronizado, handleCancelarVenda, handleCancelarVendaOFF, handleClickImprimirComprovante, handleClickImprimirComprovanteOFF, handleFinalizarVenda, handleOpenOrCloseEmail, handleOpenOrCloseEmailOFF, handleOpenOrCloseSMS, handleOpenOrCloseSMSOFF, vendaCompleta.infMov.mod, vendaCompleta.status, vendaCompleta.statusFiscal]);

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid container p={2}>
        <Grid item xs={12}>
          {isPendenciaFiscal && !isEmpty(vendaCompleta?.ultimoRetornoFiscal) && (
            <Box className={classes.errorVenda}>
              <Typography variant="body1" weight={700}>
                Rejeição:
              </Typography>
              <Typography variant="body2" weight={500}>
                {vendaCompleta.ultimoRetornoFiscal.msgSefaz}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <CardVendaDados model={detalheVenda()} />
        </Grid>
      </Grid>

      <ButtonFabMenu
        open={openMenu}
        onOpen={handleOpen}
        onClose={handleClose}
        values={onClickMenuItem}
        color={theme.palette.primary.main}
        colorItens={theme.palette.primary.main}
        icon={
          <MenuVerticalIcon
            tipo="BUTTON_FAB"
            fill={openMenu ? theme.palette.primary.main : '#FFF'}
          />
        }
        style={{
          zIndex: 0,
          position: 'fixed'
        }}
      />

      {estaSincronizado.sincronizado ? (
        <>
          {isModalSMS && (
            <DialogEnviarCupomSMS
              loading={carregando || false}
              openned={isModalSMS}
              closeModal={handleOpenOrCloseSMS}
              numero={numero}
              setNumero={setNumero}
              enviarSMS={() => {
                handleClickEnviarComprovantePorSMS();
              }}
            />
          )}

          {isModalEmail && (
            <DialogEnviarCupomEmail
              loading={carregando || false}
              openned={isModalEmail}
              closeModal={handleOpenOrCloseEmail}
              email={email}
              setEmail={setEmail}
              enviarEmail={async (emails) => {
                handleClickEnviarComprovantePorEmail(emails);
              }}
            />
          )}
        </>
      ) : (
        <>
          {isModalSMS && (
            <DialogEnviarCupomSMS
              loading={carregando || false}
              openned={isModalSMS}
              closeModal={handleOpenOrCloseSMSOFF}
              numero={numero}
              setNumero={setNumero}
              enviarSMS={() => {
                handleClickEnviarComprovantePorSMSOFF();
              }}
            />
          )}

          {isModalEmail && (
            <DialogEnviarCupomEmail
              loading={carregando || false}
              openned={isModalEmail}
              closeModal={handleOpenOrCloseEmailOFF}
              email={email}
              setEmail={setEmail}
              enviarEmail={(emails) => {
                handleClickEnviarComprovantePorEmailOFF(emails);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
