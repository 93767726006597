import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon";

export const HistoricoVendaIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M 6 2 L 6 46 L 32 46 L 32 44 L 8 44 L 8 4 L 42 4 L 42 28 L 44 28 L 44 2 Z M 14 11 L 14 13 L 36 13 L 36 11 Z M 14 24 L 14 26 L 18 26 L 18 24 Z M 21 24 L 21 26 L 36 26 L 36 24 Z M 14 30 L 14 32 L 18 32 L 18 30 Z M 21 30 L 21 32 L 30.183594 32 C 30.070313 31.6875 30 31.351563 30 31 C 30 30.648438 30.070313 30.3125 30.183594 30 Z M 33 30 C 32.398438 30 32 30.398438 32 31 C 32 31.601563 32.398438 32 33 32 L 34 32 L 34 35 C 34 36.398438 34.699219 37.699219 35.902344 38.699219 L 37.5 40 L 36 41.300781 C 34.699219 42.300781 34 43.5 34 45 L 34 48 L 33 48 C 32.398438 48 32 48.398438 32 49 C 32 49.601563 32.398438 50 33 50 L 49 50 C 49.601563 50 50 49.601563 50 49 C 50 48.398438 49.601563 48 48.902344 48 L 48 48 L 48 45 C 48 43.601563 47.398438 42.300781 46.097656 41.300781 L 44.597656 40 L 46.097656 38.699219 C 47.398438 37.699219 48 36.398438 48 35 L 48 32 L 49 32 C 49.601563 32 50 31.601563 50 31 C 50 30.398438 49.601563 30 49 30 Z M 36 32 L 46 32 L 46 35 C 46 35.800781 45.699219 36.5 44.902344 37.199219 L 42.5 39.199219 C 42.199219 39.398438 42.097656 39.699219 42.097656 40 C 42.097656 40.300781 42.199219 40.597656 42.5 40.699219 L 44.902344 42.800781 C 45.699219 43.5 46 44.101563 46 45 L 46 48 L 44 48 C 44 46 41 44 41 44 C 41 44 38 46 38 48 L 36 48 L 36 45 C 36 44.101563 36.398438 43.5 37.199219 42.800781 L 39.597656 40.800781 C 39.898438 40.601563 40 40.300781 40 40 C 40 39.699219 39.898438 39.402344 39.597656 39.300781 L 37.199219 37.199219 C 36.398438 36.5 36 35.800781 36 35 Z M 38 35 C 38 37.199219 41 38 41 38 C 41 38 44 37.199219 44 35 Z M 14 36 L 14 38 L 18 38 L 18 36 Z M 21 36 L 21 38 L 32.777344 38 C 32.433594 37.367188 32.203125 36.695313 32.09375 36 Z" />
      </DefaultIcon>
    </>
  );
};
