import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    containerGrid: {
        padding: theme.spacing(1),
        display: 'block'
    },
    cardForm: {
        "& form": {
            marginTop: 0
        },
    },
    defaultCard: {
        padding: theme.spacing(1)
    }
}));