import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardInfo: {
        background: 'rgba(31, 156, 228, 0.07)',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
    },
    tagProdutos: {
        cursor: 'default',
        background: theme.palette.text.hint,
        padding: '2px 8px',
        borderRadius: '20px',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        "& span": {
            fontSize: 10,
        },
        "& svg": {
            width: 14,
            marginRight: 4,
            height: 14,
            fill: theme.palette.text.primary,
        }
    },
    tagCodigos: {
        background: "#def3bd",
        color: theme.palette.text.primary
    },
    tagCategoria: {
        background: '#F3E8BD',
        color: theme.palette.text.primary
    },
    tagTarja: {
        background: "#f9e8d2",
        color: theme.palette.text.primary,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagClasse: {
        background: "#e5f3ee",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagTipoMedicamento: {
        background: "#f7e0f3",
        color: theme.palette.text.primary
    },
    tagComposicao: {
        background: "#e2dbf7",
        color: theme.palette.text.primary,
        maxWidth: 300,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    tagLaboratorio: {
        background: "#efefef",
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tagLaboratorioDestaque: {
        background: "#fdf6d0",
        "& span": {
            fontWeight: 600,
        }
    },
}))