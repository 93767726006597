import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useDataYup } from 'views/components/form-validations/yup/data';
import * as Yup from 'yup';

export const useFormPessoaDocumentoValidation = (validarUF: boolean) => {
  const { dataYup } = useDataYup()
  const FormPessoaDocumentoValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        tipo: Yup.number().required('Tipo é obrigatório'),
        documento: Yup.string().required('Documento é obrigatório.'),
        orgaoExpedicao: Yup.number().required('Órgão expedidor é obrigatório.').typeError('Órgão expedidor é obrigatório.'),
        ufExpedicao: validarUF ? Yup.string().required('Informe a UF').typeError('Informe a UF') : Yup.string().notRequired(),
        dataExpedicao: dataYup(),
        dataValidade: dataYup().test({
            test: (value) => {
                if(isEmpty(value)) return true
                return new Date(value || '') > new Date()
            },
            message: 'Data de validade expirada.'
        })
      })
    )
  }, [dataYup, validarUF])
  return {
    FormPessoaDocumentoValidationYup,
  }
}

