import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardNaoEncontradoSmall: {
    '& svg': {
      width: '60px!important',
      height: '60px!important',
      margin: theme.spacing(2),
      fill: theme.palette.text.primary
    }
  },
  cardNaoEncontrado: {
    '& .celula-grid-value': {
      whiteSpace: 'normal',
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem'
      }
    },

    '& svg': {
      width: 80,
      height: 80,
      margin: theme.spacing(4),
      fill: theme.palette.text.primary,
    },
    cursor: 'auto'
  },
  marginCard: {
    margin: theme.spacing(4 )
  },
  textCenter: {
    textAlign: 'center'
  },

  //IOS
  cardNaoEncontradoSmallIOS: {
    '& svg': {
      width: '50px!important',
      height: '50px!important',
      fill: theme.palette.text.primary,
      margin: theme.spacing(1),
    }
  },

}));
