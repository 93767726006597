import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormEntradaCadastroValidation } from './form-produto-entrada-validation';
import { FormEntradaCadastroProps } from './form-produto-entrada-props';
import { ProdutoEntradaFormModel } from 'model/app/forms/entrada/produto-entrada-form-model';
import { FormPeICMS } from './components/accordion-pe-icms/form-pe-icms';
import { FormPeIcmsUfDestino } from './components/accordion-pe-icms-uf-destino/form-pe-icms-uf-destino';
import { FormPeFcp } from './components/accordion-pe-fcp/form-pe-fcp';
import { FormPePis } from './components/accordion-pe-pis/form-pe-pis';
import { FormPeCofins } from './components/accordion-pe-cofins/form-pe-cofins';
import { FormPeIpi } from './components/accordion-pe-ipi/form-pe-ipi';
import { FormPeIi } from './components/accordion-pe-ii/form-pe-ii';
import { CfopMock } from 'data/mocks/cst-cfop-mock';
import { picker } from 'utils/picker';
import { AutocompleteCFOPs } from 'views/components/controles/autocompletes/autocomplete-cfops/autocomplete-cfops';
import { KeyValueModel } from 'model';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { AutocompleteNcmsGestao } from 'views/components/controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { useStyles } from './form-produto-entrada-styles';
import { AutocompleteProdutos, EnumNomeCodigo } from 'views/components/controles/autocompletes/autocomplete-produtos/autocomplete-produtos';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { AutocompleteMedidas } from 'views/components/controles/autocompletes/autocomplete-medidas/autocomplete-medidas';
import { ProdutoMedidaModel } from 'model/api/gestao/produto/produto-medida/produto-medida-model';
import { FormPeRastro } from './components/accordion-pe-rastro/form-pe-rastro';
import { toDate } from 'utils/to-date';
import { FormPeMedicamento } from './components/accordion-pe-medicamento/form-pe-medicamento';
import { CofinsModel, IcmsModel, IiModel, IpiModel, PisModel, RastroModel } from 'model/api/gestao/venda/venda-completa-model';
import { isEmpty } from 'lodash';
import { toDecimal } from 'utils/to-decimal';
import { useGetProdutoVariacao } from 'data/api/gestao/produto/produto-variacao/get-produto-variacao';
import { ProdutoVariacaoModel } from 'model/api/gestao/produto/produto-variacao/produto-variacao';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { retornoAutoComplete } from 'views/components/controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { guidEmpty } from 'utils/guid-empty';
import { UFMock } from 'data/mocks';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { MedicamentoPreco } from 'model/api/gestao/medicamento/medicamento-model';
import { EnumTarja } from 'model/enums/enum-tarja';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useGetMedicamentoCarga } from 'data/api/gestao/medicamento-preco/get-medicamento-carga';
import { AcrescimoItemModel, DescontoItemModel } from 'model/api/gestao/venda/venda-desconto-model';
import { AutocompleteDepositos } from 'views/components/controles/autocompletes/autocomplete-depositos/autocomplete-depositos';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';

export const FormProdutoEntrada = forwardRef<
    DefaultFormRefs<ProdutoEntradaFormModel>,
    FormEntradaCadastroProps
>((props, ref) => {
    const utilClasses = makeUtilClasses();
    const [model, setModel] = useState<ProdutoEntradaFormModel>(new ProdutoEntradaFormModel())
    const [temCodigo, setTemCodigo] = useState<boolean>(true);
    const { formProdutoEntradaValidationYup } = useFormEntradaCadastroValidation({temCodigo});
    const modelFormRef = useRef<ProdutoEntradaFormModel>(new ProdutoEntradaFormModel())
    const classes = useStyles()
    const { getEmpresaAtual } = useEmpresaAtual()
    const { getProdutoVariacao, carregando: carregandoProdutoVariacao } = useGetProdutoVariacao()
    const { getMedicamentoCarga, carregando: carregamentoMedicamentoCarga } = useGetMedicamentoCarga();
    const { showToast } = useToastSaurus()
    const { plano } = useSessaoAtual()

    const carregando = [carregamentoMedicamentoCarga, carregandoProdutoVariacao].includes(true)

    const {
        handleSubmit,
        control,
        formState,
        reset,
        setValue,
        getValues,
        getFieldState,
    } = useForm<ProdutoEntradaFormModel>({
        resolver: yupResolver(formProdutoEntradaValidationYup),
        criteriaMode: 'all',
        defaultValues: { ...model },
        mode: 'onChange',
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'rastro',
    });

    const { errors } = formState

    const dataFormatada = useCallback((val: string | Date) => {
        const data = toDate(val) ?? new Date(val);
        const dia = data?.getDate();
        const mes = data?.getMonth() + 1;
        const ano = data?.getFullYear();

        const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
            }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
        return dataFormated;
    }, []);

    const onSubmit = (values: ProdutoEntradaFormModel) => {
        const produto = picker<ProdutoEntradaFormModel>(values, new ProdutoEntradaFormModel())
        produto.icms = picker<IcmsModel>(values.icms, new IcmsModel())
        produto.cofins = picker<CofinsModel>(values.cofins, new CofinsModel())
        produto.ipi = picker<IpiModel>(values.ipi, new IpiModel())
        produto.pis = picker<PisModel>(values.pis, new PisModel())
        produto.ii = picker<IiModel>(values.ii, new IiModel())
        produto.rastro = isEmpty(values.rastro) ? [] : values.rastro
        produto.rastro = values.rastro.map(x => picker<RastroModel>(x, new RastroModel()))
        if (produto.icms.motDesIcms === 0) {
            produto.icms.motDesIcms = null
        }
        produto.acrescimos = new Array<AcrescimoItemModel>()
        produto.descontos = new Array<DescontoItemModel>()
        produto.depositoId = values.depositoId
        props.onSubmit(produto, modelFormRef.current);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: ProdutoEntradaFormModel) => {
            model.cfopNome = CfopMock.find(x => (x.Key = + x.Key) === model.cfop)?.Value || ''
            model.rastro = model.rastro.map(x => ({
                ...x,
                dFab: x.dFab ? dataFormatada(x.dFab) : '',
                dVal: x.dVal ? dataFormatada(x.dVal) : '',
            }))
            model.depositoNome = model.depositoId || '';
            setModel(model)
            reset({ ...model })
            modelFormRef.current = { ...model }
        },
    }));


    const calcularValores = useCallback(
        (event: any) => {
            setValue(event.target.name, event.target.value);

            const qCom = toDecimal(
                event.target.name === 'qCom'
                    ? event.target.value
                    : getValues('qCom')
            )

            const vUnCom = toDecimal(
                event.target.name === 'vUnCom'
                    ? event.target.value
                    : getValues('vUnCom')
            )

            const vProd = toDecimal(
                event.target.name === 'vProd'
                    ? event.target.value
                    : getValues('vProd')
            )

            if (event.target.name === 'vProd') {
                setValue('vUnCom', toDecimal(vProd / toDecimal(qCom, 4), 2))
                return
            }

            setValue('vProd', toDecimal(toDecimal(qCom, 4) * vUnCom))

        }, [getValues, setValue]);


    const propsForm = { control, formState, setValue, getValues, loading: props.loading }

    const buscarMedicamento = useCallback(async (codigo) => {
        try {
            const uf = UFMock.find(uf => uf.Value === getEmpresaAtual()?.uf)?.Key ?? 35;
            const codArray = [codigo];
            const res = await getMedicamentoCarga(codArray, uf);
            if (res.erro) throw res.erro

            const list = res.resultado?.data
            if (list.length <= 0) return null
            return list?.[0] as MedicamentoPreco
        } catch (error: any) {
            if (error.message === undefined) return
            showToast('error', `Ocorreu um problema ao buscar as informações do medicamento. Detalhe: ${error.message}`)
        }
    }, [getEmpresaAtual, getMedicamentoCarga, showToast])

    const getProdVariacaoWrapper = async (prodId: string, gradeId: string) => {
        try {
            const res = await getProdutoVariacao(prodId, gradeId)
            if (res.erro) throw res.erro

            return res.resultado?.data as ProdutoVariacaoModel
        } catch (error: any) {
            if (error.message === undefined) return
            showToast('error', `Erro ao carregar as informações do produto. Detalhe: ${error.message}`)
        }
    }

    const selecionarProduto = async (retorno: retornoAutoComplete<any>) => {
        const produto: ProdutoResumidoModel = retorno.value
        const variacao = await getProdVariacaoWrapper(produto.produtoId, produto.produtoGradeId)
        setValue('xProd', produto.nome)
        setValue('cEan', produto.codigo)
        setValue('cEanTrib', produto.codigo)
        setValue('categoriaId', produto.categoriaId)
        setValue('categoriaNome', produto.categoriaDescricao)
        setValue('vUnCom', variacao?.vCompra ?? 0)
        setValue('vProd', variacao?.vCompra ?? 0)
        setValue('uCom', produto.medida)
        setValue('uTrib', produto.medida)
        setValue('qCom', 1)
        setValue('ncm', produto.ncm)
        setValue('produtoGradeId', produto.produtoGradeId)
        setValue('cProdANVISA', produto.codigoAnvisa ?? '')

        setTemCodigo(Boolean(produto.codigo));

        if (produto.codigoAnvisa) {
            const medPreco = await buscarMedicamento(produto.codigoAnvisa)

            if (medPreco) {
                setValue('vendaControlada', [EnumTarja.TarjaPreta, EnumTarja.TarjaVermelha].includes(medPreco.tarja))
                setValue('vPMC', medPreco.precoMaximoConsumidor ?? 0)
            }
        }
    }

    useEffect(() => {
        if (errors && errors.rastro) {
            showToast('error', (errors.rastro?.message ?? ''))
        }
    }, [errors, showToast])

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {(props.loading && props.showLoading) || carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="cEan"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteProdutos
                                        disabled={props.loading}
                                        useCache={false}
                                        isCodigoNome
                                        label={isPlanoFarmaceutico(plano?.plano) ? "Código do Produto ou Código de Anvisa" : "Código do Produto "}
                                        allowSubmit
                                        nomeCodigo={EnumNomeCodigo.Todos}
                                        placeholder="Selecione o Produto"
                                        error={Boolean(errors.cEan && errors.cEan.message)}
                                        helperText={
                                            errors.cEan
                                                ? errors.cEan?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(retorno) => {
                                            if (!retorno.isString) {
                                                selecionarProduto(retorno)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xProd"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteProdutos
                                        disabled={props.loading}
                                        label="Descrição"
                                        useCache={false}
                                        isCodigoNome
                                        allowSubmit
                                        textNovoItem={"Editar para:"}
                                        permiteAdicionar={!isEmpty(getValues('produtoGradeId')) && getValues('produtoGradeId') !== guidEmpty()}
                                        nomeCodigo={EnumNomeCodigo.Nome}
                                        placeholder="Selecione o Produto"
                                        error={Boolean(errors.xProd && errors.xProd.message)}
                                        helperText={
                                            errors.xProd
                                                ? errors.xProd?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={retorno => {
                                            if (retorno.isString) {
                                                setValue('xProd', retorno.value)
                                                return
                                            }
                                            selecionarProduto(retorno)
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="cfopNome"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteCFOPs
                                        disabled={props.loading}
                                        label="CFOP"
                                        allowSubmit
                                        error={Boolean(errors.cfop && errors.cfop.message)}
                                        helperText={
                                            errors.cfop
                                                ? errors.cfop?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(retorno) => {
                                            if (!retorno.isString) {
                                                const cfop = retorno.value as KeyValueModel
                                                setValue("cfop", cfop.Key)
                                                setValue("cfopNome", cfop.Value)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="ncm"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteNcmsGestao
                                        modo="campoFormatado"
                                        loadingExterno={props.loading}
                                        label="Código NCM"
                                        useCache={false}
                                        placeholder="NCM:"
                                        error={Boolean(
                                            errors.ncm && errors.ncm.message,
                                        )}
                                        helperText={
                                            errors.ncm
                                                ? errors.ncm?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(retorno) => {
                                            if (!retorno.isString) {
                                                const ncm = picker<NcmModel>(
                                                    retorno.value,
                                                    new NcmModel(),
                                                );
                                                setValue('ncm', ncm.codigo);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="uCom"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteMedidas
                                        disabled={props.loading}
                                        label="Medida"
                                        allowSubmit
                                        error={Boolean(errors.uCom && errors.uCom.message)}
                                        helperText={
                                            errors.uCom
                                                ? errors.uCom?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={retorno => {
                                            if (!retorno.isString) {
                                                const medida: ProdutoMedidaModel = retorno.value
                                                setValue('uCom', medida.sigla)
                                                setValue('uTrib', medida.sigla)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vUnCom"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        manterMascara
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Valor Unitário"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vUnCom && errors.vUnCom.message)}
                                        helperText={
                                            errors.vUnCom
                                                ? errors.vUnCom?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={calcularValores}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="qCom"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        casasDecimais={3}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Quantidade"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.qCom && errors.qCom.message)}
                                        helperText={
                                            errors.qCom
                                                ? errors.qCom?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={calcularValores}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vDesc"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Valor Desconto"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vDesc && errors.vDesc.message)}
                                        helperText={
                                            errors.vDesc
                                                ? errors.vDesc?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vFrete"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Frete"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vFrete && errors.vFrete.message)}
                                        helperText={
                                            errors.vFrete
                                                ? errors.vFrete?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vSeg"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Seguro"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vSeg && errors.vSeg.message)}
                                        helperText={
                                            errors.vSeg
                                                ? errors.vSeg?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vOutro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Despesas Acessórias"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vOutro && errors.vOutro.message)}
                                        helperText={
                                            errors.vOutro
                                                ? errors.vOutro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="vProd"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'DECIMAL'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Valor Total"
                                        allowSubmit
                                        manterMascara
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.vProd && errors.vProd.message)}
                                        helperText={
                                            errors.vProd
                                                ? errors.vProd?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={calcularValores}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="depositoNome"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteDepositos
                                        loadingExterno={props.loading}
                                        label="Depósito"
                                        placeholder="Depósito"
                                        fetchOnLoad
                                        error={Boolean(
                                            errors.depositoNome && errors.depositoNome.message,
                                        )}
                                        helperText={
                                            errors.depositoNome
                                                ? errors.depositoNome?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(retorno) => {
                                            if (!retorno.isString) {
                                                const deposito = retorno.value
                                                setValue('depositoId', deposito.depositoId);
                                                setValue('depositoNome', deposito.depositoNome)
                                                return
                                            }
                                            setValue('depositoId', '')
                                            setValue('depositoNome', '')
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="h6">
                                Impostos
                            </Typography>
                            <Divider className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeICMS {...propsForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeIcmsUfDestino {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeFcp {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPePis {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeCofins {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeIpi {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeIi {...propsForm}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeRastro
                                {...{
                                    ...propsForm,
                                    append,
                                    remove,
                                    fields,
                                    getFieldState,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPeMedicamento
                                loading={props.loading}
                                control={control}
                                formState={formState}
                                setValue={setValue}
                                getValues={getValues}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
