
/*
 Criei essa modelo por causa das promoções, aparentemente no modelo as antigas, quando desativadas,
 as tabelas eram totalmente excluídas, depois caso fossem ativadas novamente eram re-colocadas na sincronização,
 porém como a sincronização de carga não trás todos os itens, eu não posso excluir a tabela inteira, então vou usar
 essa flag no hook de bancoLocal pra caso esteja true, ignore a tabela.
*/

export interface DisabledTable {
    disabledTable?: boolean;
}

export class DisabledTableModel {
    constructor(
        public disabledTable?: boolean,
    ) { }
}