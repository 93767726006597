import { useStyles } from './icon-card-styles';
import React, { useCallback } from 'react';
import DragDropCard from './components/drag-drop-card';
import classNames from 'classnames';
import { Box, Typography } from 'views/design-system';

export type IconCardBg = 'primary' | 'success' | 'error' | 'secondary'

export interface IconCardProps<T> {
  icon: React.ReactNode;
  title: string;
  onClick: (model: T) => any;
  model: T;
  children?: React.ReactNode;
  description?: string;
  index?: number;
  onDrop?: (current: number) => void;
  draggable?: boolean;
  setDraggingIndex?: (index: number) => void;
  size?: 'normal' | 'small';
  wrapText?: boolean;
  fontSize?: number;
  isOpacity?: boolean;
  background?: IconCardBg;
  disabled?: boolean;
  draggingLimitElement?: HTMLElement | null; //Passe o elemento que você deseja limitar o drag n drop
}

export function IconCard<T>(props: IconCardProps<T>) {
  const classes = useStyles();

  const renderChildren = [
    Boolean(props.children) && (
      <Box className={classes.childrenItem}>{props.children}</Box>
    ),
    props.icon,
    props.index !== undefined && (
      <Typography align="center" variant="caption" className={props.background ? classes.captionBackground : classes.caption}>
        {(props.index || 0) + 1}
      </Typography>
    ),
    <Typography color="primary" align="center">
      {props.title}
    </Typography>,
    Boolean(props.description) && (
      <Typography color="textSecondary" variant="caption" align="center" className={props.background ? classes.captionBackground : classes.caption}>
        {props.description}
      </Typography>
    )
  ];

  const retBackground = useCallback((background?: IconCardBg) => {
    switch (background) {
      case 'primary':
        return classes.backgroundPrimary;
      case 'secondary':
        return classes.backgroundSecondary;
      case 'success':
        return classes.backgroundSuccess;
      case 'error':
        return classes.backgroundError;
      default:
        return undefined;
    }
  }, [classes.backgroundError, classes.backgroundPrimary, classes.backgroundSecondary, classes.backgroundSuccess])

  return (
    <DragDropCard<T>
      {...props}
      childs={renderChildren}
      card={
        <div
          className={classNames(
            classes.card,
            Boolean(props.children) ? classes.cardFlexStart : undefined,
            props.size === 'small' ? classes.cardSmall : undefined,
            props.background ? classes.changedBackground : undefined,
            retBackground(props.background)
          )}
          onClick={() => {
            if (!props.disabled) props.onClick(props.model)
          }}
          style={{ opacity: props.isOpacity ? 1 : 0.6 }}
        >
          {Boolean(props.children) && (
            <Box className={classes.childrenItem}>{props.children}</Box>
          )}
          {props.icon}
          {props.index !== undefined && (
            <Typography
              style={{
                whiteSpace: props.wrapText ? 'unset' : 'nowrap',
                fontSize: props.fontSize || 'unset'
              }}
              align="center"
              variant="caption"
              className={props.background ? classes.captionBackground : classes.caption}
            >
              {(props.index || 0) + 1}
            </Typography>
          )}
          <Typography
            color="primary"
            align="center"
            style={{
              whiteSpace: props.wrapText ? 'unset' : 'nowrap',
              fontSize: props.fontSize || 'unset'
            }}
          >
            {props.title}
          </Typography>
          {Boolean(props.description) && (
            <Typography
              color="textSecondary"
              variant="caption"
              align="center"
              style={{
                whiteSpace: props.wrapText ? 'nowrap' : 'unset',
                fontSize: props.fontSize || 'unset'
              }}
              className={props.background ? classes.captionBackground : classes.caption}
            >
              {props.description}
            </Typography>
          )}
          {props.disabled && <div className={classes.disabledLayer} />}
        </div>
      }
    />
  );
}
