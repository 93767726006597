import { Grid, Typography } from 'views/design-system';
import { useStyles } from './opcoes-list-data-styles';
import { MenuModel } from 'model/app';
import { useCallback, useEffect, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMenu, EnumMovModelo } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
  isPlanoComVendedor,
  isPlanoControleMesasComandas,
  isPlanoConvenio,
  isPlanoDeliveryProprio,
  isPlanoEntregaDelivery,
  isPlanoFarmaceutico
} from 'utils/plano-utils';
import { VariaveisAmbiente } from 'config';
import { ModulosPDVMock } from 'data/mocks/menu-pdv-mock';
import { useMenuFavoritos } from 'services/app/hooks/menu-favoritos';
import { retornaNomeModuloPai } from 'utils/retorna-nome-modulo-pai';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { farmaceuticoMenuEnumPermissao } from 'data/mocks/farmaceutico-menu-enum-permissao';
import CardMenuPdv from '../../../../../../components/cards/card-menu-pdv/card-menu-pdv';
import { useThemeQueries } from 'views/theme';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useMovInsercaoStorage } from 'services/app/hooks/mov-insercao-storage';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumTipoSincronizacao } from 'model/enums/enum-tipo-sincronizacao';
import { EnumTpControleSessao } from 'model/enums/enum-tp-controle-sessao';

export const OpcoesListData = () => {
  // STATES E REFS
  const [modulosRetornados, setModulosRetornados] = useState([] as MenuModel[]);

  // HOOKS
  const { setFavorito, getFavoritos } = useMenuFavoritos();
  const { addHandler, removeHandler } = useEventTools();
  const { plano, getPermissaoBoolean } = useSessaoAtual();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus()
  const { actionMenuPDV } = useOpenMovRota()
  const { getConfigByCod } = useEmpresaAtual()
  const { getConfiguracoesMesaEComanda } = usePedidoLocal()
  const { setInsercaoFavorita, getInsercaoFavorita } = useMovInsercaoStorage();
  const { getMov } = useMovAtual();

  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarCliente)
  const tpControleSessao = getConfigByCod<EnumTpControleSessao>(EnumEmpresaConfig.TipoControleSessao)

  const planoDeliveryProprio = isPlanoDeliveryProprio(plano?.plano)
  const planoEntregaDelivery = isPlanoEntregaDelivery(plano?.plano)
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
  const isConvenio = isPlanoConvenio(plano?.plano);
  const isDelivery = getMov()?.mod === EnumMovModelo.DELIVERY;
  const naoControlaSessao = tpControleSessao === EnumTpControleSessao.NaoControla

  const soliClientesPorModeloTrabalho = useCallback(() => {
    return Number(solicitarClienteVenda)
  }, [solicitarClienteVenda])

  //  AUX
  const classes = useStyles();

  const serializeMenu = useCallback(() => {
    const retornarPermissao = (item: EnumMenu): boolean => {
      switch (item) {
        case EnumMenu.OPERACIONAL_DENTROVENDA_HISTORICO_VENDA:
          return getPermissaoBoolean(EnumCodigosPermissoes.HISTORICO_VENDAS);
        case EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA:
          return getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_VENDAS);
        case EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV:
          return getPermissaoBoolean(EnumCodigosPermissoes.PONTOS_DE_VENDA);
        case EnumMenu.OPERACIONAL_DENTROVENDA_FORCAR_SINCRONIZACAO:
          if (getConfigByCod(EnumEmpresaConfig.ModeloSincronizacao) === EnumTipoSincronizacao.LEGACY) {
            return true
          }
          return true
        case EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS:
          if (getConfigByCod(EnumEmpresaConfig.ModeloSincronizacao) === EnumTipoSincronizacao.LEGACY) {
            return false
          }
          return true;
        default:
          return true;
      }
    }
    const ret = ModulosPDVMock
      .filter((modulo) => modulo.codigoPai !== 0)
      .filter((x) => {

        if (isFarmaceutico && (farmaceuticoMenuEnumPermissao.includes(x.codigo))) {
          return false
        }

        if (!isConvenio && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_CONSULTAR_CONVENIO)
          return false;

        if (
          !isPlanoComVendedor(plano?.plano) &&
          EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR === x.codigo
        ) {
          return false;
        }
        const naoApareceCodigo = [
          EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE,
        ].includes(x.codigo)
        const naoApareceCodigoPai = [
          EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES,
          EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_FINALIZAR_VENDA
        ].includes(x.codigoPai)

        if (soliClientesPorModeloTrabalho() === 0 && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE) {
          return false
        }
        if (!(planoDeliveryProprio || planoEntregaDelivery) && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY) {
          return false
        }
        if ((!planoDeliveryProprio) && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY) {
          return false
        }
        if (isDelivery) {
          if (naoApareceCodigoPai || naoApareceCodigo) {
            return false
          }
        }
        if (x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_FORCAR_SINCRONIZACAO) {
          if (getConfigByCod(EnumEmpresaConfig.ModeloSincronizacao) === EnumTipoSincronizacao.LEGACY) {
            x.nome = 'Sincronizar Cadastros';
          } else {
            x.nome = 'Forçar Sincronização'
          }
        }

        if (
          !VariaveisAmbiente.performanceDebugMode &&
          EnumMenu.RELATORIOS_PERFORMANCE === x.codigo
        ) {
          return false;
        }
        if (
          !isPlanoControleMesasComandas(plano?.plano) &&
          ([EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_BALCAO,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA,
          EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS].includes(x.codigo))
        ) {
          return false;
        }

        if (getConfiguracoesMesaEComanda()?.tipoTrabalho !== EnumTipoTrabalho.COMANDA &&
          x.codigo === EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA) {
          return false
        }

        if (naoControlaSessao && (
          x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSAO ||
          x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_GERENCIAR_SESSOES
        )
        ) return false

        return retornarPermissao(x.codigo);
      })
      .map((item) => {
        //REDIRECIONAR PARA A TELA DE PRODUTOS E NÃO DASHBOARD CASO TENHA PERMISSÃO
        if (item.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA) {
          if (getPermissaoBoolean(EnumCodigosPermissoes.PRODUTOS)) {
            item.rota = '/produtos'
          }
        }
        const favorito = getFavoritos().find(
          (fav) => fav.codigo === item.codigo
        );
        if (favorito) {
          favorito.favorito = true;
          favorito.descricao = item.descricao;
          favorito.nome = item.nome;
          favorito.rota = item.rota;
          return favorito;
        }
        return item;
      });

    setModulosRetornados(ret);
  }, [getConfigByCod, getConfiguracoesMesaEComanda, getFavoritos, getPermissaoBoolean, isConvenio, isDelivery, isFarmaceutico, naoControlaSessao, plano?.plano, planoDeliveryProprio, planoEntregaDelivery, soliClientesPorModeloTrabalho]);

  useEffect(() => {
    serializeMenu();
  }, [serializeMenu]);

  useEffect(() => {
    addHandler(AppEventEnum.MenuPDVFavorito, serializeMenu);
    return () => {
      removeHandler(AppEventEnum.MenuPDVFavorito, serializeMenu);
    };
  }, [addHandler, removeHandler, serializeMenu]);

  const handleClick = useCallback(
    (opcao: MenuModel) => {
      actionMenuPDV(opcao.codigo, opcao.rota);
    },
    [actionMenuPDV]
  );

  const getValorPadrao = useCallback((rota: EnumMenu) => {
    return ModulosPDVMock.find(mock => mock.codigo === rota)?.nome ?? ''
  }, [])

  const [rotaFavorita, setRotaFavorita] = useState<EnumMenu>(getInsercaoFavorita());

  const handleAddPadrao = useCallback((rota: EnumMenu) => {
    setInsercaoFavorita(rota);
    setRotaFavorita(rota);
    showToast('success', `O modo "${getValorPadrao(rota)}" foi definido como Padrão para as próximas vendas.`)
  },
    [getValorPadrao, setInsercaoFavorita, showToast]
  );

  const handleClickAddFavorite = useCallback(
    (opcao: MenuModel) => {
      setFavorito(opcao);
    },
    [setFavorito]
  );

  const groupByCodigoPai = (items: MenuModel[]): Record<number, MenuModel[]> => {
    return items.reduce((acc, item) => {
      if (!acc[item.codigoPai]) {
        acc[item.codigoPai] = [];
      }
      acc[item.codigoPai].push(item);
      return acc;
    }, {} as Record<number, MenuModel[]>);
  };

  const agrupados = groupByCodigoPai(modulosRetornados);

  return (
    <>
      <Grid item className={classes.containerListCard} flex alignItems='center' xs={12} justifyContent={isMobile ? 'flex-start' : 'center'}>
        {Object.entries(agrupados).map(([codigoPai, itens]) => {
          const codigoMenu = Number(codigoPai) as EnumMenu;
          return <>
            <Grid className={classes.containerGrupo} mx={2} my={2} flex flexDirection='column' style={{ breakAfter: 'all' }}>
              <Grid flex justifyContent={isMobile ? 'center' : 'flex-start'} className={classes.containertitle}>
                <Typography className={classes.title}>
                  {isFarmaceutico && codigoMenu === EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES ? "Operações de Venda" : retornaNomeModuloPai(codigoMenu)}
                </Typography>
              </Grid>
              <Grid flex flexDirection='row' flexWrap='wrap' justifyContent={isMobile ? 'center' : 'flex-start'}>
                {
                  itens.map((item) => {
                    return <CardMenuPdv
                      model={item}
                      key={item.id}
                      insercaoPadrao={rotaFavorita === item.codigo}
                      handleAddPadrao={handleAddPadrao}
                      handleFavorite={handleClickAddFavorite}
                      onClick={handleClick}
                      isDeliveryAtivo={isDelivery}
                    />
                  })
                }
              </Grid>
            </Grid>
          </>
        })}
      </Grid>
    </>
  );
};
