import { SessaoGerenciamentoModel } from "model/api/gestao/sessao/sessao-gerenciamento-model";
import { CardNaoEncontrado } from "views/components/cards";
import { InformacaoIcon } from "views/components/icons";
import { GerenciamentoSessoesSearchProps } from "./gerenciamento-sessoes-search-props";
import { CardGerenciamentoSessoes } from "views/components/cards/card-gerencimento-sessoes/card-gerenciamento-sessoes";

interface GerenciamentoSessoesListDataProps {
    list: Array<SessaoGerenciamentoModel>;
    carregando: boolean;
    operadoresUnicos: Array<string>;
    searchProps: GerenciamentoSessoesSearchProps;
}

export const GerenciamentoSessoesListData = (props: GerenciamentoSessoesListDataProps) => {

    return (
        <>
            {props.operadoresUnicos.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhuma Sessão encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.operadoresUnicos.length > 0 &&
                props.operadoresUnicos.map((id, index) => {

                    const sessao = props.list.find(x => x.operadorId === id) ?? new SessaoGerenciamentoModel();

                    const nomeOperador = props.list.filter(x => x.operadorId === id).map(x => x.operadorNome);

                    let itensCancelados = 0;
                    let itens = 0;
                    let valorTotal = 0;
                    let qtdeVendas = 0

                    props.list.filter(x => x.operadorId === id).forEach((item) => {
                        itensCancelados += item.qtdCancelamentos;
                        itens += item.qtdItens;
                        qtdeVendas += item.qtdVendas
                        valorTotal += item.valorVendido;
                    })

                    return (
                        <CardGerenciamentoSessoes
                            sessoes={props.list}
                            key={index}
                            searchProps={props.searchProps}
                            nomeOperador={nomeOperador[0]}
                            qtdCanceladosSomados={itensCancelados}
                            qtdItensSomados={itens}
                            valorTotalSomados={valorTotal}
                            qtdVendasSomadas={qtdeVendas}
                            model={sessao}

                        />
                    );
                }
                )}
        </>
    );
}