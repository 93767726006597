import { Box, Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-enviar-cupom-venda-por-email-styles';
import { FecharIcon, LixoIcon, MaisIcon } from 'views/components/icons';
import { CircularLoading } from 'views';
import { useCallback } from 'react';
import { EnviarEmailIcon } from 'views/components/icons/enviar-email';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useEnviarCupomVendaPorEmailValidation } from './dialog-enviar-cupom-venda-por-email-validation';
import { yupResolver } from '@hookform/resolvers/yup';

interface DialogDialogeEnviarCupomVendaPorEmailProps {
  openned: boolean;
  loading: boolean;
  closeModal: () => void;
  enviarEmail: (emails?: string[]) => void;
  email: string[];
  setEmail: React.Dispatch<React.SetStateAction<string[]>>;
}

interface EnviarCupomVendaEmail {
  email: string
}

export const DialogEnviarCupomEmail = ({
  openned,
  closeModal,
  enviarEmail,
  loading,
  email,
  setEmail,
}: DialogDialogeEnviarCupomVendaPorEmailProps) => {
  const classes = useStyles();
  const { formEnviarCupomVendaPorEmailValidationYup } = useEnviarCupomVendaPorEmailValidation()

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    setError,
    formState: { errors }
  } = useForm<EnviarCupomVendaEmail>({
    resolver: yupResolver(formEnviarCupomVendaPorEmailValidationYup),
  })

  const onSubmit = useCallback(async (model: EnviarCupomVendaEmail) => {
    const emailForm = model.email
    if (emailForm) {
      const emailsAtualizados = [emailForm, ...email]
      setEmail(emailsAtualizados)
      setValue('email', '')
      enviarEmail(emailsAtualizados)
      return
    }
    if (email.length === 0) {
      setError('email', {
        message: 'Adicione um email válido',
      })
      return
    }
    enviarEmail()
  }, [email, enviarEmail, setEmail, setError, setValue])

  const handleAddEmail = useCallback(() => {
    if (errors.email) {
      return
    }
    setEmail(prev => [getValues('email'), ...prev])
    setValue('email', '')
  }, [errors.email, getValues, setEmail, setValue])

  function removerEmailPorIndex(index: number) {
    setEmail(prev => prev.filter((_, i) => i !== index));
  }
  return (
    <DialogSaurus
      aberto={openned || false}
      titulo="Enviar por E-mail"
      tamanho="xs"
    >
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Box className={classes.cardContainer}>
        <Box className={classes.cardContent}>
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography className={classes.label}>
                    Informe o E-mail para envio do conteúdo:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box flex>
                    <Controller
                      control={control}
                      name='email'
                      render={({ field }) => (
                        <TextFieldSaurus
                          {...field}
                          tipo="EMAIL"
                          fullWidth
                          error={Boolean(errors.email && errors.email.message)}
                          allowSubmit
                          helperText={
                            errors.email
                              ? errors.email?.message
                              : undefined
                          }
                        />
                      )}
                    />
                    <Button className={classes.maisIcon} variant="contained" color="primary" onClick={async () => {
                      const hasNoError = await trigger('email')
                      if (hasNoError) handleAddEmail()
                    }}>
                      <MaisIcon tipo="BUTTON_PRIMARY" />
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {email.map((x, i) => {
                      return (
                        <Grid item xs={'auto'}>
                          <Box className={classes.emailAdd}>
                            <Box>
                              {x}
                            </Box>
                            <Box className={classes.lixoIcon} onClick={() => removerEmailPorIndex(i)}>
                              <LixoIcon tipo="BUTTON_FAB" />
                            </Box>
                          </Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {closeModal && (
                    <Button
                      disabled={loading}
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={() => {
                        closeModal();
                      }}
                      rounded
                    >
                      <FecharIcon tipo="BUTTON" />
                      Fechar
                    </Button>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={loading}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    rounded
                  >
                    <EnviarEmailIcon tipo="BUTTON_PRIMARY" />
                    {'Enviar cupom'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </DialogSaurus>
  );
};
