import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { MovRotasMock } from './mov-rotas-mock';
import { RetaguardaRotasMock } from './retaguarda-rotas-mock';
import { PdvRotasMock } from './pdv-rotas-mock';

export const ModulosPDVMock: MenuModel[] = [

  // PDV
  new MenuModel('Modo de Operação', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS, [], '', 0, 0),
  new MenuModel('Operação de Venda', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA, [], '', 2, 0),
  new MenuModel('Operações de Pedidos', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES, [], '', 3, 0),
  new MenuModel('Delivery', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY, [], '', 4, 0),
  new MenuModel('Administrativo e Configurações', '', EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO, [], '', 6, 0),

  new MenuModel('Catálogo de Produtos', 'Venda utilizando uma grade com categorias e produtos.', EnumMenu.OPERACIONAL_DENTROVENDA_CATALOGO, [], MovRotasMock.catalogoRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS),
  new MenuModel('Teclado', 'Venda rápida digitando o valor apenas.', EnumMenu.OPERACIONAL_DENTROVENDA_AVULSO, [], MovRotasMock.lancamentoAvulsoRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS),
  new MenuModel('Leitor de Códigos', 'Adicionar produtos utilizando o leitor de barras ou com câmera do dispositivo.', EnumMenu.OPERACIONAL_DENTROVENDA_LEITOR, [], MovRotasMock.produtoLeitorRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS),
  new MenuModel('Carrinho de Compra', 'Painel com os produtos da venda com opção de inserção por digitação.', EnumMenu.OPERACIONAL_DENTROVENDA_CARRINHO, [], MovRotasMock.carrinhoRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS),

  new MenuModel('Selecionar Vendedor', 'Selecionar o vendedor dos Itens da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR, [], MovRotasMock.identificarVendedorRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA),
  new MenuModel('Identificar Cliente', 'Identificar o cliente da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE, [], MovRotasMock.identificarClienteRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA),
  new MenuModel('Receber ou Finalizar', 'Iniciar o recebimento ou finalização da Venda', EnumMenu.OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA, [], MovRotasMock.finalizarVendaRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA),
  new MenuModel('Cancelar Venda', 'Realizar o cancelamento da Venda Atual.', EnumMenu.OPERACIONAL_DENTROVENDA_CANCELAR, [], MovRotasMock.cancelarRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA),

  new MenuModel('Pedidos em Andamento', 'Gerenciar Pedidos realizados no estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS, [], MovRotasMock.pedidosRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES),
  new MenuModel('Mapa de Mesas', 'Gerenciar situação das mesas do estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA, [], MovRotasMock.pedidosMesas, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES),
  new MenuModel('Mapa de Comandas', 'Gerenciar situação das comandas do estabelecimento.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA, [], MovRotasMock.pedidosComandas, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES),
  new MenuModel('Entrada no Salão', 'Módulo para registrar entrada de clientes no estabelecimento.', EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA, [], MovRotasMock.pedidosEntrada, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES),

  new MenuModel('Atendimento Delivery', 'Iniciar Atendimento como Delivery', EnumMenu.OPERACIONAL_DENTROVENDA_DELIVERY, [], MovRotasMock.atendimentoDeliveryRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY),
  new MenuModel('Pedidos de Delivery', 'Gerenciar Pedidos para entrega.', EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY, [], MovRotasMock.pedidosDeliveryRota, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_DELIVERY),

  new MenuModel('Cadastros e Relatórios', 'Cadastro de clientes, produtos e configurações.', EnumMenu.OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA, RetaguardaRotasMock.dashboardRota.roles, RetaguardaRotasMock.dashboardRota.path, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Sincronizar Cadastros', 'Sincronizar últimas atualizações da Retaguarda.', EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS, [], PdvRotasMock.sincronizarDadosRota + '?forcar=false', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Forçar Sincronização', 'Forçar atualização de cadastros da Retaguarda.', EnumMenu.OPERACIONAL_DENTROVENDA_FORCAR_SINCRONIZACAO, [], PdvRotasMock.sincronizarDadosRota + '?forcar=true', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Enviar Movimentos', 'Enviar vendas e movimentaçções realizadas no caixa.', EnumMenu.OPERACIONAL_DENTROVENDA_SINCRONIZAR_VENDAS, [], '', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Histórico de Vendas', 'Buscar vendas realizadas anteriormente', EnumMenu.OPERACIONAL_DENTROVENDA_HISTORICO_VENDA, [EnumCodigosPermissoes.HISTORICO_VENDAS], MovRotasMock.historicoVendas, 1, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Resumo da Última Venda', 'Visualizar a última venda realizada.', EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA, [], MovRotasMock.finalizarVendaComprovanteRota + '?anterior=true', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Configurar este PDV', 'Acessar configurações de venda e operação deste PDV.', EnumMenu.OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV, [], PdvRotasMock.configuracoesPDV, 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),
  new MenuModel('Consultar Convênio', 'Consultar seu saldo e suas faturas do cartão.', EnumMenu.OPERACIONAL_DENTROVENDA_CONSULTAR_CONVENIO, [], '', 0, EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO),

];
