import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetCargas() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const getCargas = useCallback(
    (queryBusca: string, page?: number) => {
      let queryPage = "";
      if (page) {
        queryPage = "page=" + page.toString();
      }
      let queryFinal = `${queryPage?.length > 0 || queryBusca?.length > 0 ? "?" : ""
        }${queryPage}${queryBusca}`;

      return invocarApi({
        url: `/v3/conversao${queryFinal}`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlConversoes}/api`,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getCargas,
  };
}
