import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { EnumPagTpMod } from 'model';
import { isEmpty } from 'lodash';
import { BluetoothModel } from 'model/devices/bluetooth-model';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { newGuid } from 'utils/new-guid';
import { serializeHtmlToPrint } from 'utils/serializeHtmlToPrint';
import { useToastSaurus } from 'services/app/hooks';
import { HistoryModel } from 'model/devices/history-model';
import { PaymentCallbackModel } from 'model/app/pagamento-cartao/payment-callback';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { CredenciamentoSafra } from 'model/api/gestao/finalizadora/finalizadora-model';
import { EnumAcaoLeituraRfid } from 'model/enums/enum-acao-leitura-rfid';
import { EnumTemNFC } from 'model/enums/enum-tem-nfc';
import { DadosTerminalModel } from 'model/devices/dados-terminal-model';
import { roundTo } from 'utils/round-to';

export type TipoBackButton = 'back' | 'close';
export type TipoBluetoothDevices = 'paired' | 'all';

export const useDevice = () => {
  const { showToast } = useToastSaurus();
  const [carregandoInterno, setCarregandoInternoState] = useState(false);

  const setCarregandoInterno = (valor: boolean) => {
    setCarregandoInternoState((prev) => {
      if (prev !== valor) return valor;
      return prev
    })
  }
  const carregando = carregandoInterno;

  const DeviceKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).NavigatorWebKit;
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).CordovaWebKit;
    }
  }, []);

  const GetCompatibleDeviceKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchCompatibleDeviceCordova;
      default:
        return undefined;
    }
  }, []);

  const GetBalancaKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).getBalanca;
      default:
        return undefined;
    }
  }, [])

  const GetComandaRfidKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchComandaRfid;
      default:
        return undefined;
    }
  }, [])

  const GetNFCKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchTemNFC;
      default:
        return undefined;
    }
  }, [])

  const GetUSBKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchTemUSB;
      default:
        return undefined;
    }
  }, [])

  const GetDadosTerminaisKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).getDadosTerminal;
      default:
        return undefined;
    }
  }, [])

  const PaymentKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).launchPaymentMethodNavigator;
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchPaymentMethodCordova;
    }
  }, []);

  const PrintHtmlKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).launchPrintNavigator;
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchPrintCordova;
    }
  }, []);

  const PrintNativeKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).launchPrintNativeNavigator;
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchPrintNativeCordova;
    }
  }, []);

  const abrirGavetaKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
      case EnumDeviceType.CORDOVA:
        return (window as any).abrirGaveta;
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).abrirGaveta;
    }
  }, []);

  const integrationStatus = React.useCallback(async (): Promise<boolean> => {
    return !isEmpty(DeviceKit());
  }, [DeviceKit]);

  const getSerialNumber = React.useCallback((): Promise<string> => {
    setCarregandoInterno(true);
    return new Promise<string>(async (resolve, reject) => {
      try {
        var device = localStorage.getItem('deviceId');
        if (!device) {
          device = newGuid();
          localStorage.setItem('deviceId', device);
        }
        setCarregandoInterno(false);
        resolve(device);
      } catch (e: Error | any) {
        setCarregandoInterno(false);
        reject(e);
      }
    });
  }, []);

  const setBluetoothStatus = React.useCallback(
    async (ativo: boolean): Promise<void> => {
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus())) {
          reject(new Error('SDK não Inicializado'));
        }

        try {
          DeviceKit().executeNative({
            method: 'bluetooth_enable',
            args: {
              action: ativo ? 'on' : 'off',
            },
            callback: function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              resolve();
            },
          });
        } catch (e: Error | any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const getBluetoothStatus = React.useCallback(async (): Promise<boolean> => {
    setCarregandoInterno(true);
    return new Promise<boolean>(async (resolve, reject) => {
      if (!(await integrationStatus()))
        reject(new Error('SDK não Inicializado'));

      try {
        DeviceKit().executeNative({
          method: 'bluetooth_status',
          args: {},
          callback: function (result: any, error: any) {
            setCarregandoInterno(false);
            if (error) {
              reject(error);
              return;
            }
            resolve(result);
          },
        });
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    });
  }, [integrationStatus, DeviceKit]);

  const launchBluetoothSettings = React.useCallback(async () => {
    try {
      const callbackResult = (result: any, error: any) => {
        if (result === 'success') return;
        if (error) throw error;
      };
      (window as any).launchBluetoothSettings(callbackResult);
    } catch (e: any) {
      showToast(
        'error',
        'Erro ao abrir as configurações do Bluetooth. Detalhes: ' + e.message,
      );
    }
  }, [showToast]);

  const getBluetoothDevices = React.useCallback(
    async (status: TipoBluetoothDevices): Promise<BluetoothModel> => {
      setCarregandoInterno(true);
      return new Promise<BluetoothModel>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        try {
          DeviceKit().executeNative({
            method: 'bluetooth_devices',
            args: { state: status },
            callback: function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
                return;
              }
              resolve(result);
            },
          });
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const pairBluetoothDevice = React.useCallback(
    async (id: string): Promise<boolean> => {
      setCarregandoInterno(true);
      return new Promise<boolean>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        try {
          DeviceKit().executeNative({
            method: 'bluetooth_pair_request',
            args: {
              id: id,
            },
            callback: function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              resolve(result === 'success');
            },
          });
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const unpairBluetoothDevice = React.useCallback(
    async (id: string): Promise<boolean> => {
      setCarregandoInterno(true);
      return new Promise<boolean>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        try {
          DeviceKit().executeNative({
            method: 'bluetooth_pair_request',
            args: {
              id: id,
            },
            callback: function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              resolve(result === 'success');
            },
          });
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const getCompatibleDevice = React.useCallback(async (): Promise<
    string | undefined
  > => {
    setCarregandoInterno(true);

    return new Promise<string | undefined>(async (resolve, reject) => {
      try {
        const callbackResult = (result: string, error: any) => {
          if (error) {
            setCarregandoInterno(false);
            reject(error);
          }

          setCarregandoInterno(false);
          resolve(result);
        };

        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        const compatibleDevice = await GetCompatibleDeviceKit();
        if (!compatibleDevice) {
          reject(new Error('SDK não inicializado (dispositivoCompativel)'));
        }
        compatibleDevice(callbackResult);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    });
  }, [integrationStatus, GetCompatibleDeviceKit]);

  const printType = React.useCallback((): 'HTML' | 'NATIVE' => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return 'HTML';
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return 'NATIVE';
    }
  }, []);

  const printHtml = React.useCallback(
    async (data: string, colunas: number): Promise<void> => {
      setCarregandoInterno(true);
      let htmlToPrint = await serializeHtmlToPrint(data);

      if (colunas > 0) {
        htmlToPrint = (htmlToPrint || '').replace(
          'width:initial',
          `width:${(colunas * 7.5).toFixed(2)}px`,
        );
      }

      return new Promise<void>(async (resolve, reject) => {
        // if (!(await integrationStatus())) {
        //   setCarregandoInterno(false);
        //   return reject(new Error('SDK não Inicializado'));
        // }

        const print = PrintHtmlKit();

        try {
          await print(htmlToPrint, function (result: any, error: any) {

            setCarregandoInterno(false);
            if (error) {
              reject(error);
            }
            if (result !== 'success') {
              reject(
                new Error('Erro ao realizar a Impressão. Detalhe: ' + result),
              );
            }

            // setTimeout(() => resolve(), 300);
            resolve();
          });
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [PrintHtmlKit],
  );

  const printNative = React.useCallback(
    async (
      textoImpressao: string,
      qtdeColunas: number,
      caminho: string,
      modelo: string,
      vias: number,
    ): Promise<void> => {
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const print = PrintNativeKit();
        try {
          print(
            textoImpressao,
            qtdeColunas,
            caminho,
            modelo,
            vias,
            function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              if (result !== 'success') {
                reject(
                  new Error('Erro ao realizar a Impressão. Detalhe: ' + result),
                );
              }
              resolve();
            },
          );
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [PrintNativeKit, integrationStatus],
  );

  const abrirGaveta = React.useCallback(
    async (
      qtdeColunas: number,
      caminho: string,
      modelo: string,
    ): Promise<void> => {
      console.log(qtdeColunas, caminho, modelo)
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const abrir = abrirGavetaKit();
        try {
          abrir(
            caminho,
            qtdeColunas,
            modelo,
            function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              resolve();
            },
          );
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [abrirGavetaKit, integrationStatus],
  );

  const closeApp = React.useCallback(async (): Promise<void> => {
    setCarregandoInterno(true);

    return new Promise<void>(async (resolve, reject) => {
      if (!(await integrationStatus()))
        reject(new Error('SDK não Inicializado'));

      try {
        DeviceKit().executeNative({
          method: 'close',
          args: {},
        });
        setCarregandoInterno(false);
        resolve();
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    });
  }, [integrationStatus, DeviceKit]);

  const navigateBack = React.useCallback(
    async (telas: number): Promise<void> => {
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        try {
          DeviceKit().executeNative({
            method: 'back',
            args: {
              screen: telas,
            },
          });
          setCarregandoInterno(false);
          resolve();
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const alterBackBehavior = React.useCallback(
    async (tipo: TipoBackButton): Promise<void> => {
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        try {
          DeviceKit().executeNative({
            method: 'back_action',
            args: {
              type: tipo,
            },
          });
          setCarregandoInterno(false);
          resolve();
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [integrationStatus, DeviceKit],
  );

  const getHistory = React.useCallback(async (): Promise<HistoryModel> => {
    setCarregandoInterno(true);
    return new Promise<HistoryModel>(async (resolve, reject) => {
      if (!(await integrationStatus()))
        reject(new Error('SDK não Inicializado'));

      try {
        DeviceKit().executeNative({
          method: 'history',
          args: {},
          callback: (result: any, error: any) => {
            setCarregandoInterno(false);
            if (error) {
              reject(error);
              return;
            }
            resolve(result);
          },
        });
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    });
  }, [integrationStatus, DeviceKit]);

  const callbackEvent = useRef<PaymentCallbackModel>({
    sucesso: () => { },
    erro: () => { },
  });

  const evPgSuccess = React.useCallback((e: any) => {
    setCarregandoInterno(false);
    if (callbackEvent.current) {
      switch (VariaveisAmbiente.paymentDevice) {
        case EnumDeviceType.CORDOVA:
          break;
        case EnumDeviceType.CORDOVA_POS:
          callbackEvent.current.sucesso(e.detail);
          break;
        case EnumDeviceType.NAVIGATOR:
          const cardholderReceiptCAUT = e.detail.cardholderReceipt.find((x: any) => x.includes('AUT')) ?? ''
          const cAut =
            cardholderReceiptCAUT
              .slice(cardholderReceiptCAUT.indexOf('AUT'), cardholderReceiptCAUT.length)
              .replace(/\D/g, "")
          callbackEvent.current.sucesso({
            tipo: e.detail.paymentType ?? '',
            cAut: cAut ?? '',
            codNsu: e.detail.nsu ?? '',
            dhTransacao: e.detail.creationDateTime ?? '',
            valor: toDecimalString(roundTo(e.detail.amount)),
            snEquipamento: '',
            nParcelas: e.detail.installmentNumber ?? 0,
            bandeira: e.detail.brand ?? '',
            nomeCartao: e.detail.cardHolerName ?? '',
            tid: e.detail.transactionId ?? '',
            adquirente: 'SAFRAPAY',
            numCartao: e.detail.cardNumber ?? '',
            viaLojista: e.detail.merchantReceipt.join(',').trim(),
            viaCliente: e.detail.cardholderReceipt.join(',').trim(),
            envioAPI: '',
            retornoAPI: JSON.stringify(e.detail),
            processando: false,
            mensagem: '',
          });
          break;
      }
    }
  }, []);

  const evPgError = React.useCallback((e: any) => {
    setCarregandoInterno(false);
    if (callbackEvent.current) {
      callbackEvent.current.erro(e.detail);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('PointPayment.Success', evPgSuccess, false);
    window.addEventListener('PointPayment.Error', evPgError, false);

    return () => {
      window.removeEventListener('PointPayment.Success', evPgSuccess);
      window.removeEventListener('PointPayment.Error', evPgError);
    };
  }, [evPgError, evPgSuccess]);

  const startPayment = React.useCallback(
    async (
      valor: number,
      tpMod: EnumPagTpMod,
      parcelas: number,
      credenciais: CredenciamentoSafra,
      callback: PaymentCallbackModel,
    ): Promise<any> => {
      setCarregandoInterno(true);

      callbackEvent.current = callback;
      let mod = 'credit';
      if (tpMod === EnumPagTpMod.CARTAO_CREDITO) {
        mod = 'credit';
      } else if (tpMod === EnumPagTpMod.PAGAMENTO_INSTANTANEO) {
        mod = 'qr';
      } else if (tpMod === EnumPagTpMod.TRANSFERENCIA_BANCARIA) {
        mod = 'link';
      } else if ([
        EnumPagTpMod.VALE_REFEICAO,
        EnumPagTpMod.VALE_PRESENTE,
        EnumPagTpMod.VALE_COMBUSTIVEL,
        EnumPagTpMod.VALE_ALIMENTACAO
      ].includes(tpMod)) {
        mod = 'voucher';
      } else {
        mod = 'debit';
      }

      const payment = await PaymentKit();
      if (!payment) {
        const error = new Error('SDK de Pagamento não Identificada.');
        setCarregandoInterno(false)
        callback.erro(error)
        throw error
      }
      await payment(valor, mod, parcelas, credenciais);
    },
    [PaymentKit],
  );

  const getBalanca = React.useCallback((): Promise<string> => {
    setCarregandoInterno(true)
    return new Promise(async (resolve, reject) => {
      try {
        const callbackResult = (result: string, error: any) => {
          if (error) {
            setCarregandoInterno(false);
            reject(new Error(error));
          }
          const decimal = toDecimal(result[0])
          setCarregandoInterno(false);
          if (isNaN(decimal)) {
            reject(new Error('Erro ao capturar peso.'))
          }
          resolve(result)
        };
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const balanca = await GetBalancaKit();
        if (!balanca) {
          showToast('error', 'SDK não inicializado (getBalanca)');
          reject(new Error('SDK não inicializado (getBalanca)'));
        }
        //chumbando valor dps precisa vir da configuração
        balanca(callbackResult, 9600, 8, 1, 0);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    })
  }, [GetBalancaKit, integrationStatus, showToast])

  const getDadosTerminal = React.useCallback((): Promise<DadosTerminalModel | null> => {
    setCarregandoInterno(true)
    return new Promise(async (resolve, reject) => {
      try {
        const callbackResult = (result: string, error: any) => {
          if (error) {
            setCarregandoInterno(false);
            reject(error.message);
          }

          setCarregandoInterno(false);
          if (isEmpty(result)) {
            resolve(null)
          }
          const resultObj = JSON.parse(result) as DadosTerminalModel
          resolve(resultObj)
        };

        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));

        const dadosTerminal = await GetDadosTerminaisKit();
        if (!dadosTerminal) {
          showToast('error', 'SDK não inicializado (dadosTerminal)');
          reject();
        }
        dadosTerminal(callbackResult);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    })
  }, [GetDadosTerminaisKit, integrationStatus, showToast])

  const getComandaRfid = React.useCallback((acao: EnumAcaoLeituraRfid): Promise<string> => {
    setCarregandoInterno(true)
    return new Promise(async (resolve, reject) => {
      try {
        const callbackResult = (result: string, error: any) => {
          setCarregandoInterno(false);
          if (error) {
            reject('Erro ao realizar leitura.');
          }
          if (acao === EnumAcaoLeituraRfid.DESATIVAR) {
            resolve('')
            return
          }
          const arrSuccesso = JSON.parse(result)
          resolve(arrSuccesso[0])
        };
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const comandaRfid = await GetComandaRfidKit();
        if (!comandaRfid) {
          showToast('error', 'SDK não inicializado (comandaRfid)');
          reject(new Error('SDK não inicializado (comandaRfid)'));
        }
        comandaRfid(acao, callbackResult);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    })
  }, [GetComandaRfidKit, integrationStatus, showToast])

  const dispositivoTemNFC = React.useCallback((): Promise<EnumTemNFC> => {
    setCarregandoInterno(true)
    return new Promise(async (resolve, reject) => {
      try {
        const callbackResult = (result: EnumTemNFC, error: any) => {
          setCarregandoInterno(false);
          if (error) {
            resolve(error)
          }
          resolve(result)
        };
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const temNFC = await GetNFCKit();
        if (!temNFC) {
          showToast('error', 'SDK não inicializado (temNFC)');
          reject(new Error('SDK não inicializado (temNFC)'));
        }
        temNFC(callbackResult);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    })
  }, [GetNFCKit, integrationStatus, showToast])

  const dispositivoTemUSB = React.useCallback((): Promise<boolean> => {
    setCarregandoInterno(true)
    return new Promise(async (resolve, reject) => {
      try {
        const callbackResult = (result: EnumTemNFC, error: any) => {
          setCarregandoInterno(false);
          if (error) {
            resolve(false)
          }
          resolve(true)
        };
        if (!(await integrationStatus()))
          reject(new Error('SDK não Inicializado'));
        const temUSB = await GetUSBKit();
        if (!temUSB) {
          showToast('error', 'SDK não inicializado (temUSB)');
          reject(new Error('SDK não inicializado (temUSB)'));
        }
        temUSB(callbackResult);
      } catch (e: any) {
        setCarregandoInterno(false);
        reject(e);
      }
    })
  }, [GetUSBKit, integrationStatus, showToast])

  return {
    getSerialNumber,
    integrationStatus,
    closeApp,
    navigateBack,
    alterBackBehavior,
    getHistory,
    startPayment,
    getBluetoothStatus,
    setBluetoothStatus,
    getBluetoothDevices,
    pairBluetoothDevice,
    unpairBluetoothDevice,
    printHtml,
    printNative,
    abrirGaveta,
    printType,
    launchBluetoothSettings,
    getCompatibleDevice,
    getBalanca,
    getComandaRfid,
    dispositivoTemNFC,
    dispositivoTemUSB,
    carregando,
    getDadosTerminal
  };
};