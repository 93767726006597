import { MovRegistrarBodyModel } from "model/api/gestao/mov-pag/mov-registrar-response";
import { toDateString } from "utils/to-date";
import { DefaultCard, useDefaultCardStyles } from "views/components/cards/components";
import { BaixarIcon, CancelarIcon } from "views/components/icons";
import { Grid, Box, Typography } from "views/design-system";

interface Props {
    index: number;
    model: MovRegistrarBodyModel;
}

export const CardDownloadBoleto = ({
    index,
    model,
}: Props) => {

    const classes = useDefaultCardStyles();

    return (
        <DefaultCard>
            <Box flex flexDirection="column" alignItems='center' mr={4} pl={1}>
                <Typography color="primary" variant="caption">
                    Parcela
                </Typography>
                <Typography
                    className={'celula-grid-value'}
                    color="textPrimary"
                    variant="body1"
                >
                    {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {model.boleto && <Grid item xs={12}>
                    <Typography color="primary" variant="caption">
                        Data de Vencimento
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {toDateString(model.dataExpiracao)}
                    </Typography>
                </Grid>}
                {model.erro.mensagem && <Grid item xs={12}>
                    <Typography color="primary" variant="caption">
                        Erro para Emitir Boleto ({model.erro.codigo})
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.erro.mensagem}
                    </Typography>
                </Grid>}
            </Grid>
            <Box ml={2} pr={1}>
                <div className={classes.cardRightButton}>
                    {model.boleto && <a target="_blank" href={model.boleto.urlImpressao} download={`${toDateString(model.dataExpiracao)}.pdf`} rel="noreferrer">
                        <BaixarIcon />
                    </a>}
                    {model.erro.codigo && <CancelarIcon />}
                </div>
            </Box>
        </DefaultCard>
    )
}