import { isEmpty, isNil } from "lodash";

export function createQueryString(params: Record<string, any>): string {
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
        if (!isNil(value) && (typeof value === "number" || typeof value === 'boolean' || !isEmpty(value))) {
            searchParams.append(key, value.toString());
        }
    });

    return searchParams.toString();
}