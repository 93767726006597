import { EnumTableNames, TouchoneDBCargaPrimary } from "database/touchone-carga-database"
import { EnumLegacyTableNames, TabelaGenerica, TouchoneDBPrimary } from "database/touchone-database"
import { Collection, IndexableType, Table } from "dexie";
import { useCallback, useMemo } from "react"
import { EnumTipoSincronizacao } from "model/enums/enum-tipo-sincronizacao";
import { DisabledTable } from "database/interfaces/interface-disabled.table";
import { useEmpresaAtual } from "./empresa-atual";
import { EnumEmpresaConfig } from "model/enums/enum-empresa-config";

type WhereCondition = 'equals' | 'above' | 'below'
type BDQuery = Table<TabelaGenerica, IndexableType> | Collection<TabelaGenerica, IndexableType>;

interface IGetProps extends IGetFirstProps {
    filterFn?: (obj: any) => boolean;
    limit?: number;
}

interface IGetFirstProps {
    nomeTabela: EnumTableNames | EnumLegacyTableNames,
    where?: string | string[],
    condition?: WhereCondition,
    value?: any | any[],
}

interface IUpdateProps {
    nomeTabela: EnumTableNames | EnumLegacyTableNames,
    key: IndexableType,
    data: {
        [keyPath: string]: any;
    },
}

interface IAddProps {
    nomeTabela: EnumTableNames | EnumLegacyTableNames,
    data: {
        [keyPath: string]: any;
    },
    key?: IndexableType
}

interface IPutProps extends IAddProps {
    key?: IndexableType
}

export const useBancoLocal = () => {

    const { getConfigByCod } = useEmpresaAtual();

    const modoLegacy = useMemo(() => {
        const config = getConfigByCod(EnumEmpresaConfig.ModeloSincronizacao);
        return !config || (config === EnumTipoSincronizacao.LEGACY);
    }, [getConfigByCod]);

    const whereBD = useCallback((
        query: Table<TabelaGenerica, IndexableType>,
        where: any,
        condition: WhereCondition,
        value: any
    ) => {
        switch (condition) {
            case 'equals':
                return query.where(where).equals(value);
            case 'above':
                return query.where(where).above(value);
            case 'below':
                return query.where(where).below(value);
        }
    }, [])

    const removeDisableTable = useCallback((query: BDQuery) => {
        query = query.filter(entity => {
            if (Object.hasOwn(entity, 'disabledTable')) {
                return !Boolean((entity as TabelaGenerica & DisabledTable).disabledTable);
            }
            return true;
        });

        return query;
    }, [])

    const getFirst = useCallback(async <T extends TabelaGenerica>({
        nomeTabela,
        condition = 'equals',
        value,
        where,
    }: IGetFirstProps): Promise<T | undefined> => {
        let query: BDQuery;
        if (modoLegacy) {
            nomeTabela = nomeTabela as EnumLegacyTableNames;
            query = TouchoneDBPrimary[nomeTabela] as Table<TabelaGenerica, IndexableType>;
        } else {
            nomeTabela = nomeTabela as EnumTableNames;
            query = TouchoneDBCargaPrimary[nomeTabela] as Table<TabelaGenerica, IndexableType>;
        }

        if (where && value) {
            if (typeof where === 'object' && typeof value === 'object') {
                let objComparacao = {}
                where.forEach((wh, i) => {
                    objComparacao = {
                        ...objComparacao,
                        [wh]: value[i],
                    }
                })
                return await query.get(objComparacao) as T;
            } else {
                query = whereBD(query, where, condition, value) as Collection<TabelaGenerica, IndexableType>;
            }

            const res = await query.first() as T;
            return res ? res : undefined;
        }

        query = removeDisableTable(query);

        const res = await query.toArray() as T[];
        return res[0] ? res[0] : undefined;
    }, [modoLegacy, removeDisableTable, whereBD])

    const get = useCallback(async <T extends TabelaGenerica>({
        nomeTabela,
        where,
        condition = 'equals',
        value,
        filterFn,
        limit,
    }: IGetProps): Promise<T[]> => {
        let query: BDQuery;
        if (modoLegacy) {
            nomeTabela = nomeTabela as EnumLegacyTableNames;
            query = TouchoneDBPrimary[nomeTabela] as Table<TabelaGenerica, IndexableType>;
        } else {
            nomeTabela = nomeTabela as EnumTableNames;
            query = TouchoneDBCargaPrimary[nomeTabela] as Table<TabelaGenerica, IndexableType>;
        }

        if (where && value) {
            query = whereBD(query, where, condition, value) as Collection<TabelaGenerica, IndexableType>;

            const res = await query.toArray() as T[];
            return res ? res : [];
        }
        if (filterFn) {
            query = query.filter(filterFn);
        }
        if (limit) {
            query = query.limit(limit);
        }

        query = removeDisableTable(query);

        const res = await query.toArray() as T[];
        return res;
    }, [modoLegacy, removeDisableTable, whereBD])

    const update = useCallback(async ({
        nomeTabela,
        data,
        key
    }: IUpdateProps): Promise<number> => {
        if (modoLegacy) {
            nomeTabela = nomeTabela as EnumLegacyTableNames;

            const res = await (TouchoneDBPrimary[nomeTabela] as Table<any, IndexableType>).update(key, data);

            return res
        }

        nomeTabela = nomeTabela as EnumTableNames;

        const res = await (TouchoneDBCargaPrimary[nomeTabela] as Table<any, IndexableType>).update(key, data);

        return res
    }, [modoLegacy])

    const put = useCallback(async ({
        nomeTabela,
        data,
        key
    }: IPutProps): Promise<IndexableType> => {
        if (modoLegacy) {
            nomeTabela = nomeTabela as EnumLegacyTableNames;

            const res = await (TouchoneDBPrimary[nomeTabela] as Table<any, IndexableType>).put(data, key);

            return res
        }

        nomeTabela = nomeTabela as EnumTableNames;

        const res = await (TouchoneDBCargaPrimary[nomeTabela] as Table<any, IndexableType>).put(data, key);

        return res
    }, [modoLegacy])

    const clear = useCallback(async (sessao?: boolean) => {
        if (sessao) {
            await TouchoneDBPrimary.limparDadosSessao();
            await TouchoneDBCargaPrimary.limparDadosSessao();
            return
        }
        await TouchoneDBPrimary.limparDados();
        await TouchoneDBCargaPrimary.limparDados();
    }, [])

    const add = useCallback(async ({
        nomeTabela,
        data
    }: IAddProps) => {
        if (modoLegacy) {
            nomeTabela = nomeTabela as EnumLegacyTableNames;

            const res = await (TouchoneDBPrimary[nomeTabela] as Table<any, IndexableType>).add(data);

            return res
        }

        nomeTabela = nomeTabela as EnumTableNames;

        const res = await (TouchoneDBCargaPrimary[nomeTabela] as Table<any, IndexableType>).add(data);

        return res
    }, [modoLegacy])

    return {
        get,
        getFirst,
        update,
        put,
        add,
        clear
    }
}