import { useGetHistoricoVendasPendentes } from "data/api/gestao/historico-venda/get-historico-vendas-pendentes"
import { isEmpty } from "lodash"
import { EnumTpNf } from "model/enums/enum-tp-nf"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useToastSaurus } from "services/app"
import { useStyles } from "./historico-vendas-pendentes-list-styles"
import { Grid } from "views/design-system"
import { CircularLoading } from "views/components"
import { HistoricoVendasPendentesListData } from "./historico-vendas-pendentes-list-data"
import { Paginacao } from "views/components/paginacao"
import { RetaguardaRotasMock } from "data/mocks/retaguarda-rotas-mock"
import { HistoricoVendaPendentesResumidoModel } from "model/api/gestao/historico-vendas/historico-vendas-pendentes-resumido"
import { EnumMovModelo } from "model"

export const HistoricoVendasPendentesList = () => {

    const classes = useStyles()

    // STATES & REFS
    const [selectedList, setSelectedList] = useState<Array<string>>([]);
    const [queryStatus, setQueryStatus] = useState({
        page: 1,
        totalPages: 0,
        totalResults: 0,
        list: Array<HistoricoVendaPendentesResumidoModel>(),
    });

    // REQUISICOES
    const { getHistoricoVendasPendentes, carregando: carregandoHistorico } =
        useGetHistoricoVendasPendentes();
    const history = useHistory()

    // HOOKS & UTILS
    const location = useLocation()
    const { showToast } = useToastSaurus()

    const carregando = carregandoHistorico

    const filtros = useMemo(() => {
        const urlParams = new URLSearchParams(location.search)
        return {
            status: isEmpty(urlParams.get('status')) ? -1 : Number(urlParams.get('status')),
            dInicial: urlParams.get('dInicial') || '',
            dFinal: urlParams.get('dFinal') || '',
            serie: urlParams.get('serie') || '',
            empresa: urlParams.get('empresa') || '',
            nnf: urlParams.get('nnf') || '',
            nnfInicial: urlParams.get('nnfInicial') || '',
            nnfFinal: urlParams.get('nnfFinal') || '',
        }
    }, [location.search])

    const fillResult = useCallback(
        async (
            page: number,
            totalPages: number,
            totalResults: number,
            list: Array<HistoricoVendaPendentesResumidoModel>,
        ) => {
            setQueryStatus({
                page: page,
                list: list,
                totalResults: totalResults,
                totalPages: totalPages,
            });
        },
        [],
    );

    const search = useCallback(
        async (newPage: number) => {
            const query =
                `TpNFe=${EnumTpNf.SAIDA}` +
                // (filtros.status === -1
                //     ? ''
                //     : '&Status=' + filtros.status) +
                (isEmpty(filtros.dInicial)
                    ? ''
                    : `&DInicial=${filtros.dInicial}T00:00:00`) +
                (isEmpty(filtros.dFinal)
                    ? ''
                    : `&DFinal=${filtros.dFinal}T23:59:59`) +
                (isEmpty(filtros.serie)
                    ? ''
                    : `&Serie=${filtros.serie}`) +
                (isEmpty(filtros.empresa)
                    ? ''
                    : `&Empresas=${filtros.empresa}`) +
                (isEmpty(filtros.nnfInicial)
                    ? ''
                    : `&nnfInicial=${filtros.nnfInicial}`) +
                (isEmpty(filtros.nnfFinal)
                    ? ''
                    : `&nnfFinal=${filtros.nnfFinal}`) +
                (isEmpty(filtros.nnf)
                    ? ''
                    : `&NNF=${filtros.nnf}`)
                + `&mod=${EnumMovModelo.NFCE}`

            try {
                const res = await getHistoricoVendasPendentes(query, newPage);

                if (res.erro) throw res.erro;

                if (
                    res.resultado?.data?.pageIndex > res.resultado?.data?.totalPages &&
                    res.resultado?.data?.totalResults > 0
                ) {
                    search(res.resultado?.data?.totalPages);
                    return;
                }

                fillResult(
                    res.resultado?.data?.pageIndex,
                    res.resultado?.data?.totalPages,
                    res.resultado?.data?.totalResults,
                    res.resultado?.data?.list,
                );
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [filtros.dInicial, filtros.dFinal, filtros.serie, filtros.empresa, filtros.nnfInicial, filtros.nnfFinal, filtros.nnf, getHistoricoVendasPendentes, fillResult, showToast],
    );

    useEffect(() => {
        search(queryStatus.page)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= queryStatus.totalPages || newPage > 0) {
                await search(newPage);
            }
        },
        [search, queryStatus.totalPages],
    );

    const onCardChecked = (id: string) => {
        const aux = [...selectedList];
        aux.push(id);
        setSelectedList(aux);
    };

    const onCardSelected = (id: string) => {
        history.push(RetaguardaRotasMock.pendenciasFiscaisIdRota.path.replace(':id', id))
    };

    return (
        <div className={classes.defaultContainer}>
            {carregando && <CircularLoading tipo="FULLSIZED" />}
            <Grid container>
                <Paginacao
                    pageChanged={pageChanged}
                    totalPages={queryStatus.totalPages}
                    totalRegisters={queryStatus.totalResults}
                    currentPage={queryStatus.page}
                />
                <Grid item xs={12} className={classes.listContainer}>
                    <HistoricoVendasPendentesListData
                        carregando={carregando}
                        list={queryStatus.list}
                        selectedList={selectedList}
                        onCardSelected={onCardSelected}
                        onCardChecked={onCardChecked}
                    />
                </Grid>
            </Grid>
        </div>
    )
}