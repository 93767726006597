import { useCallback, FocusEvent, useState, useEffect } from 'react';

import {
  retornoAutoComplete,
  AutocompleteSaurus,
} from '../autocomplete-saurus/autocomplete-saurus';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { useGetComandas } from 'data/api/gestao/comandas/get-comandas';
import { useToastSaurus } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumComandas } from 'model/enums/enum-comandas';
import { DialogScannerGerarComanda } from 'views/pages/private/movimentacao/mov-controle-salao/mov-gerar-comanda/components/dialog-scanner-gerar-comanda/dialog-scanner-comanda';
import { BarcodeScanIcon } from 'views/components/icons/barcode-scan-icon';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useConfirm } from 'material-ui-confirm';
import { ScannerIcon } from 'views/components/icons/scanner-icon';
import { CodigoBarrasIcon } from 'views/components/icons/codigo-barras-icon';
import { EnumTipoTrabalhoComanda } from 'model/enums/enum-tipo-trabalho-comanda';
import { DialogScannRFID } from 'views/components/dialog/dialog-scann-rfid/dialog-scann-rfid';
import { isEmpty } from 'lodash';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { EnumTableNames } from 'database/touchone-carga-database';

export interface AutocompleteComandasProps {
  label?: string;
  name?: string;
  loadingExterno?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string | undefined;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => any;
  onChange?: (value: retornoAutoComplete) => any;
  placeholder?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  comandaId?: string;
  allowSubmit?: boolean;
  modo: 'codigoComanda';
  disabled?: boolean;
  localDb?: boolean;
}

export const AutocompleteComandas = ({
  onChange,
  loadingExterno,
  ...props
}: AutocompleteComandasProps) => {
  const { getComandas, carregando } = useGetComandas();
  const { showToast } = useToastSaurus();
  const { getEmpresaAtual } = useEmpresaAtual();
  const { getConfiguracoesMesaEComanda, searchComandas } = usePedidoLocal();
  const confirm = useConfirm();
  const { get } = useBancoLocal();

  const [openScanner, setOpenScanner] = useState<boolean>(false)
  const [openRFID, setOpenRFID] = useState<boolean>(false)
  const [erro, setErro] = useState<string>('')

  const [comandasState, setComandasState] = useState<
    Array<ComandasModel>
  >(new Array<ComandasModel>());

  const getComandasLocalWrapper = useCallback(async () => {
    const mesas = await get<TabelaComandas>({
      nomeTabela: EnumTableNames.COMANDAS,
      filterFn: comanda => comanda.status?.codigo === EnumComandas.ATIVO,
    }) as ComandasModel[]
    setComandasState(mesas)
  }, [get])

  const getComandasWrapper = useCallback(async (termo: string) => {
    try {
      const res = await getComandas('?somenteAtiva=true', getEmpresaAtual()!.id)

      if (res.erro) throw res.erro
      if (!res.resultado) throw new Error('Erro ao carregar comandas. Tente novamente.')

      setComandasState(res.resultado.data.list);
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getComandas, getEmpresaAtual, showToast]);

  const onChangeWrapper = useCallback(
    (value: retornoAutoComplete) => {
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const onPesquisa = useCallback(
    (termo: string) => {
      let termoPesq = termo.length > 0 ? termo.split('-')[0].trim() : termo;
      if (props.localDb) {
        getComandasLocalWrapper();
        return
      }
      getComandasWrapper(termoPesq);
    },
    [getComandasLocalWrapper, getComandasWrapper, props.localDb],
  );

  useEffect(() => {
    onPesquisa('')
  }, [onPesquisa])

  const onScan = useCallback(async (value: string, tipo: 'rfId' | 'codigoComanda') => {
    setOpenScanner(false)
    setOpenRFID(false)
    const comanda = await searchComandas(value, tipo)
    if (comanda.length === 0) {
      setErro('Número de comanda não encontrado.')
      showToast('error', 'Número de comanda não encontrado.')
      return
    }
    onChangeWrapper({
      value: comanda[0],
      isNewVal: true,
      isString: false
    })
  }, [onChangeWrapper, searchComandas, showToast])

  useEffect(() => {
    if (!openScanner) {
      props.inputRef!.current!.blur();
    }
  }, [openScanner, props.inputRef])

  const onScannerClick = useCallback(async () => {
    const tipoTrabalhoComanda = getConfiguracoesMesaEComanda()?.tipoTrabalhoComanda;
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.HIBRIDO) {
      confirm({
        title: 'Escanear Comanda',
        description: 'Como deseja escanear a comanda?',
        confirmationText: <>
          <ScannerIcon tipo='BUTTON_PRIMARY' />
          Comanda RFID
        </>,
        cancellationText: <>
          <CodigoBarrasIcon tipo='BUTTON_PRIMARY' />
          Código de Barras
        </>,
        cancellationButtonProps: {
          variant: 'contained',
          color: 'primary'
        }
      }).then(() => setOpenRFID(true))
        .catch(() => setOpenScanner(true))

      return
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_RFID) {
      setOpenRFID(true)
    }
    if (tipoTrabalhoComanda === EnumTipoTrabalhoComanda.APENAS_CODIGO) {
      setOpenScanner(true)
    }
  }, [confirm, getConfiguracoesMesaEComanda])

  return (
    <>
      <AutocompleteSaurus
        disabled={props.disabled}
        inputRef={props.inputRef}
        loading={carregando}
        tipoTextField="TEXTO"
        onPesquisa={onPesquisa}
        onChange={onChangeWrapper}
        opcoes={comandasState}
        optionLabelKey={props.modo}
        optionValueKey="id"
        disabledValueKey="id"
        disabledValues={[props.comandaId]}
        onBlur={props.onBlur}
        value={props.value}
        allowSubmit={props.allowSubmit}
        name={props.name}
        label={props.label}
        helperText={props.helperText}
        error={props.error}
        placeholder={props.placeholder}
        numerico
        externalInput
        CustomIcon={BarcodeScanIcon}
        onClickIcon={onScannerClick}
      />
      <DialogScannerGerarComanda
        openned={openScanner}
        closeModal={() => setOpenScanner(false)}
        handleScan={(codigo: string) => onScan(codigo, 'codigoComanda')}
      />
      {openRFID && <DialogScannRFID
        closeDialog={() => setOpenRFID(false)}
        confirmarComandaRFID={(rfid: string) => onScan(rfid, 'rfId')}
        openned={openRFID}
        erro={isEmpty(erro) ? erro : undefined}
      />}
    </>
  );
};