import { EnumStatusComanda } from 'model/enums/enum-status-comanda';

export class StatusComanda {
    constructor(
        public codigo: EnumStatusComanda = EnumStatusComanda.ATIVO,
        public descricao: string = 'Ativo',
    ) { }
}
export class ComandasModel {
    constructor(
        public id: string = '',
        public codigoComanda: string = '',
        public rfId: string = '',
        public status: StatusComanda = new StatusComanda(),
    ) { }
}
