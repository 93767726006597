import { EnumPlanoOpcoes } from "model/enums/enum-plano-opcoes";
import { PlanoModel } from "../model/api/gestao/plano/plano-model";
import { EnumMenu } from "model";
import { VariaveisAmbiente } from 'config';

export const getPlanoLimite = (plano: PlanoModel | undefined, opcao: EnumPlanoOpcoes): number => {
    if (!plano)
        return 0;

    var finded = plano.opcoes.find(x => x.codigo === opcao);
    if (!finded)
        return 0;

    return parseInt(finded.valor);
}

export const isPlanoFiscal = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Fiscal);
    return limite === 1;
};

export const isPlanoComVendedor = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.ControleDeVendedor);
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
};

export const isPlanoPromocoes = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Promocoes);
    return limite === 1;
};

export const isPlanoComissoes = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Comissao);
    return limite === 1;
};

export const isPlanoConvenio = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Convenio);
    return limite === 1;
};

export const isPlanoComControleDeAcesso = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.ControleDeAcesso);
    return limite === 1;
};


export const isCadastroInfinito = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.CadastroProdutos);
    return limite === -1;
};

export const isPlanoModificador = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Modificador);
    return limite === 1;
};

export const isPlanoIntegradoSaurusPDV = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.IntegraoSaurusPDV)
    return limite === 1;
}

export const isPlanoConsumacao = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Consumacao)
    return limite === 1
}

export const isPlanoComEstoque = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.ControleEstoque)
    return limite === 1
}

export const isPlanoSuporteWhatsApp = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.SuporteViaWhatsApp)
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
}

export const isPlanoPagIntegrado = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.PagamentoIntegrado)
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
}

export const isPlanoRelatoriosBasicos = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.RelatoriosBasicos)
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
}

export const isPlanoControleProducao = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.ControleProducao)
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
}

export const isPlanoControleMesasComandas = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.OperacaoPedido)
    return limite === 1
}

export const isPlanoMesasQRCode = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.MesaComQrCode)
    return limite >= 1
}


export const isPlanoCardapioPedidoIntegrado = (plano: PlanoModel | undefined) => {
    // const limite = getPlanoLimite(plano, EnumPlanoOpcoes.CardapioPedidoIntegrado)
    //DAVI REMOVEU O MÓDULO E PEDIU PRA RETORNAR TRUE MAS MANTER A VALIDAÇÃO
    return true
}
export const isPlanoFarmaceutico = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Farmaceutico)
    return limite === 1 || VariaveisAmbiente.label === 'FARMA'
}

export const isPlanoDeliveryProprio = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.DeliveryProprio)
    return limite === 1
}

export const isPlanoEntregaDelivery = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.EntregaDelivery)
    return limite === 1
}

export const isPlanoEntradaMercadoria = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.EntradaMercadoria)
    return limite === 1
}
export const isPlanoUsuarios = (plano: PlanoModel | undefined) => {
    const limite = getPlanoLimite(plano, EnumPlanoOpcoes.Usuarios)
    return limite === 1
}

export const isPlanoAtivo = (codigoModulo: number, plano: PlanoModel | undefined) => {
    switch (codigoModulo) {
        case EnumMenu.ADMINISTRATIVO_SALOES:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA:
            return isPlanoControleMesasComandas(plano);
        case EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO:
            return isPlanoCardapioPedidoIntegrado(plano)
        case EnumMenu.RELATORIOS_DASHBOARD:
            return isPlanoRelatoriosBasicos(plano)
        case EnumMenu.FISCAL_ATIVAR_REVISAR_DADOS_NFCE:
            return isPlanoFiscal(plano)
        case EnumMenu.FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS:
            return isPlanoFiscal(plano)
        case EnumMenu.RELATORIOS_PENDENCIAS_FISCAIS:
            return isPlanoFiscal(plano)
        case EnumMenu.RELATORIOS_ENTRADA_MERCADORIA:
            return isPlanoEntradaMercadoria(plano)
        case EnumMenu.RELATORIOS_MANIFESTOS:
            return isPlanoEntradaMercadoria(plano)
        default:
            return true;
    }
};