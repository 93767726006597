import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    camposContainer: {
        background: '#FAFAFA',
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(96, 97, 112, 0.16)',
        '& .MuiFormControl-root': {
            background: '#fff'
        }
    },
    container: {
        marginTop: theme.spacing(1)
    },
    card: {
        padding: '4px 8px',
        background: '#D9EEFB',
        boxShadow: 'none',
        color: theme.palette.primary.main,
        borderRadius: theme.spacing(1)
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center'
    },
    moduloNome: {
        marginRight: 4,
        fontWeight: 600
    },
}));