import { useMemo } from "react"
import QRCode from 'qrcode.react';
import { Fade, useTheme } from "@material-ui/core";
import { useStyles } from "./pagamento-pix-detalhes-styles";
import { toDecimalString } from "utils/to-decimal";
import { CancelarIcon, FecharIcon, SATIcon } from "views/components/icons";
import { EnumStatusPix } from "model/enums/enum-status-pix";
import { MovRegistrarBodyModel } from "model/api/gestao/mov-pag/mov-registrar-response";
import { guidEmpty } from "utils/guid-empty";
import { Box, Button, Divider, Grid, Typography } from "views/design-system";
import { DefaultCard } from "views/components/cards/components";

export interface PagamentoPixProps {
    model: MovRegistrarBodyModel;
    statusPix: EnumStatusPix;
    fechar: () => void;
    pixErro: boolean;
    handlePedidoPix: () => Promise<void>;
    preco: number;
}

export const PagamentoPixDetalhes = ({ preco, model, statusPix, fechar, pixErro, handlePedidoPix }: PagamentoPixProps) => {
    const classes = useStyles()
    const theme = useTheme()

    const tempoExpiracao = useMemo(() => {
        const data = new Date(model.dataRegistro)

        const expiracao = new Date(data.setMinutes(data.getMinutes() + 30))
        const horas = ('00' + expiracao.getHours()).substr(-2)
        const minutos = ('00' + expiracao.getMinutes()).substr(-2)
        return `${horas}:${minutos}`
    }, [model.dataRegistro])

    return (
        <>
            {pixErro && (
                <Fade in>
                    <Grid container spacing={2} justifyContent="space-between" style={{ flexDirection: 'column', height: '100%' }}>
                        <Grid item>
                            <Box alignItems={"center"} mb={3}>
                                <Box mb={3}>
                                    <CancelarIcon tipo="GERAL" class={classes.erroIcon} />
                                </Box>
                                <Typography variant='h6' align="center" >
                                    Ocorreu um problema ao consultar o pagamento.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={6} container justifyContent="center">
                                    <Button variant="contained" color='primary' onClick={handlePedidoPix} fullWidth>
                                        <SATIcon tipo='BUTTON_PRIMARY' />
                                        Tentar Novamente
                                    </Button>
                                </Grid>
                                <Grid item xs={6} container justifyContent="center">
                                    <Button
                                        variant="outlined"
                                        color='primary'
                                        onClick={fechar}
                                        fullWidth
                                    >
                                        <FecharIcon tipo='BUTTON' />
                                        Fechar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade >
            )}
            {statusPix === EnumStatusPix.Neutro && !pixErro && model.id !== guidEmpty() ? (
                <Fade in>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Box flex justifyContent="center" mb={2}>
                                <DefaultCard hasTagStatus={false} style={{ boxShadow: 'none', backgroundColor: theme.palette.type === 'light' ? '#f2f2f2' : "rgb(85 85 85)" }}>
                                    <Box p={1} className={classes.boxTotalaPagar}  >
                                        <Typography align='center' color="textPrimary" variant="body1" weight={500} >
                                            Valor à pagar
                                        </Typography>
                                        <Divider style={{ marginTop: theme.spacing(1) }} />
                                        <Typography color={"primary"} variant="h5" style={{
                                            fontWeight: 500,
                                            marginTop: theme.spacing(1),
                                            fontSize: '1.6rem'
                                        }}>
                                            R$ {toDecimalString(preco ?? 0)}
                                        </Typography>
                                    </Box>
                                </DefaultCard>
                            </Box>
                            <Box flex justifyContent="center" mb={2} className={classes.boxQRCode}>
                                <QRCode
                                    bgColor={"#FFFFFF"}
                                    value={model.pix.pixCopiaCola}
                                    size={250}
                                    renderAs="svg"
                                />
                            </Box>
                            <Box>
                                <Typography align="center">
                                    Escaneie o código QR para pagar via PIX.
                                </Typography>
                            </Box>
                            <Box>
                                <Typography align="center">
                                    O tempo para pagar irá acabar às: <span style={{ fontWeight: 600, fontSize: 20 }}>{tempoExpiracao}h</span>
                                </Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={5} container spacing={2}>
                            <DetalhesPedido />
                        </Grid> */}
                    </Grid>
                </Fade>
            ) : statusPix === EnumStatusPix.Cancelado && !pixErro && (
                <Fade in>
                    <Grid container spacing={2} justifyContent="space-between" style={{ height: '100%', flexDirection: 'column' }}>
                        <Grid item>
                            <Box>
                                <Typography variant="h6" align="center">
                                    PIX cancelado, ultrapassou o tempo limite de pagamento.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                fullWidth
                                color='primary'
                                onClick={fechar}
                            >
                                <FecharIcon tipo='BUTTON' />
                                Fechar
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            )
            }
        </>
    )
}