import { useSessaoAtual } from 'services/app';
import { MovModeloMock } from 'data/mocks/mov-modelo-mock';
import { ProdsModel } from 'model/api/gestao/venda/venda-completa-model';
import { Alinhamento } from 'utils/alinhamento';
import { StringBuilder } from 'utils/stringBuilder';
import { toDateString } from 'utils/to-date';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useCriptografia } from './criptografia';
import { EnumTpEmis } from 'model/enums/enum-tp-emis';
import { validaCaracter } from 'utils/valida-caracter';
import { useBancoLocal } from './banco-local';
import { TabelaClientes } from 'database/interfaces/interface-tabela-clientes';
import { EnumTableNames } from 'database/touchone-carga-database';

export interface ImpressaoTicketProps {
  venda: any
  qtdColunas: number
  tpEmis: EnumTpEmis
  hasImpressora?: boolean
}

export const useImpressaoTicket = () => {
  const { criptografar } = useCriptografia();
  const { getPessoa } = useSessaoAtual();
  const { getFirst } = useBancoLocal();

  const impressaoTicket = async ({
    qtdColunas,
    tpEmis,
    venda,
    hasImpressora
  }: ImpressaoTicketProps): Promise<string | string[]> => {
    const getPessoaWrapper = async (id: string) => {

      const res = await getFirst<TabelaClientes>({
        nomeTabela: EnumTableNames.CLIENTES,
        where: 'id',
        value: id
      })

      if (!res) {
        return validaCaracter(getPessoa().pessoa?.nome) ?? 'Vendedor';
      }

      return validaCaracter(res?.nome ?? 'Vendedor');
    };

    const nome = await getPessoaWrapper(venda!.prod[0].vendedorId);
    let indexItem = 0;

    const ticketsArr: any[] = []

    venda!.prod
      .filter((prod: ProdsModel) => prod.cancelado === false && prod.indFin)
      .forEach((prods: ProdsModel, index: number, arr: ProdsModel[]) => {
        const totalItens = arr.reduce<number>((prev, curr) => prev + curr.qCom, 0) || 0;
        for (let i = 0; i < prods.qCom; i++) {
          indexItem += 1;
          const xImp = new StringBuilder();

          xImp.append("<n>")
          xImp.appendWithln('<centro><expandido>Ticket Consumacao</expandido>');
          xImp.append("</n>")

          xImp.append('<cond>');
          const modelo =
            'Item ' +
            (indexItem) +
            '/' +
            totalItens;
          xImp.appendWithln(
            modelo
          );
          const date = 'Valido para ' + toDateString(new Date(), 'DD/MM/yyyy  HH:mm')
          xImp.appendWithln(
            Alinhamento.AlinhaCentro(
              date,
              ' ',
              date.length
            )
          )
          xImp.appendWithln(
            'Vendedor: ' + nome
          );
          xImp.append(Alinhamento.AlinhaCentro('-', '-', qtdColunas));
          xImp.append('</cond>');

          xImp.appendWithln('<esquerda>');
          xImp.append("<expandido>")
          xImp.appendWithln('Codigo: ' + prods.cProd);
          xImp.appendWithln('Valor: R$ ' + toDecimalString(prods.vUnCom));
          xImp.append("<n>")
          xImp.appendWithln(`${prods.xProd}`);
          xImp.append("</n>")
          xImp.append("</expandido>")

          xImp.appendWithln(Alinhamento.AlinhaCentro('-', '-', qtdColunas));
          xImp.appendWithln(
            '<qrCODE>' +
            criptografar(
              `${prods.id}|${String(i + 1)}|${venda.emit!.doc}|${prods.cProd
              }|${String(toDecimal(prods!.qCom))}|${String(
                toDecimal(prods!.vProd)
              )}|${prods.xProd}|${toDateString(venda.dhEmi)}|${String(
                venda!.nnf
              )}|${MovModeloMock.filter((item) => item.Key === venda.mod)[0]
                .Value
              }`
            ) +
            '</qrCODE>'
          );
          xImp.appendWithln(Alinhamento.AlinhaCentro('-', '-', qtdColunas));

          xImp.append('<guilhotina>');
          xImp.appendWithln()

          ticketsArr.push(xImp.toString());
        }
      });

    return hasImpressora ? ticketsArr : ticketsArr.join(" ");
  };

  return {
    impressaoTicket
  };
};