import { yupResolver } from '@hookform/resolvers/yup';
import { SetorEProcessoEditFormModel } from 'model/app/forms/setor/setor-edit-form-model';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { useFormSetorEditValidation } from './form-setor-edit-validation';
import { Box, Button, Grid } from 'views/design-system';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { tipoProcessoSetorMock } from 'data/mocks/tipo-processo-setor';
import { EnumTipoProcessoSetor } from 'model/enums/enum-tipo-processo-setor';
interface SetorProcessosEdit extends DefaultFormProps<SetorEProcessoEditFormModel>{
setTipoDeProcesso: (value:EnumTipoProcessoSetor) => void;
}
export const FormSetorEdit = forwardRef<
  DefaultFormRefs<SetorEProcessoEditFormModel>,
  SetorProcessosEdit
>(({ loading, ...props }: SetorProcessosEdit, ref) => {
  const utilClasses = makeUtilClasses();
  const refInputNumSetor = useRef<HTMLInputElement>(null);
  const [modelForm, setModelForm] = useState<SetorEProcessoEditFormModel>(
    new SetorEProcessoEditFormModel(),
  );
  const { FormSetorEditValidationYup } = useFormSetorEditValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
  } = useForm<SetorEProcessoEditFormModel>({
    defaultValues: { ...modelForm },
    resolver: yupResolver(FormSetorEditValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = (values: SetorEProcessoEditFormModel) => {
    const setor = picker<SetorEProcessoEditFormModel>(values, new SetorEProcessoEditFormModel());
    props.onSubmit({ ...setor }, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset({ ...modelForm });
      refInputNumSetor.current?.focus();
    },
    fillForm: (model: SetorEProcessoEditFormModel) => {
      setModelForm(model);
      reset({ ...model });
      setTimeout(() => {
        refInputNumSetor.current?.focus();
      }, 500);
    },
  }));

  return (
    <>
      <Box my={2}>
        <div className={utilClasses.formContainer}>
          {loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={loading ? utilClasses.controlLoading : ''}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="descricao"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      autoComplete='new-password'
                      tipo={'TEXTO'}
                      disabled={loading}
                      fullWidth
                      variant="outlined"
                      label="Descrição"
                      allowSubmit
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ maxLength: 70 }}
                      placeholder="Digite o nome"
                      error={Boolean(
                        errors.descricao && errors.descricao.message,
                      )}
                      helperText={
                        touchedFields.descricao || errors.descricao
                          ? errors.descricao?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="tipoProcesso"
                  control={control}
                  render={({ field }) => (
                    <SelectSaurus
                      disabled={loading}
                      conteudo={tipoProcessoSetorMock}
                      {...field}
                      fullWidth
                      error={Boolean(errors.tipoProcesso && errors.tipoProcesso.message)}
                      helperText={
                        touchedFields.tipoProcesso || errors.tipoProcesso
                          ? errors.tipoProcesso?.message
                          : undefined
                      }
                      label="Tipo de Processo"
                      onChange={(ev) => {
                        const item = tipoProcessoSetorMock.filter(
                          (item) => item.Key === ev.target.value,
                        )[0]?.Key;
                        if (item !== undefined) {
                          props.setTipoDeProcesso(item)
                          setValue('tipoProcesso', item);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </Box>
    </>
  );
});
