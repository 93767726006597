import { EnumTipoProcessoSetor } from "model/enums/enum-tipo-processo-setor";
import { guidEmpty } from "utils/guid-empty";

export class SetorEProcessoEditFormModel {
    constructor(
        public id: string = guidEmpty(),
        public descricao: string = '',
        public uriImage: string = '',
        public minutosDePreparo: number = 0,
        public tipoProcesso: EnumTipoProcessoSetor = EnumTipoProcessoSetor.ImpressorLocal
    ) { }
}