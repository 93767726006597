import { useCallback, useMemo, useState } from 'react';
import {
  EditarIcon,
  InformacaoIcon,
  LixoIcon,
  NovoIcon,
  OkIcon,
  SalvarIcon,
  VoltarIcon
} from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
  FormProdutoPrincipalEdit
} from '../../../../form/produto/form-produto-edit/produto-edit-principal/form-produto-principal-edit';
import classNames from 'classnames';
import { guidEmpty } from 'utils/guid-empty';
import { isEmpty } from 'lodash';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';
import { MenuOptions } from 'views/components/menu-options/menu-options';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
// import {
//   TabSaurusContent,
//   TabSaurusLabel,
//   TabsSaurus,
// } from '../../../../tabs/tabs-saurus';
import { FormProdutoResumoEdit } from '../../../../form/produto/form-produto-edit/produto-edit-resumo/form-produto-resumo-edit';
// import { ProdutoSubItens } from './components/produto-subitens/produto-subitens';
import { useStyles } from './produto-edit-styles';
import { CardPropagandaUrl } from 'views/components/cards/card-propaganda-url/card-propaganda-url';
import {
  FormProdutoCodigosEdit
} from 'views/components/form/produto/form-produto-edit/produto-edit-codigos/form-produto-codigos-edit';
import {
  TabSaurusContent,
  TabSaurusLabel,
  TabsSaurus
} from 'views/components/tabs/tabs-saurus';
import {
  ProdutoSubItens
} from './components/produto-subitens/produto-subitens';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { produtoPageNome } from 'views/pages/private/cadastros/produto/produto-page';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { SwitchSaurus } from 'views/components/controles';
import { useThemeQueries } from 'views/theme';
import { isPlanoControleMesasComandas } from 'utils/plano-utils';
import { TpProdutoFarmaciaMock, TpProdutoMock } from 'data/mocks/tp-produto-mock';
import { RadioList } from 'views/components/controles/radio-list/radio-list';
import { FormMedicamentoEditPrincipal } from 'views/components/form/produto/form-produto-edit/medicameto-edit-principal/medicamento-edit-principal';
import { EnumFormCodigo, FormProdutoCodigoPreCadastro } from 'views/components/form/produto/produto-pre-cadastro/form-produto-codigo/form-produto-codigo-pre-cadastro';
import { Box, Button, Grid, Typography } from 'views/design-system';
import { useProdutoEdit } from './hooks/use-produto-edit';
import { SomatoriosMovimentacoesEstoqueModel } from 'model/api/gestao/movimentacao-estoque/movimentacao-estoque-model';
import { useIOS } from 'views/theme/util-IOS';


export interface FormComponentCodigoModel {
  title: string;
  model: ProdutoCodigoModel;
}

interface ProdutoEditProps {
  id: string;
  empresaId: string;
  contratoId: string;
  tipo: EnumTipoProduto;
  handleAtualizarLista: (value: boolean) => void
  atualizarLista: boolean
}

export const ProdutoEdit = (props: ProdutoEditProps) => {
  const { isDispositivoIOS } = useIOS()
  const { callEvent } = useEventTools();
  const modalClasses = useModalStyles();

  const [ajustarEstoque, setAjustarEstoque] = useState<boolean>(false)
  const [abaEstoqueSelecionada, setAbaEstoqueSelecionada] = useState<number>(0)
  const [somatorios, setSomatorios] = useState<SomatoriosMovimentacoesEstoqueModel>(new SomatoriosMovimentacoesEstoqueModel())

  const {
    carregando,
    changeStatus,
    excluirProduto,
    handleSubmit,
    handleSubmitFormPreCadastroCodigo,
    handleSubmitResumo,
    onCloseClick,
    submitMedicamento,
    submitProduto,
    trocarTipoProduto,
    plano,
    produtoCompleto,
    preencherTela,
    produtoFormPrincipalState,
    setPreenchendoTela,
    produtoEntrega,
    produtoGenerico,
    produtoServico,
    refEditResumoForm,
    abrirCadastroProduto,
    isMedicamento,
    putConfigContratoWrapper,
    setChangeTipo,
    tituloRef,
    refEditMedicamentoForm,
    refProdutoVariacaoPadrao,
    refSubitens,
    refEditPrincipalForm,
    refPreCadastroCodigo,
    changeTipo,
    codigoProdutoRefs,
    irParaTelaDeCodigoAnvisa,
    preenchendoTela,
    abaSelecionada,
    setAbaSelecionada,
    showOptions,
    atualizarVariacao,
    alterarTipoProduto,
    isPlanoFarma
  } = useProdutoEdit(props)
  const classes = useStyles();
  const { theme } = useThemeQueries();


  const menuOptions = useCallback(() => {
    const options: any[] = [
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              Habilitado para Venda
            </Typography>
            <SwitchSaurus
              size="small"
              value={produtoFormPrincipalState.ativo}
              variant="DEFAULT"
              checked={produtoFormPrincipalState.ativo}
            />
          </Box>
        ),
        <></>,
        changeStatus
      ),
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              {`Excluir ${produtoPageNome(
                produtoFormPrincipalState.tipo,
                false
              )}`}
            </Typography>
            <LixoIcon tipo="BUTTON" fill={theme.palette.error.main} />
          </Box>
        ),
        <></>,
        excluirProduto
      ),
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoGenerico?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Produto Genérico</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoGenerico,
                props.id
              )
          )
          : null,
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoEntrega?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Taxa de Entrega</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoEntrega,
                props.id
              )
          )
          : null,
      produtoFormPrincipalState.tipo !== EnumTipoProduto.Produto
        ? null
        : produtoServico?.vConfig !== props.id
          ? new MenuOptionsModel(
            (
              <Box className={classes.menuItem}>
                <Typography>Taxa de Serviço</Typography>
                <SwitchSaurus
                  size="small"
                  value={false}
                  variant="DEFAULT"
                  checked={false}
                />
              </Box>
            ),
            <></>,
            async () =>
              await putConfigContratoWrapper(
                EnumContratoConfig.ProdutoServico,
                props.id
              )
          )
          : null,
      isPlanoControleMesasComandas(plano?.plano) &&
        produtoFormPrincipalState.tipo !== EnumTipoProduto.Medicamento
        ? new MenuOptionsModel(
          (
            <Box className={classes.menuItem}>
              <Typography>Calcula Taxa de Serviço</Typography>
              <SwitchSaurus
                size="small"
                value={produtoFormPrincipalState.cobraTaxaServico}
                variant="DEFAULT"
                checked={produtoFormPrincipalState.cobraTaxaServico}
              />
            </Box>
          ),
          <></>,
          async () => {
            await handleSubmit(
              {
                ...produtoFormPrincipalState,
                cobraTaxaServico: !produtoFormPrincipalState.cobraTaxaServico
              },
              produtoFormPrincipalState
            );
            await preencherTela();
          }
        )
        : null,
      new MenuOptionsModel(
        (
          <Box className={classes.menuItem}>
            <Typography>
              {TpProdutoMock.find(
                (x) => x.Key === produtoFormPrincipalState.tipo
              )?.Value || 'Produto'}
            </Typography>
            <EditarIcon tipo="BUTTON" />
          </Box>
        ),
        <></>,
        () => {
          callEvent(AppEventEnum.AttTituloEdicaoProduto, {
            titulo: 'Alterar Tipo Produto'
          });
          setChangeTipo(true);
        }
      )
    ];
    return options;
  }, [callEvent, changeStatus, classes.menuItem, excluirProduto, handleSubmit, plano?.plano, preencherTela, produtoEntrega?.vConfig, produtoFormPrincipalState, produtoGenerico?.vConfig, produtoServico?.vConfig, props.id, putConfigContratoWrapper, setChangeTipo, theme.palette.error.main]);

  const renderTopForm = useMemo(() => {
    return (
      <div className={classes.principal}>
        <FormProdutoResumoEdit
          tipo={props.tipo}
          ref={refEditResumoForm}
          onSubmit={handleSubmitResumo}
          setCarregandoExterno={setPreenchendoTela}
          showLoading={false}
          loading={carregando}
          trocarTipoProduto={trocarTipoProduto}
          empresaId={props.empresaId}
          contratoId={props.contratoId}
          id={props.id}
          ativo={produtoFormPrincipalState.ativo}
          tpProdutosConfig={{
            entrega: produtoEntrega?.vConfig,
            servico: produtoServico?.vConfig,
            generico: produtoGenerico?.vConfig
          }}
        />
      </div>
    );
  }, [classes.principal, props.tipo, props.empresaId, props.contratoId, props.id, refEditResumoForm, handleSubmitResumo, setPreenchendoTela, carregando, trocarTipoProduto, produtoFormPrincipalState.ativo, produtoEntrega?.vConfig, produtoServico?.vConfig, produtoGenerico?.vConfig]);

  //RENDERIZAR FORM CASO SEJA MEDICAMENTO
  const renderMedicamentoForm = (
    <div className={classes.tabContent}>
      <FormMedicamentoEditPrincipal
        ref={refEditMedicamentoForm}
        onSubmit={submitMedicamento}
        showLoading={false}
        loading={carregando}
      />
    </div>
  );

  //RENDERIZAR FORM SUBITENS

  const renderSubitens = (
    <Grid className={classes.tabContent}>
      <ProdutoSubItens
        produtoGradeId={
          !isEmpty(produtoCompleto.grades) ? produtoCompleto.grades[0].id : ''
        }
        setCarregandoExterno={setPreenchendoTela}
        carregandoExterno={carregando}
        empresaId={props.empresaId}
        produtoId={props.id}
        variacaoId={refProdutoVariacaoPadrao.current.id}
        tipo={produtoFormPrincipalState.tipo}
        tpProduto={produtoCompleto.tipo}
        ref={refSubitens}
        isAbaSubitens={abaSelecionada === 1}
      />
    </Grid>
  );

  //RENDERIZAR TABS CASO SEJA PRODUTO
  const renderProdutoForm = (
    <Grid className={classes.tabContent}>
      <FormProdutoPrincipalEdit
        handleSomatoriosDeEstoque={setSomatorios}
        handleAbaSelecionada={setAbaEstoqueSelecionada}
        abaSelecionada={abaEstoqueSelecionada}
        handleAjustarEstoque={setAjustarEstoque}
        abrirEstoque={ajustarEstoque}
        tipo={produtoFormPrincipalState.tipo}
        id={props.id}
        ref={refEditPrincipalForm}
        onSubmit={submitProduto}
        setCarregandoExterno={setPreenchendoTela}
        atualizarVariacao={atualizarVariacao}
        showLoading={false}
        variacaoPadrao={refProdutoVariacaoPadrao.current}
        loading={preenchendoTela}
        empresaId={props.empresaId}
        contratoId={props.contratoId}
        openDialogCodigo={(codigo?: string) => {
          let model = new ProdutoCodigoModel();
          if (codigo) {
            model =
              refProdutoVariacaoPadrao.current.codigos.find(
                (x) => x.codigo === codigo
              ) || model;
          }
          codigoProdutoRefs.current?.adicionarNovo(model, model.id !== guidEmpty() ? 'Editar Código' : 'Adicionar Código');
        }}
      />
    </Grid>
  );

  return (
    <div className={classNames(modalClasses.root, classes.root)}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={
          carregando
            ? ''
            : `Edição de ${produtoPageNome(
              produtoFormPrincipalState.tipo,
              false
            )}`
        }
        titleRef={tituloRef}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
        rightArea={
          showOptions ? <MenuOptions
            options={menuOptions()}
            labels={[
              'Ações',
              '',
              'Produtos Especiais',
              '',
              '',
              'Taxa de Serviço',
              'Tipo de Produto'
            ]}
          /> : null
        }
      />
      {!irParaTelaDeCodigoAnvisa ? (
        <Grid container className={classNames(modalClasses.content, classes.relative)}>
          <Grid item
            className={classNames(
              modalClasses.contentForms,
              carregando ? modalClasses.contentFormsLoading : undefined
            )}
          >
            {renderTopForm}
            <Grid item
              className={classNames(
                classes.contentAbas,
                isMedicamento ? classes.contentAbasMedicamento : undefined
              )}
            >
              <TabsSaurus
                selectedTabIndex={abaSelecionada}
                onChange={(aba) => {
                  setAbaSelecionada(aba);
                }}
                contentRelative={false}
                tabsLabel={[
                  new TabSaurusLabel('Principal', 0),
                  new TabSaurusLabel(
                    produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento
                      ? 'Medicamento'
                      : 'Ficha Técnica',
                    1
                  )
                ]}
                tabsContent={[
                  new TabSaurusContent(
                    0,
                    (
                      <>
                        {renderProdutoForm}
                        <div>
                          <FormProdutoCodigosEdit
                            model={refProdutoVariacaoPadrao.current.codigos}
                            produtoId={refProdutoVariacaoPadrao.current.produtoId}
                            variacaoId={refProdutoVariacaoPadrao.current.id}
                            preencherTela={preencherTela}
                            hideList={
                              refProdutoVariacaoPadrao.current.codigos.length < 2
                            }
                            ref={codigoProdutoRefs}
                          />
                        </div>
                      </>
                    )
                  ),
                  new TabSaurusContent(
                    1,
                    produtoFormPrincipalState.tipo === EnumTipoProduto.Medicamento
                      ? renderMedicamentoForm
                      : renderSubitens
                  )
                ]}
              />
            </Grid>
          </Grid>

          <Grid container className={modalClasses.acoes}>
            <div className={classes.propagandaContent}>
              <CardPropagandaUrl />
            </div>
            {(isDispositivoIOS && ajustarEstoque && abaEstoqueSelecionada === 0) &&
              <Grid container spacing={1} className={isDispositivoIOS ? classes.containerIOS : ''} item p={1} alignItems="center" justifyContent="space-between" flex>
                <Grid item xs={9} flex alignItems="center" >
                  <Box pr={1}>
                    <InformacaoIcon tipo="INPUT" fill={theme.palette.primary.main} />
                  </Box>
                  <Typography variant="body2" >
                    O valor total exibido ao lado corresponderá exclusivamente à data selecionada no filtro.
                  </Typography>
                </Grid>
                <Grid item xs={3} flex justifyContent="flex-end">
                  <Typography variant="h6">Total: <strong>
                    {somatorios.TotalQComOriginal ?? 0}
                  </strong></Typography>
                </Grid>
              </Grid>
            }
            {!ajustarEstoque &&
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    disabled={carregando}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      abrirCadastroProduto('', props.tipo, '', true);
                    }}
                  >
                    <NovoIcon tipo="BUTTON" />
                    Novo
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    disabled={carregando}
                    onClick={() => {
                      refEditResumoForm.current?.submitForm();
                    }}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <SalvarIcon tipo="BUTTON_PRIMARY" />
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            }
          </Grid>
          {changeTipo && (
            <RadioList
              handleSubmit={alterarTipoProduto}
              selecionado={produtoFormPrincipalState.tipo}
              mock={isPlanoFarma ? TpProdutoFarmaciaMock : TpProdutoMock}
              text="Para alterar o tipo do produto, selecione um tipo abaixo e confirme:"
            />
          )}

        </Grid>
      ) : (
        <Grid container justifyContent='space-between' spacing={2} className={classes.formularioAnvisa}>
          <Grid item xs={12}>
            <FormProdutoCodigoPreCadastro
              obrigatorio
              showLoading={false}
              ref={refPreCadastroCodigo}
              loading={carregando}
              setCarregandoExterno={setPreenchendoTela}
              onSubmit={handleSubmitFormPreCadastroCodigo}
              label={'Código ANVISA'}
              tipoForm={EnumFormCodigo.AMBOS}
            /> </Grid>
          <Grid item xs={12} className={classes.botaoPesquisar} alignItems='flex-end'>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type='submit'
              onClick={() => { refPreCadastroCodigo.current?.submitForm() }}
            >
              <OkIcon tipo="BUTTON_PRIMARY" />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default ProdutoEdit;
