import classNames from 'classnames';
import { useGetFaturasById } from 'data/api/gestao/fatura/get-faturas-by-id';
import { useGetHistoricos } from 'data/api/gestao/fatura/get-historicos';
import { usePostFaturasAntecipar } from 'data/api/gestao/fatura/post-faturas-antecipar';
import { StatusFaturaMock } from 'data/mocks/status-fatura-mock';
import { FaturaHistoricoModel } from 'model/api/gestao/fatura/fatura-historico';
import { FaturaModel } from 'model/api/gestao/fatura/fatura-model';
import { EnumFaturaStatus } from 'model/enums/enum-fatura-status';
import { useCallback, useEffect, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { toDecimalString } from 'utils/to-decimal';
import { CircularLoading } from 'views';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header';
import { DialogDadosFaturas } from 'views/components/dialog/dialog-dados-faturas/dialog-dados-faturas';
import { VoltarIcon } from 'views/components/icons';
import { ModalHeader } from '../../components';
import { useModalStyles } from '../../utils/modal-styles';
import { useStyles } from './faturas-modal-styles';
import { toDateString } from 'utils/to-date';
import { Button, Divider, Grid, Typography } from 'views/design-system';
import { useTheme } from '@material-ui/core';
import { DefaultCard } from 'views/components/cards/components';

export const FaturasVisualizar = (props: { id: string }) => {
  // STATES E REFS
  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true);
  const [dataFaturas, setDataFaturas] = useState<FaturaModel>(
    new FaturaModel(),
  );
  const [dataHistoricos, setDataHistoricos] = useState<
    Array<FaturaHistoricoModel>
  >([new FaturaHistoricoModel()]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // PROVIDERS
  const { fecharCadastroFaturas } = useCadastros();

  // CHAMDAS NA API
  const { getFaturasById, carregando: getFaturasByIdCarregando } =
    useGetFaturasById();
  const { postFaturasAntecipar, carregando: postFaturasAnteciparCarregando } =
    usePostFaturasAntecipar();
  const { getHistoricos, carregando: getHistoricosCarregando } =
    useGetHistoricos();

  // AUX
  const classes = useModalStyles();
  const theme = useTheme();
  const faturaClasses = useStyles();
  const { showToast } = useToastSaurus();

  const carregando =
    preenchendoTela ||
    getFaturasByIdCarregando ||
    getHistoricosCarregando ||
    postFaturasAnteciparCarregando;

  const getFaturasByIdWrapper = useCallback(async () => {
    try {
      const ret = await getFaturasById(props.id);

      if (ret.erro) {
        throw ret.erro;
      }

      return ret.resultado?.data as FaturaModel;
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getFaturasById, props.id, showToast]);

  const getHistorico = useCallback(async () => {
    try {
      const ret = await getHistoricos(props.id);
      if (ret.erro) {
        throw ret.erro;
      }

      return ret.resultado?.data?.list as Array<FaturaHistoricoModel>;
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [getHistoricos, props.id, showToast]);

  const preencherTela = useCallback(async () => {
    try {
      const faturas = await getFaturasByIdWrapper();
      const historicos = await getHistorico();

      setDataFaturas(faturas || new FaturaModel());
      setDataHistoricos(historicos || [new FaturaHistoricoModel()]);
    } catch (e: any) { }
  }, [getFaturasByIdWrapper, getHistorico]);

  useEffect(() => {
    (async () => {
      await preencherTela();
      setPreenchendoTela(false);
    })();
  }, [preencherTela]);

  const onCloseClick = useCallback(() => {
    fecharCadastroFaturas();
  }, [fecharCadastroFaturas]);

  const gerarBoleto = useCallback(async () => {
    try {
      const ret = await postFaturasAntecipar(props.id);

      if (ret.erro) {
        if (ret.statusCode === 412) {
          setError(ret.erro);
          setOpenDialog(true);
          throw ret.erro;
        }
        throw ret.erro;
      }
      const result = ret.resultado?.data as any;

      window.open(result.urlPDF);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [postFaturasAntecipar, props.id, showToast]);

  const retornarCorStatus = useCallback((): any => {
    switch (dataFaturas.status) {
      case EnumFaturaStatus.ABERTA:
        return '#34A4D3';
      case EnumFaturaStatus.PAGA:
        return '#41D334';
      case EnumFaturaStatus.CANCELADA:
        return '#ED2020';
      case EnumFaturaStatus.REJEITADA:
        return '#ED9B20';
      case EnumFaturaStatus.FECHADO:
        return '#2059ED';
      case EnumFaturaStatus.EMATRASO:
        return '#E6EA16';
    }
  }, [dataFaturas.status]);

  const nomeStatus = StatusFaturaMock.filter(
    (x) => x.Key === dataFaturas.status,
  )[0].Value;

  return (
    <div className={classes.root}>
      <ModalHeader
        title={dataFaturas.status === 0 ? 'Fatura em aberto' : 'Sobre a fatura'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />

      {carregando ? (
        <CircularLoading tipo="FULLSIZED" />
      ) : (
        <div className={classes.content} style={{ marginBottom: '6rem' }}>
          <div className={classNames(classes.contentForms)}>
            <Typography
              color="textSecondary"
              weight={700}
              variant="h6"
            >
              {dataFaturas.descricao}
            </Typography>
            <Typography color="initial" variant="caption">
              <span style={{ fontWeight: 500 }}>
                <b>Feito dia:</b> {toDateString(new Date(dataFaturas.dInserido))}
              </span>
            </Typography>

            <div
              style={{
                width: '100%',
                marginTop: 16,
                display: 'flex',
                padding: theme.spacing(1),
                borderRadius: 4,
                justifyContent: 'center',
                backgroundColor: 'rgba(149, 132, 132, .1)',
                border: 'solid 1px rgb(209 209 209)',
              }}
            >
              <Typography
                className={'celula-grid-value'}
                color="textPrimary"
                style={{ color: retornarCorStatus(), fontWeight: 700 }}
              >
                {nomeStatus}
              </Typography>
            </div>
            <DefaultCard hasTagStatus={false} style={{ margin: '1.5rem 0' }}>
              <div className={faturaClasses.contentFatura}>
                <div>
                  <Typography>
                    <b>Valor Original</b>
                  </Typography>
                  <Typography>
                    R$ {toDecimalString(dataFaturas.valor, 2)}
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <b>Encargo</b>
                  </Typography>
                  <Typography>
                    R$ {toDecimalString(dataFaturas.encargo, 2)}
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <b>Subtotal</b>
                  </Typography>
                  <Typography>
                    R${' '}
                    {toDecimalString(
                      dataFaturas.valor + dataFaturas.encargo,
                      2,
                    )}
                  </Typography>
                </div>
              </div>
              <Divider
                variant="middle"
                style={{ backgroundColor: theme.palette.grey[300] }}
              />
              <div className={faturaClasses.contentFatura}>
                <div>
                  <Typography>
                    <b>Data de Vencimento</b>
                  </Typography>
                  <Typography>
                    {toDateString(new Date(dataFaturas.dVencimento))}
                  </Typography>
                </div>
              </div>
            </DefaultCard>
            <Typography
              color="textSecondary"
              style={{ fontWeight: 700, marginBottom: '1rem' }}
              variant="h5"
            >
              Histórico da Fatura
            </Typography>
            {dataHistoricos.map((item) => {
              return (
                <DefaultCard hasTagStatus={false} style={{ margin: '8px 0' }}>
                  <div className={faturaClasses.contentFatura}>
                    <div>
                      <div>
                        <Typography>{item.descricao}</Typography>
                      </div>
                    </div>
                    <div>
                      <Typography>
                        <b>Dia Inserido</b>
                      </Typography>
                      <Typography>
                        {toDateString(new Date(item.dInserido))}
                      </Typography>
                    </div>
                    <div>
                      <Typography>
                        <b>Valor</b>
                      </Typography>
                      <Typography>
                        R$ {toDecimalString(item.valor, 2)}
                      </Typography>
                    </div>
                  </div>
                </DefaultCard>
              );
            })}

            <>
              <Grid
                container
                className={faturaClasses.buttonBottom}
                style={{ backgroundColor: '#fff' }}
              >
                <Button
                  fullWidth
                  disabled={dataFaturas.status === EnumFaturaStatus.BONIFICADA}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ padding: '1em 0' }}
                  onClick={gerarBoleto}
                >
                  {dataFaturas.status === EnumFaturaStatus.PAGA
                    ? 'Visualizar fatura'
                    : 'Gerar Boleto'}
                </Button>
              </Grid>

              <DialogDadosFaturas
                error={error}
                closeModal={() => setOpenDialog(false)}
                openned={openDialog}
              />
            </>
          </div>
        </div>
      )}
    </div>
  );
};
