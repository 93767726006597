import { useTheme } from '@material-ui/core';
import { useStyles } from '../card-dashboard-chart-styles';
import Chart from 'react-apexcharts';
import { useCallback } from 'react';
import { CardDashboardChartDonutProps } from '../card-dashboard-chart-props';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { CircularLoading } from 'views/components/utils';
import { Box, Divider, Grid, Typography } from 'views/design-system';
import { DefaultCard } from 'views/components/cards/components';
import { tpPagamentoResolver } from 'utils/tp-pagamento-resolver';

export const CardDashboardChartDonut = ({
  model,
  carregando
}: CardDashboardChartDonutProps) => {
  const utilClasses = useStyles();
  const theme = useTheme();

  let modUnicos = new Map(
    model.map((obj) => [obj.modPagamento, obj]),
  );

  const modsUnicosList = Array.from(modUnicos.values());

  const pagamentosSerelizados = modsUnicosList.map((pagamento) => {
    return {
      ...pagamento,
      vVenda: 0,
      qtde: 0
    };
  });

  pagamentosSerelizados.map((pagamento) => {
    model.map((pag) => {
      if (pag.modPagamento === pagamento.modPagamento) {
        let novaQuantidade = (pagamento.vVenda += pag.vVenda);
        let novaQTDE = pagamento.qtde += 1
        return {
          ...pagamento,
          vPag: novaQuantidade,
          qtde: novaQTDE
        };
      }
      return pag;
    });
    return pagamento;
  });

  const arraySeries = useCallback(() => {
    const modUnique = Array.from(
      new Set<number>(model?.map((item) => item?.modPagamento)),
    );

    const retornaSeries = modUnique.map((item) => {
      return model
        ?.filter((x) => x?.modPagamento === item && x)
        .reduce((a, b) => a + b.vVenda, 0);
    });
    return retornaSeries;
  }, [model]);

  const labelsText = model?.map((tp) => {
    return tpPagamentoResolver(tp.modPagamento, tp.tpTransacao)?.Value;
  });

  const unique = Array.from(
    new Set<string>(labelsText?.map((item) => String(item))),
  );

  const options = useCallback(() => {
    const options = {
      labels: unique,
      tooltip: {
        style: {
          fontSize: '24px',
        },
        y: {
          formatter: function (val: any) {
            return `R$ ${toDecimalString(val)}`;
          },
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                formatter: function (val: any) {
                  return `R$ ${toDecimalString(val)}`;
                },
              }
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            legend: {
              fontSize: 16,
              position: 'top',
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            legend: {
              position: 'top',
            },
          },
        },
      ],
      legend: {
        show: true,
      },
    };
    return options;
  }, [unique]);

  return (
    <>
      <DefaultCard hasTagStatus={false}>
        <Grid container>
          <Grid item xs={12} p={1}>
            <Typography
              color="primary"
              weight={500}
            >
              Modelos de pagamentos
            </Typography>
            <Divider variant='fullWidth' />
          </Grid>

          {carregando ?
            <div style={{ position: 'relative' }}>
              <CircularLoading tipo='FULLSIZED' />
            </div>
            :
            <>
              {
                model.map(item => item.modPagamento)[0] === 0 || model.length === 0 ? (
                  <Grid item xs={12} my={2} justifyContent='center'>
                    <Typography variant="body1" align='center'>
                      Não há dados para exibir
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {VariaveisAmbiente.paymentDevice === undefined ? (
                      <>
                        <div >
                          <Grid item mt={1} >
                            {pagamentosSerelizados.sort((a, b) => b.qtde - a.qtde).filter(item => item.modPagamento !== 0).map((item, index) => {
                              const pegaVendaTotal = model.reduce(
                                (a, b) => a + b.vVenda,
                                0,
                              );
                              const porcentagem = (
                                (item.vVenda * 100) /
                                pegaVendaTotal
                              ).toFixed(0);

                              return (
                                <DefaultCard
                                  hasTagStatus={false}
                                  key={index}
                                  style={{
                                    marginLeft: theme.spacing(1),
                                    marginRight: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                    position: 'relative',
                                    overflow: 'hidden'
                                  }}
                                >
                                  <Box
                                    flex
                                    justifyContent='space-between'
                                    alignItems='center'
                                    px={2}
                                    flexDirection='column'
                                  >

                                    <Typography
                                      weight={600}
                                      variant='body1'
                                      color='textPrimary'
                                    >
                                      {item !== null
                                        ? `${index + 1}° ${tpPagamentoResolver(item.modPagamento, item.tpTransacao)?.Value
                                        }`
                                        : 'Sem vendas para mostrar'}
                                    </Typography>
                                    <Box
                                      py={1}
                                    >
                                      <Typography
                                        weight={600}
                                        align='right'
                                        color='textSecondary'
                                      >
                                        R$ {toDecimalString(
                                          item.vVenda,
                                          2,
                                        )}
                                      </Typography>
                                      <Typography
                                        weight={500}
                                        align='right'
                                        color='textSecondary'
                                      >
                                        {parseInt(porcentagem.toString())}%
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <div style={{
                                    width: `${porcentagem}%`,
                                    backgroundColor: `#${((Math.random() * 0xffffff) << 0)
                                      .toString(16)
                                      .padStart(6, '0')}`,
                                    height: 5,
                                    zIndex: '-1000'
                                  }} />
                                </DefaultCard>
                              );
                            })}
                          </Grid>
                        </div>
                      </>
                    ) : (
                      <Grid flex justifyContent='center' mt={2} item xs={12} >
                        <Chart
                          options={options()}
                          series={arraySeries()}
                          type="donut"
                          className={utilClasses.chartDonut}
                        />
                      </Grid>
                    )}
                  </>
                )
              }
            </>
          }
        </Grid>
      </DefaultCard>
    </>
  );
};