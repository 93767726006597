import { Button, Divider, Grid, Typography } from 'views/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GestaoStorageKeys, useGestaoStorage, } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { AlertaIcon, OkIcon, VoltarIcon } from 'views/components/icons';
import { useThemeQueries } from 'views/theme';
import { useSincronizacaoCadastros } from 'services/app/hooks/sincronizacao-cadastros';
import { usePDV } from 'services/app/hooks/pdv';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { TouchoneBacupkup } from 'database/touchone-backup';
import { useStyles } from './pdv-diferente-styles';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';
import { useBancoLocal } from 'services/app/hooks/banco-local';

type Props = {
  onClick: () => void;
};

export const PDVDiferenteConta = ({ onClick }: Props) => {
  // STATES E REFS
  const [dadosMovimentacao, setDadosMovimentacao] = useState<
    { qtdeMovimentacao: number, vendas: number } | undefined
  >(undefined);
  const classes = useStyles();
  // HOOKS
  const { delRegistro } = useGestaoStorage();
  const { limparCadastros } = useSincronizacaoCadastros();
  const { getPDV } = usePDV();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { clear } = useBancoLocal();

  // AUX
  const history = useHistory();
  const { isMobile } = useThemeQueries();

  const pdv = getPDV();

  const clickContinuar = useCallback(async () => {
    try {
      await limparCadastros();

      await TouchoneBacupkup.sessao.bulkAdd(
        await TouchoneDBPrimary.sessao
          .filter(
            (sessao) => !sessao.dataFechamento && sessao.caixaId === pdv?.id,
          )
          .toArray(),
      );

      await TouchoneBacupkup.sessaoValores.bulkAdd(
        await TouchoneDBPrimary.sessaoValores
          .filter(
            (valor) => !valor.dataSincronizacao && valor.caixaId === pdv?.id,
          )
          .toArray(),
      );

      await TouchoneBacupkup.vendas.bulkAdd(
        await TouchoneDBPrimary.vendas
          .filter(
            (valor) => !valor.dataSincronizacao && valor.empresaId === getEmpresaSelecionada()!.Id
          )
          .toArray(),
      );

      await clear(true);

      delRegistro(GestaoStorageKeys.PDV, false);
      delRegistro(GestaoStorageKeys.MovSimplesAnterior, false);
      delRegistro(GestaoStorageKeys.MovSimplesAtual, false);

      setTimeout(() => {
        onClick();
      }, 500);
    } catch (e: any) {
      await clear(true);
      onClick();
    }
  }, [clear, delRegistro, getEmpresaSelecionada, limparCadastros, onClick, pdv?.id]);

  useEffect(() => {
    const getDadosMovimentacao = async () => {
      const movimentacoes = await TouchoneDBPrimary.sessaoValores
        .filter(
          (valor) => !valor.dataSincronizacao && valor.caixaId === pdv?.id,
        )
        .count();
      const vendas = await TouchoneDBPrimary.vendas
        .filter(
          (valor) => !valor.dataSincronizacao
        )
        .count();

      setDadosMovimentacao({
        qtdeMovimentacao: movimentacoes,
        vendas: vendas
      });
    };

    getDadosMovimentacao();
  }, [pdv?.id]);

  return (
    <Grid
      item
      style={{
        maxWidth: 600,
        textAlign: 'center',
        padding: 16,
        borderRadius: 10,
        background: isMobile ? 'none' : '#FFF',
      }}
    >
      <Typography className={classes.titulo} variant="h4" color="primary">
        <AlertaIcon tipo='GERAL' />
        Dispositivo em Uso!
      </Typography>
      <Divider
        style={{
          marginBottom: 16,
          background: 'rgb(85 85 85 / 24%)',
          marginTop: 8,
        }}
      />
      <Typography variant="body1">
        Este dispositivo está conectado na conta de outra empresa.
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 8 }}>
        CNPJ: <b>{formatarCPFCNPJ(pdv?.dominio ?? '')}</b> - PDV <b>{(pdv?.numCaixa ?? 0)}</b>.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 32 }}>
        Para inciar o módulo de venda precisaremos descartar todas as informações da outra conta. Deseja continuar?
      </Typography>
      {((dadosMovimentacao?.vendas ?? 0) > 0 ||
        (dadosMovimentacao?.qtdeMovimentacao ?? 0) > 0) && (
          <>
            <Typography variant="body1" color='primary' style={{ marginBottom: 32 }}>
              Você possui algumas pendências de Sincronização:<br /><b>(Movimentações:{` ${dadosMovimentacao?.qtdeMovimentacao ?? 0}, `} Vendas: {` ${dadosMovimentacao?.vendas ?? 0}`})</b>
            </Typography>
          </>
        )}
      {!((dadosMovimentacao?.vendas ?? 0) > 0 ||
        (dadosMovimentacao?.qtdeMovimentacao ?? 0) > 0) && (
          <>
            <Typography variant="body1" color='primary' style={{ marginBottom: 32 }}>
              <b>Nehuma informação será perdida neste processo.</b>
            </Typography>
          </>
        )}

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={8}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            // TODO: corrigir este problema de rota depois, pois ao voltar a retaguarda, ele não troca o modo
            onClick={() => history.push(RetaguardaRotasMock.dashboardRota.path)}
          >
            <VoltarIcon tipo="BUTTON_PRIMARY" />
            Voltar para o Dashboard
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            onClick={clickContinuar}
          >
            <OkIcon tipo="BUTTON" />
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
