import { useCallback, useState, useMemo, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { FormMovimentacaoProdutoEstoque } from './components';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { DepositosEstoque } from './components/depositos-estoque';
import { useGetDeposito } from 'data/api/gestao/deposito';
import { DepositoModel } from 'model/api/gestao/deposito/deposito-model';
import { usePostProdutoVariacaoAtualizarSaldo } from 'data/api/gestao/produto/produto-variacao/post-produto-variacao-atualizar-saldo';
import { guidEmpty } from 'utils/guid-empty';
import { useStyles } from './produto-estoque-styles';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { CircularLoading } from 'views/components/utils';
import { useGetProdutoVariacaoDeposito } from 'data/api/gestao/produto/produto-variacao/get-produto-variacao-deposito';
import { ProdutoVariacaoDeposito } from 'model/api/gestao/produto/produto-variacao/produto-variacao-deposito';
import { TabSaurusContent, TabSaurusLabel, TabsSaurus } from 'views/components/tabs/tabs-saurus';
import { Box, Grid } from 'views/design-system';
import { MovimentacoesEstoqueList } from './components/movimentacoes-list/movimentacoes-list';
import { EnumTpMovimentacaoEstoque } from 'model/enums/enum-tipo-movimentacao-estoque';
import { SomatoriosMovimentacoesEstoqueModel } from 'model/api/gestao/movimentacao-estoque/movimentacao-estoque-model';
import { useIOS } from 'views/theme/util-IOS';

export interface ProdutoEstoqueRefs {
    close: () => void;
}

export class DepositoListModel extends DepositoModel {
    qSaldo: number = 0;
}

interface ProdutoEstoqueProps {
    variacaoPadraoId: string
    id: string
    atualizarVariacao: () => void
    onClose: () => void;
    setAbaSelecionada: (abaSelecionada: number) => void
    abaSelecionada: number;
    setSomatoriosEstoque: (somatorios: SomatoriosMovimentacoesEstoqueModel) => void
}

export const ProdutoEstoque = forwardRef<
    ProdutoEstoqueRefs,
    ProdutoEstoqueProps
>(({    
    variacaoPadraoId,
    id,
    atualizarVariacao,
    onClose,
    setAbaSelecionada,
    abaSelecionada,
    setSomatoriosEstoque,
}: ProdutoEstoqueProps, ref) => {
    const classes = useStyles();
    const [acao, setAcao] = useState<number>(4)
    const { postProdutoVariacaoAtualizarSaldo, carregando: carregandoPost } = usePostProdutoVariacaoAtualizarSaldo()
    const { getDeposito, carregando: carregandoGet } = useGetDeposito()
    const { getProdutoVariacaoDeposito, carregando: carregandoGetDepos } = useGetProdutoVariacaoDeposito();
    const [depList, setDepList] = useState<Array<DepositoListModel>>([])
    const { getEmpresaSelecionada } = useSessaoAtual()
    const { showToast } = useToastSaurus()
    const [depId, setDepId] = useState<string>('')
    const [valorEstoque, setValorEstoque] = useState<number>(0)
    const { callEvent } = useEventTools();
    const {isDispositivoIOS} = useIOS()
    const depsRef = useRef<ProdutoVariacaoDeposito[]>([])
    const carregando =
        carregandoGet ||
        carregandoPost ||
        carregandoGetDepos

    const getDepositosWrapper = useCallback(async () => {
        const res = await getDeposito("PageSize=100", 1)
        if (res.erro) throw res.erro

        return res.resultado?.data.list as DepositoModel[]
    }, [getDeposito])

    const getDepositosVariacaoWrapper = useCallback(async () => {
        const res = await getProdutoVariacaoDeposito(id, variacaoPadraoId);
        if (res.erro) throw res.erro

        return res.resultado?.data as ProdutoVariacaoDeposito[];
    }, [getProdutoVariacaoDeposito, id, variacaoPadraoId])

    useEffect(() => {
        const getDep = async () => {
            try {
                const depositoWrapper = await getDepositosWrapper();
                const depsProduto = await getDepositosVariacaoWrapper();
                depsRef.current = depsProduto;

                if (depositoWrapper.length > 1) {
                    setDepList(depositoWrapper.map(dep => {
                        return {
                            ...dep,
                            qSaldo: (depsProduto.find(depProd => depProd.depositoId === dep.id)?.qSaldoTotal ?? 0)
                        }
                    }))
                    setAcao(4)
                    return;
                } else if (depositoWrapper.length === 1) {
                    setDepId(depositoWrapper[0].id);

                    const resGetDep = depsProduto[0]
                    setDepList(depositoWrapper.map(dep => {
                        return {
                            ...dep,
                            qSaldo: (depsProduto.find(depProd => depProd.depositoId === dep.id)?.qSaldoTotal ?? 0)
                        }
                    }))
                    setValorEstoque(resGetDep?.qSaldoTotal ?? 0);
                }
                setAcao(4)
            } catch (e: any) {
                showToast('error', e.message)
            }
        }

        getDep();

    }, [getDepositosVariacaoWrapper, getDepositosWrapper, getEmpresaSelecionada, id, showToast, variacaoPadraoId])

    const onClickDepId = useCallback(async (idDeposito: string) => {
        try {
            setDepId(idDeposito);

            const dep = depsRef.current.find(dep => dep.depositoId === idDeposito);

            setValorEstoque(dep?.qSaldoTotal ?? 0);
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [showToast])

    const saveChangesProdutoEstoque = useCallback(
        async (value: number) => {

            if (value === undefined) {
                value = 0;
            }
            const produtoEstoque = {
                qSaldo: value!,
                depositoId: depId,
                empresaId: getEmpresaSelecionada()?.Id || ''
            };
            const ret = await postProdutoVariacaoAtualizarSaldo(
                id,
                variacaoPadraoId,
                { ...produtoEstoque, movId: guidEmpty() },
            );

            if (ret.erro) {
                throw ret.erro;
            }

            showToast('success', 'Estoque do produto atualizado com sucesso!');

            atualizarVariacao()
        },
        [atualizarVariacao, depId, getEmpresaSelecionada, id, postProdutoVariacaoAtualizarSaldo, showToast, variacaoPadraoId],
    );
    const depositoNome = depList.find(item => item.id === depId)?.nome ?? ''

    const retornaDescricao = (tipoDeMovimentacaoEstoque: EnumTpMovimentacaoEstoque) => {
        switch (tipoDeMovimentacaoEstoque) {
            case EnumTpMovimentacaoEstoque.CorrigirSaldo:
                return 'Informe a nova quantidade de estoque:';

            case EnumTpMovimentacaoEstoque.Entrada:
                return 'Informe a quantidade de entrada de estoque:';

            case EnumTpMovimentacaoEstoque.Saida:
                return 'Informe a quantidade de saída de estoque:';
        }
    }

    const propsFormEstoque = useMemo(() => ({
        closeModal: onClose,
        valorEstoque,
        depositoId: depId,
        depositoNome,
        carregando,
        handleSubmit: saveChangesProdutoEstoque,
    }), [carregando, depId, depositoNome, onClose, saveChangesProdutoEstoque, valorEstoque])

    const acoes = useMemo(() => {
        switch (acao) {
            case 1:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Corrigir de Saldo'
                })

                return (
                    <FormMovimentacaoProdutoEstoque
                        {...propsFormEstoque}
                        tipoDeOperacao={EnumTpMovimentacaoEstoque.CorrigirSaldo}
                        descricao={retornaDescricao(EnumTpMovimentacaoEstoque.CorrigirSaldo)}
                    />
                )
            case 2:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Entrada de Estoque'
                })

                return (
                    <FormMovimentacaoProdutoEstoque
                        {...propsFormEstoque}
                        tipoDeOperacao={EnumTpMovimentacaoEstoque.Entrada}
                        descricao={retornaDescricao(EnumTpMovimentacaoEstoque.Entrada)}
                    />
                )
            case 3:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Saída de Estoque'
                })

                return (
                    <FormMovimentacaoProdutoEstoque
                        {...propsFormEstoque}
                        tipoDeOperacao={EnumTpMovimentacaoEstoque.Saida}
                        descricao={retornaDescricao(EnumTpMovimentacaoEstoque.Saida)}
                    />
                )
            case 4:
                callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                    titulo: 'Depósitos'
                })
                return (
                    <DepositosEstoque
                        depositos={depList}
                        onClick={onClickDepId}
                        mudarAcao={setAcao}
                    />
                )
        }
    }, [acao, callEvent, depList, onClickDepId, propsFormEstoque])
    const esconderTabs = useMemo(() => acao !== 4, [acao])
    useImperativeHandle(ref, () => ({
        close() {
            const redirectToList = [1, 2, 3].includes(acao)

            if (redirectToList) {
                setAcao(4);
                return
            }
            callEvent(AppEventEnum.AttTituloEdicaoProduto, {
                titulo: ''
            })
            onClose();
        },
    }))

    return (
        <Box className={isDispositivoIOS ? classes.containerIOS :classes.container} >
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                <div className={classes.tabsContent}>
                    <TabsSaurus
                        selectedTabIndex={abaSelecionada}
                        onChange={(aba) => { setAbaSelecionada(aba) }}
                        tabsLabel={[
                            new TabSaurusLabel('Histórico', 0),
                            new TabSaurusLabel('Posição em Estoque', 1)
                        ]}
                        hideTabs={esconderTabs}
                        disabledSwipe
                        tabsContent={[
                            new TabSaurusContent(0,
                                (
                                    <MovimentacoesEstoqueList
                                        setSomatorios={setSomatoriosEstoque}
                                        variacaoProdutoId={variacaoPadraoId}
                                        depositoId={depId}
                                        depositosList={depList}
                                        carregando={carregando}
                                    />
                                )),
                            new TabSaurusContent(1,
                                (
                                    <Grid>
                                        {acoes}
                                    </Grid>
                                )
                            ),
                        ]} />
                </div>
        </Box>

    );
})