import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonSize: {
        width: 200,
        [theme.breakpoints.down('xs')]: {
            width: 150,
        },
        '& svg': {
            width: '23px',
            height: '23px',
        },
    },
    consultarLabel: {
        fontSize: '11px !important'
    },
    estoqueLabel: {
        fontSize: '12px !important',
    },
    numberLabel: {
        display: 'block',
        fontSize: '16px !important'

    }
}));