import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useFormPrescritorPreCadastroValidation } from './form-prescritor-validations';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrescritorPreCadastroFormModel } from 'model/app/forms/receita/prescritor-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { TpDocumentoMock } from 'data/mocks/tp-documento-mock';
import { TipoDocumentoOrgaoExpedidorMock } from 'data/mocks/orgao-expedidor-mock';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import { UFMock } from 'data/mocks';
import { EnumCadastroTipo, KeyValueModel } from 'model';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { useConsultaCEP } from 'data/api/wsmaster';
import { useToastSaurus } from 'services/app';
import { stringNumeros } from 'utils/string-numeros';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { useGetEnderecoPessoa } from 'data/api/gestao/pessoa';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { Button, Divider, Grid, Typography } from 'views/design-system';

export interface FormPrescritorProps extends DefaultFormProps<PrescritorPreCadastroFormModel> {
}

const documentosPessoais = [
    EnumTipoDocumento.CarteiraIdentidade,
    EnumTipoDocumento.CarteiraTrabalhoPrevidenciaSocial,
    EnumTipoDocumento.Passaporte,
    EnumTipoDocumento.CarteiraRegistroProfissional
]

export const FormPrescritorPreCadastro = forwardRef<
    DefaultFormRefs<PrescritorPreCadastroFormModel>,
    FormPrescritorProps
>(({ loading,
    ...props }: FormPrescritorProps, ref,) => {

    const utilClasses = makeUtilClasses();
    const [, setAtt] = useState<boolean>(false)
    const { theme } = useThemeQueries()
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa()
    const { showToast } = useToastSaurus()
    const [initialValues, setInitialValues] =
        useState<PrescritorPreCadastroFormModel>(
            new PrescritorPreCadastroFormModel(),
        );
    const { FormYupValidationPrePrescritor } =
        useFormPrescritorPreCadastroValidation();

    const carregando = [carregandoCEP, carregandoEndereco].includes(true)

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<PrescritorPreCadastroFormModel>({
        defaultValues: { ...initialValues },
        resolver: yupResolver(FormYupValidationPrePrescritor),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: PrescritorPreCadastroFormModel) => {
        values.documento = stringNumeros(values.documento)
        if (!documentosPessoais.includes(Number(getValues('tipoDocumento')) || 0)) {
            values.orgaoExpedidor = 1
            values.ufOrgaoExpedidor = ''
        }
        props.onSubmit(values);
    };

    const handleAtt = () => setAtt(prev => !prev)

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            setInitialValues(new PrescritorPreCadastroFormModel());
            reset();
        },
        fillForm: (model: PrescritorPreCadastroFormModel) => {
            setInitialValues(model);
            reset({ ...model })
        },
    }));


    const buscarEnderecoPessoa = useCallback(async (pessoaId: string) => {
        try {
            const res = await getEnderecoPessoa(pessoaId)
            if (res.erro) throw res.erro

            const resultado = res.resultado?.data

            return resultado as PessoaEnderecoModel[]
        } catch (error: any) {
            showToast('error', 'Ocorreu um problema ao buscar o endereço, tente novamente ou preencha manualmente. Detalhe: ' + error.message)
            return null
        }
    }, [getEnderecoPessoa, showToast])

    const handleConsultaCep = async (value: string) => {
        try {
            let res = await consultaCEP(value);

            setValue('cep', res.CEP);
            setValue('logradouro', res.Logradouro);
            setValue('uf', res.UF);
            setValue('codMunicipio', res.CMun ? Number(res.CMun) : 0);
            setValue('municipio', res.Municipio)
        } catch (e: any) {
            showToast('error', e.message);
        }
    }

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={3}>
                            <AutocompletePessoas
                                allowSubmit
                                disabled={loading}
                                autoFocus
                                label={"Selecione o Prescritor"}
                                tipo={EnumCadastroTipo.PRESCRITOR}
                                nomeCnpj={EnumNomeCnpj.Todos}
                                error={Boolean(errors.nome && errors.nome.message)}
                                helperText={
                                    touchedFields.nome || errors.nome
                                        ? errors.nome?.message
                                        : undefined
                                }
                                onChange={async (retorno) => {
                                    if (!retorno.isString) {
                                        const pessoa: PessoaModel = retorno.value
                                        const enderecos = await buscarEnderecoPessoa(pessoa.id)
                                        setValue('nome', pessoa.nome)
                                        setValue('pessoaId', pessoa.id)
                                        setValue('cpfcnpj', pessoa.cpfcnpj)

                                        const [endereco] = enderecos ?? []

                                        setValue('cep', stringNumeros(endereco?.cep ?? ''))
                                        setValue('codMunicipio', endereco?.cMun ? Number(endereco.cMun) : 0)
                                        setValue('municipio', endereco?.xMun)
                                        setValue('logradouro', endereco?.logradouro)
                                        setValue('numero', endereco?.numero)
                                        setValue('complemento', endereco?.complemento)
                                        setValue('uf', endereco?.uf)

                                        const documentos = pessoa.documentos
                                        const documentoCRP = documentos.find(x => x.tipo === EnumTipoDocumento.CarteiraRegistroProfissional)
                                        const documento = documentoCRP ? documentoCRP : documentos[0]

                                        setValue('tipoDocumento', documento?.tipo ?? EnumTipoDocumento.CarteiraRegistroProfissional)
                                        setValue('documento', documento?.documento)
                                        setValue('orgaoExpedidor', documento?.orgaoExpedicao ?? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalMedicina)
                                        setValue('ufOrgaoExpedidor', documento?.ufExpedicao)
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="nome"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Nome"}
                                        tipo={"TEXTO"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.nome && errors.nome.message)}
                                        helperText={
                                            touchedFields.nome || errors.nome
                                                ? errors.nome?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="cpfcnpj"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"CPF/CNPJ"}
                                        tipo={"CNPJ_CPF"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.cpfcnpj && errors.cpfcnpj.message)}
                                        helperText={
                                            touchedFields.cpfcnpj || errors.cpfcnpj
                                                ? errors.cpfcnpj?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Controller
                                name="especialidade"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Especialidade"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Controller
                                name="tipoDocumento"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={TpDocumentoMock}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Tipo de Documento"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.tipoDocumento && errors.tipoDocumento.message)}
                                        helperText={
                                            touchedFields.tipoDocumento || errors.tipoDocumento
                                                ? errors.tipoDocumento?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            if (event) {
                                                const item = TpDocumentoMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0];
                                                if (item) {
                                                    setValue('tipoDocumento', item.Key);
                                                }
                                            }
                                            handleAtt()
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="documento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label="Documento"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.documento && errors.documento.message)}
                                        helperText={
                                            touchedFields.documento || errors.documento
                                                ? errors.documento?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        {documentosPessoais.includes(Number(getValues('tipoDocumento')) || 1) && (
                            <>
                                <Grid item xs={12}>
                                    <Controller
                                        name="orgaoExpedidor"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={TipoDocumentoOrgaoExpedidorMock.sort((a, b) => a.Value.localeCompare(b.Value))}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"Órgão Expedidor"}
                                                placeholder='Ex: SSP'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.orgaoExpedidor && errors.orgaoExpedidor.message)}
                                                helperText={
                                                    touchedFields.orgaoExpedidor || errors.orgaoExpedidor
                                                        ? errors.orgaoExpedidor?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = TipoDocumentoOrgaoExpedidorMock.filter(
                                                            (item) => item.Key === event.target.value,
                                                        )?.[0];
                                                        if (item) {
                                                            setValue('orgaoExpedidor', item.Key);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="ufOrgaoExpedidor"
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                                allowSubmit
                                                disabled={loading}
                                                fullWidth
                                                variant="outlined"
                                                label={"UF do Órgão Expedidor"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={Boolean(errors.ufOrgaoExpedidor && errors.ufOrgaoExpedidor.message)}
                                                helperText={
                                                    touchedFields.ufOrgaoExpedidor || errors.ufOrgaoExpedidor
                                                        ? errors.ufOrgaoExpedidor?.message
                                                        : undefined
                                                }
                                                {...field}
                                                onChange={(event) => {
                                                    if (event) {
                                                        const item = UFMock.filter(
                                                            (item) => item.Value === event.target.value,
                                                        )[0];
                                                        if (item) {
                                                            setValue('ufOrgaoExpedidor', item.Value);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary">
                                Endereço
                            </Typography>
                            <Divider style={{ backgroundColor: theme.palette.secondary.main }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CEP"
                                        fullWidth
                                        searchable
                                        autoComplete={'off'}
                                        label="CEP"
                                        placeholder=""
                                        onSearch={handleConsultaCep}
                                        error={Boolean(errors.cep && errors.cep.message)}
                                        helperText={
                                            errors.cep
                                                ? errors.cep?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={e => {
                                            field.onChange(e)
                                            const { value } = e.target

                                            if (stringNumeros(value).length === 8) {
                                                setTimeout(() => handleConsultaCep(value), 400)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="logradouro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Logradouro"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.logradouro && errors.logradouro.message)}
                                        helperText={
                                            touchedFields.logradouro || errors.logradouro
                                                ? errors.logradouro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="numero"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Número"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.numero && errors.numero.message)}
                                        helperText={
                                            touchedFields.numero || errors.numero
                                                ? errors.numero?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="complemento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Complemento"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.complemento && errors.complemento.message)}
                                        helperText={
                                            touchedFields.complemento || errors.complemento
                                                ? errors.complemento?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                name="municipio"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={"TEXTO"}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"Município"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.municipio && errors.municipio.message)}
                                        helperText={
                                            touchedFields.municipio || errors.municipio
                                                ? errors.municipio?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="uf"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={UFMock.map(x => new KeyValueModel(x.Value, x.Value))}
                                        allowSubmit
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        label={"UF"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.uf && errors.uf.message)}
                                        helperText={
                                            touchedFields.uf || errors.uf
                                                ? errors.uf?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={(event) => {
                                            if (event) {
                                                const item = UFMock.filter(
                                                    (item) => item.Value === event.target.value,
                                                )[0];
                                                if (item) {
                                                    setValue('uf', item.Value);
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </>
    );
},
);
