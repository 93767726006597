import { EnumIndDesperdicio } from 'model/enums/enum-ind-desperdicio';
import { guidEmpty } from 'utils/guid-empty';
import { Med, RastroVenda } from '../movimentacao/simples/mov-simples-produto-model';
import { VendaAcrescimoModel, VendaDescontoModel } from './venda-desconto-model';

export class VendaProdutoModel {
    constructor(
        public id: string = guidEmpty(),
        public produtoGradeId: string = guidEmpty(),
        public vendedorId: string | null = null,
        public cProd: string = '',
        public cean: string = '',
        public xProd: string = 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
        public qCom: number = 0,
        public vUnCom: number = 0,
        public vProd: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDesc: number = 0,
        public vDescEmbutido: number = 0,
        public vOutro: number = 0,
        public vOutroEmbutido: number = 0,
        public infAdic: string = '',
        public adicionalId: string = '',
        public tabelaPrecoId: string = guidEmpty(),
        public setorId: string | null = null,
        public cancelado: boolean = false,
        public uCom: string = '',
        public taxaServico: number = 0,
        public valorServico: number = 0,
        public produtoPaiId: string | null = null,
        public indFin: boolean = true,
        public indEstoque: boolean = true,
        public tpProduto: number = 0,
        public indDesperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica,
        public mesaCodigo: string | null = null,
        public codigoComanda: string | null = null,
        public enviarSetor: boolean = false,
        public pedidoId: string = '',
        public depositoId: string | null = null,
        public vUnComOrig: number = 0,
        public nSeqReceitaMedica: number | null = null,
        public cProdANVISA: string | null = null,
        public rastro: RastroVenda[] | null = null,
        public med: Med | null = null,
        public dataHoraLancamento: string | null = null,
        public descontos: VendaDescontoModel[] = [],
        public acrescimos: VendaAcrescimoModel[] = [],
        public motivoCancelamento: string = ''
    ) { }
}