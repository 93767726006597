import { isEmpty } from "lodash";
import { Med } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model"
import { EnumTarja } from "model/enums/enum-tarja";
import { useCallback } from "react";
import { useBancoLocal } from "./banco-local";
import { EnumTableNames } from "database/touchone-carga-database";
import { TabelaProdutos } from "database/interfaces/interface-tabela-produtos";
import { TabelaMedicamentos } from "database/interfaces/interface-tabela-medicamentos";

const tarjasControladas = [
    EnumTarja.TarjaPreta,
    EnumTarja.TarjaVermelha
]

export const useControleMedicamento = () => {
    const { getFirst } = useBancoLocal();
    const retornarInfoMedicamento = useCallback(async (codigoAnvisa: string | null, produtoId: string | null): Promise<Med | null> => {
        try {
            if (!codigoAnvisa) {
                const retProd = await getFirst<TabelaProdutos>({nomeTabela: EnumTableNames.PRODUTOS, where: 'produtoId', value: produtoId, condition: 'equals'});
                if (!retProd || !retProd.codigoAnvisa) {
                    throw new Error('Medicamento não encontrado!');
                }
                codigoAnvisa = retProd.codigoAnvisa;
            }
            const medicamento = await getFirst<TabelaMedicamentos>({nomeTabela: EnumTableNames.MEDICAMENTOS, where: 'codigoAnvisa', value: codigoAnvisa, condition: 'equals'});
            if (!isEmpty(medicamento)) {
                return {
                    cProdANVISA: medicamento.codigoAnvisa,
                    medicamento: {
                        ...medicamento
                    },
                    nSeqReceitaMedica: 0,
                    vendaControlada: tarjasControladas.includes(medicamento.tarja),
                    vPMC: medicamento.precoMaximoConsumidor,
                    xMotivoIsencao: null,
                }
            } else {
                return {
                    cProdANVISA: codigoAnvisa,
                    nSeqReceitaMedica: 0,
                    vendaControlada: false,
                    vPMC: 0,
                    xMotivoIsencao: null,
                    medicamento: null,
                }
            }
        } catch {
            return null
        }
    }, [getFirst])

    return {
        retornarInfoMedicamento,
    }
}