import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-convenio-autenticar-styles';
import { CartaoIcon, QrCodeIcon, VoltarIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePostConvenioAutenticar } from 'data/api/gestao/conta-pessoa';
import { DefaultFormRefs } from 'views/components/form/utils';
import { ConvenioCartaoAutenticarModel } from 'model/api/gestao/convenio';
import { ConvenioCartaoAutenticarResponseModel } from 'model/api/gestao/convenio/convenio-cartao-autenticar-response-model';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { DigitarAutenticacao } from './components/digitar-autenticacao/digitar-autenticacao';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { ConvenioCartaoAutenticarFormModel } from 'model/app/forms/convenio/convenio-cartao-autenticar-form-model';
import { picker } from 'utils/picker';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumModeloAutenticacao } from 'model/enums/enum-modelo-autenticacao';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { stringNumeros } from 'utils/string-numeros';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaClientes } from 'database/interfaces/interface-tabela-clientes';

enum EnumTelaConvenioAutenticar {
  Inicial,
  DigitarAutenticacao
}

interface DialogConvenioAutenticarProps {
  aberto: boolean;
  modo: 'consulta' | 'venda';
  dadosPagamento?: {
    pagamento: MovSimplesPagamentoModel;
    credenciais: any;
  }
}

export const DialogConvenioAutenticar = ({
  aberto,
  modo,
  dadosPagamento
}: DialogConvenioAutenticarProps) => {
  // STATES E REFS
  const refForm = useRef<DefaultFormRefs<ConvenioCartaoAutenticarFormModel>>(null)

  // CHAMADAS API
  const { postConvenioAutenticar, carregando } = usePostConvenioAutenticar()

  // HOOKS & AUX
  const { getEmpresaAtual, getConfigByCod } = useEmpresaAtual()
  const { fecharConvenioAutenticar, abrirConvenioResumoFaturas, abrirCadastroBarCodeCam } = useCadastros()
  const classesComponent = useStyles();
  const { theme } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const [faseTela, setFaseTela] = useState<EnumTelaConvenioAutenticar>(EnumTelaConvenioAutenticar.Inicial)
  const { addHandler, removeHandler } = useEventTools()
  const modeloAutenticacao = getConfigByCod(EnumEmpresaConfig.ModeloAutenticacao) as EnumModeloAutenticacao
  const isModeloDocumentoCliente = modeloAutenticacao === EnumModeloAutenticacao.DocumentoCliente
  const { getConsumidor } = useCadastroPadrao()
  const { getMov } = useMovAtual()
  const { getFirst } = useBancoLocal();

  const onCloseClick = useCallback(() => {
    fecharConvenioAutenticar();
  }, [fecharConvenioAutenticar]);

  const { redirectProcessarPagamento } = useMovRota();
  useEffect(() => {
    if (isModeloDocumentoCliente) {
      setFaseTela(EnumTelaConvenioAutenticar.DigitarAutenticacao)
    }
  }, [getMov, isModeloDocumentoCliente])

  const preencherForm = useCallback(async () => {
    const mov = getMov()
    if (faseTela === EnumTelaConvenioAutenticar.DigitarAutenticacao) {
      const consumidorPadrao = await getConsumidor()
      const cpfcnpj =
        isModeloDocumentoCliente
          ? mov?.cliente?.cpfcnpj !== consumidorPadrao?.cpfcnpj
            ? mov?.cliente?.cpfcnpj ?? '' : undefined
          : undefined
      refForm.current?.fillForm({
        ...new ConvenioCartaoAutenticarFormModel(),
        cpfcnpj,
      })
    }
  }, [faseTela, getConsumidor, getMov, isModeloDocumentoCliente])

  useEffect(() => {
    preencherForm()
  }, [preencherForm])

  const novoPagamento = useCallback(async (model: ConvenioCartaoAutenticarModel) => {
    try {
      if (!dadosPagamento) return
      const { credenciais, pagamento } = dadosPagamento

      await redirectProcessarPagamento({
        modoPagamento: pagamento,
        credenciais,
        credenciado: false,
        loginConvenio: model
      });
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [dadosPagamento, redirectProcessarPagamento, showToast])

  const convenioAutenticarWrapper = useCallback(async (model: ConvenioCartaoAutenticarModel) => {
    if (modo === 'consulta') {
      const res = await postConvenioAutenticar(model, getEmpresaAtual()?.id ?? '')
      if (res.erro) throw res.erro

      const resultado = res.resultado?.data as ConvenioCartaoAutenticarResponseModel

      if (!resultado) {
        throw new Error('Cartão em mau funcionamento ou inexistente.')
      }
      abrirConvenioResumoFaturas({ pessoaId: resultado.pessoaId, tipo: resultado.tipo })
      fecharConvenioAutenticar()
      return
    }

    novoPagamento(model)
    fecharConvenioAutenticar()
  }, [abrirConvenioResumoFaturas, fecharConvenioAutenticar, getEmpresaAtual, modo, novoPagamento, postConvenioAutenticar])

  const handleSubmit = useCallback(async (model: ConvenioCartaoAutenticarFormModel) => {
    try {
      let pessoaId: string | undefined

      if (isModeloDocumentoCliente) {
        const pessoa = await getFirst<TabelaClientes>({
          nomeTabela: EnumTableNames.CLIENTES,
          where: 'cpfcnpj',
          value: stringNumeros(model.cpfcnpj || ''),
        })
        pessoaId = pessoa?.id ?? ''
      }

      const auth = picker<ConvenioCartaoAutenticarModel>(model, new ConvenioCartaoAutenticarModel())
      auth.pessoaId = pessoaId
      await convenioAutenticarWrapper(auth)
    }
    catch (err: any) {
      showToast('error', 'Ocorreu um problema ao autenticar seu cartão. Detalhe: ' + err.message)
    }
  }, [convenioAutenticarWrapper, getFirst, isModeloDocumentoCliente, showToast])

  const irParaFaseDigitarAuth = () => {
    setFaseTela(EnumTelaConvenioAutenticar.DigitarAutenticacao)
  }

  const onClickSair = () => {
    if (isModeloDocumentoCliente) {
      fecharConvenioAutenticar()
      return
    }
    setFaseTela(EnumTelaConvenioAutenticar.Inicial)
  }

  const abrirQrCode = () => {
    abrirCadastroBarCodeCam()
  }

  const authLeitor = useCallback(async (auth) => {
    const descript = atob(auth)
    const [numeroCartao, senha] = descript.split('|')

    const model: ConvenioCartaoAutenticarModel = {
      numeroCartao,
      senha,
    }

    convenioAutenticarWrapper(model)
      .catch((err: any) => {
        showToast('error', 'Ocorreu um problema ao autenticar seu cartão. Detalhe: ' + err.message)
      })
  }, [convenioAutenticarWrapper, showToast])

  useEffect(() => {
    addHandler(AppEventEnum.SetValueCam, authLeitor)

    return () => removeHandler(AppEventEnum.SetValueCam, authLeitor)
  }, [addHandler, authLeitor, removeHandler])

  const faseInicial = () => (
    <Grid container className={classesComponent.cardContent} spacing={2}>
      <Grid item xs={6}>
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={abrirQrCode}
          className={classesComponent.btn}
        >
          <Box flex flexDirection='column'>
            <Box style={{ width: '100%' }} flex justifyContent="center">
              <QrCodeIcon tipo="BUTTON" class={classesComponent.icon} />
            </Box>
            <Typography
              variant="h6"
              className={classesComponent.btnTitle}
            >
              QRCode
            </Typography>
          </Box>
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={irParaFaseDigitarAuth}
          className={classesComponent.btn}
        >
          <Box flex flexDirection="column" justifyContent="center">
            <Box style={{ width: '100%' }} flex justifyContent="center">
              <CartaoIcon tipo="BUTTON" class={classesComponent.icon} />
            </Box>
            <Typography
              variant="h6"
              className={classesComponent.btnTitle}
            >
              Digitar
            </Typography>
          </Box>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider variant='fullWidth' className={classesComponent.divider} />
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={carregando}
          onClick={onCloseClick}
          variant="outlined"
          color="primary"
          fullWidth
        >
          <VoltarIcon tipo="BUTTON" />
          Voltar
        </Button>
      </Grid>
    </Grid>
  )

  const faseDigitarAuth = () => (
    <DigitarAutenticacao {...{ carregando, handleSubmit, onClickSair, refForm, modeloAutenticacao }} />
  )

  const exibirFase = () => {
    const fases = {
      [EnumTelaConvenioAutenticar.Inicial]: () => faseInicial(),
      [EnumTelaConvenioAutenticar.DigitarAutenticacao]: () => faseDigitarAuth(),
    }

    return fases[faseTela]() || faseInicial()
  }

  return (
    <DialogSaurus
      aberto={aberto || false}
      titulo="Autenticar Cartão do Convênio"
      tamanho="xs"
      centralizarTitulo
      colorTitulo={theme.palette.primary.main}
    >
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      {exibirFase()}
    </DialogSaurus>
  );
};
