import { Button } from "@material-ui/core";
import { useGetProdutoVariacaoDepositoById } from "data/api/gestao/produto/produto-variacao/get-produto-variacao-deposito-by-id";
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { useCallback, useState } from "react";
import { useToastSaurus } from "services/app";
import { usePDV } from "services/app/hooks/pdv"
import { ProdutoIcon } from "views/components/icons";
import { useStyles } from './visualizar-estoque-styles'
import { LoadingButton } from "views/components/utils";

interface IQtdEstoque {
    qtd: number;
    showEstoque: boolean;
}

interface Props {
    produto: MovSimplesProdutoModel;
}

export const VisualizarEstoque = ({ produto }: Props) => {

    const classes = useStyles();
    const { getPDV } = usePDV();
    const { getProdutoVariacaoDepositoById, carregando } = useGetProdutoVariacaoDepositoById();
    const { showToast } = useToastSaurus();

    const [qtdEstoque, setQtdEstoque] = useState<IQtdEstoque>({ qtd: 0, showEstoque: false })

    const carregarEstoque = useCallback(async () => {
        try {
            const depositoId = getPDV()?.depositoId || '';
            if (!depositoId) {
                throw new Error('PDV Não possuí depósito vinculado');
            }
            const res = await getProdutoVariacaoDepositoById(produto.produtoId || '', produto.produtoGradeId || '', depositoId);
            if (res.erro) throw res.erro
            if (!res.resultado?.data) throw new Error('Erro ao retornar estoque. Tente novamente')

            setQtdEstoque({
                qtd: res.resultado.data.qSaldoTotal || 0,
                showEstoque: true,
            })

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getPDV, getProdutoVariacaoDepositoById, produto.produtoGradeId, produto.produtoId, showToast])

    return <>
        <Button onClick={carregarEstoque} variant="text" color='primary' size="small" className={classes.buttonSize}>
            {carregando ? <LoadingButton size="NORMAL" tipo="AZUL" /> :
                <>
                    <ProdutoIcon tipo='BUTTON' />
                    {qtdEstoque.showEstoque ? <span className={classes.estoqueLabel}>
                        Estoque: <span className={classes.numberLabel}>{qtdEstoque.qtd}</span>
                    </span> : <span className={classes.consultarLabel}>Consultar Estoque</span>}
                </>}
        </Button>
    </>
}