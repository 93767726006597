import { IconButton } from "@material-ui/core"
import { useEffect, useCallback } from "react"

import { ExportarExcelIcon } from "views/components/icons/export-excel-icon"
import { Paginacao } from "views/components/paginacao"
import { useComissaoApuracaoService } from "../../useComissaoApuracaoService"
import { ApuracaoCard } from "../cards/apuracao-card"
// import { DateFilterHeader } from "../date-filter-header"
import { CardNaoEncontrado } from "views/components/cards"
import { EditarIcon, InformacaoIcon } from "views/components/icons"
import { toDateString } from "utils/to-date"
import { useThemeQueries } from "views/theme"
import { useComissaoStore } from "views/components/modals/comissao/comissao-modal/stores/comissao-store"
import { useApuracaoStore } from "../../stores/apuracao-store"
import { useCadastros } from "services/app"
import { Button, Grid, Typography } from "views/design-system"
import { useStyles } from "./apuracao-list-styles"
import { DefaultCard } from "views/components/cards/components"

export const ApuracoesList = () => {
    const {
        setComissaoApuracaoUIDialogTitle,
        setComissaoApuracaoAcao,
    } = useApuracaoStore()
    const comissionados = useApuracaoStore(state => state.stateComissaoApuracao.comissionados)
    const comissao = useComissaoStore(state => state.state.comissao)
    const { selectComissionadoApuracao, getApuracaoListByComissaoId, exportCampanhaApuracaoListExcel } = useComissaoApuracaoService()
    const classes = useStyles()
    const { abrirCadastroComissao, fecharComissaoApuracao } = useCadastros()

    const pagination = useApuracaoStore(state => state.stateComissaoApuracao.ui.apuracoesPagination)

    useEffect(() => {
        setComissaoApuracaoUIDialogTitle(`${comissao.nome} - Comissionados`)
    }, [comissao.nome, setComissaoApuracaoUIDialogTitle])

    const pageChanged = useCallback(
        async (newPage: number) => {
            if (newPage <= (pagination?.totalPages ?? 0) || newPage > 0) {
                getApuracaoListByComissaoId(comissao.id, newPage, 1, undefined, true)
            }
        },
        [comissao.id, getApuracaoListByComissaoId, pagination.totalPages],
    );


    const { theme } = useThemeQueries()

    const handleEditComissao = () => {
        abrirCadastroComissao(comissao.id, '', true)
        fecharComissaoApuracao()
    }

    return (
        <Grid container p={2} justifyContent="space-between" className={classes.container}>
            <Grid item xs={12}>
                <DefaultCard hasTagStatus={false}>
                    <Grid container p={1} alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="caption">
                                Nome
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                {
                                    comissao.nome
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="caption">
                                Data Campanha
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                {
                                    toDateString(comissao.dataInicial)
                                } - {
                                    toDateString(comissao.dataFinal)
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    handleEditComissao()
                                }}
                            >
                                <EditarIcon fill={theme.palette.primary.main} tipo="BUTTON" />
                            </IconButton>
                        </Grid>
                        {/* <DateFilterHeader /> */}
                    </Grid>
                </DefaultCard>
            </Grid>

            <Grid item xs={12} my={1} flex alignItems="center" className={classes.paginacao} >
                <Paginacao
                    pageChanged={pageChanged}
                    totalPages={pagination.totalPages ?? 0}
                    currentPage={pagination.page}
                    totalRegisters={pagination.totalRegisters}
                    countLabel="Comissionados"
                    action={<>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                exportCampanhaApuracaoListExcel()
                            }}
                        >
                            <ExportarExcelIcon tipo="BUTTON_PRIMARY" />
                        </IconButton>
                    </>}
                />
            </Grid>
            <Grid className={classes.listagem} container >
                {comissionados.length === 0 &&
                    <Grid item xs={12}>
                        <CardNaoEncontrado
                            mensagem={`Nenhuma apuração encontrada.`}
                            icon={<InformacaoIcon tipo="GERAL" />}
                        />
                    </Grid>
                }
                {comissionados.map((comissionado) => {
                    return <Grid item xs={12} key={comissionado.id}>
                        <ApuracaoCard comissionado={comissionado} onClick={() => {
                            selectComissionadoApuracao(comissionado.id)
                        }} />
                    </Grid>
                })
                }
            </Grid>
            <Grid item xs={12}>
                <Button color="primary" fullWidth variant="contained" onClick={() => {
                    setComissaoApuracaoAcao('criar')
                }}>
                    Nova Apuração
                </Button>
            </Grid>
        </Grid>
    )
}