import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading } from 'views/components/utils';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { CompartilhamentoDadosWebHookModel } from 'model/api/gestao/compartilhamento-dados-webhook/compartilhamento-dados-webhook-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { CompartilhamentoDeDadosMock } from 'data/mocks/compartilhamento-de-dados-mock';
import { useStyles } from './form-webhook-styles';
import { CancelarIcon, SalvarIcon } from 'views/components/icons';
import { useCadastros } from 'services/app';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormWebhookValidation } from './form-webhook-validation';
import { Button, Grid, Typography } from 'views/design-system';


export const FormWebhook = forwardRef<
  DefaultFormRefs<CompartilhamentoDadosWebHookModel>,
  DefaultFormProps<CompartilhamentoDadosWebHookModel>
>((props: DefaultFormProps<CompartilhamentoDadosWebHookModel>, ref) => {
  const utilClasses = makeUtilClasses();

  const { FormWebhookValidationYup } = useFormWebhookValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    reset,
  } = useForm<CompartilhamentoDadosWebHookModel>({
    defaultValues: { ...props.model },
    resolver: yupResolver(FormWebhookValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const classes = useStyles()
  const { fecharConfigWebhook } = useCadastros()
  const { theme } = useThemeQueries();



  const onSubmit = (values: CompartilhamentoDadosWebHookModel) => {
    const model = new CompartilhamentoDadosWebHookModel();
    model.webhook = values.webhook;
    model.tipoNotificacaoWebhook = values.tipoNotificacaoWebhook;
    props.onSubmit(model);

  }

  useImperativeHandle(ref, () => ({

    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      reset(new CompartilhamentoDadosWebHookModel());
    },
    fillForm: (model: CompartilhamentoDadosWebHookModel) => {
      reset(model);
    },
  }));

  const cancelarEdicao = useCallback(() => {
    fecharConfigWebhook();
  }, [fecharConfigWebhook]);

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading ? (
          <div className={utilClasses.controlLoading}>
            <CircularLoading tipo="NORMAL" />
          </div>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={props.loading ? utilClasses.controlLoading : ''}
        >
          <Grid item xs={12}>
            <Grid className={classes.tipo} xs={12}>
              <Typography variant='h6' style={{ marginBottom: 8 }}>
                Selecione os dados que deseja compartilhar via Webhook
              </Typography>
              <Controller
                name='tipoNotificacaoWebhook'
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    fullWidth
                    label="Módulos"
                    variant="outlined"
                    allowSubmit
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(
                      errors.tipoNotificacaoWebhook && errors.tipoNotificacaoWebhook.message,
                    )}
                    helperText={
                      touchedFields.tipoNotificacaoWebhook || errors.tipoNotificacaoWebhook
                        ? errors.tipoNotificacaoWebhook?.message
                        : undefined
                    }
                    {...field}
                    conteudo={CompartilhamentoDeDadosMock}
                    onChange={(e) => {
                      const item = CompartilhamentoDeDadosMock.filter(i => i.Key === e.target.value)
                      setValue("tipoNotificacaoWebhook", item[0].Key)
                    }}
                  />

                )} />
            </Grid>
            <Grid className={classes.tipo} xs={12}>
              <Controller
                name="webhook"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    fullWidth
                    label="Endereço do Webhook"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Digite o URL do Webhook"
                    error={Boolean(
                      errors.webhook && errors.webhook.message,
                    )}
                    helperText={
                      touchedFields.webhook || errors.webhook
                        ? errors.webhook?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid className={classes.botoes}>
              <Button
                className={classes.botao}
                variant='outlined'
                color="primary"
                onClick={cancelarEdicao}
                type='button'>
                <CancelarIcon tipo='BUTTON' fill={theme.palette.primary.main} />
                Cancelar
              </Button>
              <Button
                className={classes.botao}
                variant="contained"
                color="primary"
                type='submit'>
                <SalvarIcon tipo='BUTTON_PRIMARY' />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
});
