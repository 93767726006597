import { useCallback } from 'react';
import { PrivatePageHeader } from 'views/components/headers';
import { ButtonPrivateHeader } from 'views/components/controles/buttons';
import { useHistory } from 'react-router-dom';
import { VoltarIcon } from 'views/components/icons';
import { RetaguardaRotasMock } from 'data/mocks/retaguarda-rotas-mock';

export const DetalhesVendaHeader = () => {
  const { push, location } = useHistory()
  const { pathname } = location

  const voltarHistorico = useCallback(() => push({
    pathname: pathname.includes(RetaguardaRotasMock.pendenciasFiscaisRota.path)
      ? '/pendencias-fiscais'
      : '/historico',
    search: location.search
  }), [location.search, pathname, push])

  const leftArea = useCallback(
    () => {
      return (
        <ButtonPrivateHeader
          icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
          tooltip="Voltar"
          onClick={voltarHistorico}
        />
      )
    },
    [voltarHistorico],
  );

  return (
    <>
      <PrivatePageHeader title={'Histórico da Venda'} leftArea={leftArea()} />
    </>
  );
};
