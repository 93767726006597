import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { MesasModel } from 'model/api/gestao/mesa/mesa-model';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { guidEmpty } from 'utils/guid-empty';
import { CircularLoading } from 'views/components';
import { FormSelecionarSalao } from 'views/components/form/selecionar-salao/form-selecionar-salao/form-selecionar-salao';
import { useDefaultCadastroStyles } from '../../cadastros/components/default-cadastro-styles';
import { AtendimentoHeader } from './components/atendimento-header/atendimento-header';
import { AtendimentoTabs } from './components/atendimento-tabs/atendimento-tabs';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { isEmpty } from 'lodash';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { FormPesquisarPedido } from 'views/components/form/selecionar-salao/form-pesquisar-pedido/form-pesquisar-pedido';
import { Grid } from 'views/design-system';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaMesas } from 'database/interfaces/interface-tabela-mesas';
import { EnumStatusComanda } from 'model/enums/enum-status-comanda';

export const AtendimentoPage = () => {
  const {
    getSaloesCadastrados,
    getSalaoAtual,
    setSalaoAtual,
    getConfiguracoesMesaEComanda,
  } = usePedidoLocal();

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { getFirst } = useBancoLocal();

  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();

  const history = useHistory();
  const salaoFavorito = getSalaoAtual();
  const [saloes, setSaloes] = useState<SaloesModel[]>([]);
  const [salaoAtualId, setSalaoAtualId] = useState<string>(() => {
    if (salaoFavorito) {
      return salaoFavorito.id!;
    }
    return guidEmpty();
  });
  const [pesquisaPedido, setPesquisaPedido] = useState<string>('')

  const [pedidoId, setPedidoId] = useState('')

  const classes = useDefaultCadastroStyles();
  const carregando = carregandoGetPedidos;
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools()

  const configuracaoMesaComanda = getConfiguracoesMesaEComanda();

  const buscarSaloes = useCallback(async () => {
    try {
      const res = await getSaloesCadastrados();
      setSaloes(res.filter(x => x.status.codigo === EnumStatusSalao.ATIVO));
      if ((configuracaoMesaComanda?.qtdeMesa ?? 0) <= 0) {
        setSalaoAtual(guidEmpty());
        setSalaoAtualId(guidEmpty());
        return;
      }

      if (res.length === 1) {
        setSalaoAtual(guidEmpty());
        setSalaoAtualId(guidEmpty());
      }
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [configuracaoMesaComanda?.qtdeMesa, getSaloesCadastrados, setSalaoAtual, showToast]);

  useEffect(() => {
    buscarSaloes();
  }, [buscarSaloes]);

  const redirecionaComandaLida = useCallback(async (codigoLido: string) => {
    const comanda = (await getFirst<TabelaComandas>({
      nomeTabela: EnumTableNames.COMANDAS,
      where: 'codigoComanda',
      value: codigoLido
    })) as ComandasModel;

    if (!comanda) {
      throw new Error(
        `Nenhuma comanda identificada com o codigo: ${codigoLido}`,
      );
    }

    if (comanda && comanda?.status?.codigo !== EnumStatusComanda.ATIVO) {
      throw new Error(
        `A comanda com o código ${codigoLido} está com status de inativo`,
      );
    }

    const respostGetPedidos = await getPedidoSalao(
      getEmpresaSelecionada()?.Id ?? '',
      `comandaId=${comanda.id}`,
    );

    if (respostGetPedidos.erro) {
      throw new Error(respostGetPedidos.erro);
    }

    if (respostGetPedidos.resultado?.data.list.length === 0) {
      throw new Error(
        `Nenhum pedido identificado com a comanda: ${codigoLido}`,
      );
    }

    const pedido = respostGetPedidos.resultado?.data.list[0];

    return history.push(`/venda-simples/visualizar-pedido/${pedido.comandaId}`);
  }, [getEmpresaSelecionada, getFirst, getPedidoSalao, history])

  const redirecionaMesaLida = useCallback(async (codigoLido: string, salao: string) => {
    const mesa = (await getFirst<TabelaMesas>({
      nomeTabela: EnumTableNames.MESAS,
      where: ['codigo', 'salaoId'],
      value: [codigoLido, salao]
    })) as MesasModel;
    if (!mesa) {
      throw new Error(
        `Nenhuma mesa identificada com o codigo: ${codigoLido}`,
      );
    }

    if (mesa && mesa?.status?.codigo !== EnumMesas.ATIVO) {
      throw new Error(
        `A mesa com o código ${codigoLido} está com status de inativo`,
      );
    }

    const respostGetPedidos = await getPedidoSalao(
      getEmpresaSelecionada()?.Id ?? '',
      `mesaId=${mesa.id}`,
    );

    if (respostGetPedidos.erro) {
      throw new Error(respostGetPedidos.erro);
    }

    if (respostGetPedidos.resultado?.data.list.length === 0) {
      throw new Error(
        `Nenhum pedido identificado com a mesa: ${codigoLido}`,
      );
    }

    const pedido = respostGetPedidos.resultado?.data.list[0];

    return history.push(`/venda-simples/visualizar-pedido/${pedido.mesaId}`);
  }, [getEmpresaSelecionada, getFirst, getPedidoSalao, history])

  const redirecionarParaMesaOuComandaLida = useCallback(
    async (codigoLido: string) => {
      try {
        const separa = codigoLido.split("|")

        let codigo = separa[0]
        const salao = separa[1]

        if (codigo.length > 3) {
          codigo = codigo.slice(codigo.length - 3, codigo.length)

          if (codigo[0] === '0' && codigo[1] === '0') {
            codigo = codigo.slice(codigo.length - 1, codigo.length)
          }
          else if (codigo[0] === '0') {
            codigo = codigo.slice(codigo.length - 2, codigo.length)
          }
        } else {
          if (codigo[0] === '0' && codigo[1] === '0') {
            codigo = codigo.slice(codigo.length - 1, codigo.length)
          }
          else if (codigo[0] === '0') {
            codigo = codigo.slice(codigo.length - 2, codigo.length)
          }
        }

        if (configuracaoMesaComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA) {
          await redirecionaComandaLida(codigo)
          return
        }

        await redirecionaMesaLida(codigo, salao)

      } catch (err: any) {
        showToast('error', err.message);
      }
    },
    [configuracaoMesaComanda?.tipoTrabalho, redirecionaComandaLida, redirecionaMesaLida, showToast],
  );

  useEffect(() => {
    addHandler(AppEventEnum.SetValueCam, setPedidoId)

    return () => removeHandler(AppEventEnum.SetValueCam, setPedidoId)
  }, [addHandler, removeHandler])

  useEffect(() => {
    if (!isEmpty(pedidoId)) {
      redirecionarParaMesaOuComandaLida(pedidoId)
    }
  }, [pedidoId, redirecionaComandaLida, redirecionarParaMesaOuComandaLida])

  const isAbaDelivery = useMemo(() => {
    const rotaDelivery = MovRotasMock.pedidosDeliveryRota
    return history.location.pathname === rotaDelivery
  }, [history.location.pathname])

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.root}>
        <Grid className={classes.header}>
          <AtendimentoHeader />
        </Grid>

        {
          saloes.length > 1 &&
          (
            (configuracaoMesaComanda?.qtdeMesa ?? 0) > 0 ||
            saloes.filter(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA).length > 0) &&
          !isAbaDelivery && (
            <Grid item style={{ padding: '0px 8px', background: '#fff' }}>
              <FormSelecionarSalao
                setSalaoAtual={setSalaoAtual}
                setSalaoAtualId={setSalaoAtualId}
                saloes={saloes}
                model={getSalaoAtual()}
                isTodosSaloes
              />
            </Grid>

          )}
        {isAbaDelivery && (
          <Grid item style={{ padding: '0px 8px', background: '#fff' }}>
            <FormPesquisarPedido
              setPesquisa={setPesquisaPedido}
              pesquisa={pesquisaPedido}
            />
          </Grid>
        )}

        <AtendimentoTabs
          salaoAtualId={salaoAtualId}
          saloes={saloes}
          temMesaEmAlgumSalao={
            configuracaoMesaComanda && configuracaoMesaComanda?.qtdeMesa > 0
              ? true
              : false
          }
          pesquisaPedido={pesquisaPedido}
        />
      </Grid>
    </>
  );
};

export default AtendimentoPage;
