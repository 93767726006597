import { VariaveisAmbiente } from "config";
import { useApiBase } from "data/api/base";
import { useCallback } from "react";

export function useDeleteCancelarCarga() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
  
    const deleteCancelarCarga = useCallback(
      (id: string) => {
        return invocarApi({
          url: `/v3/conversao/${id}/cancelar`,
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          enviarTokenUsuario: true,
          baseURL: `${VariaveisAmbiente.apiUrlConversoes}/api`,
        });
      },
      [invocarApi]
    );
  
    return {
      ...outrasPropriedades,
      deleteCancelarCarga,
    };
  }