import { useThemeQueries } from "views/theme"
import { CardPendenciasFiscaisProps } from "./card-pendencias-fiscais-props"
import { CardPendenciasFiscaisMobile } from "./components/card-pendencias-fiscais-mobile"
import { CardPendenciasFiscaisDesktop } from "./components/card-pendencias-fiscais-desktop"

export const CardPendenciasFiscais = (props: CardPendenciasFiscaisProps) => {
    const { isMobile } = useThemeQueries()

    return isMobile ? (
        <CardPendenciasFiscaisMobile {...props} />
    ) : (
        <CardPendenciasFiscaisDesktop {...props} />
    )
}