import { useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import classNames from 'classnames';
import { useTabStyles } from './tabs-saurus-styles';
import { SwipeSaurus } from './components/swipe-saurus/swipe-saurus';

export class TabSaurusLabel {
  constructor(
    public label: React.ReactNode,
    public index: number,
    public icon?: React.ReactElement
  ) {}
}
export class TabSaurusContent {
  constructor(public index: number, public content: React.ReactNode) {}
}
export interface TabSaurusProps {
  tabsLabel: Array<TabSaurusLabel>;
  tabsContent: Array<TabSaurusContent>;
  selectedTabIndex: number;
  onChange?(index: number): void;
  hideTabs?: boolean;
  contentRelative?: boolean;
  disabledSwipe?: boolean;
}
export const TabsSaurus = ({
  tabsLabel,
  tabsContent,
  selectedTabIndex,
  onChange,
  hideTabs = false,
  contentRelative = true,
  disabledSwipe
}: TabSaurusProps) => {
  const classes = useTabStyles();
  const handleChange = useCallback(
    (event, newValue) => {
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  return (
    <>
      <div className={classes.tabContainer}>
        <AppBar
          className={classNames(
            'tabs-class',
            classes.tabsContainer,
            hideTabs && classes.hideTabs
          )}
          position="static"
          style={{ zIndex: 0 }}
        >
          <Tabs
            value={selectedTabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="auto"
          >
            {!hideTabs &&
              tabsLabel.map((tab, index) => (
                <Tab
                  classes={{
                    wrapper: classes.iconLabelWrapper,
                    labelIcon: classes.labelIcon
                  }}
                  key={index}
                  value={tab.index}
                  icon={tab.icon}
                  label={tab.label}
                  id={`tab${tab.index}`}
                />
              ))}
          </Tabs>
        </AppBar>

        {tabsContent.map((tab, index) => (
          <div
            className={classes.tabContent}
            style={{
              position: contentRelative ? 'relative' : 'unset'
            }}
            hidden={tab.index !== selectedTabIndex}
          >
            <SwipeSaurus
              index={index}
              selectedTabIndex={selectedTabIndex}
              tabsLabel={tabsLabel}
              disabled={disabledSwipe}
              onChange={onChange}
            >
              {tab.content}
            </SwipeSaurus>
          </div>
        ))}
      </div>
    </>
  );
};
