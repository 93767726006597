import { RetornoApiModel } from "model";

interface Props<T = any> {
    funcao: (...args: any[]) => Promise<RetornoApiModel<T>>;
    tentativaAtual: number;
    tentativas?: number;
    milisegundosRetry?: number;
    args?: any[];
}


export function retryReq<T = any>(props: Props<T>): Promise<RetornoApiModel<T>> {
    return new Promise(async (resolve, reject) => {
        const {
            funcao,
            tentativaAtual,
            tentativas = 10,
            milisegundosRetry = 200,
            args = [],
        } = props;

        const res = await funcao(...args);

        if (!res.resultado?.data || res.erro) {

            if (tentativaAtual < tentativas) {
                setTimeout(() => {
                    retryReq({
                        ...props,
                        tentativaAtual: tentativaAtual + 1,
                    })
                        .then(e => {
                            resolve(e);
                        })
                        .catch(e => {
                            reject(e);
                        })
                }, tentativaAtual * milisegundosRetry);
            } else {
                reject(new Error(`Requisição não está disponível para uso. Por favor, tente em alguns instantes. Código: ${funcao.name}`));
            }
            return;
        }
        resolve(res);
    })
}