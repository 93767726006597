import { Box, Grid, Typography } from 'views/design-system';
import { CardInfoPlanoProps } from './card-info-plano-props';
import { useStyles } from './card-info-plano-styles';
import { CancelarIcon, OkIcon } from 'views/components/icons';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { useSessaoAtual } from 'services/app';
import { toDateString } from 'utils/to-date';
import { useThemeQueries } from 'views/theme';
import { DefaultCard } from 'views/components/cards/components';

export const CardInfoPlano = ({ data }: CardInfoPlanoProps) => {
  const classes = useStyles();
  const { plano } = useSessaoAtual();
  const { theme } = useThemeQueries()

  const expiracaoPlano = toDateString(plano?.contratado.dExpiracao);

  return (
    <DefaultCard hasTagStatus={false}>
      <Grid container alignItems='center' justifyContent='space-between' p={1} >
        <Grid item>
          <Typography variant="caption" className={classes.textTitle}>
            {data.nome}
          </Typography>

        </Grid>
        <Grid item>
          {data.valor === '1' ? (
            <OkIcon tipo="BUTTON" />
          ) : data.valor === '0' ? <CancelarIcon tipo="BUTTON" fill={theme.palette.error.main} /> : data.valor === '-1' ? (
            <Box flex >
              <Typography variant="body2" className={classes.textTitle}>
                {data.quant}/
              </Typography>
              <Typography className={classes.textInfinite}>ꝏ</Typography>
            </Box>
          ) : data.valor !== '0' ? (
            <Box flex >
              {data.codigo === EnumPlanoOpcoes.DiasExpiracao ? (
                <Typography variant="body2" className={classes.textTotal}>
                  {expiracaoPlano}
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" className={classes.textTitle}>
                    {data.quant}/
                  </Typography>
                  <Typography variant="body2" className={classes.textTotal}>
                    {data.valor}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </DefaultCard>
  );
};
