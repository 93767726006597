import { EnumMenu } from 'model';
import { MenuModel } from '../../model/app';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { MovRotasMock } from './mov-rotas-mock';
import { RetaguardaRotasMock } from './retaguarda-rotas-mock';

export const ModulosRetaguardaMock: MenuModel[] = [
  new MenuModel('Vender', 'Acessar o módulo de vendas.', EnumMenu.OPERACIONAL_FORAVENDA_INICIAR_VENDA, [], MovRotasMock.landingRota + '?verificarCadastros=true', 0, EnumMenu.RETAGUARDA),

  new MenuModel('Cadastros', '', EnumMenu.CADASTRO_GROUP, [], '', 7, 0),
  new MenuModel('Produtos', 'Manutenção de Produto, Estoque e Preço.', EnumMenu.CADASTRO_PRODUTOS, RetaguardaRotasMock.produtosRota.roles, RetaguardaRotasMock.produtosRota.path, 1, EnumMenu.CADASTRO_GROUP),
  new MenuModel('Promoções', 'Gestão das campanhas de promoção.', EnumMenu.CADASTRO_PROMOCAO, RetaguardaRotasMock.promocoesRota.roles, RetaguardaRotasMock.promocoesRota.path, 2, EnumMenu.CADASTRO_GROUP),
  new MenuModel('Formas de Pagamento', 'Parâmetros de forma de pagamento.', EnumMenu.CADASTRO_FINALIZADORA, RetaguardaRotasMock.finalizadorasRota.roles, RetaguardaRotasMock.finalizadorasRota.path, 6, EnumMenu.CADASTRO_GROUP),
  new MenuModel('Outros Cadastros', 'Categorias, Impostos e Kits.', EnumMenu.CADASTRO_OUTROS, RetaguardaRotasMock.outrosCadastrosRota.roles, RetaguardaRotasMock.outrosCadastrosRota.path, 7, EnumMenu.CADASTRO_GROUP),

  new MenuModel('Gestão de Pessoas', '', EnumMenu.PESSOAS_GROUP, [], '', 7, 0),
  new MenuModel('Pessoas', 'Clientes, funcionários e fornecedores.', EnumMenu.CADASTRO_PESSOAS, RetaguardaRotasMock.pessoasRota.roles, RetaguardaRotasMock.pessoasRota.path, 1, EnumMenu.PESSOAS_GROUP),
  new MenuModel('Comissões', 'Gestão de campanhas de comissão.', EnumMenu.CADASTRO_COMISSAO, RetaguardaRotasMock.comissoesRota.roles, RetaguardaRotasMock.comissoesRota.path, 2, EnumMenu.PESSOAS_GROUP),
  new MenuModel('Convênios', 'Gestão de contas fiado da empresa.', EnumMenu.CADASTRO_CONVENIO, RetaguardaRotasMock.conveniosRota.roles, RetaguardaRotasMock.conveniosRota.path, 3, EnumMenu.PESSOAS_GROUP),
  new MenuModel('Gestão de Faturas', 'Acompanhamento das faturas dos clientes.', EnumMenu.RELATORIOS_CONVENIO_FATURAS, RetaguardaRotasMock.conveniosFaturasRota.roles, RetaguardaRotasMock.conveniosFaturasRota.path, 4, EnumMenu.PESSOAS_GROUP),

  new MenuModel('Farmácias', '', EnumMenu.FARMA_GROUP, [], '', 7, 0),
  new MenuModel('Gestão de Controlados', 'Realizar gestão de controlados e SNGPC', EnumMenu.FARMA_SNGPC, [EnumCodigosPermissoes.SNGPC], '', 1, EnumMenu.FARMA_GROUP),

  //RELATORIOS
  new MenuModel('Relatórios', '', EnumMenu.RELATORIOS_GROUP, [], '', 8, 0),
  new MenuModel('Dashboard', 'Gráficos para inteligência de negócio.', EnumMenu.RELATORIOS_DASHBOARD, RetaguardaRotasMock.dashboardRota.roles, RetaguardaRotasMock.dashboardRota.path, 1, EnumMenu.RELATORIOS_GROUP),
  new MenuModel('Relatórios Avançados', 'Realize consultas específicas para o seu negócio.', EnumMenu.RELATORIOS_AVANCADOS, RetaguardaRotasMock.relatoriosAvancadosRota.roles, RetaguardaRotasMock.relatoriosAvancadosRota.path, 1, EnumMenu.RELATORIOS_GROUP),
  new MenuModel('Histórico de Vendas', 'Exibir detalhamento de vendas realizadas.', EnumMenu.RELATORIOS_HISTORICO_VENDAS, RetaguardaRotasMock.historicoRota.roles, RetaguardaRotasMock.historicoRota.path, 1, EnumMenu.RELATORIOS_GROUP),
  new MenuModel('Relatório de Renderizações', 'Teste de Desempenho.', EnumMenu.RELATORIOS_PERFORMANCE, RetaguardaRotasMock.relatorioPerformanceRota.roles, RetaguardaRotasMock.relatorioPerformanceRota.path, 1, EnumMenu.RELATORIOS_GROUP),
  new MenuModel('Pendências Fiscais', 'Exibir vendas com pendências fiscais.', EnumMenu.RELATORIOS_PENDENCIAS_FISCAIS, RetaguardaRotasMock.pendenciasFiscaisRota.roles, RetaguardaRotasMock.pendenciasFiscaisRota.path, 1, EnumMenu.RELATORIOS_GROUP),

  //SALAO
  new MenuModel('Pré-Venda', '', EnumMenu.SALAO_GROUP, [EnumCodigosPermissoes.REALIZAR_PEDIDOS], '', 10, 0),
  new MenuModel('Mesas e Comandas', 'Manutenção de parâmetros para Pré-Venda.', EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS, RetaguardaRotasMock.gerenciarMesaComandaRota.roles, RetaguardaRotasMock.gerenciarMesaComandaRota.path + "/mesas", 4, EnumMenu.SALAO_GROUP),
  new MenuModel('Salões', 'Separação dos pedidos em dois ou mais ambientes.', EnumMenu.ADMINISTRATIVO_SALOES, RetaguardaRotasMock.saloesRota.roles, RetaguardaRotasMock.saloesRota.path, 5, EnumMenu.SALAO_GROUP),
  new MenuModel('Meu Cardápio', 'Acessar portal de pedidos para clientes.', EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO, [EnumCodigosPermissoes.CARDAPIO], '', 6, EnumMenu.SALAO_GROUP),
  new MenuModel('Setor e Processos', 'Configuração de etapas de produção.', EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS, RetaguardaRotasMock.setoresRota.roles, RetaguardaRotasMock.setoresRota.path, 6, EnumMenu.SALAO_GROUP),

  //ENTRADAS
  new MenuModel('Entradas', '', EnumMenu.ENTRADA_GROUP, [], '', 9, 0),
  new MenuModel('Entrada de Mercadoria', 'Importação de NFs via Xml ou Manual.', EnumMenu.RELATORIOS_ENTRADA_MERCADORIA, RetaguardaRotasMock.entradaMercadoriasRota.roles, RetaguardaRotasMock.entradaMercadoriasRota.path, 1, EnumMenu.ENTRADA_GROUP),
  new MenuModel('Manifestos do Destinatário', 'Integração de NFs pela SEFAZ.', EnumMenu.RELATORIOS_MANIFESTOS, RetaguardaRotasMock.manifestosRota.roles, RetaguardaRotasMock.manifestosRota.path, 2, EnumMenu.ENTRADA_GROUP),

  //ADMINISTRATIVO
  new MenuModel('Administrativo', '', EnumMenu.ADMINISTRATIVO_GROUP, [], '', 11, 0),
  new MenuModel('Gerenciamento de Sessões', 'Conferência de fechamentos de caixa.', EnumMenu.ADMINISTRATIVO_GERENCIAMENTO_DE_SESSOES, RetaguardaRotasMock.gerenciamentoSessoesRota.roles, RetaguardaRotasMock.gerenciamentoSessoesRota.path, 0, EnumMenu.ADMINISTRATIVO_GROUP),
  new MenuModel('Acessos e Permissões', 'Atribuir níveis de acesso aos usuários.', EnumMenu.ADMINISTRATIVO_ACESSOS_PERMISSOES, RetaguardaRotasMock.permissoesRota.roles, RetaguardaRotasMock.permissoesRota.path, 3, EnumMenu.ADMINISTRATIVO_GROUP),
  new MenuModel('Crachá de Autenticação', 'Gerar crachá para concessão de permissões.', EnumMenu.ADMINISTRATIVO_AUTENTICACAO, RetaguardaRotasMock.autenticacaoRota.roles, RetaguardaRotasMock.autenticacaoRota.path, 6, EnumMenu.ADMINISTRATIVO_GROUP),
  new MenuModel('Impressoras', 'Gestão de impressoras remotas.', EnumMenu.ADMINISTRATIVO_IMPRESSORAS, RetaguardaRotasMock.impressoraRota.roles, RetaguardaRotasMock.impressoraRota.path, 1, EnumMenu.ADMINISTRATIVO_GROUP),
  new MenuModel('Pontos de Venda', 'Gestão dos PDVs da empresa.', EnumMenu.ADMINISTRATIVO_PONTOS_VENDAS, RetaguardaRotasMock.pdvRota.roles, RetaguardaRotasMock.pdvRota.path, 2, EnumMenu.ADMINISTRATIVO_GROUP),
  new MenuModel('Configurações', 'Parâmetros do sistema.', EnumMenu.ADMINISTRATIVO_CONFIGURACOES, RetaguardaRotasMock.configuracoesRota.roles, RetaguardaRotasMock.configuracoesRota.path, 6, EnumMenu.ADMINISTRATIVO_GROUP),

  // FISCAL
  new MenuModel('Fiscal', '', EnumMenu.FISCAL_GROUP, [], '', 12, 0),
  new MenuModel('Revisar Dados NFC-e', 'Conferência para emissão de NFC-e.', EnumMenu.FISCAL_ATIVAR_REVISAR_DADOS_NFCE, RetaguardaRotasMock.ativarNFCERota.roles, RetaguardaRotasMock.ativarNFCERota.path, 1, EnumMenu.FISCAL_GROUP),
  new MenuModel('Retirada de XMLs', 'Envio dos XMLs para o contador.', EnumMenu.FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS, RetaguardaRotasMock.documentosRota.roles, RetaguardaRotasMock.documentosRota.path, 2, EnumMenu.FISCAL_GROUP),

  // SUPORTE
  new MenuModel('Ajuda', '', EnumMenu.SUPORTE_GROUP, [], '', 13, 0),
  new MenuModel('Suporte Técnico', 'Canal de comunicação com nossos atendentes.', EnumMenu.SUPORTE_CHAT, [], '', 1, EnumMenu.SUPORTE_GROUP),

  // TODO: POR ENQUANTO COMENTADO POR CONTA DO WHITELABEL, ENTENDER MELHOR O QUE DEVEMOS FAZER PARA DISPONIBILIZAR O TUTORIAL.
  // new MenuModel('Base de Conhecimento', 'Centralização e acesso rápido a informações', EnumMenu.SUPORTE_BASE_CONHECIMENTO, [], '', 1, EnumMenu.SUPORTE_GROUP)
];
