import { useEventTools } from "services/app/hooks/events/event-tools";
import { useStyles } from "./environment-info-styles";
import { AppEventEnum } from "model/enums/enum-app-event";
import { VariaveisAmbiente } from "config/enviroment";
import { EnumDeviceType } from "model/enums/enum-device-type";

export const EnvironmentInfo = () => {
    const classes = useStyles();
    const { callEvent } = useEventTools();

    const showLabel = (
        window.location.hostname.toLowerCase().includes('touchone') ||
        window.location.hostname.toLowerCase().includes('localhost')
    );

    return <div className={classes.versionControl} onClick={(e) => {
        callEvent(AppEventEnum.EnvironmentConfig, e);
    }}>
        <div>
            <b>Versão: </b>
            <i>{VariaveisAmbiente.version}</i>
        </div>
        <div>
            <i>{VariaveisAmbiente.environment}/{VariaveisAmbiente.gatewayVersion}</i>
        </div>
        {showLabel && <>
            <div>
                <i>{VariaveisAmbiente.label}</i>
            </div>
            <div>
                <i>{
                    VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ? "mobile" :
                        VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS ? "pos" : "web"
                }</i>
            </div>
        </>}
        <div>
            <i>{VariaveisAmbiente.isDev ? '*dev' : ''}</i>
        </div>
        <div>
            <i>{VariaveisAmbiente.performanceDebugMode ? '*performanceDebugMode' : ''}</i>
        </div>
    </div>
}