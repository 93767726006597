import { Button, Grid, Typography } from 'views/design-system';
import { useStyles } from './dashboard-content-styles';
import { ProcurarIcon } from 'views/components/icons';
import { useGetDashboardCategorias } from 'data/api/gestao/relatorios/dashboard/get-dashboard-categorias';
import { useGetDashboardPagamentos } from 'data/api/gestao/relatorios/dashboard/get-dashboard-pagamentos';
import { useGetDashboardVendas } from 'data/api/gestao/relatorios/dashboard/get-dashboard-vendas';
import { DashboardVendasModel } from 'model/api/gestao/dashboard/dashboard-vendas';
import { useToastSaurus } from 'services/app';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardPagamentosModel } from 'model/api/gestao/dashboard/dashboard-pagamentos';
import { DashboardCategoriasModel } from 'model/api/gestao/dashboard/dashboard-categorias';
import { CircularLoading } from 'views';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { PorDiaMock, PorMesMock } from 'data/mocks/ultimos-mock';
import { PeriodoDashboardMock } from 'data/mocks/periodo-dashboard-mock';
import { DashboardVendedoresModel } from 'model/api/gestao/dashboard/dashboard-vendedores';
import { useGetDashboardVendedores } from 'data/api/gestao/relatorios/dashboard/get-dashboard-vendedores';
import { useGetDashboardProdutos } from 'data/api/gestao/relatorios/dashboard/get-dashboard-produtos';
import { DashboardProdutosModel } from 'model/api/gestao/dashboard/dashboard-produtos';
import { toDate } from 'utils/to-date';
import { isEmpty } from 'lodash';
import DashboardInfo from './components/dashboard-info/dashboard-info';
import { KeyValueModel, RetornoApiModel } from 'model';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDashboardValidation } from './dashboard-content-validations';
import { DashboardFormModel } from 'model/app/forms/dashboard/dashboard-form-model';

export interface DashboardContentProps {
  dataPagamentos: DashboardPagamentosModel[];
  dataVendas: DashboardVendasModel[];
  dataCategorias: DashboardCategoriasModel[];
  dataVendedores: DashboardVendedoresModel[];
  dataProdutos: DashboardProdutosModel[];
  empresaNome: string;
  empresaId: string;
}

const dataFormatada = (val: Date) => {
  const data = toDate(val) ?? new Date(val);
  const dia = data?.getDate();
  const mes = data?.getMonth() + 1;
  const ano = data?.getFullYear();

  const dataFormated = `${ano}-${mes?.toString().length === 1 ? '0' + mes : mes
    }-${dia?.toString().length === 1 ? '0' + dia : dia}`;
  return dataFormated;
};

const apenasMesAno = () => {
  const data = new Date();
  const mes = data.getMonth();
  const ano = data.getFullYear();
  return `${ano}-${`${mes}`.padStart(2, '0')}`;
};

export const DashboardContent = () => {
  const { showToast } = useToastSaurus();
  const classes = useStyles();

  const [content, setContent] = useState<DashboardContentProps[]>([]);
  const [index, setIndex] = useState<number>(0);

  const empresasMock = useMemo(
    () =>
      content.map(
        (empresa) => new KeyValueModel(empresa.empresaId, empresa.empresaNome)
      ),
    [content]
  );

  const [preenchendoTela, setPreenchendoTela] = useState<boolean>(true);
  const [periodoPesquisar, setPeriodoPesquisar] = useState<Number>(0);
  const [mudarPeriodos, setMudarPeriodos] = useState<Number>(1);
  const [dataFinalSelect, setDataFinalSelect] = useState<any>(new Date());
  const [dataInicialSelect, setDataInicialSelect] = useState<any>(
    new Date().setDate(new Date().getDate() - 7)
  );
  const [periodosMock, setPeriodosMock] = useState(PorDiaMock);
  const [ocultarDatas, setOcultarDatas] = useState<boolean>(false);

  const {
    getDashboardCategorias,
    carregando: carregandoGetDashboardCategorias
  } = useGetDashboardCategorias();
  const {
    getDashboardPagamentos,
    carregando: carregandoGetDashboardPagamentos
  } = useGetDashboardPagamentos();
  const { getDashboardVendas, carregando: carregandoGetDashboardVendas } =
    useGetDashboardVendas();
  const {
    getDashboardVendedores,
    carregando: carregandoGetDashboardVendedores
  } = useGetDashboardVendedores();
  const { getDashboardProdutos, carregando: carregandoGetDashboardProdutos } =
    useGetDashboardProdutos();

  const carregando =
    carregandoGetDashboardCategorias ||
    carregandoGetDashboardPagamentos ||
    carregandoGetDashboardVendas ||
    carregandoGetDashboardVendedores ||
    carregandoGetDashboardProdutos ||
    preenchendoTela;


  const { FormDashboardYupValidation } = useFormDashboardValidation()

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<DashboardFormModel>({
    resolver: yupResolver(FormDashboardYupValidation)
  })

  const diaInicialFormated = dataFormatada(dataInicialSelect);

  const queryPesquisaPeriodos = useCallback(() => {
    if (mudarPeriodos === 3) {
      setOcultarDatas(true);
    } else {
      setOcultarDatas(false);
    }
  }, [mudarPeriodos]);

  const queryPesquisa = useCallback(() => {
    let data = new Date();
    if (periodosMock === PorDiaMock) {
      if (mudarPeriodos === 0) {
        data.setDate(data.getDate());
        const d = dataFormatada(data);
        setDataInicialSelect(d);
      }
      if (mudarPeriodos === 1) {
        data.setDate(data.getDate() - 7);
        setDataInicialSelect(dataFormatada(data));
      }
      if (mudarPeriodos === 2) {
        data.setDate(data.getDate() - 14);
        setDataInicialSelect(dataFormatada(data));
      }
    }
    if (periodosMock === PorMesMock) {
      if (mudarPeriodos === 0) {
        data.setDate(data.getDate());
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = '01';
        let dataInicial = `${ano}-${mes}-${dia}`;
        setDataInicialSelect(dataInicial);
        setDataFinalSelect(new Date());
      }
      if (mudarPeriodos === 1) {
        data.setDate(data.getDate());
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = '01';
        const lastDate = new Date(ano, mes === 1 ? 12 : mes - 1, 0)
        let dataInicial = `${mes === 1 ? ano - 1 : ano}-${mes === 1 ? (mes = 12) : mes - 1
          }-${dia}`;
        let dataFinal = `${mes === 1 ? ano - 1 : ano}-${mes === 1 ? (mes = 12) : mes - 1
          }-${lastDate.getDate()}`;
        setDataFinalSelect(dataFinal);
        setDataInicialSelect(dataInicial);
      }
      if (mudarPeriodos === 2) {
        data.setDate(data.getDate());
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = '01';
        let dataInicial = `${mes === 1 || mes === 2 ? ano - 1 : ano}-${mes === 1
          ? (mes = 10)
          : mes === 2
            ? (mes = 11)
            : mes === 3
              ? (mes = 12)
              : mes - 3
          }-${dia}`;
        setDataFinalSelect(new Date());
        setDataInicialSelect(dataInicial);
      }
    }
  }, [mudarPeriodos, periodosMock]);

  const handleGetValores = useCallback(
    async (callback: (query: string) => Promise<RetornoApiModel<any>>) => {
      try {

        const query =
          '?Status=30' +
          '&DInicial=' +
          diaInicialFormated +
          '&DFinal=' +
          dataFormatada(dataFinalSelect);

        const res = await callback(query)
        if (res.erro) {
          throw res.erro;
        }
        return res.resultado?.data;
      } catch (e: any) {
        showToast('error', e.message);
        return []
      }
    }, [dataFinalSelect, diaInicialFormated, showToast])

  const preencherTela = useCallback(async () => {
    setPreenchendoTela(true);
    const dashboardCategorias = await handleGetValores(getDashboardCategorias) as Array<DashboardCategoriasModel>;
    const dashboardPagamentos = await handleGetValores(getDashboardPagamentos) as Array<DashboardPagamentosModel>;
    const dashboardVendas = await handleGetValores(getDashboardVendas) as Array<DashboardVendasModel>;
    const dashboardVendedores = await handleGetValores(getDashboardVendedores) as Array<DashboardVendedoresModel>;
    const dashboardProdutos = await handleGetValores(getDashboardProdutos) as Array<DashboardProdutosModel>;

    const reduceModel = (prev: any, curr: any) => {
      const mesmaEmpresa = prev.find((arr: any) =>
        arr.find((obj: any) => obj.empresaId === curr.empresaId)
      );
      if (!isEmpty(mesmaEmpresa) && mesmaEmpresa) {
        const index = prev.indexOf(mesmaEmpresa);
        prev[index].push(curr);
        return prev;
      }

      prev.push([curr]);

      return prev;
    }

    const dataCategorias = dashboardCategorias.reduce(reduceModel, []);
    const dataPagamentos = dashboardPagamentos.reduce(reduceModel, []);
    const dataVendas = dashboardVendas.reduce(reduceModel, []);
    const dataVendedores = dashboardVendedores.reduce(reduceModel, []);
    const dataProdutos = dashboardProdutos.reduce(reduceModel, []);

    let dataContent: DashboardContentProps[] = [];

    const acharMaiorArray = () => {
      const maiorLength = Math.max(
        dataCategorias.length,
        dataPagamentos.length,
        dataProdutos.length,
        dataVendas.length,
        dataVendedores.length
      );

      const maiorArray =
        dataCategorias.length === maiorLength
          ? dataCategorias
          : dataPagamentos.length === maiorLength
            ? dataPagamentos
            : dataProdutos.length === maiorLength
              ? dataProdutos
              : dataVendas.length === maiorLength
                ? dataVendas
                : dataVendedores.length === maiorLength
                  ? dataVendedores
                  : [];

      return { maiorLength, maiorArray };
    };

    const { maiorLength, maiorArray } = acharMaiorArray();

    dataContent.push({
      dataCategorias: dashboardCategorias,
      dataPagamentos: dashboardPagamentos,
      dataProdutos: dashboardProdutos,
      dataVendas: dashboardVendas,
      dataVendedores: dashboardVendedores,
      empresaId: '-1',
      empresaNome: 'Todas'
    });

    for (let i = 0; i < maiorLength; i++) {
      const empresaId = maiorArray[i][0].empresaId;

      const findData = (item: any) => !isEmpty(item.find((innerItem: any) => innerItem.empresaId === empresaId))

      dataContent.push({
        dataCategorias: dataCategorias.find(findData) || [],
        dataPagamentos: dataPagamentos.find(findData) || [],
        dataProdutos: dataProdutos.find(findData) || [],
        dataVendas: dataVendas.find(findData) || [],
        dataVendedores: dataVendedores.find(findData) || [],
        empresaNome: maiorArray[i][0].empresa,
        empresaId: maiorArray[i][0].empresaId
      });
    }
    setContent(dataContent);
    setPreenchendoTela(false);

  }, [getDashboardCategorias, getDashboardPagamentos, getDashboardProdutos, getDashboardVendas, getDashboardVendedores, handleGetValores]);

  const trocarEmpresa = useCallback(
    (id: string) => {
      const empresa = content.find((item) => item.empresaId === id);
      const index = content.indexOf(empresa!);

      setIndex(index);
    },
    [content]
  );

  useEffect(() => {
    queryPesquisa();
    queryPesquisaPeriodos();
  }, [queryPesquisa, queryPesquisaPeriodos]);

  useEffect(() => {
    preencherTela();
  }, [preencherTela]);

  function obterUltimoDiaDoMes(data: string | Date): string {
    const dataInicial: Date = typeof data === 'string' ? new Date(data) : data;

    const primeiroDiaDoMesSeguinte = new Date(dataInicial.getFullYear(), dataInicial.getMonth() + 1, 1);

    const ultimoDiaDoMes = new Date(primeiroDiaDoMesSeguinte);
    ultimoDiaDoMes.setDate(ultimoDiaDoMes.getDate() - 1);

    const dataFormatada = ultimoDiaDoMes.toISOString().split('T')[0];
    return dataFormatada;
  }

  const onSubmit = (model: DashboardFormModel) => {
    periodoPesquisar === 1
      ? setDataInicialSelect(model.dataInicial)
      : setDataInicialSelect(model.dataInicial);
    periodoPesquisar === 1
      ? setDataFinalSelect(obterUltimoDiaDoMes(model.dataFinal))
      : setDataFinalSelect(model.dataFinal);
  }

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item md={4} xs={6}>
            <SelectSaurus
              style={{ height: '40px' }}
              variant="outlined"
              conteudo={PeriodoDashboardMock}
              allowSubmit
              onChange={(event) => {
                if (event) {
                  const newStatus = PeriodoDashboardMock.filter(
                    (item) => item.Key === event.target.value
                  )[0]?.Key;
                  switch (newStatus) {
                    case 0:
                      setValue('dataFinal', dataFormatada(new Date()))
                      setValue('dataInicial', dataFormatada(new Date()))
                      setPeriodosMock(PorDiaMock);
                      break;
                    case 1:
                      setValue('dataFinal', apenasMesAno())
                      setValue('dataInicial', apenasMesAno())
                      setPeriodosMock(PorMesMock);
                      break;
                  }
                  setPeriodoPesquisar(newStatus);

                }
              }}
              value={periodoPesquisar}
            />
          </Grid>
          {empresasMock.length > 2 && (
            <Grid item md={4} xs={6}>
              <SelectSaurus
                style={{ height: '40px' }}
                variant="outlined"
                conteudo={empresasMock}
                label="Empresa"
                allowSubmit
                onChange={(event) => {
                  if (event) {
                    setPreenchendoTela(true);
                    setTimeout(() => {
                      trocarEmpresa(event.target.value);
                      setPreenchendoTela(false);
                    }, 300);
                  }
                }}
                value={!isEmpty(content) ? content[index].empresaId : '-1'}
              />
            </Grid>
          )}
          <Grid item md={empresasMock.length > 2 ? 4 : 8} xs={empresasMock.length > 2 ? 12 : 6}>
            <SelectSaurus
              style={{ height: '40px' }}
              variant="outlined"
              conteudo={periodosMock}
              allowSubmit
              onChange={(event) => {
                if (event) {
                  const newStatus = periodosMock.filter(
                    (item) => item.Key === event.target.value
                  )[0]?.Key;
                  setMudarPeriodos(newStatus);
                }
              }}
              value={mudarPeriodos ?? 1}
            />
          </Grid>

          {ocultarDatas && (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Grid container spacing={1} className={classes.gridInputDate}>
                <Grid item>
                  <Controller
                    control={control}
                    name="dataInicial"
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={periodoPesquisar === 1 ? 'DATA_MES' : 'DATA'}
                        label="Data Inicial"
                        error={Boolean(
                          errors.dataInicial && errors.dataInicial.message,
                        )}
                        helperText={
                          errors.dataInicial
                            ? errors.dataInicial?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name="dataFinal"
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={periodoPesquisar === 1 ? 'DATA_MES' : 'DATA'}
                        label="Data Final"
                        error={Boolean(
                          errors.dataFinal && errors.dataFinal.message && !errors.dataFinal.message.includes('menor'),
                        )}
                        helperText={
                          errors.dataFinal && !(errors?.dataFinal?.message ?? '').includes('menor')
                            ? errors.dataFinal?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.btnPesquisa}
                  >
                    <ProcurarIcon tipo="BUTTON_PRIMARY" />
                  </Button>
                </Grid>
                {errors.dataFinal && (errors.dataFinal?.message ?? '').includes('menor') && (
                  <Grid item xs={12}>
                    <Typography align="right" className={classes.errorData} variant="body2">
                      {errors.dataFinal?.message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Grid>
        {carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : (
          <DashboardInfo
            data={content[index]}
            mudarPeriodos={mudarPeriodos}
            periodoPesquisar={periodoPesquisar}
          />
        )}
      </div>
    </>
  );
};
