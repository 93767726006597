import { Tooltip } from "../tooltip"

interface Props {
    children: JSX.Element,
    title?: string
}

export const OptionalTooltip = ({ children, title }: Props) => {
    console.log(title)
    if (title) {
        return (<Tooltip title={title} >
            {children}
        </Tooltip>)
    }

    return (
        <>
            {children}
        </>
    )
}