import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { makeUtilClasses, useThemeQueries } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { useFormGerarComandaValidation } from './form-gerar-comanda-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
// import SemImagem from 'assets/img/sem-imagem.jpg';
import { useStyles } from './form-gerar-comanda-styles';
import { useConsultaCEP } from 'data/api/wsmaster';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { useToastSaurus } from 'services/app';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';
import { picker } from 'utils/picker';
import { removeGuidEmpty } from 'utils/remove-guid-empty';
import { useGetEnderecoPessoa, useGetPessoas } from 'data/api/gestao/pessoa';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { guidEmpty } from 'utils/guid-empty';
import { FecharIcon } from 'views/components/icons';
import { isEmpty } from 'lodash';
import { validarCPFCNPJ } from 'utils/cpfcnpj-validate';
import { AutocompleteComandas } from 'views/components/controles/autocompletes/autocomplete-comandas/autocomplete-comandas';
import { retornoAutoComplete } from 'views/components/controles/autocompletes/autocomplete-saurus/autocomplete-saurus';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { stringNumeros } from 'utils/string-numeros';

interface FormGerarComandaProps extends DefaultFormProps<PedidoDadosModelPost> {
    handleSubmit: (model: PedidoDadosModelPost, enderecoBeforeModel?: PessoaEnderecoModel, pessoaBeforeModel?: PessoaModel) => Promise<any>;
    esconderMesaEComandas?: boolean;
}

export const FormGerarComanda = forwardRef<
    DefaultFormRefs<PedidoDadosModelPost>,
    FormGerarComandaProps
>((props: FormGerarComandaProps, ref) => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { theme } = useThemeQueries();
    const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
    const { getPessoas, carregando: carregandoPessoa } = useGetPessoas();
    const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa();
    const refInputNumero = useRef<HTMLInputElement>(null);
    const refInputComanda = useRef<HTMLInputElement>(null);

    const carregando = props.loading || carregandoCep || carregandoEndereco || carregandoPessoa

    const { FormGerarComandaValidationYup } = useFormGerarComandaValidation();

    const [uf, setUf] = useState<string>('')
    const [jaCadastrado, setJaCadastrado] = useState<boolean>(false);
    const pessoaBeforeModel = useRef<PessoaModel>(new PessoaModel())
    const enderecoBeforeModel = useRef<PessoaEnderecoModel>(new PessoaEnderecoModel())
    const [comandaId, setComandaId] = useState<string>('')

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getFieldState,
        trigger
    } = useForm<PedidoDadosModelPost>({
        defaultValues: { ...props.model },
        resolver: yupResolver(FormGerarComandaValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: PedidoDadosModelPost) => {
        let model = picker<PedidoDadosModelPost>(values, new PedidoDadosModelPost(), false)
        model = removeGuidEmpty(model, true)
        props.handleSubmit(model,
            enderecoBeforeModel.current && enderecoBeforeModel.current.id !== guidEmpty() ? enderecoBeforeModel.current : undefined,
            pessoaBeforeModel.current ? pessoaBeforeModel.current : undefined
        );
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: PedidoDadosModelPost) => {
            reset({ ...model, quantidadePessoas: 1 })
        },
    }));

    const searchCliente = useCallback(
        async (query: string, noToast?: boolean) => {
            try {
                query = query.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
                const res = await getPessoas(`generico=${query}`, 0);
                if (res.erro) throw res.erro;
                if (!res.resultado) throw new Error('Erro ao pesquisar Cliente.')

                if (res.resultado?.data.list.length < 1 && !noToast) {
                    showToast('info', 'Cliente não encontrado.')
                    return
                }

                const cliente = res.resultado.data.list[0] as PessoaModel

                pessoaBeforeModel.current = cliente

                setValue('cliente.cpfCnpj', cliente.cpfcnpj)
                setValue('cliente.nomeFantasia', cliente.nome)
                setValue('cliente.email', cliente.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)?.valor || '')
                setValue('cliente.telefone', cliente.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '')

                try {
                    const resEndereco = await getEnderecoPessoa(cliente.id)

                    if (resEndereco.erro) throw res.erro
                    if (!resEndereco.resultado) throw new Error()

                    if (res.resultado?.data.length < 1) {
                        return
                    }

                    const endereco = resEndereco.resultado.data[0] as PessoaEnderecoModel

                    enderecoBeforeModel.current = endereco

                    setValue('enderecoEntrega.cep', endereco.cep)
                    setValue('enderecoEntrega.codigoMunicipio', endereco.cMun)
                    setValue('enderecoEntrega.municipio', endereco.xMun)
                    setValue('enderecoEntrega.logradouro', endereco.logradouro)
                    setValue('enderecoEntrega.bairro', endereco.bairro)
                    setValue('enderecoEntrega.uf', endereco.uf)
                    setValue('enderecoEntrega.numero', endereco.numero)
                    setValue('enderecoEntrega.complemento', endereco.complemento)
                    setUf(endereco.uf)

                } catch (e: any) {

                }

                setJaCadastrado(true)
            } catch (e: any) {
                if (!noToast) {
                    showToast('error', e.message);
                }
            }
        },
        [getEnderecoPessoa, getPessoas, setValue, showToast],
    );

    const removerCliente = useCallback(() => {
        pessoaBeforeModel.current = new PessoaModel()
        enderecoBeforeModel.current = new PessoaEnderecoModel()

        setJaCadastrado(false)
    }, [])

    const handleConsultaCep = async (value: string) => {
        try {
            let res = await consultaCEP(value);

            setValue('enderecoEntrega.cep', res.CEP)
            setValue('enderecoEntrega.codigoMunicipio', res.CMun)
            setValue('enderecoEntrega.municipio', res.Municipio)
            setValue('enderecoEntrega.logradouro', res.Logradouro)
            setValue('enderecoEntrega.bairro', res.Bairro)
            setValue('enderecoEntrega.uf', res.UF)
            setUf(res.UF)

            refInputNumero.current?.focus();
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={carregando ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2} justifyContent='center' className={classes.container}>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                            <Typography color='primary' variant='h6'>Dados da Comanda</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant='fullWidth' />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                name="comandaId"
                                control={control}
                                render={({ field }) => (
                                    <AutocompleteComandas
                                        modo='codigoComanda'
                                        disabled={carregando}
                                        label="Número da Comanda *"
                                        error={Boolean(
                                            errors.comandaId && errors.comandaId.message,
                                        )}
                                        helperText={
                                            touchedFields.comandaId || errors.comandaId
                                                ? errors.comandaId?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={comandaId}
                                        onChange={(e: retornoAutoComplete<ComandasModel>) => {
                                            setComandaId(e.value.id)
                                            setValue('comandaId', e.value.id)
                                            refInputComanda.current?.blur()
                                        }}
                                        localDb
                                        inputRef={refInputComanda}

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: 0, display: 'flex', gap: theme.spacing(2) }}>
                            <Typography color='primary' variant='h6'>Dados do Cliente</Typography>
                            {jaCadastrado && (
                                <Box className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <Typography className={classes.moduloNome} variant="body2">
                                            Cliente: {pessoaBeforeModel.current.nome}
                                        </Typography>
                                        <FecharIcon tipo="BUTTON" style={{ width: 16, cursor: 'pointer' }} onClick={() => removerCliente()} />
                                    </div>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant='fullWidth' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="cliente.cpfCnpj"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CNPJ_CPF"
                                        disabled={carregando}
                                        fullWidth
                                        variant="outlined"
                                        label="Documento *"
                                        searchable
                                        onSearch={async (value: string) => searchCliente(value)}
                                        placeholder={'CPF ou CNPJ'}
                                        error={Boolean(
                                            getFieldState("cliente.cpfCnpj").error,
                                        )}
                                        helperText={
                                            getFieldState("cliente.cpfCnpj").isTouched || getFieldState("cliente.cpfCnpj").error
                                                ? getFieldState("cliente.cpfCnpj").error?.message
                                                : undefined
                                        }
                                        {...field}
                                        onBlur={(e) => {
                                            trigger('cliente.cpfCnpj')

                                            if (
                                                !isEmpty(e.target.value) &&
                                                validarCPFCNPJ(e.target.value) &&
                                                !jaCadastrado
                                            ) {
                                                searchCliente(e.target.value, true)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="cliente.nomeFantasia"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TEXTO"
                                        disabled={carregando}
                                        fullWidth
                                        variant="outlined"
                                        label="Nome *"
                                        searchable
                                        onSearch={async (value: string) => searchCliente(value)}
                                        placeholder={'EX: Miguel Rodrigues da Silva'}
                                        error={Boolean(
                                            getFieldState("cliente.nomeFantasia").error,
                                        )}
                                        helperText={
                                            getFieldState("cliente.nomeFantasia").isTouched || getFieldState("cliente.nomeFantasia").error
                                                ? getFieldState("cliente.nomeFantasia").error?.message
                                                : undefined
                                        }
                                        {...field}
                                        onBlur={(e) => {
                                            trigger('cliente.nomeFantasia')

                                            if (
                                                !isEmpty(e.target.value) &&
                                                !jaCadastrado
                                            ) {
                                                searchCliente(e.target.value, true)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="cliente.telefone"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="TELEFONE"
                                        disabled={carregando}
                                        fullWidth
                                        variant="outlined"
                                        label="Telefone"
                                        placeholder={'Ex: (11) 12345-6789'}
                                        error={Boolean(
                                            getFieldState("cliente.telefone").error,
                                        )}
                                        helperText={
                                            getFieldState("cliente.telefone").isTouched || getFieldState("cliente.telefone").error
                                                ? getFieldState("cliente.telefone").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="cliente.email"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="EMAIL"
                                        disabled={carregando}
                                        fullWidth
                                        variant="outlined"
                                        label="E-mail"
                                        placeholder={'seunome@email.com'}
                                        error={Boolean(
                                            getFieldState("cliente.email").error,
                                        )}
                                        helperText={
                                            getFieldState("cliente.email").isTouched || getFieldState("cliente.email").error
                                                ? getFieldState("cliente.email").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                            <Typography color='primary' variant='h6'>Dados do Endereço</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant='fullWidth' />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Controller
                                name="enderecoEntrega.cep"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        tipo="CEP"
                                        label="CEP"
                                        placeholder='Ex: 99999-999'
                                        searchable
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.cep").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.cep").isTouched || getFieldState("enderecoEntrega.cep").error
                                                ? getFieldState("enderecoEntrega.cep").error?.message
                                                : undefined
                                        }
                                        {...field}
                                        onSearch={handleConsultaCep}
                                        onChange={e => {
                                            field.onChange(e)
                                            const { value } = e.target

                                            if (stringNumeros(value).length === 8) {
                                                setTimeout(() => handleConsultaCep(value), 400)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Controller
                                name="enderecoEntrega.logradouro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        placeholder='Ex: Rua Ipiranga'
                                        allowSubmit={false}
                                        label="Endereço"
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.logradouro").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.logradouro").isTouched || getFieldState("enderecoEntrega.logradouro").error
                                                ? getFieldState("enderecoEntrega.logradouro").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="enderecoEntrega.numero"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        inputRef={refInputNumero}
                                        tipo="NUMERO"
                                        label="Número"
                                        placeholder='Ex: 10'
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.numero").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.numero").isTouched || getFieldState("enderecoEntrega.numero").error
                                                ? getFieldState("enderecoEntrega.numero").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="enderecoEntrega.complemento"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        label="Complemento"
                                        placeholder='Ex: Bloco 3, apartamento 05'
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.municipio").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.municipio").isTouched || getFieldState("enderecoEntrega.municipio").error
                                                ? getFieldState("enderecoEntrega.municipio").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Controller
                                name="enderecoEntrega.bairro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        label="Bairro"
                                        placeholder='Ex: Tatuapé'
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.bairro").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.bairro").isTouched || getFieldState("enderecoEntrega.bairro").error
                                                ? getFieldState("enderecoEntrega.bairro").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Controller
                                name="enderecoEntrega.municipio"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        tipo="TEXTO"
                                        label="Município"
                                        placeholder='Ex: São Paulo'
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.municipio").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.municipio").isTouched || getFieldState("enderecoEntrega.municipio").error
                                                ? getFieldState("enderecoEntrega.municipio").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                name="enderecoEntrega.codigoMunicipio"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        readOnly={carregando}
                                        allowSubmit={false}
                                        tipo="NUMERO"
                                        label="Cód. Município"
                                        placeholder='Ex: 15654'
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.codigoMunicipio").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.codigoMunicipio").isTouched || getFieldState("enderecoEntrega.codigoMunicipio").error
                                                ? getFieldState("enderecoEntrega.codigoMunicipio").error?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Controller
                                name="enderecoEntrega.uf"
                                control={control}
                                render={({ field }) => (
                                    <SelectSaurus
                                        conteudo={UFMock}
                                        disabled={carregando}
                                        allowSubmit={false}
                                        label="UF"
                                        fullWidth
                                        autoComplete={'off'}
                                        error={Boolean(
                                            getFieldState("enderecoEntrega.uf").error,
                                        )}
                                        helperText={
                                            getFieldState("enderecoEntrega.uf").isTouched || getFieldState("enderecoEntrega.uf").error
                                                ? getFieldState("enderecoEntrega.uf").error?.message
                                                : undefined
                                        }
                                        {...field}
                                        value={UFMock.find(ufItem => ufItem.Value === uf)?.Key}
                                        onChange={(event) => {
                                            if (event) {
                                                const item = UFMock.filter(
                                                    (item) => item.Key === event.target.value,
                                                )[0];
                                                if (item) {
                                                    setValue('enderecoEntrega.uf', item.Value);
                                                    setUf(item.Value)
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid >
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form >
            </div >
        </>
    );
});
