import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useCadastros } from '../../../../../../services/app/hooks/cadastros';
import { ModalHeader } from '../../../components/modal-header/modal-header';
import { useModalStyles } from '../../../utils/modal-styles';
import { VoltarIcon } from '../../../../icons/voltar-icon';
import { ButtonModalHeader } from '../../../../controles/buttons/button-modal-header/button-modal-header';
import { SalvarNovoIcon } from '../../../../icons/salvar-novo-icon';
import { CircularLoading } from '../../../../utils';
import classNames from 'classnames';
import { ComandasGerarFormModel, ComandasGerarModel } from 'model/api/gestao/comanda/comanda-gerar-model';
import { usePostGerarComanda } from 'data/api/gestao/comandas/post-gerar-comandas.';
import { FormComandasCadastro } from 'views/components/form/comandas/form-comandas-cadastro/form-comandas-cadastro';
import { picker } from 'utils/picker';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { PutConfigEmpresaProps } from 'data/api/gestao/empresa/put-config-empresa';
import { Button, Grid } from 'views/design-system';

interface ComandasCadastroProps {
  atualizarLista: boolean
  handleAtualizarLista: (value: boolean) => void
}

export const ComandasCadastro = ({ atualizarLista, handleAtualizarLista }: ComandasCadastroProps) => {
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { postGerarComanda, carregando: carregandoPostComanda } = usePostGerarComanda();
  const { fecharCadastroComandas, abrirCadastroComandas } = useCadastros();
  const { getConfigByCod, getEmpresaAtual, putEmpresaConfig, carregando: carregandoConfigs } = useEmpresaAtual();

  const carregando = carregandoPostComanda || carregandoConfigs

  const classes = useModalStyles();
  const { showToast } = useToastSaurus();

  const cadComandasFormRef = useRef<DefaultFormRefs<ComandasGerarFormModel>>(null);
  const redirect = useRef<boolean>(true);

  const submitPrefixo = useCallback(async (prefixo: string) => {
    try {
      const updatedConfigs = [
        new PutConfigEmpresaProps(getEmpresaAtual()?.id, EnumEmpresaConfig.PrefixoDeComanda, prefixo)
      ]
      await putEmpresaConfig(updatedConfigs)
    } catch (e: any) {
      showToast('error', e.message)
    }
  }, [getEmpresaAtual, putEmpresaConfig, showToast])

  const onCloseClick = useCallback((forcarAtt?: boolean) => {
    fecharCadastroComandas(forcarAtt || atualizarLista);
    handleAtualizarLista(false)
  }, [atualizarLista, fecharCadastroComandas, handleAtualizarLista]);

  const saveNewComandas = useCallback(
    async (model: ComandasGerarFormModel) => {
      const value = picker<ComandasGerarModel>(model, new ComandasGerarModel());
      await submitPrefixo(model.prefixo)
      try {
        const ret = await postGerarComanda(
          value,
          getEmpresaSelecionada()?.Id || '',
        );
        if (ret.erro) {
          throw ret.erro;
        }
        handleAtualizarLista(true)
        if (redirect.current) {
          setTimeout(() => {
            abrirCadastroComandas(ret.resultado?.data.id, '', true);
          }, 1);
        }
        showToast('success', 'Comandas geradas com sucesso!');

        if (!redirect.current) {
          onCloseClick(true);
        }
      } catch (e: any) {
        showToast('error', e.message);
        cadComandasFormRef.current?.fillForm(model);
      }
    },
    [
      postGerarComanda,
      getEmpresaSelecionada,
      showToast,
      abrirCadastroComandas,
      submitPrefixo,
      handleAtualizarLista,
      onCloseClick,
    ],
  );

  const getPrefixo = useCallback(() => {
    const prefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda)
    const model = new ComandasGerarFormModel();
    model.prefixo = prefixo || '';
    cadComandasFormRef.current?.fillForm(model);
  }, [getConfigByCod])

  const handleSubmit = useCallback(
    async (model: ComandasGerarFormModel) => {
      return saveNewComandas(model);
    },
    [saveNewComandas],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadComandasFormRef.current?.submitForm();
  }, []);

  useEffect(() => {
    getPrefixo();
  }, [getPrefixo]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Gerar Comandas'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormComandasCadastro
            ref={cadComandasFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(false)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Gerar Comandas
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
