import { Table } from "dexie";
import { TouchoneDatabase } from "./touchone-database";
import { TabelaCaixaConfiguracao, TabelaEmpresaConfiguracao } from "./interfaces/interface-tabela-configuracoes";
import { TabelaMarcaModel } from "./interfaces/interface-tabela-marcas";
import { TabelaEntidadesPromocoes } from "./interfaces/interface-tabela-entidades-promocoes";
import { TabelaRegrasPromocoes } from "./interfaces/interface-tabela-regras-promocoes";
import { TabelaPromocoesDePor } from "./interfaces/interface-tabela-promocoes-de-por";
import { DisabledTable } from "./interfaces/interface-disabled.table";

export enum EnumTableNames {
    PRODUTOS = 'produtos',
    CLIENTES = 'clientes',
    FINALIZADORAS = 'finalizadoras',
    CATEGORIAS = 'categorias',
    NCMS = 'ncms',
    IMPOSTOS = 'impostos',
    MESAS = 'mesas',
    SALOES = 'saloes',
    COMANDAS = 'comandas',
    NUMERACAO = 'numeracao',
    VENDAS = 'vendas',
    SESSAO = 'sessao',
    SESSAO_VALORES = 'sessaoValores',
    MODIFICADORES = 'modificadores',
    MEDICAMENTOS = 'medicamentos',
    CONFIG_EMPRESA = 'configEmpresa',
    CONFIG_CAIXA = 'configCaixa',
    MARCAS = 'marcas',
    PROMOCOES = 'promocoes',
    ENTIDADES_PROMOCOES = 'entidadesPromocoes',
    REGRAS_PROMOCOES = 'regrasPromocoes',
}


export class TouchoneCargaDatabase extends TouchoneDatabase {

    configEmpresa!: Table<TabelaEmpresaConfiguracao>;
    configCaixa!: Table<TabelaCaixaConfiguracao>;
    marcas!: Table<TabelaMarcaModel>;
    entidadesPromocoes!: Table<TabelaEntidadesPromocoes>;
    regrasPromocoes!: Table<TabelaRegrasPromocoes>;
    promocoes!: Table<TabelaPromocoesDePor & DisabledTable>;

    constructor(dbName: string) {
        super(dbName);
        this.version(4).stores({
            produtos: `++idIndexed, ativo, balanca, categoriaId, codigo, empresaId, grupoImpostoId, imagemUrl, medida, medidaDesc, ncm, ncmId, nome, produtoGradeId, produtoId, vPreco, favorito, codigos, setorId, subItens, prodSubItem,cobraTaxaServico, numCodigos, tipo, codigoAnvisa, medicamento`,
            clientes: `++idIndexed, id, contratoId, regimeTributarioId, tabelaPrecoId, representanteId, tpCadastro, cpfcnpj, ierg, im, indIEDest, nome, fantasia, infOperador, infInterno, status, contatos, dtNasc, documentos, usuarioId, enderecos`,
            finalizadoras: `++idIndexed, id, empresaId, descricao, resumido, tpTransacao, tpMod, tpPagamento, vDesc, vAcresc, pDesc, pAcresc, vMinParc, qMaxParc, vencimento, qDias, ativo, cnpjCred, credenciais, credenciado, dataCredenciamento, ordem`,
            categorias: `++idIndexed, id, contratoId, categoriaPaiId, nome, descricao, cor, ativo, breadCrumbs, temProdutos, foto, tipoExibicao`,
            ncms: `++idIndexed, id, contratoId, ncmId, codigo, cest, descricao, pTribManual, ativo, pTribMunicipal, pTribFederal, pTribEstadual`,
            impostos: `++idIndexed, id, contratoId, empresaId, regimeTributarioId, descricao, cfop, cBenef, icmsCst, icmsModBc, icmsPRedBc, icmsPIcms, icmsModBcSt, icmsPMvaVUnidSt, icmsPredBcSt, icmsPIcmsSt, icmsMotDesIcms, icmsPBcOp, icmsUfSt, icmsPCredSn, icmsPredSn, icmsPFcp, icmsPFcpSt, pisCst, pisPercVUnidPis, cofinsCst, cofinsPercVUnidCofins, ecfAliq, ativo`,
            mesas: `++idIndexed, id, salaoId, codigo, status`,
            saloes: `++idIndexed, id, descricao, balcao, status`,
            comandas: `++idIndexed, id, codigoComanda, rfId, status`,
            numeracao: `++idIndexed, mod, serie, numero, empresaId, tpAmb, data`,
            vendas: `++idIndexed, id, empresaId, operadorId, sessaoId, status, tpAmb, dEmi, serie, nnf, mod, pessoaId, documento, regimeTributarioId, produtos, pags, cliente, dataSincronizacao, vNF, tpEmis, telefone, mesaCodigo, codigoComanda, vDesc, vAcresc, receitasMedicas, documentoNaNota, cobr`,
            sessao: `++idIndexed, id, dataAbertura, dataFechamento, operadorId, aberta, empresaId, caixaId`,
            sessaoValores: `++idIndexed, id, sessaoId, pagamento, valor, responsavel, empresaId, tpLancamento, observacao, operador, dataInsercao, portador, dataSincronizacao, caixaId`,
            modificadores: `++idIndexed, id, contratoId, empresaId, nome, descricao, tpCalculo, produtos`,
            medicamentos: `++idIndexed, id, codigoEan, codigoAnvisa, nome, qtdEmbalagem, composicao, tipoMedicamento, tarja, status, precos`,
            configEmpresa: `++idIndexed, cod, descConfig, descricao, empresaId, grupoConfig, opcoes, padrao, titulo, tpControle, vConfig`,
            configCaixa: `++idIndexed, caixaId, cod, contratoId, descConfig, editado, empresaId, grupoConfig, id, itemDrop, ordem, tpControle, tpTxt, vConfig, vMax, vMin`,
            marcas: `++idIndexed, nome, cnpjFab, indEscala, contratoId, refId, id`,
        });
        this.version(3).stores({
            promocoes: `++idIndexed,promocaoId,promocao,dataInicial,dataFinal,horaInicial,horaFinal,diasSemana,valorPromocao,variacaoId,codigo,id,disabledTable`,
            entidadesPromocoes: `++idIndexed,nome,dataInicial,dataFinal,horarioInicial,horarioFinal,diasSemana,ativo,contratoId,id`,
            regrasPromocoes: `++idIndexed,id,promocaoId,tipoRegra,valorMinimoCupom,limiteOcorrencia,ativo,contratoId`
        });
    }

    limparDados = async () => {
        await this.produtos.clear();
        await this.promocoes.clear();
        await this.clientes.clear();
        await this.ncms.clear();
        await this.finalizadoras.clear();
        await this.impostos.clear();
        await this.categorias.clear();
        await this.mesas.clear();
        await this.saloes.clear();
        await this.comandas.clear();
        await this.modificadores.clear();
        await this.medicamentos.clear();

        await this.configCaixa.clear();
        await this.configEmpresa.clear();
        await this.marcas.clear();
        await this.entidadesPromocoes.clear();
        await this.regrasPromocoes.clear();
    }

    isEmpty = async () => {
        let finalCount = 0;
        finalCount += await this.produtos.count();
        finalCount += await this.promocoes.count();
        finalCount += await this.clientes.count();
        finalCount += await this.ncms.count();
        finalCount += await this.finalizadoras.count();
        finalCount += await this.impostos.count();
        finalCount += await this.categorias.count();
        finalCount += await this.mesas.count();
        finalCount += await this.saloes.count();
        finalCount += await this.comandas.count();
        finalCount += await this.modificadores.count();
        finalCount += await this.medicamentos.count();

        finalCount += await this.configCaixa.count();
        finalCount += await this.configEmpresa.count();
        finalCount += await this.marcas.count();
        finalCount += await this.entidadesPromocoes.count();
        finalCount += await this.regrasPromocoes.count();

        return finalCount === 0;
    }

    copiarBanco = async (
        targetDB: TouchoneCargaDatabase,
    ) => {
        await targetDB.limparDados();
        
        await targetDB.clientes.bulkAdd(
            await this.clientes.toArray()
        );
        await targetDB.produtos.bulkAdd(
            await this.produtos.toArray()
        );
        await targetDB.ncms.bulkAdd(
            await this.ncms.toArray()
        );
        await targetDB.finalizadoras.bulkAdd(
            await this.finalizadoras.toArray()
        );
        await targetDB.impostos.bulkAdd(
            await this.impostos.toArray()
        );
        await targetDB.categorias.bulkAdd(
            await this.categorias.toArray()
        );
        await targetDB.mesas.bulkAdd(
            await this.mesas.toArray()
        );
        await targetDB.saloes.bulkAdd(
            await this.saloes.toArray()
        );
        await targetDB.comandas.bulkAdd(
            await this.comandas.toArray()
        );
        await targetDB.modificadores.bulkAdd(
            await this.modificadores.toArray()
        );
        await targetDB.medicamentos.bulkAdd(
            await this.medicamentos.toArray()
        );
        await targetDB.promocoes.bulkAdd(
            await this.promocoes.toArray()
        );
        await targetDB.marcas.bulkAdd(
            await this.marcas.toArray()
        );
        await targetDB.marcas.bulkAdd(
            await this.marcas.toArray()
        );
        await targetDB.configCaixa.bulkAdd(
            await this.configCaixa.toArray()
        );
        await targetDB.configEmpresa.bulkAdd(
            await this.configEmpresa.toArray()
        );
        await targetDB.entidadesPromocoes.bulkAdd(
            await this.entidadesPromocoes.toArray()
        );
        await targetDB.regrasPromocoes.bulkAdd(
            await this.regrasPromocoes.toArray()
        );
    }
}

export const TouchoneDBCargaPrimary = new TouchoneCargaDatabase('touchoneCargav1.11');
export const TouchoneDBCargaSinc = new TouchoneCargaDatabase('touchoneCarga-tempv1.11');