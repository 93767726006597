import { makeStyles } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
  grid: {
    flex: 1
  },
  statusContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardContainer: {
    padding: theme.spacing(1),
  },
  cardSelected: {
    borderColor: theme.palette.primary.main,
  },
  celulaGridTipoVenda: {
    marginRight: theme.spacing(0.5),
    background: theme.palette.primary.main,
  },
  celulaGridStatusVenda: {
    display: 'nowrap',
  },
  textHidden:{
    display:'flex',
    flexWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardColor: {
    width: "25px",
    height: "25px",
    borderRadius: "5px",
    borderColor: theme.palette.grey[700],
    borderStyle: "solid",
    marginBottom: 10,
    margin: 0,
    boxShadow: theme.shadows[6],
  },

  cardContent: {
    flex: "1 1 100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    "& div:last-child": {
      marginRight: theme.spacing(1),
    },
    "& span": {
      fontSize: "0.7em",
    },
    "& p": {
      fontSize: "1.0em",
    },
  },
  celulaGridNomeCliente: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  celulaGridNome: {
    maxHeight: 200,
    "& p": {
      maxHeight: 200,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGrid: {
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGridFull: {
    marginLeft: theme.spacing(2),
    flex: "1 1 auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "& p": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  celulaGridStatus: {
    display: "flex",
    padding: theme.spacing(0.5),
    borderRadius: 4,
    justifyContent: "center",
    textOverflow: "ellipsis"
  }
}));
