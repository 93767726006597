import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        minHeight: '100%',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            padding: 0
        }
    },
    containerPai: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    cardContainer: {
        transition: "0.3s ease-in-out border",
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(8)
        }
    },
    cardContent: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    submitButton: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            boxShadow: theme.shadows[8],
            position: "fixed",
            width: '95%',
            right: theme.spacing(1),
            left: theme.spacing(1),
            bottom: theme.spacing(12),
            zIndex: 2,
        }
    },
    textWarning: {
        color: theme.palette.warning.main
    },
    iconAviso: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            fill: theme.palette.warning.main,
            width: 100
        }
    },
    mapContainer: {
        height: '100%',
        width: '100%',
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
            height: '200px',
            borderRadius: theme.shape.borderRadius,
        }
    },
    gridFull: {
        width: '100%'
    },
    gridFullP: {
        width: '100%',
        padding: theme.spacing(2),
    },
    content: {
        width: '100%'
    },
    enderecoEntregaMobile: {
        width: "100%",
        border: `dashed 1px ${theme.palette.text.secondary}`,
        borderRadius: theme.shape.borderRadius
    },
    boxCustom: {
        width: "100%",
        height: "100%"
    }
}));