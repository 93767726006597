import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    containerScroll: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        justifyContent: 'center',
        display: 'flex',
        padding: theme.spacing(1)
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    cardImg: {
        display: "flex",
        objectFit: "contain",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: 120,
        height: 120,
        borderRadius: "5px",
        transition: "0.2s ease-in-out transform",
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 100
        }
    },
    infoProd: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    textInfo: {
        fontSize: '1.5rem',
        width: '100%',
        fontWeight: 600,
        lineHeight: '32px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    containerButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonChanged: {
        width: 48,
        height: 48,
        padding: '0 !important',
        borderRadius: '50%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        "& svg": {
            width: 48,
            height: 48,
        }
    },
    buttonMaisMenos: {
        fill: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        width: 48,
        height: 48,
    },
    textPerson: {
        fontSize: '1.5rem',
        padding: 0,
        lineHeight: '10px',
        fontWeight: 700,
        textAlignLast: 'center',
        width: '100px',
        height: '61px',
        '& input': {
            textAlign: 'center'
        }
    },
    buttonQuantidade: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonAdicionar: {
        width: '100%',
        flex: 'none',
        padding: theme.spacing(3, 2),
        paddingBottom: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    divider: {
        background: theme.palette.grey[400],
    },
    infoContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        gap: theme.spacing(1),
        width: '100%',
    },
    infoTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        '& h5': {
            fontSize: '1.2rem',
            fontWeight: 600,
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(1),
        }
    },
    infoLine: {
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap'
    },
    valorUnitario: {
        paddingTop: theme.spacing(1),
    },
    qtdContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
    },
    imageContainer: {
        width: 140,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 120
        }
    },
    prodTitle: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));