import { useEffect, useState, useCallback, useRef } from 'react';
import { Grid } from 'views/design-system';
import { useStyles } from './selecao-vendedor-styles';
import { VendedorListData } from './vendedor-list-data';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { DialogSelecionarVendedor } from 'views/components/dialog/dialog-selecionar-vendedor/dialog-selecionar-vendedor';
import { useToastSaurus } from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumCadastroTipo } from 'model';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useKeyNavigation } from 'services/app/hooks/key-navigation';
import { isEmpty } from 'lodash';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { EnumTableNames } from 'database/touchone-carga-database';
import { TabelaClientes } from 'database/interfaces/interface-tabela-clientes';

export const SelecaoVendedorList = () => {
  const classes = useStyles();
  const { setVendedor } = useMovAtual();
  const { showToast } = useToastSaurus();
  const [queryStatus, setQueryStatus] = useState<Array<PessoaModel>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { redirectLancadorPadrao } = useMovRota();
  const { get } = useBancoLocal();

  const selectedIndex = useRef<number>(-1);

  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [vendedorTela, setVendedorTela] = useState<PessoaModel | undefined>();

  const handleClose = () => {
    setVendedorTela(undefined);
    setShowModal(false);
  };

  const search = useCallback(async () => {
    try {
      const res = await get<TabelaClientes>({
        nomeTabela: EnumTableNames.CLIENTES,
        filterFn: (vendedor) => {
          return vendedor.status === 0 && [EnumCadastroTipo.FUNCIONARIO, EnumCadastroTipo.REPRESENTANTE].includes(vendedor.tpCadastro);
        }
      })
      setQueryStatus(res as PessoaModel[]);
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setLoading(false)
    }
  }, [get, showToast]);

  const timer = useRef({} as NodeJS.Timeout);
  const ultimoTermoPesquisado = useRef('');
  const termoPesquisar = useVendaHeaderStore(state => state.props.search) || '';

  const queryStatusFiltered = queryStatus.filter((item) => {
    if (isEmpty(termoPesquisar)) {
      return item;
    }
    return item.nome.toLowerCase().includes(termoPesquisar.toLowerCase())
  },
  );

  useEffect(() => {
    (async () => {
      clearTimeout(timer.current);
      setLoading(true)
      if (
        ultimoTermoPesquisado.current !== termoPesquisar ||
        termoPesquisar.length === 0
      ) {
        timer.current = setTimeout(async () => {
          await search();
        }, 500);
      }
      return () => {
        clearTimeout(timer.current);
      };
    })();
  }, [search, termoPesquisar]);

  const substituirTodosProdutos = async () => {
    try {
      if (vendedorTela) {
        await setVendedor(vendedorTela, true);
      }
      await redirectLancadorPadrao();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const substituirProximosProdutos = async () => {
    try {
      if (vendedorTela) {
        await setVendedor(vendedorTela, false);
      }
      await redirectLancadorPadrao();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const onCardSelected = async (model: PessoaModel) => {
    setVendedorTela(model);
    setShowModal(true);
  };

  const onCardChecked = (id: string) => {
    const aux = [...selectedList];
    aux.push(id);
    setSelectedList(aux);
  };

  useKeyNavigation(
    queryStatusFiltered.length,
    selectedIndex,
    (index) => {
      if (queryStatusFiltered[index])
        onCardSelected(queryStatusFiltered[index]);
    }
  )

  return (
    <>
      <div className={classes.defaultContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.listContainer}>
            <VendedorListData
              carregando={loading}
              list={
                queryStatusFiltered
              }
              selectedList={selectedList}
              onCardSelected={onCardSelected}
              onCardChecked={onCardChecked}
            />
            {showModal && (
              <DialogSelecionarVendedor
                openned={showModal}
                loading={false}
                nomeVendedor={vendedorTela?.nome || ''}
                closeModal={handleClose}
                substituirTodosProdutos={substituirTodosProdutos}
                substituirProximosProdutos={substituirProximosProdutos}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
