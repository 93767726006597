import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    downloadButton:{
        margin: '4px',
        height:'100%',
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius
    }
}))

