import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { VendaCompletaModel } from "model/api/gestao/venda/venda-completa-model";
import { createQueryString } from "utils/create-query-string";

export function usePutFinalizarVenda() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putFinalizarVenda = useCallback(
        (id: string, timeout: number = 30000, finalizarComPendencia?: boolean) => {
            const query = createQueryString({
                finalizarComPendencia: finalizarComPendencia
            })
            return invocarApi<VendaCompletaModel | undefined>({
                url: `/api/v3/Movimento/${id}/finalizar${query ? '?' + query : ''}`,
                method: "PUT",
                enviarTokenUsuario: true,
                timeout: timeout,
                baseURL: VariaveisAmbiente.apiUrlMovs,
            })
        }
        , [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putFinalizarVenda,
    };
}
