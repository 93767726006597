import { Grid } from 'views/design-system';
import { useDefaultCadastroStyles } from 'views/pages/private/cadastros/components/default-cadastro-styles';
import { SelecionarVendedorList } from './components/selecionar-vendedor-produto-list/selecionar-vendedor-produto-list';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';

interface LocationProps {
  pedido: PedidoModel;
}

export const MovAlterarVendedorProdutoPedidoPage = () => {
  const location = useLocation<LocationProps>();
  const classes = useDefaultCadastroStyles();
  const history = useHistory()

  useEffect(() => {
    if (isEmpty(location?.state?.pedido)) {
      history.push(MovRotasMock.pedidosRota)
    }
  }, [history, location?.state?.pedido])

  const {
    getConfiguracoesMesaEComanda,
  } = usePedidoLocal();

  const isTipoTrabalhoComanda =
    getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.COMANDA;
  const pedido = location.state?.pedido

  const handleClickVoltar = useCallback(() => {
    const search = history.location.search ? history.location.search : pedido?.comandaId ? '?modo=comanda' : pedido.mesaId ? '?modo=mesa' : '?modo=balcao';
    if (isTipoTrabalhoComanda && pedido.comandaId) {
      history.push({
        pathname: `${MovRotasMock.visualizarPedidoRota}/${pedido?.comandaId}`,
        search
      })
      return;
    }
    let id = pedido?.id;
    if (search === '?modo=comanda' && pedido?.comandaId) {
      id = pedido.comandaId
    }
    if (search === '?modo=mesa' && pedido?.mesaId) {
      id = pedido.mesaId
    }
    history.push({
      pathname: `/venda-simples/visualizar-pedido/${id}`,
      search: search,
      state: {
        pedido: pedido
      }
    });
  }, [history, isTipoTrabalhoComanda, pedido]);

  const setVendaHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setVendaHeaderProps({
      title: 'Selecionar Vendedor',
      pesquisa: {
        allowLetras: true,
        capturarDigitacao: true,
      },
      voltar: {
        onClick() {
          handleClickVoltar();
        },
      }
    })
  }, [handleClickVoltar, setVendaHeaderProps])

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.root}>
          <Grid className={classes.list}>
            <SelecionarVendedorList pedido={location?.state?.pedido} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
