import { Grid } from 'views/design-system';
import { useDefaultCadastroStyles } from '../cadastros/components/default-cadastro-styles';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useEffect, useMemo } from 'react';
import { HistoricoVendasPendentesHeader } from './components/historico-vendas-pendentes-header/historico-vendas-pendentes-header';
import { HistoricoVendasPendentesList } from './components/historico-vendas-pendentes-list/historico-vendas-pendentes-list';

export const HistoricoVendasPendentesPage = () => {
  const classes = useDefaultCadastroStyles();
  const setHideHeader = useVendaHeaderStore(state => state.setHideHeader);

  useEffect(() => {
    setHideHeader(true);
  }, [setHideHeader])

  const component = useMemo(() => <>
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <HistoricoVendasPendentesHeader />
      </Grid>
      <Grid className={classes.list}>
        <HistoricoVendasPendentesList />
      </Grid>
    </Grid>
  </>, [classes.header, classes.list, classes.root]);
  return component;
};