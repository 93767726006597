import { CardDashboardProps } from './card-dashboard-props';
import { useStyles } from './card-dashboard-styles';
import classNames from 'classnames';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Typography } from 'views/design-system';
import { DefaultCard } from '../../components';
export const CardDashboard = ({
  conteudo,
  icone,
  titulo,
  tipo,
  isDivider,
}: CardDashboardProps) => {
  const utilClasses = useStyles();

  return (
    <Box>
      <DefaultCard hasTagStatus={false} className={classNames(utilClasses.cardContainer)}>
        <Box className={utilClasses.cardContent}>
          <Box className={utilClasses.cardTitle}>
            <Box>
              <Typography className={'celula-grid-value'} color="textPrimary">
                {icone}
              </Typography>
            </Box>
            <Box>
              <Typography
                color="textPrimary"
                variant="subtitle1"
                weight={500}
              >
                {titulo}
              </Typography>
            </Box>
          </Box>

          <Box>
            {isDivider && (
              <Divider className={utilClasses.divider} />
            )}
          </Box>

          <Box mt={1} flex flexDirection='row-reverse'>
            {tipo === 'dinheiro' ? (
              <>
                <Box ml={1}>
                  <Typography weight={600} variant="h6">
                    {toDecimalString(conteudo, 2)}
                  </Typography>
                </Box>
                <span>R$</span>
              </>
            ) : (
              <Typography weight={600} variant="h6">
                {conteudo}
              </Typography>
            )}
          </Box>
        </Box>
      </DefaultCard>
    </Box>
  );
};
