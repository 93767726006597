import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function useGetCargaDetalhes() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();
  const getCargaDetalhes = useCallback(
    (id: string) =>
      invocarApi({
        url: `/v3/conversao/${id}/detalhe`,
        method: "GET",
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlConversoes}/api`,
      }),
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    getCargaDetalhes,
  };
}
