import { Button, Grid, Typography } from 'views/design-system';
import { useStyles } from './mov-erro-finalizar-page-styles';
import { CircularLoading, useThemeQueries } from 'views';
import { ErroFinalizarVendaIcon } from 'views/components/icons/erro-finalizar-venda-icon';
import { usePutAlterarNumeracao } from 'data/api/gestao/ultima-numerao/put-alterar-numeracao';
import { useToastSaurus } from 'services/app';
import { AlterarNumeracaoModel } from 'model/api/gestao/alterar-numeracao/alterar-numeracao-model';
import { DialogAlterarNumeracao } from 'views/components/dialog/dialog-alterar-numeracao/dialog-alterar-numeracao';
import { ChangeEvent, useEffect, useState } from 'react';
import { toDecimalString } from 'utils/to-decimal';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import classNames from 'classnames';
import { AtualizarIcon, EditarIcon, HistoricoIcon, LixoIcon } from 'views/components/icons';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { EnumMovModelo } from 'model';

const MovErroFinalizarPage = () => {
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const {
    carregando: getStatusErroFinalizarCarregando,
    getMov,
    alterarNNF,
    handleFinalizarComoPendencia
  } = useMovAtual();
  const { avancarFluxoMov, redirectCancelamento } = useMovRota();
  const { putAlterarNumeracao, carregando: putAlterarNumeracaoCarregando } =
    usePutAlterarNumeracao();
  const { showToast } = useToastSaurus();
  const mov = getMov();

  const [modal, setModal] = useState<boolean>();
  const [numero, setNumero] = useState<number>(mov?.nnf || 0);

  const carregando =
    getStatusErroFinalizarCarregando || putAlterarNumeracaoCarregando;

  const duplicidade: boolean =
    mov?.retornoFinalizacao?.error !== undefined &&
    mov?.retornoFinalizacao.adicional === 424;
  const loteProcessamento: boolean =
    mov?.retornoFinalizacao?.error !== undefined &&
    mov?.retornoFinalizacao.adicional === 423;

  const atualizarNumeracao = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    try {
      const res = await putAlterarNumeracao(
        new AlterarNumeracaoModel(mov?.serie, numero),
        mov?.id || ''
      );

      if (res.erro) {
        throw res.erro;
      }

      await alterarNNF(numero ?? 0);
      await avancarFluxoMov();
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const finalizarComoPendencia = async () => {
    try {
      await handleFinalizarComoPendencia()
      await avancarFluxoMov()
    } catch (err: any) {
      showToast('error', err.message)
    }
  }

  const setHeaderProps = useVendaHeaderStore(state => state.setHeaderProps);
  useEffect(() => {
    setHeaderProps({
      title: 'Erro de Finalização',
      showMenuPdv: true,
    })
  }, [setHeaderProps])

  const finalizarComPendencia = mov?.mod === EnumMovModelo.NFCE && navigator.onLine

  return (
    <>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Grid
        className={`${classes.container} ${classes.containerBackgroundColor}`}
      >
        <Grid
          className={`${classes.containerErroFinalizar} ${!isMobile ? classes.alignCenter : ''}`}
        >
          <Grid className={classes.content}>
            <Grid className={classes.infoContainer}>
              <Typography className={classes.textTitle}>
                {!duplicidade
                  ? 'Ops, achamos um problema!'
                  : 'Duplicidade de Numeração'}
              </Typography>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    maxWidth: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <ErroFinalizarVendaIcon
                    tipo="GERAL"
                    class={classes.iconImg}
                  />
                </Grid>
              </Grid>
              <Grid className={!isMobile ? classes.cardInfo : ''}>
                <Grid container justifyContent="space-around">
                  <Typography className={classes.label}>
                    Número / Série
                  </Typography>
                  <Typography className={classes.label}>Valor</Typography>
                </Grid>
                <Grid
                  container
                  mb={3}
                  justifyContent="space-around"
                >
                  <Typography className={classes.infoNomeEQuantidade}>
                    {mov?.nnf + ' / ' + mov?.serie}
                  </Typography>
                  <Typography className={classes.infoNomeEQuantidade}>
                    R$ {toDecimalString(mov?.vNF)}
                  </Typography>
                </Grid>
                <Typography className={classes.textSubtitle}>
                  {!duplicidade
                    ? 'Ocorreu um erro ao finalizar a venda.'
                    : 'Identificamos uma duplicidade de numeração em sua venda. Isso pode acontecer por ter realizado uma venda com a mesmo numeração em outro sistema ou por algum eventual problema na SEFAZ.'}
                </Typography>
                <Typography
                  className={`${classes.textSubtitle} ${!isMobile ? classes.maxDetails : ''
                    }`}
                >
                  Detalhe:{' '}
                  {mov?.retornoFinalizacao?.error
                    ? mov?.retornoFinalizacao?.error
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.containerButton}>
          <Grid container spacing={2}>
            <Grid item xs={duplicidade ? 12 : finalizarComPendencia ? 6 : 12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                className={classNames(classes.button, 'round')}
                onClick={
                  !duplicidade
                    ? async () => {
                      try {
                        await avancarFluxoMov()
                      } catch (e: any) {
                        showToast('error', e.message);
                      }
                    }
                    : () => setModal(!modal)
                }
              >
                {!duplicidade ? (
                  <AtualizarIcon tipo="BUTTON_PRIMARY" />
                ) : (
                  <EditarIcon tipo="BUTTON_PRIMARY" />
                )}
                {!duplicidade ? 'Tentar novamente' : 'Mudar Numeração'}
              </Button>
            </Grid>
            {!duplicidade && finalizarComPendencia && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  className={classNames(classes.button, 'round')}
                  onClick={finalizarComoPendencia}
                >
                  <HistoricoIcon tipo="BUTTON_PRIMARY" />
                  Finalizar com Pendência
                </Button>
              </Grid>
            )}
            {!loteProcessamento && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  className={classNames(classes.button, 'round')}
                  onClick={async () => {
                    try {
                      await redirectCancelamento()
                    } catch (e: any) {
                      showToast('error', e.message);
                    }
                  }}
                >
                  <LixoIcon tipo="BUTTON" />
                  Descartar venda
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid >
      <DialogAlterarNumeracao
        closeModal={() => setModal(!modal)}
        loading={carregando}
        enviarNumero={atualizarNumeracao}
        numero={numero || 0}
        setNumero={setNumero}
        openned={modal || false}
      />
    </>
  );
};

export default MovErroFinalizarPage;
