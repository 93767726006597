import { useTheme } from '@material-ui/core';
import { CardDashboardChartProdutosProps } from '../card-dashboard-chart-props';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { VariaveisAmbiente } from 'config';
import { toDecimalString } from 'utils/to-decimal';
import { Box, Divider, Grid, Typography } from 'views/design-system';
import { DefaultCard } from 'views/components/cards/components';

export const CardDashboardChartProdutos = ({
  model,
  carregando
}: CardDashboardChartProdutosProps) => {
  const theme = useTheme();

  let modUnicos = new Map(model.map((obj) => [obj.produto, obj]));

  const modsUnicosList = Array.from(modUnicos.values());

  let produtosSerelizados = modsUnicosList.map((item) => {
    return {
      ...item,
      vVenda: 0,
      qCom: 0,
    };
  });

  produtosSerelizados = produtosSerelizados.map((item) => {
    model.map((i) => {
      if (i.produto === item.produto) {
        let novaQuantidade = (item.vVenda += i.vVenda);
        let novaQTDE = (item.qCom += i.qCom);
        return {
          ...item,
          vVenda: novaQuantidade,
          qCom: novaQTDE,
        };
      }
      return i;
    });
    return item;
  });

  const totalVendas = model.reduce((a, b) => a + b.vVenda, 0);

  return (
    <>
      <DefaultCard hasTagStatus={false}>
        <Grid container >
          <Grid item xs={12} p={1}>
            <Typography
              color="primary"
              weight={500}
            >
              Top 10 Produtos
            </Typography>
            <Divider variant='fullWidth' />
          </Grid>

          <Grid item flex flexDirection='column' xs={12} my={2}>
            {model.length === 0 ? (
              <Grid item xs={12} flex flexDirection='column' my={2}>
                <Typography variant="body1" align='center'>
                  Não há dados para exibir
                </Typography>
              </Grid>
            ) : (
              <>
                {produtosSerelizados
                  .sort((a, b) => b.vVenda - a.vVenda)
                  .filter((item, index) => index < 10)
                  .map((item, index) => {
                    let dinheiro = item.vVenda;
                    let vendas = item.qCom;

                    const porcentagem = (
                      (dinheiro * 100) /
                      parseInt(totalVendas.toString())
                    ).toFixed(2);

                    const options = {
                      chart: {
                        sparkline: {
                          enabled: true,
                          offsetY: -20,
                        },
                      },
                      plotOptions: {
                        radialBar: {
                          hollow: {
                            size: '65%',
                          },
                          dataLabels: {
                            name: {
                              show: true,
                              offsetY: 5,
                              fontSize: '14px',
                              fontWeight: 700,
                              color: '#666',
                              formatter: function (val: any) {
                                return `R$ ${val}`;
                              },
                            },
                            value: {
                              show: false,
                            },
                          },
                        },
                      },
                      labels: [String(toDecimalString(dinheiro, 2))],
                      colors: [
                        '#' +
                        ((Math.random() * 0xffffff) << 0)
                          .toString(16)
                          .padStart(6, '0'),
                      ],
                    };

                    return (
                        <DefaultCard
                          hasTagStatus={false}
                          key={index}
                          className={classNames('card')}
                          style={{
                            marginLeft: theme.spacing(1),
                            marginRight: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            position: 'relative',
                            overflow: 'hidden',
                          }}
                        >
                          <Grid container px={2} flex justifyContent='space-between' alignItems='center'>
                            <Grid item xs={8}>
                              <Typography
                                weight={600}>
                                {index + 1}°{' '}
                                {item.produto !== null && item.produto !== ''
                                  ? item.produto
                                  : 'Sem nome'}
                              </Typography>
                              <Typography variant="body2">
                                {vendas > 1
                                  ? `${vendas} Produtos vendidos`
                                  : `${vendas} Produto vendido`}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {VariaveisAmbiente.paymentDevice === undefined ? (
                                <Box flex flexDirection='column' px={1} >
                                  <Typography
                                    weight={600}
                                    align='right'
                                  >
                                    R$ {String(toDecimalString(dinheiro, 2))}
                                  </Typography>
                                  <Typography
                                    weight={500}
                                    align='right'
                                  >
                                    {porcentagem}%
                                  </Typography>
                                </Box>
                              ) : (
                                <Chart
                                  options={options}
                                  series={[Number(porcentagem)]}
                                  type="radialBar"
                                  height={130}
                                  width={130}
                                />
                              )}
                            </Grid>
                          </Grid>
                          {VariaveisAmbiente.paymentDevice === undefined ? (
                            <div
                              style={{
                                width: `${porcentagem}%`,
                                backgroundColor: `#${(
                                  (Math.random() * 0xffffff) <<
                                  0
                                )
                                  .toString(16)
                                  .padStart(6, '0')}`,
                                height: 5,
                                zIndex: '-1000',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </DefaultCard>
                    );
                  })}
              </>
            )}
          </Grid>
        </Grid >
      </DefaultCard >
    </>
  );
};
