import { Box, Grid, Typography } from 'views/design-system';
import { useCallback } from 'react';
import { useThemeQueries } from 'views';
import { StatusHistoricoVendaMock } from 'data/mocks/status-historico-venda-mock';
import { TpHistoricoVendaMock } from 'data/mocks/tp-historico-venda-mock';
import { toDecimalString } from 'utils/to-decimal';
import { toDateString } from 'utils/to-date';
import { useStyles } from './card-pendencias-fiscais-mobile-styles';
import { CardPendenciasFiscaisProps } from '../card-pendencias-fiscais-props';
import { EnumTpStatusMov } from 'model/enums/enum-tp-status-mov';
import { EnumMovModelo } from 'model';
import { DefaultCard } from '../../components';
import classNames from 'classnames';

export const CardPendenciasFiscaisMobile = ({
    model,
    onClick,
    onCheck,
    selected,
    consumidorPadrao
}: CardPendenciasFiscaisProps) => {
    const classes = useStyles();
    const { theme } = useThemeQueries();

    const status = model.mod === EnumMovModelo.NFCE ? (model.statusFiscal ?? model.status) : model.status

    const retornarCorStatus = useCallback((): string => {
        switch (status) {
            case EnumTpStatusMov.Pendente:
                return theme.palette.warning.main;
            case EnumTpStatusMov.Finalizado:
                return theme.palette.success.main;
            case EnumTpStatusMov.Cancelado:
            case EnumTpStatusMov.Abortado:
            case EnumTpStatusMov.Rejeitado:
                return theme.palette.error.main;
            case EnumTpStatusMov.EmDigitacao:
                return theme.palette.warning.dark;
            default:
                return theme.palette.primary.main;
        }
    }, [
        theme.palette.success.main,
        theme.palette.warning.main,
        theme.palette.primary.main,
        status,
        theme.palette.error.main,
        theme.palette.warning.dark,
    ]);


    const mod = TpHistoricoVendaMock.filter((x) => x.Key === model.mod)[0]?.Value;

    const statusMock = StatusHistoricoVendaMock.filter(
        (x) => x.Key === status,
    )[0]?.Value;

    const isConsumidorPadrao = (model.destDoc) === consumidorPadrao.cpfcnpj

    return (
        <div
            onClick={() =>
                onClick(model.id)
            }
        >
            <DefaultCard className={classes.cardContainer}>
                <Box flex flexDirection='column' className={classes.grid}>
                    <Grid container flex justifyContent='space-between' alignItems='center' className={classes.grid}>
                        <Grid flexDirection='column' item xs={7} className={classNames(classes.celulaGridNome, classes.textHidden)}>
                            <Typography color="textPrimary" variant="caption">
                                Dados do cliente
                            </Typography>

                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                <strong>{isConsumidorPadrao ? consumidorPadrao.nome : model?.destNome}</strong>
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Box flex flexDirection='row' m={0} justifyContent='center' alignItems='center' className={classes.statusContainer}>

                                <Box
                                    className={classNames(classes.celulaGridStatus, classes.celulaGridTipoVenda)}
                                >
                                    <Typography
                                        className={'celula-grid-value'}
                                        variant="body2"
                                        color='commomWhite'
                                    >
                                        {mod}
                                    </Typography>
                                </Box>

                                <Box
                                    className={classNames(classes.celulaGridStatus, classes.celulaGridStatusVenda)}
                                    style={{
                                        background: retornarCorStatus(),
                                    }}
                                >
                                    <Typography
                                        className={'celula-grid-value'}
                                        color="commomWhite"
                                        variant="body2"

                                    >
                                        {statusMock}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container flex justifyContent='space-between'>
                        <Grid item className={classes.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Itens:
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.totalQtCom}
                            </Typography>
                        </Grid>

                        <Grid item className={classes.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Data:
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                {
                                    toDateString(model.dhEmi, 'DD/MM/yyyy HH:mm')
                                }
                            </Typography>
                        </Grid>

                        <Grid item className={classes.celulaGrid}>
                            <Typography color="textPrimary" variant="caption">
                                Número:
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                {model.nnf === 0 || !model.nnf ? `- /${model.serie}` : `${model.nnf}/${model.serie}`}
                            </Typography>
                        </Grid>

                        <Grid item className={classes.celulaGrid}>
                        <Typography color="textPrimary" variant="caption">
                                Valor total:
                            </Typography>
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                            >
                                <strong>R$ {toDecimalString(model.totalVNf, 2)}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DefaultCard>
        </div >
    );
};
