import { useMemo } from 'react';
import { toDecimal } from 'utils/to-decimal';
import * as Yup from 'yup';

interface Props{
  temCodigo: boolean
}

export const useFormEntradaCadastroValidation = ({temCodigo}: Props) => {

  const valorObrigatorio = (descricao: string) => {
    return Yup.string()
      .required(descricao)
      .typeError(descricao)
      .test({
        message: descricao,
        test: (value) => {
          const valor = toDecimal(value)
          if (valor <= 0) {
            return false
          }
          return true
        }
      })
  }

  const formProdutoEntradaValidationYup = useMemo(() => {
    return (
      Yup.object().shape({
        xProd: Yup.string().required('Descrição é obrigatório'),
        cEan: temCodigo ? Yup.string().required('Código é obrigatório') : Yup.string().notRequired().nullable(true),
        qCom: valorObrigatorio('Quantidade é obrigatório'),
        vUnCom: valorObrigatorio('Valor Unitário é obrigatório'),
        vProd: valorObrigatorio('Valor Total é obrigatório'),
        depositoId: Yup.string().required('Depósito é obrigatório').typeError('Depósito é obrigatório'),
        depositoNome: Yup.string().required('Depósito é obrigatório').typeError('Depósito é obrigatório'),
        rastro: Yup.array().of(
          Yup.object().shape({
            nLote: Yup.string().required('Lote é obrigatório'),
            qLote: Yup.string().required('Quantidade é obrigatório').typeError('Quantidade é obrigatório'),
            dFab: Yup.string().required('Data de Fabricação é obrigatório'),
            dVal: Yup.string().required('Data de Validade é obrigatório')
          })
        )
          .test('qLote-total', 'A soma do lote não pode ser maior que a quantidade do produto.', function (rastro) {
            const { qCom } = this.parent; // Acessando o valor de qCom
            if (rastro) {
              const qtdRastros = rastro.reduce((acc, item) => {
                return acc + (toDecimal(item.qLote, 3) ?? 0)
              }, 0);
              return qtdRastros <= toDecimal(qCom, 3); // Ajuste da condição para <=
            }
            return true;
          }),
        vDesc: Yup.string().test({
          message: 'Desconto maior que o valor total',
          test: (value, context) => {
            if (toDecimal(value) > toDecimal(context.parent.vProd)) {
              return false;
            }
            return true;
          }
        })
      })
    )
  }, [temCodigo])

  return {
    formProdutoEntradaValidationYup,
  }
}

