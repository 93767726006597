import { Button, Grid, Typography } from 'views/design-system';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-adicionar-produto-fiscal-styles';
import { OkIcon } from '../../icons';
import { CircularLoading, useThemeQueries } from '../../..';
import { useCallback, useEffect, useRef } from 'react';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCadastros, useToastSaurus } from 'services/app';
import { AutocompleteGrupoImpostos } from 'views/components/controles/autocompletes/autocomplete-grupo-impostos/autocomplete-grupo-impostos';
import { AutocompleteNcmsGestao } from 'views/components/controles/autocompletes/autocomplete-ncms/autocomplete-ncms-gestao/autocomplete-ncms-gestao';
import { NcmModel } from 'model/api/gestao/ncm/ncm-model';
import { picker } from 'utils/picker';
import { ProdutoFiscalModel } from 'model/api/gestao/produto/produto-fiscal/produto-fiscal-model';
import { ProdutoImpostoPreCadastroFormModel } from 'model/app/forms/produto/produto-pre-cadastro/produto-pre-cadastro-imposto-form-model';
import { useFormProdutoNcmPreCadastroValidation } from 'views/components/form/produto/produto-pre-cadastro/form-produto-imposto/form-produto-imposto-pre-cadastro-validations';
import { ProdutoVincularFiscalModel } from 'model/api/gestao/produto/produto-imposto/produto-vincular-fiscal';
import { NcmMasterSummaryModel } from 'model/api/ncm-master/ncm-master-model';
import { usePostNcm } from 'data/api/gestao/ncm';
import { guidEmpty } from 'utils/guid-empty';
import { useGetNcmsMaster } from 'data/api/ncm-master/get-ncms-master';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useSessaoAtual } from 'services/app';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaImpostos } from 'database/interfaces/interface-tabela-impostos';
import { EnumTableNames } from 'database/touchone-carga-database';

export interface DialogAdicionarProdutoFiscalProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel | null;
}

export const DialogAdicionarProdutoFiscal = ({
  aberto,
  produto,
}: DialogAdicionarProdutoFiscalProps) => {
  //STATES E REFS
  const refInputNcm = useRef<HTMLInputElement>(null);
  const refNCMId = useRef<string>('');
  const { carregando, alterarDadosFiscaisProduto } = useMovProd()
  const { fecharAdicionarProdutoFiscais } = useCadastros()

  //CHAMADAS API
  const { getNcmsMaster, carregando: carregandoNcmsMaster } =
    useGetNcmsMaster();
  const { postNcm, carregando: carregandoPostNcm } = usePostNcm();

  //PROVIDERS E OUTROS
  const classes = useStyles();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { showToast } = useToastSaurus();
  const { isMobile } = useThemeQueries();
  const { FormProdutoNcmYupValidation } =
    useFormProdutoNcmPreCadastroValidation();
  const { callEvent } = useEventTools()
  const { get, add } = useBancoLocal();

  useEffect(() => {
    if (aberto && produto) {
      callEvent(AppEventEnum.PermiteEntradaDigitada, false)
    }

    return () => callEvent(AppEventEnum.PermiteEntradaDigitada, true)
  }, [aberto, callEvent, produto])

  const loading = carregando || carregandoNcmsMaster || carregandoPostNcm;

  const searchProdutoFiscal = useCallback(
    async (grupoImpostoId: string): Promise<string> => {
      const res = await get<TabelaImpostos>({
        nomeTabela: EnumTableNames.IMPOSTOS,
        where: 'id',
        value: grupoImpostoId,
      });

      return res[0].descricao!;
    },
    [get],
  );

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    reset
  } = useForm<ProdutoImpostoPreCadastroFormModel>({
    defaultValues: { ...new ProdutoImpostoPreCadastroFormModel() },
    resolver: yupResolver(FormProdutoNcmYupValidation),
    criteriaMode: 'all',
    mode: 'onChange',
  });


  const searchNcm = useCallback(
    async (modelo: MovSimplesProdutoModel, codigoNcmDigitado: string) => {
      try {
        //primeiro vou buscar na minha base
        const retNcmGestao = await get({
          nomeTabela: EnumTableNames.NCMS,
          where: 'codigo',
          value: codigoNcmDigitado,
        })

        if (retNcmGestao.length > 0) {
          const ncm = retNcmGestao[0] as NcmModel;
          modelo.ncm = ncm.codigo;
          modelo.ncmId = ncm.id;
          refNCMId.current = ncm.id;
        }
      } catch (e: any) { }
      //se o id do ncm não estiver preenchido significa que não existe na nossa api esse ncm cadastrado

      if (modelo.ncmId === guidEmpty() || modelo.ncmId === null) {
        //entao busco na api de ncms master
        const retNcmGestao = await getNcmsMaster(codigoNcmDigitado);
        if (retNcmGestao.erro) throw retNcmGestao.erro;
        if (retNcmGestao.resultado?.data.list.length > 0) {
          const ncm = retNcmGestao.resultado?.data
            ?.list[0] as NcmMasterSummaryModel;
          const ncmModel = new NcmModel(
            guidEmpty(),
            getEmpresaSelecionada()?.ContratoId || '',
            ncm.id,
            ncm.codigo,
            ncm.descricao,
            ncm.cest,
          );
          //cadastro em nossa base
          const ret = await postNcm(ncmModel);
          if (ret.erro) {
            throw ret.erro;
          }
          const novoNcm = ret.resultado?.data as NcmModel;

          await add({nomeTabela: EnumTableNames.NCMS, data: novoNcm})

          modelo.ncm = novoNcm.codigo;
          modelo.ncmId = novoNcm.id;
          refNCMId.current = novoNcm.id;
        }
      }
    },
    [add, get, getEmpresaSelecionada, getNcmsMaster, postNcm],
  );

  useEffect(() => {
    if (produto) {
      // valores iniciais serão os dados do produto.
      reset({
        codigoNcm: produto.ncm ?? '',
        ncmId: produto.ncmId ?? '',
        imposto: '',
        impostoId: produto.grupoImpostoId ?? '',
      });

      if (aberto) {
        setTimeout(() => {
          refInputNcm.current?.focus();
        }, 50);
      }
    }
    const loadProduto = async () => {
      try {
        if (produto!.grupoImpostoId) {
          //buscando a descrição do imposto caso o produto tenha um grupoImpostoId
          const descricao = await searchProdutoFiscal(produto!.grupoImpostoId);
          setValue('imposto', descricao);
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    };

    loadProduto();

  }, [aberto, isMobile, produto, reset, searchProdutoFiscal, setValue, showToast]);

  let informacaoFiscal = picker<ProdutoVincularFiscalModel>(
    produto,
    new ProdutoVincularFiscalModel(),
  );

  const handleSubmitFiscal = async (model: any) => {
    try {
      await searchNcm(produto!, getValues('codigoNcm'));
      informacaoFiscal.grupoImpostoId = getValues('impostoId')!;
      informacaoFiscal.ncmId = refNCMId.current!;
      informacaoFiscal.produtoGradeId = produto!.produtoGradeId!;
      informacaoFiscal.codncm = getValues('codigoNcm');

      await alterarDadosFiscaisProduto(
        informacaoFiscal,
        getEmpresaSelecionada()?.Id ?? '',
        produto!.produtoId ?? '',
        produto!
      );
      fecharAdicionarProdutoFiscais()
    } catch (e: any) {
      showToast(
        'error',
        `Erro, não foi possível atualizar as informações fiscais do produto, detalhe: ${e.message}`,
      );
    }
  };

  const onSubmit = async (values: ProdutoImpostoPreCadastroFormModel) => {
    const model = picker<ProdutoImpostoPreCadastroFormModel>(
      values,
      new ProdutoImpostoPreCadastroFormModel(),
    );

    await handleSubmitFiscal(model);
  };

  return (
    <DialogSaurus aberto={aberto || false}
      isButtonTitleClose={fecharAdicionarProdutoFiscais !== undefined}
      handleClickClose={fecharAdicionarProdutoFiscais}
      titulo="Venda Fiscal" tamanho="xs">
      {loading && <CircularLoading tipo="FULLSIZED" />}
      <Grid className={classes.cardContainer}>
        <Typography variant="body2" className={classes.textInfo}>
          O produto "{produto!.xProd}" não possui informações fiscais, por favor
          preencha para continuar com a inserção do produto na venda!
        </Typography>
        <Grid className={classes.cardContent}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="codigoNcm"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteNcmsGestao
                      modo="campoFormatado"
                      inputRef={refInputNcm}
                      loadingExterno={false}
                      useCache={false}
                      label="NCM do Produto"
                      placeholder="Ex: 84109000"
                      error={Boolean(
                        errors.codigoNcm && errors.codigoNcm.message,
                      )}
                      helperText={
                        touchedFields.codigoNcm || errors.codigoNcm
                          ? errors.codigoNcm?.message
                          : undefined
                      }
                      {...field}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          const ncm = picker<NcmModel>(
                            retorno.value,
                            new NcmModel(),
                          );
                          setValue('codigoNcm', ncm.codigo);

                          setValue('ncmId', ncm.id);
                        }
                      }}
                      value={getValues('codigoNcm')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="imposto"
                  control={control}
                  render={({ field }) => (
                    <AutocompleteGrupoImpostos
                      // a prop dentro da venda é apenas para saber se o input esta dentro de uma tela de venda para utilizar os cadastros sincronizados
                      dentroDaVenda
                      allowSubmit
                      loadingExterno={false}
                      label="Grupo de Imposto"
                      placeholder="-Selecione-"
                      error={Boolean(errors.imposto && errors.imposto.message)}
                      helperText={
                        touchedFields.imposto || errors.imposto
                          ? errors.imposto?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('imposto')}
                      onChange={(retorno) => {
                        if (!retorno.isString) {
                          const imposto = picker<ProdutoFiscalModel>(
                            retorno.value,
                            new ProdutoFiscalModel(),
                          );
                          setValue('imposto', imposto.descricao);
                          setValue('impostoId', imposto.id);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item mt={2} xs={8}>
                <Button style={{ display: 'none' }} type="submit"></Button>
                <Button
                  disabled={carregando}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                >
                  <OkIcon tipo="BUTTON_PRIMARY" />
                  {'Confirmar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogSaurus>
  );
};
