import { Button, Grid, Tooltip, Typography } from "views/design-system";
import { isEmpty } from "lodash";
import { toDecimalString } from "utils/to-decimal";
import { useStyles } from "../../card-operadores-styles";
import { ImpressoraIcon } from "views/components/icons/impressora-icon";
import { CardOperadoresSituacaoProps } from "../../card-operadores";

export const CardOperadoresMobile = ({
    model,
    pontoDeVenda,
    dataAbertura,
    dataConferencia,
    dataFechamento,
    handleImpressaoClick
}: CardOperadoresSituacaoProps) => {

    const classes = useStyles()

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs pr={2}>
                    <Typography variant="caption" color="textPrimary">
                        Caixa
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {pontoDeVenda.numCaixa}
                    </Typography>
                </Grid>
                <Grid item xs={7} pr={2}>
                    <Typography variant="caption" color="textPrimary">
                        Data de Abertura
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {dataAbertura}
                    </Typography>
                </Grid>
                <Grid item xs={'auto'} pl={2} className={classes.buttonImpressora}>
                    <Tooltip arrow title="Imprimir Sessão">
                        <Button
                            variant="text"
                            size="small"
                            color="primary"
                            className={classes.buttons}
                            onClick={handleImpressaoClick}
                        >
                            <ImpressoraIcon tipo="BUTTON" />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="caption" color="textPrimary">
                        Data de Fechamento
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {isEmpty(model?.dFechamento) ? '-' : dataFechamento}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="caption" color="textPrimary">
                        Data de Conferência
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {isEmpty(model?.dConferencia) ? '-' : dataConferencia}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption" color="textPrimary">
                        Vendas
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdVendas}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption" color="textPrimary">
                        Itens
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdItens}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption" color="textPrimary">
                        Cancelados
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {model.qtdCancelamentos}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="caption" color="textPrimary">
                        Valor Total
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {`R$ ${toDecimalString(model.valorVendido, 2)}`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}