import { MedicamentoPreco } from 'model/api/gestao/medicamento/medicamento-model';
import { MovSimplesProdutoModel } from "model/api/gestao/movimentacao/simples/mov-simples-produto-model";
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { ProdutoNovoSubItemModel } from "model/api/gestao/produto/produto-subitem/produto-subitem-model";
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { TabelaPromocoesDePor } from './interface-tabela-promocoes-de-por';

export interface TabelaProdutos {
    idIndexed?: number;
    ativo: boolean;
    balanca: number;
    categoriaId: string | null;
    categoriaDescricao: string | null;
    codigo: string | null;
    empresaId: string | null;
    grupoImpostoId: string | null
    imagemUrl: string | null;
    medida: string | null;
    medidaDesc: string | null;
    ncm: string | null;
    ncmId: string | null;
    nome: string | null;
    produtoGradeId: string | null;
    produtoId: string | null;
    vPreco: number;
    favorito: boolean;
    codigos: Array<ProdutoCodigoModel> | null;
    setorId: string | null;
    cobraTaxaServico: boolean | null;
    subItens: Array<ProdutoNovoSubItemModel> | null;
    prodSubItem: MovSimplesProdutoModel[] | TabelaProdutos[] | null;
    numCodigos: number;
    tipo: EnumTipoProduto;
    codigoAnvisa: string | null;
    medicamento: MedicamentoPreco | null;
    promocoes: Array<TabelaPromocoesDePor> | null;
    infAdic: string | null;
}

export class TabelaProdutosModel {
    constructor(
        public ativo: boolean = false,
        public balanca: number = 0,
        public categoriaId: string | null = '',
        public categoriaDescricao: string | null = '',
        public codigo: string | null = null,
        public empresaId: string | null = '',
        public grupoImpostoId: string | null = '',
        public imagemUrl: string | null = '',
        public medida: string | null = '',
        public medidaDesc: string | null = '',
        public ncm: string | null = '',
        public ncmId: string | null = '',
        public nome: string | null = '',
        public infAdic: string | null = '',
        public produtoGradeId: string | null = '',
        public produtoId: string | null = '',
        public vPreco: number = 0,
        public favorito: boolean = false,
        public codigos: Array<ProdutoCodigoModel> | null = [],
        public setorId: string | null = '',
        public cobraTaxaServico: boolean | null = false,
        public subItens: Array<ProdutoNovoSubItemModel> | null = [],
        public prodSubItem: MovSimplesProdutoModel[] | TabelaProdutos[] | null = [],
        public numCodigos: number = 0,
        public tipo: EnumTipoProduto = EnumTipoProduto.Produto,
        public codigoAnvisa: string | null = null,
        public medicamento: MedicamentoPreco | null = null,
        public promocoes: Array<TabelaPromocoesDePor> | null = [],
        public idIndexed?: number,
    ) {}
}
