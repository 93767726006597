import { TabelaCaixaConfiguracao, TabelaEmpresaConfiguracao } from "database/interfaces/interface-tabela-configuracoes";
import { TabelaClientes, TabelaClientesModel } from "database/interfaces/interface-tabela-clientes";
import { TabelaFinalizadoras } from "database/interfaces/interface-tabela-finalizadoras";
import { TabelaImpostos } from "database/interfaces/interface-tabela-impostos";
import { TabelaMedicamentos } from "database/interfaces/interface-tabela-medicamentos";
import { TabelaModificadoresModel } from "database/interfaces/interface-tabela-modificadores";
import { TabelaNCM } from "database/interfaces/interface-tabela-ncm";
import { TabelaProdutosModel } from "database/interfaces/interface-tabela-produtos";
import { TabelaPromocoesDePor, TabelaPromocoesDePorModel } from "database/interfaces/interface-tabela-promocoes-de-por";
import { EnumTableNames, TouchoneDBCargaSinc } from "database/touchone-carga-database";
import { CargaCaixaConfiguracaoModel, CargaCategoriaFotoModel, CargaCategoriaModel, CargaCodigoModel, CargaComandaModel, CargaConfiguracaoModel, CargaFinalizadoraModel, CargaImpostoModel, CargaMarcaModel, CargaMedicamento, CargaMedidaModel, CargaMesaModel, CargaModificadorModel, CargaModificadorProdutoEntity, CargaNcmModel, CargaPessoaContatoModel, CargaPessoaDocumentoModel, CargaPessoaEnderecoModel, CargaPessoaModel, CargaProdutoFavoritoModel, CargaProdutoImagemModel, CargaProdutoImpostoModel, CargaProdutoModel, CargaProdutoPrecoModel, CargaProdutoVariacaoEmpresaModel, CargaProdutoVariacaoModel, CargaProdutoVariacaoSubItem, CargaPromocaoDetalheModel, CargaPromocaoItemModel, CargaPromocaoModel, CargaPromocaoRegraModel, CargaSalaoModel } from "model/api/gestao/carga/carga-model";
import { EnumPromocaoTipoRegra } from "model/enums/enum-promocao-tipo-regra";
import { EnumSincronizacao } from "model/enums/enum-sincronizacao";
import { EnumTipoProduto } from "model/enums/enum-tipo-produto";
import { toDate, toDateString } from "utils/to-date";
import { TabelaMarcaModel } from "database/interfaces/interface-tabela-marcas";
import { TabelaComandas } from "database/interfaces/interface-tabela-comandas";
import { TabelaMesas } from "database/interfaces/interface-tabela-mesas";
import { EnumMesas } from "model/enums/enum-mesas";
import { TabelaSaloes } from "database/interfaces/interface-tabela-saloes";
import { EnumStatusSalao } from "model/enums/enum-status-salao";
import { EnumBalcaoSalao } from "model/enums/enum-balcao-salao";
import { TabelaEntidadesPromocoes } from "database/interfaces/interface-tabela-entidades-promocoes";
import { TabelaRegrasPromocoes } from "database/interfaces/interface-tabela-regras-promocoes";
import { TabelaGenerica } from "database/touchone-database";
import { ProdutoCodigoModel } from "model/api/gestao/produto/produto-codigo/produto-codigo-model";
import { ProdutoNovoSubItemModel } from "model/api/gestao/produto/produto-subitem/produto-subitem-model";
import { isEmpty } from "lodash";
import { completarTabela } from "./completar-tabelas";
import { TabelaCategoriasModel } from "database/interfaces/interface-tabela-categorias";
import { ProdutoModificadorModel } from "model/api/gestao/produto/produto-modificador/produto-modificador-model";
import { TpStatusComandaMock } from "data/mocks/tp-status-comanda-mock";
import { EnumStatusComanda } from "model/enums/enum-status-comanda";
import { TpStatusSalaoMock } from "data/mocks/tp-status-salao-mock";
import { TpBalcaoSalaoMock } from "data/mocks/tp-balcao-salao-mock";
import { MesaStatusMock } from "data/mocks/mesas-mock";

async function updateDBField(itens: any[], tableName: EnumTableNames, pk: string = 'id') {
    await TouchoneDBCargaSinc.transaction('rw', TouchoneDBCargaSinc[tableName], async () => {
        for (const item of itens) {
            try {
                const existingItem = await TouchoneDBCargaSinc[tableName].get({ [pk]: item[pk] }) as TabelaGenerica
                if (existingItem) {
                    //tive q fazer um switch enorme aqui pq o typescript cheio de frescura n deixou fazer de forma genérica
                    switch (tableName) {
                        case EnumTableNames.CATEGORIAS:
                            await TouchoneDBCargaSinc.categorias.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.CLIENTES:
                            await TouchoneDBCargaSinc.clientes.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.COMANDAS:
                            await TouchoneDBCargaSinc.clientes.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.CONFIG_CAIXA:
                            await TouchoneDBCargaSinc.configCaixa.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.CONFIG_EMPRESA:
                            await TouchoneDBCargaSinc.configEmpresa.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.FINALIZADORAS:
                            await TouchoneDBCargaSinc.finalizadoras.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.IMPOSTOS:
                            await TouchoneDBCargaSinc.impostos.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.MARCAS:
                            await TouchoneDBCargaSinc.marcas.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.MEDICAMENTOS:
                            await TouchoneDBCargaSinc.medicamentos.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.MESAS:
                            await TouchoneDBCargaSinc.mesas.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.MODIFICADORES:
                            await TouchoneDBCargaSinc.modificadores.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.NCMS:
                            await TouchoneDBCargaSinc.ncms.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.NUMERACAO:
                            await TouchoneDBCargaSinc.numeracao.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.PRODUTOS:
                            await TouchoneDBCargaSinc.produtos.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.SALOES:
                            await TouchoneDBCargaSinc.saloes.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.SESSAO:
                            await TouchoneDBCargaSinc.sessao.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.SESSAO_VALORES:
                            await TouchoneDBCargaSinc.sessaoValores.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.VENDAS:
                            await TouchoneDBCargaSinc.vendas.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.PROMOCOES:
                            await TouchoneDBCargaSinc.promocoes.update(existingItem.idIndexed!, item)
                            break;
                        case EnumTableNames.ENTIDADES_PROMOCOES:
                            await TouchoneDBCargaSinc.entidadesPromocoes.update(existingItem.idIndexed!, item);
                            break;
                        case EnumTableNames.REGRAS_PROMOCOES:
                            await TouchoneDBCargaSinc.regrasPromocoes.update(existingItem.idIndexed!, item);
                            break;
                        default:
                            continue
                    }
                } else {
                    await TouchoneDBCargaSinc[tableName].add(item);
                }
            } catch (e: any) {
                continue
            }
        }
    })
}

async function updateFields(tipo: EnumSincronizacao, data: any[], pk: string = 'id') {

    switch (tipo) {
        case EnumSincronizacao.ENTIDADES_PESSOAS:
            data = await completarTabela<TabelaClientesModel>(
                data,
                new TabelaClientesModel(),
                EnumTableNames.CLIENTES,
                pk,
                ['contatos', 'documentos', 'enderecos']
            );
            await updateDBField(data, EnumTableNames.CLIENTES)
            break;
        case EnumSincronizacao.FINALIZADORAS:
            await updateDBField(data, EnumTableNames.FINALIZADORAS)
            break;
        case EnumSincronizacao.IMPOSTOS:
            await updateDBField(data, EnumTableNames.IMPOSTOS)
            break;
        case EnumSincronizacao.NCMS:
            await updateDBField(data, EnumTableNames.NCMS)
            break;
        case EnumSincronizacao.ENTIDADES_CATEGORIAS:
            data = await completarTabela<TabelaCategoriasModel>(
                data,
                new TabelaCategoriasModel(),
                EnumTableNames.CATEGORIAS,
                pk,
            );
            await updateDBField(data, EnumTableNames.CATEGORIAS)
            break;
        case EnumSincronizacao.MESAS:
            await updateDBField(data, EnumTableNames.MESAS)
            break;
        case EnumSincronizacao.COMANDAS:
            await updateDBField(data, EnumTableNames.COMANDAS)
            break;
        case EnumSincronizacao.SALOES:
            await updateDBField(data, EnumTableNames.SALOES)
            break;
        case EnumSincronizacao.ENTIDADES_MODIFICADORES:
            data = await completarTabela<TabelaModificadoresModel>(
                data,
                new TabelaModificadoresModel(),
                EnumTableNames.MODIFICADORES,
                pk,
                ['produtos']
            );
            await updateDBField(data, EnumTableNames.MODIFICADORES)
            break;
        case EnumSincronizacao.MEDICAMENTOS:
            await updateDBField(data, EnumTableNames.MEDICAMENTOS)
            break;
        case EnumSincronizacao.PROMOCOES_DEPOR:
            data = await completarTabela<TabelaPromocoesDePorModel>(
                data,
                new TabelaPromocoesDePorModel(),
                EnumTableNames.PROMOCOES,
                pk,
            );
            await updateDBField(data, EnumTableNames.PROMOCOES, pk);
            break;
        case EnumSincronizacao.CONFIGURACOES:
            await updateDBField(data, EnumTableNames.CONFIG_EMPRESA, pk)
            break;
        case EnumSincronizacao.CONFIGURACOES_PDV:
            await updateDBField(data, EnumTableNames.CONFIG_CAIXA, pk)
            break;
        case EnumSincronizacao.ENTIDADES_PROMOCOES:
            await updateDBField(data, EnumTableNames.ENTIDADES_PROMOCOES);
            break;
        case EnumSincronizacao.REGRAS_PROMOCOES:
            await updateDBField(data, EnumTableNames.REGRAS_PROMOCOES);
            break;
        case EnumSincronizacao.ENTIDADES_PRODUTOS:
            data = await completarTabela<TabelaProdutosModel>(
                data,
                new TabelaProdutosModel(),
                EnumTableNames.PRODUTOS,
                pk,
                ['codigos', 'promocoes', 'subItens', 'prodSubItem']
            )
            await updateDBField(data, EnumTableNames.PRODUTOS, pk);
            break;
        default:
            return;
    }
}

async function translateToCategoria(
    key: string,
    data: any[],
) {
    let ret = [];
    let pk = 'id'
    switch (key) {
        case 'CategoriaEntity':

            for (let categoria of data) {
                const produtos = await TouchoneDBCargaSinc.produtos.toArray();
                const prod = produtos.findIndex(
                    (x) => {
                        return x.categoriaId === categoria.id &&
                            (x.tipo === EnumTipoProduto.Produto || x.tipo === EnumTipoProduto.Combo || x.tipo === EnumTipoProduto.Medicamento)
                            && x.ativo
                    }
                );
                ret.push({
                    id: categoria.id,
                    ativo: categoria.ativo,
                    breadCrumbs: categoria.nome,
                    categoriaPaiId: categoria.categoriaPaiId,
                    contratoId: categoria.contratoId,
                    cor: categoria.cor,
                    descricao: categoria.descricao,
                    nome: categoria.nome,
                    ordem: categoria.ordem,
                    tipoExibicao: categoria.tipoExibicao,
                    temProdutos: prod > -1,
                })
            }
            break;
        case 'CategoriaFotoEntity':
            ret = data.map((x: CargaCategoriaFotoModel) => ({
                foto: x.imagemUrl,
                id: x.categoriaId,
            }));
            break;
        default:
            return null;
    }

    if (!ret) return null;

    return {
        ret,
        pk
    };
}

async function translateToPessoas(
    key: string,
    data: any[],
) {
    let ret;
    let idsArr: string[] = [];
    let pk = 'id'
    let pessoas: TabelaClientes[] = await TouchoneDBCargaSinc.clientes.toArray();
    switch (key) {
        case 'PessoaEntity':
            ret = data.map((pessoa: CargaPessoaModel) => {
                let representante: any = pessoas.find(x => x.id === pessoa.representanteId);
                if (!representante) {
                    representante = data.find(x => x.id === pessoa.representanteId);
                    if (representante) {
                        representante.fantasia = representante.nomeFantasia || '';
                        delete representante.nomeFantasia;
                    }
                }
                return {
                    contratoId: pessoa.contratoId,
                    cpfcnpj: pessoa.cpfcnpj,
                    dtNasc: pessoa.dtNasc,
                    fantasia: pessoa.nomeFantasia,
                    id: pessoa.id,
                    infInterno: pessoa.infInterno,
                    infOperador: pessoa.infOperador,
                    nome: pessoa.nome,
                    regimeTributarioId: pessoa.regimeTributarioId,
                    representanteId: pessoa.representanteId,
                    status: pessoa.status,
                    tabelaPrecoId: pessoa.tabelaPrecoId,
                    tpCadastro: pessoa.tpCadastro,
                    usuarioId: pessoa.usuarioId,
                    representante: representante ? {
                        cpfcnpj: representante.cpfcnpj,
                        dtNasc: representante.dtNasc || '',
                        fantasia: representante.fantasia || '',
                        id: representante.id,
                        nome: representante.nome,
                        sexo: 0,
                        status: representante.status,
                        tpCadastro: representante.tpCadastro,
                    } : null,
                }
            });
            break;
        case 'PessoaEnderecoEntity':
            idsArr = [...(data as CargaPessoaContatoModel[])].map(x => x.pessoaId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const enderecoData: CargaPessoaEnderecoModel[][] = idsArr.map(x => {
                const promos = (data as CargaPessoaEnderecoModel[]).filter(promo => promo.pessoaId === x);
                return [...promos];
            })
            for (let enderecos of enderecoData) {
                let pessoaEndereco = pessoas.find(x => x.id === enderecos[0].pessoaId);
                if (pessoaEndereco) {
                    let formattedEndereco = [
                        ...enderecos.map(endereco => ({
                            id: endereco.id || null,
                            bairro: endereco.bairro || null,
                            cMun: endereco.codigoMunicipio || null,
                            cep: endereco.cep || null,
                            complemento: endereco.complemento || null,
                            cuf: endereco.codigoUF || 0,
                            ierg: endereco.ierg || null,
                            indIEDest: endereco.indIEDest || 0,
                            isEntrega: endereco.isEntrega || null,
                            isFiscal: endereco.isFiscal || null,
                            latitude: Number(endereco.latitude) || 0,
                            logradouro: endereco.logradouro,
                            longitude: Number(endereco.longitude) || 0,
                            numero: endereco.numero || null,
                            pessoaId: endereco.pessoaId || null,
                            pessoaTransportadoraPadraoId: endereco.pessoaTransportadoraPadraoId || null,
                            referencia: endereco.referencia || null,
                            uf: endereco.uf || null,
                            xMun: endereco.municipio || null,
                            im: endereco.im || null,
                        })),
                        ...pessoaEndereco.enderecos || [],
                    ].reduce<any[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            enderecos: formattedEndereco,
                            id: formattedEndereco[0].pessoaId,
                        }]
                    } else {
                        ret = [{
                            enderecos: formattedEndereco,
                            id: formattedEndereco[0].pessoaId,
                        }]
                    }
                }
            }
            break;
        case 'PessoaContatoEntity':
            idsArr = [...(data as CargaPessoaContatoModel[])].map(x => x.pessoaId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const contatosData: CargaPessoaContatoModel[][] = idsArr.map(x => {
                const promos = (data as CargaPessoaContatoModel[]).filter(promo => promo.pessoaId === x);
                return [...promos];
            })
            for (let contatos of contatosData) {
                let pessoaContato = pessoas.find(x => x.id === contatos[0].pessoaId);
                if (pessoaContato) {
                    let formattedContatos = [
                        ...contatos.map(x => ({
                            id: x.id,
                            pessoaId: x.pessoaId,
                            tipo: String(x.tipo),
                            valor: x.valor,
                        })),
                        ...pessoaContato.contatos || [],
                    ].reduce<any[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            contatos: formattedContatos,
                            id: formattedContatos[0].pessoaId,
                        }]
                    } else {
                        ret = [{
                            contatos: formattedContatos,
                            id: formattedContatos[0].pessoaId,
                        }]
                    }
                }
            }
            break;
        case 'PessoaDocumentoEntity':
            idsArr = [...(data as CargaPessoaDocumentoModel[])].map(x => x.pessoaId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const documentosData: CargaPessoaDocumentoModel[][] = idsArr.map(x => {
                const promos = (data as CargaPessoaDocumentoModel[]).filter(promo => promo.pessoaId === x);
                return [...promos];
            })
            for (let documentos of documentosData) {
                let pessoaDocumento = pessoas.find(x => x.id === documentos[0].pessoaId);
                if (pessoaDocumento) {
                    let formattedDocumentos = [
                        ...documentos.map(x => ({
                            id: x.id,
                            pessoaId: x.pessoaId,
                            tipo: x.tipo,
                            documento: x.documento,
                            dataExpedicao: x.dataExpedicao,
                            ufExpedicao: x.ufExpedicao,
                            orgaoExpedicao: x.orgaoExpedicao,
                            dataValidade: x.dataValidade,
                            informacoes: x.informacoes,
                            indIEDest: 0,
                        })),
                        ...pessoaDocumento.documentos || [],
                    ].reduce<any[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            documentos: formattedDocumentos,
                            id: formattedDocumentos[0].pessoaId,
                        }]
                    } else {
                        ret = [{
                            documentos: formattedDocumentos,
                            id: formattedDocumentos[0].pessoaId,
                        }]
                    }
                }
            }
            break;
        default:
            return null;
    }

    if (!ret) return null;

    return {
        ret,
        pk
    };
}

function translateToFinalizadora(
    finalizadoras: CargaFinalizadoraModel[],
): TabelaFinalizadoras[] {
    let retFinalizadoras: TabelaFinalizadoras[] = [];

    for (let finalizadora of finalizadoras) {
        const retFinalizadora: TabelaFinalizadoras = {
            ...finalizadora,
            vDesc: finalizadora.vAcresc || 0,
            vAcresc: finalizadora.vAcresc || 0,
            pDesc: finalizadora.pDesc || 0,
            pAcresc: finalizadora.pAcresc || 0,
            vMinParc: finalizadora.vMinParc || 0,
            qMaxParc: finalizadora.qMaxParc || 0,
            vencimento: finalizadora.vencimento || 0,
            qDias: finalizadora.qDias || 0,
            credenciado: Boolean(finalizadora.credenciado),
        }

        retFinalizadoras.push(retFinalizadora);
    }

    return retFinalizadoras;
}

function translateToImpostos(
    impostos: CargaImpostoModel[],
): TabelaImpostos[] {
    let retImpostos: TabelaImpostos[] = [];

    for (let imposto of impostos) {
        const retImposto: TabelaImpostos = {
            ...imposto,
            cfop: imposto.cfop ? String(imposto.cfop) : null,
        }

        retImpostos.push(retImposto);
    }
    return retImpostos;
}

async function translateToModificadores(
    key: string,
    data: any[],
) {
    let ret;
    let idsArr: string[] = [];
    let pk = 'id'
    switch (key) {
        case 'ModificadorEntity':
            ret = data.map((modificador: CargaModificadorModel) => ({
                id: modificador.id,
                contratoId: modificador.contratoId,
                descricao: modificador.descricao,
                empresaId: modificador.empresaId,
                nome: modificador.nome,
                tpCalculo: modificador.tpCalculo
            }));
            break;
        case 'ModificadorProdutoEntity':
            idsArr = [...(data as CargaModificadorProdutoEntity[])].map(x => x.modificadorId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const prodModificadores: CargaModificadorProdutoEntity[][] = idsArr.map(x => {
                const prodMods = (data as CargaModificadorProdutoEntity[]).filter(prod => prod.modificadorId === x);
                return [...prodMods];
            })
            for (let prodMod of prodModificadores) {
                let mod = await TouchoneDBCargaSinc.modificadores.get({ [pk]: prodMod[0].modificadorId });
                if (mod) {
                    let produtos = [
                        ...prodMod.map<ProdutoModificadorModel>(x => ({
                            id: x.id,
                            modificadorId: x.modificadorId,
                            ordem: x.ordem,
                            produtoGrade: x.produtoVariacaoId,
                            produtoGradeId: x.produtoVariacaoId,
                            valor: x.valor,
                        })),
                        ...mod.produtos || [],
                    ].reduce<ProdutoModificadorModel[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            produtos,
                            id: produtos[0].modificadorId,
                        }]
                    } else {
                        ret = [{
                            produtos,
                            id: produtos[0].modificadorId,
                        }]
                    }
                }
            }
            break;
        default:
            return null;
    }

    if (!ret) return null;

    return {
        ret,
        pk
    };
}

function translateToEntidadePromocoes(
    promocoes: CargaPromocaoModel[],
): TabelaEntidadesPromocoes[] {
    let retEntidadesPromocoes: TabelaEntidadesPromocoes[] = [];

    for (let promocao of promocoes) {

        const retPromocao: TabelaEntidadesPromocoes = {
            ativo: promocao.ativo,
            contratoId: promocao.contratoId,
            dataFinal: promocao.dataFinal,
            dataInicial: promocao.dataInicial,
            diasSemana: promocao.diasSemana,
            horarioFinal: promocao.horarioFinal,
            horarioInicial: promocao.horarioInicial,
            id: promocao.id,
            nome: promocao.nome,
        }
        retEntidadesPromocoes.push(retPromocao);
    }
    return retEntidadesPromocoes;
}

function translateToRegrasPromocoes(
    regras: CargaPromocaoRegraModel[],
): TabelaRegrasPromocoes[] {
    let retRegrasPromocoes: TabelaRegrasPromocoes[] = [];

    for (let regra of regras) {
        const retRegra: TabelaRegrasPromocoes = {
            ativo: regra.ativo,
            contratoId: regra.contratoId,
            limiteOcorrencia: regra.limiteOcorrencia,
            promocaoId: regra.promocaoId,
            tipoRegra: regra.tipoRegra,
            valorMinimoCupom: regra.valorMinimoCupom,
            id: regra.id,
        }
        retRegrasPromocoes.push(retRegra);
    }
    return retRegrasPromocoes;
}

function translateToPromocoes(
    promocoes: TabelaEntidadesPromocoes[],
    regras: TabelaRegrasPromocoes[],
    detalhes: CargaPromocaoDetalheModel[],
    itens: CargaPromocaoItemModel[],
): TabelaPromocoesDePor[] {
    let retPromocoes: TabelaPromocoesDePor[] = [];
    const anoMesDia = parseInt(toDateString(new Date(), 'yyyyMMDD') ?? '0');
    for (let item of itens) {
        const detalhe = detalhes.filter(x => {
            return item.detalheId === x.id;
        })

        if (!detalhe) continue;

        const regra = regras.find(x => {
            return detalhe.some(dtl => dtl.regraId === x.id);
        })

        if (!regra || regra.tipoRegra !== EnumPromocaoTipoRegra.DePor) continue

        const promocao = promocoes.find(x => x.id === regra.promocaoId);

        if (!promocao) continue;

        const valorPromocao = detalhe.find(x => x.id === item.detalheId)?.valor;

        const disabledTable = !(
            Boolean(item.ativo && promocao.ativo) &&
            parseInt(toDateString(toDate(promocao.dataFinal), "yyyyMMDD") ?? '0') >= anoMesDia &&
            parseInt(toDateString(toDate(promocao.dataInicial), "yyyyMMDD") ?? '0') <= anoMesDia
        )

        const promoDePor: TabelaPromocoesDePor = {
            ativo: Boolean(item.ativo && promocao.ativo),
            codigo: item.codigo,
            dataFinal: parseInt(toDateString(toDate(promocao.dataFinal), "yyyyMMDD") ?? '0'),
            dataInicial: parseInt(toDateString(toDate(promocao.dataInicial), "yyyyMMDD") ?? '0'),
            diasSemana: promocao.diasSemana,
            horaFinal: promocao.horarioFinal,
            horaInicial: promocao.horarioInicial,
            promocao: promocao.nome || '',
            promocaoId: promocao.id || '',
            valorPromocao: valorPromocao || 0,
            variacaoId: item.variacaoId,
            id: item.variacaoId + (promocao.id || ''),
            disabledTable,
        }

        retPromocoes.push(promoDePor);
    }
    return retPromocoes;
}

function translateToNCMS(
    ncms: CargaNcmModel[],
): TabelaNCM[] {
    let retNCMS: TabelaNCM[] = [];

    for (let ncm of ncms) {
        const retNCM: TabelaNCM = {
            ...ncm
        }

        retNCMS.push(retNCM);
    }
    return retNCMS;
}

function translateToConfigEmp(
    configs: CargaConfiguracaoModel[],
): TabelaEmpresaConfiguracao[] {
    let retConfigs: TabelaEmpresaConfiguracao[] = [];

    for (let config of configs) {
        const retConfig: TabelaEmpresaConfiguracao = {
            cod: config.cod,
            descConfig: config.descricao,
            descricao: config.descricao,
            empresaId: config.empresaId,
            grupoConfig: config.grupoConfigId,
            opcoes: config.opcoes,
            padrao: config.padrao,
            titulo: config.titulo,
            tpControle: config.tpControle,
            vConfig: config.vConfig,
        }

        retConfigs.push(retConfig);
    }
    return retConfigs;
}

function translateToConfigPDV(
    configs: CargaCaixaConfiguracaoModel[],
): TabelaCaixaConfiguracao[] {
    let retConfigs: TabelaCaixaConfiguracao[] = [];

    for (let config of configs) {
        const retConfig: TabelaCaixaConfiguracao = {
            caixaId: config.caixaId,
            cod: config.codigo,
            contratoId: config.contratoId,
            descConfig: '',
            editado: 0,
            id: config.id,
            itemDrop: '',
            ordem: 0,
            empresaId: '',
            grupoConfig: '',
            tpControle: 0,
            tpTxt: 0,
            vConfig: config.valor,
            vMax: 0,
            vMin: 0,
        }
        retConfigs.push(retConfig);
    }
    return retConfigs;
}

function translateToMedicamento(
    medicamentos: CargaMedicamento[],
): TabelaMedicamentos[] {
    let retMedicamentos: TabelaMedicamentos[] = [];

    for (let medicamento of medicamentos) {

        const retMedicamento: TabelaMedicamentos = {
            ...medicamento,
            ncmId: medicamento.ncmId || '',
        }

        retMedicamentos.push(retMedicamento);
    }
    return retMedicamentos;
}

function translateToMarca(
    marcas: CargaMarcaModel[],
): TabelaMarcaModel[] {
    let retMarcas: TabelaMarcaModel[] = [];

    for (let marca of marcas) {
        const retMarca: TabelaMarcaModel = {
            cnpjFab: marca.cnpjFab,
            contratoId: marca.contratoId,
            id: marca.id,
            indEscala: marca.indEscala,
            nome: marca.nome,
        }

        retMarcas.push(retMarca);
    }

    return retMarcas;
}

async function translateToProdutos(
    key: string,
    data: any[],
) {
    let ret;
    let idsArr: string[] = [];
    let pk = 'produtoGradeId'
    switch (key) {
        case 'ProdutoImagemEntity':
            ret = data.map((x: CargaProdutoImagemModel) => ({
                imagemUrl: x.imagemUrl,
                produtoGradeId: x.produtoVariacaoId,
            }));
            break;
        case 'ProdutoImpostoEntity':
            ret = data.map((x: CargaProdutoImpostoModel) => ({
                grupoImpostoId: x.impostoId,
                produtoGradeId: x.produtoVariacaoId,
            }));
            break;
        case 'ProdutoPrecoEntity':

            ret = data.map((x: CargaProdutoPrecoModel) => ({
                vPreco: x.vPreco,
                produtoGradeId: x.produtoVariacaoId,
            }));
            break;
        case 'ProdutoVariacaoEmpresaEntity':
            ret = data.map((x: CargaProdutoVariacaoEmpresaModel) => ({
                ativo: x.ativo,
                empresaId: x.empresaId,
                produtoGradeId: x.produtoVariacaoId,
            }));
            break;
        case 'ProdutoCodigoEntity':
            //filtro ids das variacoes pra depois separar os codigos de cada possivel produto
            idsArr = [...(data as CargaCodigoModel[])].map(x => x.produtoVariacaoId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const codDatas: CargaCodigoModel[][] = idsArr.map(x => {
                const codigos = (data as CargaCodigoModel[]).filter(cod => cod.produtoVariacaoId === x);
                return [...codigos];
            })
            for (let cod of codDatas) {
                let prodCod = await TouchoneDBCargaSinc.produtos.get({ [pk]: cod[0].produtoVariacaoId });
                if (prodCod) {
                    let codigos = [
                        ...cod.map<ProdutoCodigoModel>(x => ({
                            codigo: x.codigo,
                            fator: x.fatorVenda,
                            id: x.id,
                            percTabela: x.percTabela,
                            precoFixo: x.precoFixo,
                            produtoGradeId: x.produtoVariacaoId,
                            produtoId: prodCod?.produtoId || '',
                            isEanTrib: false,
                        })),
                        ...prodCod.codigos || [],
                    ].reduce<ProdutoCodigoModel[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            codigos,
                            numCodigos: codigos.length,
                            codigo: codigos[0].codigo,
                            produtoGradeId: codigos[0].produtoGradeId,
                        }]
                    } else {
                        ret = [{
                            codigos,
                            numCodigos: codigos.length,
                            codigo: codigos[0].codigo,
                            produtoGradeId: codigos[0].produtoGradeId,
                        }]
                    }
                }
            }
            break;
        case 'ProdutoVariacaoEntity':
            pk = 'produtoId'
            ret = data.map((x: CargaProdutoVariacaoModel) => ({
                balanca: x.balanca,
                produtoGradeId: x.id,
                codigoAnvisa: x.codigoAnvisa,
                medida: x.medidaSaidaId,
                produtoId: x.produtoId,
            }));
            break;
        case 'ProdutoFavoritoEntity':
            pk = 'produtoId'
            ret = data.map((x: CargaProdutoFavoritoModel) => ({
                favorito: x.favorito,
                produtoId: x.produtoId,
            }))
            break;
        case 'ProdutoEntity':
            pk = 'produtoId';
            ret = data.map((x: CargaProdutoModel) => ({
                categoriaId: x.categoriaId,
                cobraTaxaServico: x.cobraTaxaServico,
                ncmId: x.ncmId,
                nome: x.nome,
                produtoId: x.id,
                setorId: x.setorId,
                tipo: x.tipo,
                infAdic: isEmpty(x.infAdic) ? null : x.infAdic,
            }));
            break;
        case 'ProdutoVariacaoSubItemEntity':
            idsArr = [...(data as CargaProdutoVariacaoSubItem[])].map(x => x.produtoVariacaoId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const subItemData: CargaProdutoVariacaoSubItem[][] = idsArr.map(x => {
                const subItens = (data as CargaProdutoVariacaoSubItem[]).filter(sub => sub.produtoVariacaoId === x);
                return [...subItens];
            })
            for (let subs of subItemData) {
                let prodSubItem = await TouchoneDBCargaSinc.produtos.get({ [pk]: subs[0].produtoVariacaoId });
                if (prodSubItem) {
                    let subItens: ProdutoNovoSubItemModel[] = [
                        ...subs.map(x => ({
                            fator: x.fator,
                            id: x.id,
                            modificadorId: x.modificadorId,
                            ordem: x.ordem,
                            produtoGradeId: x.produtoVariacaoId,
                            produtoSubGradeId: x.produtoSubVariacaoId,
                            qMax: x.qMax,
                            qMin: x.qMin,
                            qPadrao: x.qPadrao,
                            tipo: x.tipo,
                        })),
                        ...prodSubItem.subItens || [],
                    ].reduce<ProdutoNovoSubItemModel[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []);
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            subItens,
                            produtoGradeId: subs[0].produtoVariacaoId,
                        }]
                    } else {
                        ret = [{
                            subItens,
                            produtoGradeId: subs[0].produtoVariacaoId,
                        }]
                    }
                }
            }
            break;
        case 'PromocoesEntity':
            idsArr = [...(data as TabelaPromocoesDePor[])].map(x => x.variacaoId).reduce<string[]>((prev, curr) => {
                if (prev.includes(curr)) return prev;
                return [...prev, curr];
            }, [])
            const promosData: TabelaPromocoesDePor[][] = idsArr.map(x => {
                const promos = (data as TabelaPromocoesDePor[]).filter(promo => promo.variacaoId === x);
                return [...promos];
            })
            for (let promos of promosData) {
                let prodPromos = await TouchoneDBCargaSinc.produtos.get({ [pk]: promos[0].variacaoId });
                if (prodPromos) {
                    let promocoes: TabelaPromocoesDePor[] = [
                        ...promos.map(x => ({
                            ativo: x.ativo,
                            codigo: x.codigo,
                            dataFinal: x.dataFinal,
                            dataInicial: x.dataInicial,
                            diasSemana: x.diasSemana,
                            horaFinal: x.horaFinal,
                            horaInicial: x.horaInicial,
                            promocao: x.promocao,
                            promocaoId: x.promocaoId,
                            valorPromocao: x.valorPromocao,
                            variacaoId: x.variacaoId,
                            id: x.id,
                            disabledTable: x.disabledTable,
                        })),
                        ...prodPromos.promocoes || [],
                    ].reduce<TabelaPromocoesDePor[]>((prev, curr) => {
                        if (prev.some((y) => y.id === curr.id)) return [...prev]
                        return [...prev, curr];
                    }, []).filter(promo => {
                        return promo.ativo && !promo.disabledTable
                    })
                    if (typeof ret === 'object') {
                        ret = [...ret, {
                            promocoes,
                            produtoGradeId: promos[0].variacaoId,
                        }]
                    } else {
                        ret = [{
                            promocoes,
                            produtoGradeId: promos[0].variacaoId,
                        }]
                    }
                }
            }
            // const prodsWithEmptyProm = await TouchoneDBCargaSinc.produtos.filter(prod => {
            //     return Boolean(prod.promocoes?.some(prodProm => !data.some(prom => prom.id === prodProm.id)))
            // }).toArray()
            // ret = [
            //     ...ret || [],
            //     ...prodsWithEmptyProm.map(prod => ({
            //         promocoes: [],
            //         produtoGradeId: prod.produtoGradeId,
            //     }))
            // ]
            break;
        case 'MedidaEntity':
            const medidaProds = await TouchoneDBCargaSinc.produtos.filter(x => !isEmpty(x.medida) && !isEmpty(x.produtoGradeId)).toArray();
            ret = medidaProds.map(prod => {
                const medida = (data as CargaMedidaModel[]).find(x => Boolean(prod.medida && x.id === prod.medida));
                if (medida) {
                    return { medida: medida.sigla, medidaDesc: medida.descricao, produtoGradeId: prod.produtoGradeId }
                }
                return { medida: '', medidaDesc: prod.medidaDesc, produtoGradeId: prod.produtoGradeId }
            })
            break;
        case 'NcmEntity':
            const ncmProds = await TouchoneDBCargaSinc.produtos.filter(x => !isEmpty(x.ncmId) && !isEmpty(x.produtoGradeId)).toArray();
            ret = ncmProds.map(prod => {
                const ncm = (data as CargaNcmModel[]).find(x => Boolean(prod.ncmId && x.id === prod.ncmId));
                if (ncm) {
                    return { ncmId: ncm.id, ncm: ncm.codigo, produtoGradeId: prod.produtoGradeId }
                }
                return { ncmId: prod.ncmId, ncm: prod.ncm, produtoGradeId: prod.produtoGradeId }
            })
            break;
        case 'CategoriaEntity':
            const categProds = await TouchoneDBCargaSinc.produtos.filter(x => !isEmpty(x.categoriaId) && !isEmpty(x.produtoGradeId)).toArray();
            ret = categProds.map(prod => {
                const categ = (data as CargaCategoriaModel[]).find(x => Boolean(prod.ncmId && x.id === prod.categoriaId));
                if (categ) {
                    return { categoriaId: categ.id, categoriaDescricao: categ.nome, produtoGradeId: prod.produtoGradeId }
                }
                return { categoriaId: prod.categoriaId, categoriaDescricao: prod.categoriaDescricao, produtoGradeId: prod.produtoGradeId }
            })
            break;
        case 'Medicamento':
            for (let medicamento of (data as CargaMedicamento[])) {
                const prodsMed = await TouchoneDBCargaSinc.produtos.filter(x => x.codigoAnvisa === medicamento.codigoAnvisa).toArray();
                ret = [
                    ...ret || [],
                    prodsMed.map(x => {
                        return {
                            produtoGradeId: x.produtoGradeId,
                            medicamento: {
                                ...medicamento,
                                ncmId: medicamento.ncmId || '',
                            }
                        }
                    })
                ]
            }
            break;
        default:
            return null;
    }

    if (!ret) return null;

    return {
        ret,
        pk
    };
}

function translateToComandas(
    comandas: CargaComandaModel[],
): TabelaComandas[] {
    let retComandas: TabelaComandas[] = [];

    for (let comanda of comandas) {
        const statusComanda = TpStatusComandaMock.find(x => x.Key === comanda.statusCodigo)
        const retComanda: TabelaComandas = {
            codigoComanda: comanda.codigoComanda,
            id: comanda.id,
            rfId: comanda.rfId,
            status: {
                codigo: comanda.statusCodigo || EnumStatusComanda.INATIVO,
                descricao: statusComanda?.Value || 'Inativo',
            }
        }

        retComandas.push(retComanda);
    }
    return retComandas;
}

function translateToMesas(
    mesas: CargaMesaModel[],
): TabelaMesas[] {
    let retMesas: TabelaMesas[] = [];

    for (let mesa of mesas) {
        const statusMesa = MesaStatusMock.find(x => x.Key === mesa.statusCodigo);
        const retMesa: TabelaMesas = {
            codigo: mesa.codigo,
            id: mesa.id,
            salaoId: mesa.salaoId,
            status: {
                codigo: mesa.statusCodigo || EnumMesas.INATIVO,
                descricao: statusMesa?.Value || 'Inativo',
            },
        }

        retMesas.push(retMesa);
    }
    return retMesas;
}

function translateToSaloes(
    saloes: CargaSalaoModel[],
): TabelaSaloes[] {
    let retSaloes: TabelaSaloes[] = [];

    for (let salao of saloes) {
        const statusSalao = TpStatusSalaoMock.find(x => x.Key === salao.statusCodigo);
        const balcaoSalao = TpBalcaoSalaoMock.find(x => x.Key === salao.balcaoCodigo);

        const retSalao: TabelaSaloes = {
            id: salao.id,
            descricao: salao.descricao,
            status: {
                codigo: salao.statusCodigo || EnumStatusSalao.INATIVO,
                descricao: statusSalao?.Value || 'Inativo',
            },
            balcao: {
                codigo: salao.balcaoCodigo || EnumBalcaoSalao.NAO_UTILIZA,
                descricao: balcaoSalao?.Value || 'Não Utiliza'
            }
        }

        retSaloes.push(retSalao);
    }
    return retSaloes;
}

function translateToPromocaoUpdate(
    promos: TabelaPromocoesDePor[],
    carga: TabelaEntidadesPromocoes[]
) {
    const anoMesDia = parseInt(toDateString(new Date(), 'yyyyMMDD') ?? '0');
    return promos.map<TabelaPromocoesDePor>(promocao => {
        const atualizacoes = carga.find(x => x.id === promocao.promocaoId);
        if (!atualizacoes) {
            return promocao;
        }
        const disabledTable = !(
            Boolean(atualizacoes.ativo) &&
            parseInt(toDateString(toDate(atualizacoes.dataFinal), "yyyyMMDD") ?? '0') >= anoMesDia &&
            parseInt(toDateString(toDate(atualizacoes.dataInicial), "yyyyMMDD") ?? '0') <= anoMesDia
        )
        return {
            dataFinal: parseInt(toDateString(toDate(atualizacoes.dataFinal), "yyyyMMDD") ?? '0'),
            dataInicial: parseInt(toDateString(toDate(atualizacoes.dataInicial), "yyyyMMDD") ?? '0'),
            diasSemana: atualizacoes.diasSemana,
            horaFinal: atualizacoes.horarioFinal,
            horaInicial: atualizacoes.horarioInicial,
            promocao: atualizacoes.nome || '',
            promocaoId: atualizacoes.id || '',
            ativo: atualizacoes.ativo,
            codigo: promocao.codigo,
            id: promocao.id,
            valorPromocao: promocao.valorPromocao,
            variacaoId: promocao.variacaoId,
            disabledTable,
        }
    })
}

export async function organizarCarga(
    tipo: EnumSincronizacao,
    forcar: boolean,
    ...args: any[]
) {
    switch (tipo) {
        case EnumSincronizacao.ENTIDADES_CATEGORIAS:
            const [categoriaData, categoriaKey] = args;
            const tableCategoria = await translateToCategoria(categoriaKey, categoriaData);
            if (!tableCategoria) return;
            await updateFields(tipo, tableCategoria.ret, tableCategoria.pk);
            break;
        case EnumSincronizacao.ENTIDADES_PESSOAS:
            const [pessoaData, pessoaKey] = args;
            const retPessoa = await translateToPessoas(pessoaKey, pessoaData);
            if (!retPessoa) return;

            await updateFields(tipo, retPessoa.ret, retPessoa.pk);
            break;
        case EnumSincronizacao.FINALIZADORAS:
            const [finalizadoras] = args;
            const tableFinalizadoras = translateToFinalizadora(finalizadoras);
            await updateFields(tipo, tableFinalizadoras)
            break;
        case EnumSincronizacao.IMPOSTOS:
            const [impostos] = args;
            const tableImpostos = translateToImpostos(impostos);
            await updateFields(tipo, tableImpostos)
            break;
        case EnumSincronizacao.ENTIDADES_MODIFICADORES:
            const [modData, modKey] = args;
            const tableModificadores = await translateToModificadores(modKey, modData);
            if (!tableModificadores) return;
            await updateFields(tipo, tableModificadores.ret, tableModificadores.pk);
            break;
        case EnumSincronizacao.PROMOCOES_DEPOR:
            const [promocoes, regras, detalhes, itens] = args;

            const tablePromocoes = translateToPromocoes(promocoes, regras, detalhes, itens);
            await updateFields(tipo, tablePromocoes)
            break;
        case EnumSincronizacao.NCMS:
            const [ncms] = args;
            const tableNCM = translateToNCMS(ncms);
            await updateFields(tipo, tableNCM)
            break;
        case EnumSincronizacao.CONFIGURACOES:
            const [configsEmp] = args;
            const tableConfigEmp = translateToConfigEmp(configsEmp);
            await updateFields(tipo, tableConfigEmp, 'cod')
            break;
        case EnumSincronizacao.CONFIGURACOES_PDV:
            const [configsCaixa] = args;
            const tableConfigCaixa = translateToConfigPDV(configsCaixa);
            await updateFields(tipo, tableConfigCaixa)
            break;
        case EnumSincronizacao.MEDICAMENTOS:
            const [medicamentos] = args;
            const tableMedicamentos = translateToMedicamento(medicamentos);

            await updateFields(tipo, tableMedicamentos)
            break;
        case EnumSincronizacao.MARCA:
            const [marcas] = args;
            const tableMarcas = translateToMarca(marcas);
            await updateFields(tipo, tableMarcas)
            break;
        case EnumSincronizacao.SALOES:
            const [saloes] = args;
            const tableSaloes = translateToSaloes(saloes);
            await updateFields(tipo, tableSaloes)
            break;
        case EnumSincronizacao.COMANDAS:
            const [comandas] = args;
            const tableComandas = translateToComandas(comandas);
            await updateFields(tipo, tableComandas)
            break;
        case EnumSincronizacao.MESAS:
            const [mesas] = args;
            const tableMesas = translateToMesas(mesas);
            await updateFields(tipo, tableMesas)
            break;
        case EnumSincronizacao.ENTIDADES_PROMOCOES:
            const [entPromocoes] = args;
            const tableEntidadesPromocoes = translateToEntidadePromocoes(entPromocoes);
            await updateFields(tipo, tableEntidadesPromocoes)
            if (!forcar) {
                const promocoes = await TouchoneDBCargaSinc.promocoes.filter(promo => {
                    return tableEntidadesPromocoes.some(x => x.id === promo.promocaoId);
                }).toArray();
                const tableItensPromocoes = translateToPromocaoUpdate(promocoes, tableEntidadesPromocoes);
                await updateFields(EnumSincronizacao.PROMOCOES_DEPOR, tableItensPromocoes)
            }
            break;
        case EnumSincronizacao.REGRAS_PROMOCOES:
            const [regrasPromocoes] = args;
            const tableRegrasPromocoes = translateToRegrasPromocoes(regrasPromocoes);
            await updateFields(tipo, tableRegrasPromocoes)
            break;
        case EnumSincronizacao.ENTIDADES_PRODUTOS:
            const [dataProd, prodKey] = args;

            const retProd = await translateToProdutos(prodKey, dataProd);

            if (!retProd) return;

            await updateFields(tipo, retProd.ret, retProd.pk);
    }
}