import { EnumTableNames, TouchoneDBCargaSinc } from "database/touchone-carga-database";
import { picker } from "utils/picker";

export async function completarTabela<T extends {}>(
    data: any[],
    newTable: T,
    tableName: EnumTableNames,
    pk: string,
    chavesArray?: string[],
) {
    return await Promise.all<T>(data.map(async obj => {
        try {
            const tabelaProd = await TouchoneDBCargaSinc[tableName].get({ [pk]: obj[pk] })
            let retObj = newTable;
            if (tabelaProd) {
                retObj = picker(obj, tabelaProd);
                const chaves = Object.entries({ ...retObj }).length;
                const chavesObjOriginal = Object.entries(newTable).length;
                if (chavesObjOriginal > chaves) {
                    retObj = picker(obj, newTable);
                }
            } else {
                retObj = picker(obj, newTable);
            }
            if (chavesArray) {
                for (let key of chavesArray) {
                    if (retObj[key as keyof T] && typeof retObj[key as keyof T] === 'object') {
                        (retObj as any)[key] = Object.values(retObj[key as keyof T] || {});
                    }
                }
            }
            return retObj;
        } catch (e: any) {
            return obj
        }
    }))
}