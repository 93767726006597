import { Grid, Typography } from "views/design-system"
import { DefaultCard } from "../../components"
import { CardPendenciasFiscaisProps } from "../card-pendencias-fiscais-props"
import { formatDecimalInteger, toDecimalString } from "utils/to-decimal"
import { toDateString } from "utils/to-date"
import { TpHistoricoVendaMock } from "data/mocks/tp-historico-venda-mock"
import { StatusHistoricoVendaMock } from "data/mocks/status-historico-venda-mock"
import { useThemeQueries } from "views/theme"
import { useCallback } from "react"
import { useStyles } from "./card-pendencias-fiscais-desktop-styles"
import { EnumTpStatusMov } from "model/enums/enum-tp-status-mov"
import { EnumMovModelo } from "model"
import classNames from "classnames"

export const CardPendenciasFiscaisDesktop = ({ model, selected, onCheck, onClick, consumidorPadrao }: CardPendenciasFiscaisProps) => {

    const classes = useStyles()
    const { theme } = useThemeQueries()

    const status = model.mod === EnumMovModelo.NFCE ? (model.statusFiscal ?? model.status) : model.status

    const retornarCorStatus = useCallback((): string => {
        switch (status) {
            case EnumTpStatusMov.Pendente:
                return theme.palette.warning.main;
            case EnumTpStatusMov.Finalizado:
                return theme.palette.success.main;
            case EnumTpStatusMov.Cancelado:
            case EnumTpStatusMov.Abortado:
            case EnumTpStatusMov.Rejeitado:
                return theme.palette.error.main;
            case EnumTpStatusMov.EmDigitacao:
                return theme.palette.warning.dark;
            default:
                return theme.palette.primary.main;
        }
    }, [
        theme.palette.success.main,
        theme.palette.warning.main,
        theme.palette.primary.main,
        status,
        theme.palette.error.main,
        theme.palette.warning.dark,
    ]);

    const isConsumidorPadrao = model.destDoc === consumidorPadrao.cpfcnpj

    const tpStatus = TpHistoricoVendaMock.find((x) => x.Key === model.mod)?.Value;
    const descricao = StatusHistoricoVendaMock.find(
        (x) => x.Key === status,
    )?.Value;

    const tamanhoParaMotivo = window.innerWidth - 450

    return (
        <DefaultCard
            isSelected={selected}
            onClick={async () =>
                onClick(model.id)
            }
        >
            <div className={classes.cardContent}>
                <div className={classes.celulaGrid} style={{ width: 200 }}>
                    <Typography color="textPrimary" variant="caption">
                        Dados do cliente
                    </Typography>

                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        <strong>{isConsumidorPadrao ? consumidorPadrao.nome : model.destNome}</strong>
                    </Typography>
                </div>

                <div className={classes.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                        Itens:
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {formatDecimalInteger(model.totalQtCom, 3)}
                    </Typography>
                </div>

                <div className={classes.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                        Data:
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {
                            toDateString(model.dhEmi, 'DD/MM/yyyy HH:mm')
                        }
                    </Typography>
                </div>

                <div className={classes.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                        Número:
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        {model.nnf === 0 || !model.nnf ? `- /${model.serie}` : `${model.nnf}/${model.serie}`}
                    </Typography>
                </div>
                <div className={classes.celulaGrid}>
                    <Typography color="textPrimary" variant="caption">
                        Valor total:
                    </Typography>
                    <Typography
                        className={'celula-grid-value'}
                        color="textPrimary"
                        variant="body1"
                    >
                        <strong>{toDecimalString(model.totalVNf, 2)}</strong>
                    </Typography>
                </div>
                <div className={classes.celulaGrid}>
                    <Grid className={classes.containerStatus}>
                        <div
                            style={{
                                display: 'flex',
                                padding: theme.spacing(0.5),
                                marginRight: theme.spacing(0.5),
                                borderRadius: 4,
                                justifyContent: 'center',
                                background: theme.palette.primary.main,
                            }}
                        >
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                                style={{ color: '#fff' }}
                            >
                                {tpStatus}
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                padding: theme.spacing(0.5),
                                borderRadius: 4,
                                justifyContent: 'center',
                                background: retornarCorStatus(),
                            }}
                        >
                            <Typography
                                className={'celula-grid-value'}
                                color="textPrimary"
                                variant="body1"
                                style={{ color: '#fff' }}
                            >
                                {descricao}
                            </Typography>
                        </div>
                    </Grid>
                </div>
                {model.msgSefaz && (
                    <div className={classes.celulaGrid} style={{
                        maxWidth: tamanhoParaMotivo,
                        minWidth: tamanhoParaMotivo,
                    }}>
                        <Typography color="textPrimary" variant="caption">
                            Motivo
                        </Typography>
                        <Typography
                            className={classNames('celula-grid-value', classes.motivo)}
                            color="textPrimary"
                            variant="body2"
                        >
                            {model.msgSefaz}
                        </Typography>
                    </div>
                )}
            </div>
        </DefaultCard>
    )
}