import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';

export const RetaguardaRotasMock = {
    // CONFIGURACOES
    configuracoesRota: {
        path: '/configuracoes',
        roles: [EnumCodigosPermissoes.CONFIGURACOES]
    },
    permissoesRota: {
        path: '/permissoes',
        roles: [EnumCodigosPermissoes.PERFIS]
    },

    // RELATORIOS
    dashboardRota: {
        path: '/dashboard',
        roles: [EnumCodigosPermissoes.RELATORIOS]
    },
    historicoRota: {
        path: '/historico',
        roles: [EnumCodigosPermissoes.HISTORICO_VENDAS]
    },
    detalhesVendaRota: {
        path: '/detalhes-venda/:id',
        roles: [EnumCodigosPermissoes.HISTORICO_VENDAS]
    },
    pendenciasFiscaisIdRota: {
        path: '/pendencias-fiscais/:id',
        roles: [EnumCodigosPermissoes.HISTORICO_VENDAS]
    },
    relatorioPerformanceRota: {
        path: '/relatorio-performance',
        roles: [EnumCodigosPermissoes.RELATORIOS]
    },
    relatoriosAvancadosRota: {
        path: '/relatorios-avancados',
        roles: [EnumCodigosPermissoes.RELATORIOS]
    },
    pendenciasFiscaisRota: {
        path: '/pendencias-fiscais',
        roles: [EnumCodigosPermissoes.HISTORICO_VENDAS]
    },

    // CADASTROS - PRODUTOS
    produtosRota: {
        path: '/produtos',
        roles: [EnumCodigosPermissoes.PRODUTOS]
    },
    insumosRota: {
        path: '/insumos',
        roles: [EnumCodigosPermissoes.PRODUTOS]
    },
    combosRota: {
        path: '/combos',
        roles: [EnumCodigosPermissoes.PRODUTOS]
    },
    modificadoresRota: {
        path: '/modificadores',
        roles: [EnumCodigosPermissoes.PRODUTOS]
    },

    // CADASTROS - PESSOAS
    pessoasRota: {
        path: '/pessoas',
        roles: [EnumCodigosPermissoes.PESSOAS, EnumCodigosPermissoes.FUNCIONARIOS, EnumCodigosPermissoes.FORNECEDORES, EnumCodigosPermissoes.REPRESENTANTES, EnumCodigosPermissoes.TRANSPORTADORAS]
    },

    // CADASTROS - FINALIZADORAS
    finalizadorasRota: {
        path: '/formas-de-pagamento',
        roles: [EnumCodigosPermissoes.FINALIZADORAS]
    },

    // OUTROS CADASTROS
    marcasRota: {
        path: '/marcas',
        roles: [EnumCodigosPermissoes.MARCAS]
    },
    ncmsRota: {
        path: '/ncms',
        roles: [EnumCodigosPermissoes.NCMS]
    },
    depositosRota: {
        path: '/depositos',
        roles: []
    },
    adicionarEmpresaRota: {
        path: '/adicionar-empresa',
        roles: []
    },
    configuracoesEmpresaRota: {
        path: '/configuracoes-empresa/:id',
        roles: []
    },
    listaEmpresaRota: {
        path: '/lista-empresas',
        roles: []
    },
    pdvRota: {
        path: '/pontos-de-venda',
        roles: [EnumCodigosPermissoes.PONTOS_DE_VENDA]
    },
    gerenciarMesaComandaRota: {
        path: '/gerenciar',
        roles: [EnumCodigosPermissoes.MESAS_E_COMANDAS]
    },
    saloesRota: {
        path: '/saloes',
        roles: [EnumCodigosPermissoes.SALOES]
    },
    setoresRota: {
        path: '/setor',
        roles: [EnumCodigosPermissoes.SETOR_E_PROCESSOS]
    },
    outrosCadastrosRota: {
        path: '/outros-cadastros',
        roles: []
    },
    comissoesRota: {
        path: '/comissoes',
        roles: [EnumCodigosPermissoes.COMISSOES]
    },
    conveniosRota: {
        path: '/convenios',
        roles: [EnumCodigosPermissoes.CONVENIO]
    },
    conveniosFaturasRota: {
        path: '/faturas',
        roles: [EnumCodigosPermissoes.RELATORIOS]
    },
    promocoesRota: {
        path: '/promocoes',
        roles: [EnumCodigosPermissoes.PROMOCOES]
    },
    impressoraRota: {
        path: '/impressoras',
        roles: []
    },
    categoriasRota: {
        path: '/categorias',
        roles: [EnumCodigosPermissoes.CATEGORIAS_PRODUTOS]
    },
    impostosRota: {
        path: '/impostos',
        roles: [EnumCodigosPermissoes.IMPOSTOS]
    },

    // ENTRADA MERCADORIA
    entradaMercadoriasRota: {
        path: '/entrada-mercadoria',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarRota: {
        path: '/entrada-mercadoria/visualizar/:movId',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarEntradaRota: {
        path: '/entrada-mercadoria/visualizar/:movId/entrada',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarEmitenteRota: {
        path: '/entrada-mercadoria/visualizar/:movId/emitente',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarTransportadoraRota: {
        path: '/entrada-mercadoria/visualizar/:movId/transportadora',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarDestinatarioRota: {
        path: '/entrada-mercadoria/visualizar/:movId/destinatario',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaInformacaoComplementarRota: {
        path: '/entrada-mercadoria/visualizar/:movId/info-complementares',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaInfoPedidoCompraRota: {
        path: '/entrada-mercadoria/visualizar/:movId/info-pedido-compra',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaDocReferenciaRota: {
        path: '/entrada-mercadoria/visualizar/:movId/doc-referenciados',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaPagamentoRota: {
        path: '/entrada-mercadoria/visualizar/:movId/pagamento',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaSituacaoEntradaRota: {
        path: '/entrada-mercadoria/visualizar/:movId/situacao-entrada',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaProdutosRota: {
        path: '/entrada-mercadoria/visualizar/:movId/produtos',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarProdutosRota: {
        path: '/entrada-mercadoria/visualizar/:movId/produtos/:prodId',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    entradaMercadoriaVisualizarXML: {
        path: '/entradas/visualizar/:id/importacao-xml',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },

    // OUTROS
    alterarPlanoRota: {
        path: '/alterar-plano',
        roles: []
    },
    uploadCargaRota: {
        path: '/upload-carga',
        roles: []
    },
    uploadCargaDetalhadaRota: {
        path: '/upload-carga/detalhes/:id',
        roles: []
    },
    ativarNFCERota: {
        path: '/configurar-nfc-e',
        roles: [EnumCodigosPermissoes.CONFIGURACOES]
    },
    listaEmpresasRota: {
        path: '/lista-empresas',
        roles: []
    },
    documentosRota: {
        path: '/documentos-fiscais',
        roles: [EnumCodigosPermissoes.CONFIGURACOES]
    },
    gerenciamentoSessoesRota: {
        path: '/gerenciamento-sessoes',
        roles: [EnumCodigosPermissoes.GERENCIAMENTO_DE_SESSOES]
    },
    autenticacaoRota: {
        path: '/meu-cracha',
        roles: []
    },
    manifestosRota: {
        path: '/manifestos',
        roles: [EnumCodigosPermissoes.ENTRADA_MERCADORIA]
    },
    semPermissaoRota: {
        path: '/not-allowed',
        roles: []
    },
}