import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    buttonCancelar: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        padding: 0,
        height: 48,
        width: 48,
        minWidth: 48,
        marginRight: theme.spacing(2),
        "&:hover": {
            backgroundColor: theme.palette.error.dark
        },
        "&:disabled": {
            backgroundColor: theme.palette.error.light
        }
    },
    gridContainer: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
    },
    novoSubitemLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontWeight: 500
    },
    closeButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    closeButtonIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        fill: theme.palette.primary.main
    },
    defaultCard: {
        padding: 16,
        position: 'relative'
    }
}))