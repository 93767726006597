import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";
import { VariaveisAmbiente } from "config";
import { useEmpresaAtual } from "services/app/hooks/empresa-atual";

export function useGetHistoricoVendasPendentesById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { getEmpresaAtual } = useEmpresaAtual()

    const getHistoricoVendasPendentesById = useCallback(
        (pendenciaId: string) => {

            return invocarApi({
                url: `api/v3/empresa/${getEmpresaAtual()?.id ?? ''}/Movimento/pendencias/${pendenciaId}`,
                method: "GET",
                enviarTokenUsuario: true,
                baseURL: VariaveisAmbiente.apiUrlMovs
            });
        },
        [getEmpresaAtual, invocarApi]
    );
    return {
        getHistoricoVendasPendentesById,
        ...outrasPropriedades,
    };
}
