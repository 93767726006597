import { ProdutoModificadorModel } from 'model/api/gestao/produto/produto-modificador/produto-modificador-model';

export interface TabelaModificadores {
    idIndexed?: number;
    id: string | null;
    contratoId: string | null;
    empresaId: string | null;
    nome: string | null;
    descricao: string | null;
    tpCalculo: number | null;
    produtos: ProdutoModificadorModel[];
}

export class TabelaModificadoresModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public empresaId: string = '',
        public nome: string = '',
        public descricao: string = '',
        public tpCalculo: number = 0,
        public produtos: ProdutoModificadorModel[] = [],
        public idIndexed?: number
    ) {}
}

  