import classNames from "classnames"
import { BarcodeIcon, CategoriaIcon } from "views/components/icons"
import { LaboratorioIcon } from "views/components/icons/laboratorio-icon"
import { MedicamentoIcon } from "views/components/icons/medicamentos-icon"
import { Box, Grid, Typography } from "views/design-system"
import { useStyles } from "./produto-tags-styles"
import { ProdutoTagsProps } from "./produto-tags-props"
import { TarjaMedicamentoMock } from "data/mocks/tarja-medicamento-mock"
import { isEmpty } from "lodash"
import { TipoMedicamentoMock } from "data/mocks/tipo-medicamento-mock"

export const ProdutoTags = ({ produto, codigo }: ProdutoTagsProps) => {
    const classes = useStyles()
    return (

        <Box className={classes.cardInfo}>
            {(codigo?.codigo || produto.cEan) && <Box className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                <BarcodeIcon tipo="GERAL" />
                <Typography variant="caption">
                    {codigo?.codigo || produto.cEan}
                </Typography>
            </Box>}
            {(produto.cProd !== produto.cEan && produto.cProd) && (
                <Grid className={classNames(classes.tagProdutos, classes.tagCodigos)}>
                    <BarcodeIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {produto.cProd}
                    </Typography>
                </Grid>
            )}
            {produto.categoria && <Box className={classNames(classes.tagProdutos, classes.tagCategoria)}>
                <CategoriaIcon tipo="GERAL" />
                <Typography variant="caption">
                    {produto.categoria}
                </Typography>
            </Box>}
            {produto.med?.medicamento?.tarja && (
                <Grid className={classNames(classes.tagProdutos, classes.tagTarja)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {TarjaMedicamentoMock.find((tarja) => tarja.Key === produto.med?.medicamento?.tarja)?.Value ?? ''}
                    </Typography>
                </Grid>
            )}
            {produto.med?.medicamento?.tipoMedicamento !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagTipoMedicamento)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {TipoMedicamentoMock.find((tipo) => tipo.Key === produto.med?.medicamento?.tipoMedicamento)?.Value ?? ''}
                    </Typography>
                </Grid>
            )}
            {!isEmpty(produto.med?.medicamento?.laboratorioNome) && (
                <Grid className={classNames(classes.tagProdutos, classes.tagLaboratorio, (produto.med?.medicamento?.laboratorioNome ?? '').toUpperCase().indexOf("CIMED") > -1 ? classes.tagLaboratorioDestaque : undefined)}>
                    <LaboratorioIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {produto.med?.medicamento?.laboratorioNome}
                    </Typography>
                </Grid>
            )}
            {produto.med?.medicamento?.classeTerapeutica !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagClasse)}>
                    <CategoriaIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {produto.med?.medicamento?.classeTerapeutica}
                    </Typography>
                </Grid>
            )}
            {produto.med?.medicamento?.composicao !== undefined && (
                <Grid className={classNames(classes.tagProdutos, classes.tagComposicao)}>
                    <MedicamentoIcon tipo="GERAL" />
                    <Typography variant="caption">
                        {produto.med?.medicamento?.composicao}
                    </Typography>
                </Grid>
            )}
        </Box>
    )
}