import { Box, Button, Grid, Typography } from 'views/design-system';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useStyles } from './form-empresa-styles';
import { useFormEmpresaValidation } from './form-empresa-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { EmpresaFormModel } from 'model/app';
import { UFMock } from 'data/mocks/uf-mock';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { useToastSaurus } from '../../../../../services/app/hooks';
import { useConsultaCEP } from 'data/api/wsmaster/consultar-cep';
import { RegimeMock } from '../../../../../data/mocks/regime-mock';
import { stringNumeros } from 'utils/string-numeros';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutocompleteSegmento } from 'views/components/controles/autocompletes/autocomplete-segmento/autocomplete-segmento';
import { SegmentoModel } from 'model/api/gestao/master/segmento';
import { picker } from 'utils/picker';
import { guidEmpty } from 'utils/guid-empty';
import { SwitchSaurus } from 'views/components/controles';
import { useCadastros } from 'services/app/hooks/cadastros';
import { useTermosDeUsoStore } from 'views/states/termos-de-uso-store';
import { DefaultCard } from 'views/components/cards/components';

export interface FormEmpresaProps extends DefaultFormProps<EmpresaFormModel> {
  setCarregandoExterno(carregando: boolean): void;
}
export const FormEmpresa = React.forwardRef<
  DefaultFormRefs<EmpresaFormModel>,
  FormEmpresaProps
>((props: FormEmpresaProps, refs) => {
  const txtRazaoSocial = useRef<HTMLInputElement>();
  const txtNro = useRef<HTMLInputElement>();
  const txtLogradouro = useRef<HTMLInputElement>();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({ modeloForm: new EmpresaFormModel('') });
  const { showToast } = useToastSaurus();
  const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
  const { isMobile } = useThemeQueries();
  const { abrirTermosDeUso } = useCadastros()
  const { FormEmpresaValidationYup } = useFormEmpresaValidation(model.modeloForm.cpfcnpj.length !== 11);
  const { setTermosAceitos } = useTermosDeUsoStore()
  const termosAceitos = useTermosDeUsoStore(store => store.state.termosAceitos)
  useEffect(() => {
    if (props.setCarregandoExterno) props.setCarregandoExterno(Boolean(carregandoCep));
  }, [props, carregandoCep]);

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue,
  } = useForm<EmpresaFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormEmpresaValidationYup),
    criteriaMode: 'all',
    mode: 'onSubmit',
  });

  const onSubmit = useCallback((form: EmpresaFormModel) => {
    if (!termosAceitos) {
      showToast('error', 'Aceite os Termos de Uso para poder completar o cadastro.')
      return
    }
    props.onSubmit(form, model.modeloForm);
  }, [model.modeloForm, props, showToast, termosAceitos]);

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new EmpresaFormModel('', '') });
      reset();
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
    fillForm: (model: EmpresaFormModel) => {

      if (process.env.REACT_APP_LABEL as string === 'FARMA' && !model.segmentoId && !model.segmento) {
        //ID DO SEGMENTO DE FARMÁCIA!
        model.segmento = 'Farmácias.'
        model.segmentoId = 'da82238c-ee1d-4383-afcb-e62110c64f77';
      }
      setModel({ modeloForm: model });
      reset({ ...model });
      if (!isMobile) txtRazaoSocial?.current?.focus();
    },
  }));

  const abrirTermos = useCallback(() => {
    abrirTermosDeUso(async () => await handleSubmit(onSubmit)())
  }, [abrirTermosDeUso, handleSubmit, onSubmit])

  const handleConsultaCep = async (value: string) => {
    try {
      let res = await consultaCEP(value);

      setValue('cep', stringNumeros(res.CEP));
      setValue('bairro', res.Bairro);
      setValue('logradouro', res.Logradouro);
      setValue('uf', res.UF);
      setValue('municipio', res.Municipio);
      setValue('cMunicipio', res.CMun?.toString());
      if (!isMobile) txtNro.current?.focus();
    } catch (e: any) {
      showToast('error', e.message);
      if (!isMobile) txtLogradouro.current?.focus();
    }
  }

  return (
    <>
      <div className={utilClasses.formContainer}>
        {props.loading && props.showLoading && (
          <CircularLoading tipo="FULLSIZED" />
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading && utilClasses.controlLoading
            }`}
        >
          <Grid container spacing={2}>
            {stringNumeros(model.modeloForm.cpfcnpj).length === 11 ? (
              <Grid item xs={12}>
                <Controller
                  name="fone"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo={'TELEFONE'}
                      disabled={props.loading}
                      fullWidth={true}
                      showStartAdornment={true}
                      allowSubmit={false}
                      label="Telefone"
                      placeholder=""
                      error={Boolean(errors.fone && errors.fone.message)}
                      helperText={
                        errors.fone
                          ? errors.fone?.message
                          : undefined
                      }
                      {...field}
                      value={getValues('fone')}
                      fieldValidation={FormEmpresaValidationYup.fields.fone}
                    />
                  )}
                />
              </Grid>
            ) : stringNumeros(model.modeloForm.cpfcnpj).length === 14 &&
              model.modeloForm.cnae.length > 0 ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="fone"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TELEFONE'}
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        label="Telefone"
                        placeholder=""
                        error={Boolean(errors.fone && errors.fone.message)}
                        helperText={
                          errors.fone
                            ? errors.fone?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('fone')}
                        fieldValidation={FormEmpresaValidationYup.fields.fone}
                      />
                    )}
                  />
                </Grid>
                {!model.modeloForm.segmentoId &&
                  <Grid item xs={12}>
                    <Controller
                      name="segmento"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteSegmento
                          disabled={props.loading}
                          loadingExterno={props.loading}
                          label="Segmento"
                          error={Boolean(errors.segmento && errors.segmento.message)}
                          helperText={
                            touchedFields.segmento || errors.segmento
                              ? errors.segmento?.message
                              : undefined
                          }
                          {...field}
                          onChange={(retorno) => {
                            if (!retorno.isString) {
                              const segmento = picker<SegmentoModel>(
                                retorno.value,
                                new SegmentoModel(),
                              );
                              setValue('segmento', segmento.descricao);
                              setValue('segmentoId', segmento.id);
                            } else {
                              setValue('segmento', '');
                            }
                          }}
                          value={getValues('segmento')}
                          fieldValidation={FormEmpresaValidationYup.fields.segmento}
                        />
                      )}
                    />
                  </Grid>
                }
              </>
            ) : (
              <>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="cpfcnpj"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="CNPJ_CPF"
                        disabled={true}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="CPF / CNPJ"
                        placeholder=""
                        error={Boolean(
                          errors.cpfcnpj && errors.cpfcnpj.message,
                        )}
                        helperText={
                          touchedFields.cpfcnpj || errors.cpfcnpj
                            ? errors.cpfcnpj?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cpfcnpj')}
                        fieldValidation={FormEmpresaValidationYup.fields.cpfcnpj}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="razaoSocial"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        inputRef={txtRazaoSocial}
                        autoComplete={'off'}
                        label="Nome Completo / Razão Social"
                        placeholder=""
                        error={Boolean(
                          errors.razaoSocial && errors.razaoSocial.message,
                        )}
                        helperText={
                          touchedFields.razaoSocial || errors.razaoSocial
                            ? errors.razaoSocial?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('razaoSocial')}
                        fieldValidation={FormEmpresaValidationYup.fields.razaoSocial}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="nomeFantasia"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="Nome Fantasia"
                        placeholder=""
                        error={Boolean(
                          errors.nomeFantasia && errors.nomeFantasia.message,
                        )}
                        helperText={
                          touchedFields.nomeFantasia || errors.nomeFantasia
                            ? errors.nomeFantasia?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('nomeFantasia')}
                        fieldValidation={FormEmpresaValidationYup.fields.nomeFantasia}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="ierg"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={false}
                        allowSubmit={false}
                        autoComplete={'off'}
                        label="IERG"
                        placeholder=""
                        error={Boolean(errors.ierg && errors.ierg.message)}
                        helperText={
                          touchedFields.ierg || errors.ierg
                            ? errors.ierg?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('ierg')}
                        fieldValidation={FormEmpresaValidationYup.fields.ierg}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="fone"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TELEFONE'}
                        disabled={props.loading}
                        fullWidth={true}
                        showStartAdornment={true}
                        allowSubmit={false}
                        label="Telefone"
                        placeholder="(99) 99999-9999"
                        error={Boolean(errors.fone && errors.fone.message)}
                        helperText={
                          errors.fone
                            ? errors.fone?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('fone')}
                        fieldValidation={FormEmpresaValidationYup.fields.fone}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="CEP"
                        disabled={props.loading}
                        fullWidth={true}
                        allowSubmit={false}
                        searchable
                        autoComplete={'off'}
                        label="CEP"
                        placeholder=""
                        onSearch={handleConsultaCep}
                        error={Boolean(errors.cep && errors.cep.message)}
                        helperText={
                          touchedFields.cep || errors.cep
                            ? errors.cep?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cep')}
                        fieldValidation={FormEmpresaValidationYup.fields.cep}
                        onChange={e => {
                          field.onChange(e)
                          const { value } = e.target

                          if (stringNumeros(value).length === 8) {
                            setTimeout(() => handleConsultaCep(value), 400)
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="logradouro"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TEXTO'}
                        inputRef={txtLogradouro}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Endereço"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(
                          errors.logradouro && errors.logradouro.message,
                        )}
                        helperText={
                          touchedFields.logradouro || errors.logradouro
                            ? errors.logradouro?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('logradouro')}
                        fieldValidation={FormEmpresaValidationYup.fields.logradouro}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3} md={3}>
                  <Controller
                    name="numero"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo="TEXTO"
                        inputRef={txtNro}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Número"
                        InputLabelProps={{ shrink: true }}
                        placeholder=""
                        error={Boolean(errors.numero && errors.numero.message)}
                        helperText={
                          touchedFields.numero || errors.numero
                            ? errors.numero?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('numero')}
                        fieldValidation={FormEmpresaValidationYup.fields.numero}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Controller
                    name="complemento"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TEXTO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Complemento"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Ex: Apartamento 111"
                        error={Boolean(
                          errors.complemento && errors.complemento.message,
                        )}
                        helperText={
                          touchedFields.complemento || errors.complemento
                            ? errors.complemento?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('complemento')}
                        fieldValidation={FormEmpresaValidationYup.fields.complemento}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Controller
                    name="bairro"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TEXTO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Bairro"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors.bairro && errors.bairro.message)}
                        helperText={
                          touchedFields.bairro || errors.bairro
                            ? errors.bairro?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('bairro')}
                        fieldValidation={FormEmpresaValidationYup.fields.bairro}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={7}>
                  <Controller
                    name="municipio"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TEXTO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Município"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          errors.municipio && errors.municipio.message,
                        )}
                        helperText={
                          touchedFields.municipio || errors.municipio
                            ? errors.municipio?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('municipio')}
                        fieldValidation={FormEmpresaValidationYup.fields.municipio}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Controller
                    name="cMunicipio"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'NUMERO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Cód. Município"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(
                          errors.cMunicipio && errors.cMunicipio.message,
                        )}
                        helperText={
                          touchedFields.cMunicipio || errors.cMunicipio
                            ? errors.cMunicipio?.message
                            : undefined
                        }
                        {...field}
                        value={getValues('cMunicipio')?.toString()}
                        fieldValidation={FormEmpresaValidationYup.fields.cMunicipio}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Controller
                    name="uf"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        conteudo={UFMock}
                        fullWidth
                        variant="outlined"
                        label={'UF'}
                        {...field}
                        value={UFMock.find(uf => uf.Value === getValues('uf'))?.Key}
                        onChange={(event) => {
                          if (event) {
                            const item = UFMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0];
                            if (item) {
                              setValue('uf', item.Value);
                            }
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                {process.env.REACT_APP_LABEL as string !== 'FARMA' && <>
                  <Grid item xs={6} md={4}>
                    <Controller
                      name="cnae"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          disabled={props.loading}
                          fullWidth
                          autoComplete='new-password'
                          variant="outlined"
                          placeholder=""
                          label="CNAE"
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(errors.cnae && errors.cnae.message)}
                          helperText={
                            touchedFields.cnae || errors.cnae
                              ? errors.cnae?.message
                              : undefined
                          }
                          {...field}
                          value={getValues('cnae')}
                          fieldValidation={FormEmpresaValidationYup.fields.cnae}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Controller
                      name="segmento"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteSegmento
                          disabled={props.loading}
                          loadingExterno={props.loading}
                          label="Segmento"
                          error={Boolean(errors.segmento && errors.segmento.message)}
                          helperText={
                            touchedFields.segmento || errors.segmento
                              ? errors.segmento?.message
                              : undefined
                          }
                          {...field}
                          onChange={(retorno) => {
                            if (retorno.isNewVal) {
                              setValue('segmento', retorno.value);
                              setValue('segmentoId', guidEmpty());
                            } else if (!retorno.isString) {
                              const segmento = picker<SegmentoModel>(
                                retorno.value,
                                new SegmentoModel(),
                              );
                              setValue('segmento', segmento.descricao);
                              setValue('segmentoId', segmento.id);
                            } else {
                              setValue('segmento', '');
                            }
                          }}
                          value={getValues('segmento')}
                          fieldValidation={FormEmpresaValidationYup.fields.segmento}
                        />
                      )}
                    />
                  </Grid>
                </>}
                <Grid item xs={12} md={process.env.REACT_APP_LABEL as string !== 'FARMA' ? 4 : 12}>
                  <Controller
                    name="regime"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        disabled={props.loading}
                        conteudo={RegimeMock}
                        fullWidth
                        variant="outlined"
                        label={'Regime Atual'}
                        {...field}
                        onChange={(event) => {
                          if (event) {
                            setValue(
                              'regime',
                              RegimeMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0]?.Key,
                            );
                          }
                        }}
                        value={getValues('regime')}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <DefaultCard className={classes.paperTermos}>
              <Grid container spacing={2} justifyContent='center' alignItems='center' >
                <Grid item xs={12} md={6}>
                  <Button fullWidth type='button' variant='outlined' color='primary' onClick={abrirTermos}>
                    Ler os Termos de Uso
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Box className={classes.switchTermos}  >
                    <Typography variant='body1' className={classes.switchTermosText}>
                      Li e concordo com os termos
                    </Typography>
                    <SwitchSaurus
                      className={classes.switchTermosText}
                      variant='LIGHT'
                      checked={termosAceitos}
                      onChange={(e) => { setTermosAceitos(e.target.checked) }} />
                  </Box>
                </Grid>
              </Grid>
            </DefaultCard>
          </Grid>
          <input name="cnae" type="hidden" value={getValues('cnae')}></input>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div >
    </>
  );
});
