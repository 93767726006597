import { useStyles } from './gerar-comanda-cadastro-styles';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormGerarComanda } from 'views/components/form/comandas/form-gerar-comanda/form-gerar-comanda';
import { ConfirmarIcon } from 'views/components/icons/confirmar-icon';
import { GerarComandaConfirmacao } from '../gerar-comanda-confirmacao/gerar-comanda-confirmacao';
import { useThemeQueries } from 'views/theme';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';
import { usePostPedidoDados } from 'data/api/gestao/pedido-dados/post-pedido-dados';
import { useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { PedidoDadosIntegracaoModel } from 'model/api/gestao/pedido/pedido-integracao-model';
import { PessoaCadastroFormModel } from 'model/app/forms/pessoa/pessoa-cadastro-form-model';
import { PessoaContatosModel, PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { picker } from 'utils/picker';
import { usePostEnderecoPessoa, usePostPessoa, usePutEnderecoPessoa, usePutPessoa } from 'data/api/gestao/pessoa';
import { EnumCadastroTipo } from 'model';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { isEmpty } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { newGuid } from 'utils/new-guid';
import classNames from 'classnames';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { stringNumeros } from 'utils/string-numeros';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { Box, Button } from 'views/design-system';
import { DefaultCard } from 'views/components/cards/components';

interface GerarComandaCadastroProps {
    tipoTrabalho: EnumTipoTrabalho
    fluxoPedido?: boolean;
}

export const GerarComandaCadastro = ({ tipoTrabalho, fluxoPedido }: GerarComandaCadastroProps) => {
    const classes = useStyles();
    const { xs } = useThemeQueries();
    const { setClienteByDoc } = useMovAtual();
    const { isTodosOsSaloesPossuemMesa } = usePedidoLocal();

    const { postPedidoDados, carregando: carregandoPedidos } = usePostPedidoDados();
    const { postPessoa, carregando: carregandoPessoa } = usePostPessoa();
    const { putPessoa, carregando: carregandoPutPessoa } = usePutPessoa();
    const { postEnderecoPessoa, carregando: carregandoEnderecoPessoa } = usePostEnderecoPessoa();
    const { putEnderecoPessoa, carregando: carregandoPutEnderecoPessoa } = usePutEnderecoPessoa();
    const { getEmpresaAtual } = useEmpresaAtual()
    const { getEmpresaSelecionada, getPessoa } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const { avancarFluxoMov, redirectPedidoVincularMesa } = useMovRota();
    const carregando = carregandoPessoa || carregandoPedidos || carregandoEnderecoPessoa || carregandoPutPessoa || carregandoPutEnderecoPessoa

    const formRef = useRef<DefaultFormRefs<PedidoDadosModelPost>>(null);
    const [fase, setFase] = useState<number>(1)

    const redirecionarPedido = useCallback(async () => {
        try {
            const possuemMesa = await isTodosOsSaloesPossuemMesa();
            if (!possuemMesa) {
                await redirectPedidoVincularMesa();
                return;
            }
            await avancarFluxoMov();
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [avancarFluxoMov, isTodosOsSaloesPossuemMesa, redirectPedidoVincularMesa, showToast]);

    const compararObjetos = useCallback((pedidoDados: PedidoDadosModelPost, pessoa: PessoaModel, endereco?: PessoaEnderecoModel) => {
        const pessoaPedido = pedidoDados.cliente;
        const enderecoPedido = pedidoDados.enderecoEntrega;

        const pessoaTelefone = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || ''
        const pessoaEmail = pessoa.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)?.valor || ''

        const isPessoalEqual = (
            pessoaPedido.cpfCnpj === pessoa.cpfcnpj &&
            pessoaPedido.email === pessoaEmail &&
            pessoaPedido.telefone === pessoaTelefone &&
            pessoaPedido.nomeFantasia === pessoa.nome
        )

        const isEnderecoEqual = endereco ? (
            enderecoPedido.bairro === endereco.bairro &&
            enderecoPedido.cep === endereco.cep &&
            enderecoPedido.codigoMunicipio === endereco.cMun &&
            enderecoPedido.complemento === endereco.complemento &&
            enderecoPedido.numero === endereco.numero &&
            enderecoPedido.uf === endereco.uf &&
            enderecoPedido.municipio === endereco.xMun &&
            enderecoPedido.logradouro === endereco.logradouro
        ) : false

        return {
            isEnderecoEqual,
            isPessoalEqual
        }
    }, [])

    const pedidoIntegradorWrapper = useCallback(() => {
        const integrador = new PedidoDadosIntegracaoModel();

        integrador.nomeIntegrador = "TouchOne";
        integrador.cnpjIntegrador = "11914993000123";
        integrador.credencialCliente = getEmpresaSelecionada()?.Id ?? '';
        integrador.codigoAplicacao = 990009859;

        return integrador;
    }, [getEmpresaSelecionada])

    const handlePostPessoa = useCallback(
        async (modelP: PessoaCadastroFormModel, contatos: PessoaContatosModel[]) => {
            try {
                const pessoaToCreate = picker<PessoaModel>(modelP, new PessoaModel());

                pessoaToCreate.cpfcnpj = stringNumeros(pessoaToCreate.cpfcnpj);
                pessoaToCreate.documentos = [];

                pessoaToCreate.contatos = contatos
                pessoaToCreate.contratoId = getPessoa()?.pessoa?.contratoId ?? ''

                const ret = await postPessoa(pessoaToCreate);

                if (ret.erro) throw ret.erro

                return ret.resultado?.data
            } catch (e: any) {
                throw e
            }
        },
        [getPessoa, postPessoa],
    );

    const handlePutPessoa = useCallback(async (model: PessoaModel) => {
        try {
            const ret = await putPessoa(model);


            if (ret.erro) throw ret.erro

            return ret.resultado?.data
        } catch (e: any) {
            throw e
        }
    }, [putPessoa])

    const handleSubmit = useCallback(async (model: PedidoDadosModelPost, enderecoBeforeModel?: PessoaEnderecoModel, pessoaBeforeModel?: PessoaModel) => {
        let contatos: PessoaContatosModel[] = []
        if (!isEmpty(model.cliente.telefone)) {
            contatos.push(new PessoaContatosModel(
                newGuid(),
                guidEmpty(),
                EnumTipoPessoaContato.TELEFONE,
                model.cliente.telefone
            ))
        }

        if (!isEmpty(model.cliente.email)) {
            contatos.push(new PessoaContatosModel(
                newGuid(),
                guidEmpty(),
                EnumTipoPessoaContato.EMAIL,
                model.cliente.email
            ))
        }
        try {
            //Verificando se tem algum campo de endereço preenchido
            const isEnderecoEmpty = Object.values(model.enderecoEntrega).reduce<boolean>((prev, curr) => {
                if (!isEmpty(curr) || !prev) {
                    return false
                }

                return true
            }, true)

            //IF CASO A PESSOA JÁ EXISTA
            if (pessoaBeforeModel && pessoaBeforeModel?.id !== guidEmpty()) {

                contatos = contatos.map(contato => ({
                    ...contato,
                    pessoaId: pessoaBeforeModel.id
                }))

                const { isEnderecoEqual, isPessoalEqual } = compararObjetos(model, pessoaBeforeModel, enderecoBeforeModel)
                if (!isPessoalEqual) {
                    const pessoaPutModel = {
                        ...pessoaBeforeModel,
                        contatos,
                        cpfcnpj: model.cliente.cpfCnpj,
                        nome: model.cliente.nomeFantasia,
                    }
                    await handlePutPessoa(pessoaPutModel)
                }

                if (!isEnderecoEqual && enderecoBeforeModel) {
                    const enderecoPutModel = {
                        ...enderecoBeforeModel,
                        cep: model.enderecoEntrega.cep,
                        logradouro: model.enderecoEntrega.logradouro,
                        numero: model.enderecoEntrega.numero,
                        complemento: model.enderecoEntrega.complemento,
                        referencia: model.enderecoEntrega.pontoReferencia,
                        bairro: model.enderecoEntrega.bairro,
                        cMun: model.enderecoEntrega.codigoMunicipio,
                        xMun: model.enderecoEntrega.municipio,
                        uf: model.enderecoEntrega.uf,
                    }
                    await putEnderecoPessoa(enderecoPutModel)
                } else if (!isEnderecoEmpty && !isEnderecoEqual) {
                    const endereco = new PessoaEnderecoModel(
                        guidEmpty(),
                        pessoaBeforeModel.id,
                        model.enderecoEntrega.cep,
                        model.enderecoEntrega.logradouro,
                        model.enderecoEntrega.numero,
                        model.enderecoEntrega.complemento,
                        model.enderecoEntrega.pontoReferencia,
                        model.enderecoEntrega.bairro,
                        model.enderecoEntrega.codigoMunicipio,
                        model.enderecoEntrega.municipio,
                        model.enderecoEntrega.uf,
                    )
                    await postEnderecoPessoa(endereco)
                }
            } else { //ELSE CASO VÁ CRIAR UMA PESSOA NOVA

                const retPessoa = await handlePostPessoa(new PessoaCadastroFormModel(
                    EnumCadastroTipo.CLIENTE,
                    model.cliente.cpfCnpj,
                    model.cliente.nomeFantasia,
                ), contatos) as PessoaModel

                if (!isEnderecoEmpty) {
                    const endereco = new PessoaEnderecoModel(
                        guidEmpty(),
                        retPessoa.id,
                        model.enderecoEntrega.cep,
                        model.enderecoEntrega.logradouro,
                        model.enderecoEntrega.numero,
                        model.enderecoEntrega.complemento,
                        model.enderecoEntrega.pontoReferencia,
                        model.enderecoEntrega.bairro,
                        model.enderecoEntrega.codigoMunicipio,
                        model.enderecoEntrega.municipio,
                        model.enderecoEntrega.uf,
                    )

                    await postEnderecoPessoa(endereco)
                }
            }

            if (fluxoPedido) {
                await setClienteByDoc(model.cliente.cpfCnpj)
                redirecionarPedido();
                return
            }

            model.documentoLoja = getEmpresaAtual()!.cpfcnpj;
            model.dadosIntegracao = pedidoIntegradorWrapper();

            const res = await postPedidoDados(getEmpresaAtual()!.id, {
                ...model,
                enderecoEntrega: new PedidoDadosModelPost().enderecoEntrega
            })

            if (res.erro) throw res.erro

            setFase(2)

        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [compararObjetos, fluxoPedido, getEmpresaAtual, handlePostPessoa, handlePutPessoa, pedidoIntegradorWrapper, postEnderecoPessoa, postPedidoDados, putEnderecoPessoa, redirecionarPedido, setClienteByDoc, showToast])

    useEffect(() => {
        if (fase === 1)
            formRef.current?.fillForm(new PedidoDadosModelPost())
    }, [fase])

    return (
        <Box className={classes.container}>
            {fase === 1 && (
                <DefaultCard hasTagStatus={false} className={classes.cardContainer}>
                    <Box className={classes.cardContent}>
                        <FormGerarComanda
                            loading={carregando}
                            onSubmit={async () => { }}
                            handleSubmit={handleSubmit}
                            showLoading={carregando}
                            ref={formRef}
                            esconderMesaEComandas={fluxoPedido}
                        />
                        <Button
                            fullWidth
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                formRef.current?.submitForm()
                            }}
                            className={xs ? classNames(classes.submitButton, 'round') : 'round'}
                        >
                            <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                            {fluxoPedido ? 'Confirmar Cliente' : 'Confirmar Dados e Registrar'}
                        </Button>
                    </Box>
                </DefaultCard>
            )}
            {fase === 2 && (
                <Box flex flexDirection='column' justifyContent='space-between' pb={1} pt={2}>
                    <GerarComandaConfirmacao fase={fase} label={"Sucesso ao Registrar"} />
                    <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            formRef.current?.resetForm()
                            setFase(1)
                        }}
                        className={xs ? classNames(classes.submitButton, 'round') : 'round'}
                    >
                        <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                        Novo Registro
                    </Button>
                </Box>
            )}
        </Box>
    );
};