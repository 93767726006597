import { usePostRelatorioAvancado } from "data/api/gestao/relatorios/relatorios-avancados/post-relatorio-avancado";
import { TipoRelatorio } from "model/api/gestao/relatorios-avancados/relatorios-avancados-model";
import { useCallback } from "react";
import { FormRelatoriosAvancadosPost } from "views/components/form/relatorios-avancados/form-relatorios-avancados-edit/form-relatorios-avancados-edit";
import { useToastSaurus } from "./toast-saurus";
import { CodRelatorioFileMock } from "data/mocks/cod-relatorio-mock";

export const useExportarRelatorio = () => {
    const { postRelatorioAvancado, carregando } = usePostRelatorioAvancado();
    const { showToast } = useToastSaurus();

    const downloadFile = async (
        link: string,
        codigo: number,
    ) => {
        window.open(link, '_blank', `title=${`relatorio-${codigo}`}`);
    };

    const handleDownload = useCallback(
        (link: any, codigo: number, modelo: TipoRelatorio) => {
            downloadFile(
                link,
                codigo
            );
        },
        []
    );

    const saveChangesRelatorio = useCallback(
        async (
            relatoriosAvancadosModelForm: FormRelatoriosAvancadosPost,
            modelo: TipoRelatorio
        ) => {
            const ret = await postRelatorioAvancado(
                relatoriosAvancadosModelForm,
                modelo
            );

            if (ret.erro) {
                throw ret.erro;
            }

            if (!ret.resultado?.data) {
                throw new Error('Consulta não retornou resultados.')
            }

            switch (modelo) {
                case TipoRelatorio.EXCEL:
                    const blob = new Blob([ret.resultado?.data], { type: 'application/vnd.ms-excel' });
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a')

                    anchor.href = url
                    anchor.download = `Relatorio-${CodRelatorioFileMock.find(x => x.Key === relatoriosAvancadosModelForm.relatorio)?.Value || ''}-${new Date().toLocaleDateString().replaceAll('/', '-')}.xls`
                    anchor.click()
                    break;
                case TipoRelatorio.PDF:
                    if (ret.resultado?.data?.urlRelatorio.length >= 1) {
                        handleDownload(
                            ret.resultado?.data?.urlRelatorio,
                            relatoriosAvancadosModelForm.relatorio,
                            modelo
                        );
                        showToast(
                            'success',
                            ret.resultado?.data?.retTexto ?? 'Relatório tirado com sucesso!'
                        );
                    } else {
                        showToast('info', 'Não identificamos nenhum relatório');
                    }
                    break;
            }

        },
        [
            handleDownload,
            postRelatorioAvancado,
            showToast
        ]
    );

    const exportarRelatorio = useCallback(
        async (
            model: any,
            modelo: TipoRelatorio,
        ) => {
            await saveChangesRelatorio(
                model,
                modelo,
            );
        },
        [saveChangesRelatorio]
    );
    return {
        exportarRelatorio,
        carregando,
    }
}