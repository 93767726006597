import { CardNaoEncontrado } from 'views/components/cards';
import { InformacaoIcon } from 'views/components/icons';
import { CardPendenciasFiscais } from 'views/components/cards/card-pendencias-fiscais/card-pendencias-fiscais';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { useEffect, useState } from 'react';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { HistoricoVendaPendentesResumidoModel } from 'model/api/gestao/historico-vendas/historico-vendas-pendentes-resumido';

export interface HistoricoVendasGridProps {
    list: Array<HistoricoVendaPendentesResumidoModel>;
    carregando: boolean;
    selectedList: Array<string>;
    onCardSelected: (id: string) => any;
    onCardChecked: (id: string) => any;
}

export const HistoricoVendasPendentesListData = (props: HistoricoVendasGridProps) => {

    const [consumidorPadrao, setConsumidorPadrao] = useState<PessoaModel>(new PessoaModel())

    const { getConsumidor } = useCadastroPadrao();

    const onCardSelected = (id: string) => {
        props.onCardSelected(id);
    };

    const onCardChecked = (id: string) => {
        props.onCardChecked(id);
    };

    useEffect(() => {
        (async () => {
            const consumidorPadrao = await getConsumidor();
            if (consumidorPadrao) {
                setConsumidorPadrao(consumidorPadrao)
            }
        })()
    }, [getConsumidor])

    return (
        <>
            {props.list.length === 0 && !props.carregando && (
                <CardNaoEncontrado
                    mensagem="Nenhuma movimentação encontrada."
                    icon={<InformacaoIcon tipo="GERAL" />}
                />
            )}
            {props.list.length > 0 &&
                props.list.map((historico) => {

                    const index = `card-pendencias-fiscais-${historico.id}`

                    return <CardPendenciasFiscais
                        key={index}
                        consumidorPadrao={consumidorPadrao}
                        model={historico}
                        onCheck={onCardChecked}
                        onClick={onCardSelected}
                        selected={props.selectedList.includes(historico.id)}
                    />
                })}
        </>
    );
};
