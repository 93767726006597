import { VariaveisAmbiente } from "config";
import { useCallback } from "react";
import { useApiBase } from "../../base/api-base";

export function usePostConversaoPessoaCSV() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postCargaPessoas = useCallback(
    (carga: string) => {
      return invocarApi({
        url: `/v3/conversao/pessoa/csv`,
        method: "POST",
        headers: { "Content-Type": "application/csv" },
        data: `${carga}`,
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlConversoes}/api`,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postCargaPessoas,
  };
}

export function usePostConversaoProdutoCSV() {
  const { invocarApi, ...outrasPropriedades } = useApiBase();

  const postCargaProdutos = useCallback(
    (carga: string) => {
      return invocarApi({
        url: `/v3/conversao/produto/csv`,
        method: "POST",
        headers: { "Content-Type": "application/csv" },
        data: `${carga}`,
        enviarTokenUsuario: true,
        baseURL: `${VariaveisAmbiente.apiUrlConversoes}/api`,
      });
    },
    [invocarApi]
  );

  return {
    ...outrasPropriedades,
    postCargaProdutos,
  };
}
