import { RepresentanteCliente } from 'model/api/gestao/pessoa/pessoa-model';

export interface TabelaClientes {
    idIndexed?: number;
    id: string | null;
    contratoId: string | null;
    regimeTributarioId: string | null;
    tabelaPrecoId: string | null;
    representanteId: string | null;
    tpCadastro: number;
    cpfcnpj: string | null;
    ierg: string | null;
    im: string | null;
    indIEDest: number;
    nome: string | null;
    fantasia: string | null;
    infOperador: string | null;
    infInterno: string | null;
    usuarioId: string | null
    status: number;
    contatos: [
        {
            id: string | null;
            pessoaId: string | null;
            tipo: string | null;
            valor: string | null
        }
    ] | [],
    documentos: [
        {
            id: string;
            pessoaId: string;
            tipo: number;
            documento: string;
            dataExpedicao: string;
            ufExpedicao: string;
            orgaoExpedicao: string;
            dataValidade: string;
            informacoes: string;
            indIEDest: number;
        }
    ] | [],
    dtNasc: string | null,
    sexo: number | null,
    representante: RepresentanteCliente | null
    enderecos: [
        {
            id: string | null
            bairro: string | null
            cMun: string | null
            cep: string | null
            complemento: string | null
            cuf: number
            ierg: string | null
            indIEDest: number
            isEntrega: boolean | null
            isFiscal: boolean | null
            latitude: number
            logradouro: string | null
            longitude: number
            numero: string | null
            pessoaId: string | null
            pessoaTransportadoraPadraoId: string | null
            referencia: string | null
            uf: string | null
            xMun: string | null
            im: string | null
        }
    ] | []
}

export class TabelaClientesModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public regimeTributarioId: string = '',
        public tabelaPrecoId: string = '',
        public representanteId: string = '',
        public tpCadastro: number = 0,
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public indIEDest: number = 0,
        public nome: string = '',
        public fantasia: string = '',
        public infOperador: string = '',
        public infInterno: string = '',
        public usuarioId: string = '',
        public status: number = 0,
        public contatos: Array<{ id: string; pessoaId: string; tipo: string; valor: string }> = [],
        public documentos: Array<{ id: string; pessoaId: string; tipo: number; documento: string; dataExpedicao: string; ufExpedicao: string; orgaoExpedicao: string; dataValidade: string; informacoes: string; indIEDest: number }> = [],
        public dtNasc: string = '',
        public sexo: number = 0,
        public representante: RepresentanteCliente | null = null,
        public enderecos: Array<{ id: string; bairro: string; cMun: string; cep: string; complemento: string; cuf: number; ierg: string; indIEDest: number; isEntrega: boolean; isFiscal: boolean; latitude: number; logradouro: string; longitude: number; numero: string; pessoaId: string; pessoaTransportadoraPadraoId: string; referencia: string; uf: string; xMun: string; im: string }> = [],
        public idIndexed?: number,
    ) {}
}
