import { AppEventEnum } from "model/enums/enum-app-event";
import { useCallback, useEffect, useState } from "react";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { HistoricoVendaFiltroModal } from "views/components/modals/historico-venda-filtro/historico-venda-filtro-modal";
import { HistoricoVendaListSearchProps } from "views/pages/private/historico-vendas/components/historico-venda-tabs/components/historico-venda-list-search-props";

export const HistoricoVendailtroModalRender = () => {
    const { addHandler, removeHandler } = useEventTools();
    const [modalState, setModalState] = useState({
        openned: false,
        filtros: {
            serie: '',
            status: -1,
            dFinal: '',
            dInicial: '',
            empresa: '',
            nnf: ''
        } as HistoricoVendaListSearchProps,
        apenasPendencia: false,
    });

    const modalAlterado = useCallback(({ openned, filtros, apenasPendencia }: any) => {
        setModalState({
            openned,
            filtros,
            apenasPendencia,
        });
    }, [])

    useEffect(() => {
        addHandler(AppEventEnum.HistoricoVendaFiltroModal, modalAlterado);
        return () => {
            removeHandler(AppEventEnum.HistoricoVendaFiltroModal, modalAlterado);
        };
    }, [addHandler, modalAlterado, removeHandler]);

    if (!modalState.openned) {
        return null
    }

    return (
        <HistoricoVendaFiltroModal
            filtros={modalState.filtros}
            openned={modalState.openned}
            apenasPendencia={modalState.apenasPendencia}
        />
    )
}