import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    errorVenda: {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        border: `solid 1px ${theme.palette.error.main}`,
        marginBottom: theme.spacing(2),
        background: 'rgb(244 67 54 / 8%)',
    }
}))