import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormAtendimentoDeliveryValidation } from './form-cliente-delivery-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { useStyles } from './form-cliente-delivery-styles';
import { useConsultaCEP } from 'data/api/wsmaster';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { useToastSaurus } from 'services/app';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';
import { picker } from 'utils/picker';
import { removeGuidEmpty } from 'utils/remove-guid-empty';
import { useGetEnderecoPessoa, useGetPessoaById, useGetPessoas } from 'data/api/gestao/pessoa';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { guidEmpty } from 'utils/guid-empty';
import { FecharIcon } from 'views/components/icons';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { DefaultFormProps, DefaultFormRefs } from '../../utils';
import { CircularLoading } from 'views/components/utils';
import { isEmpty } from 'lodash';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import classNames from 'classnames';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { stringNumeros } from 'utils/string-numeros';

interface FormAtendimentoDeliveryProps extends DefaultFormProps<PedidoDadosModelPost> {
    handleSubmit: (model: PedidoDadosModelPost, enderecoBeforeModel?: PessoaEnderecoModel, pessoaBeforeModel?: PessoaModel) => Promise<any>;
    pedidoState?: PedidoModel;
}

export const FormAtendimentoDelivery = forwardRef<
    DefaultFormRefs<PedidoDadosModelPost>,
    FormAtendimentoDeliveryProps
>(({ pedidoState, ...props }: FormAtendimentoDeliveryProps, ref) => {
    const utilClasses = makeUtilClasses();
    const classes = useStyles();
    const { showToast } = useToastSaurus();
    const { theme } = useThemeQueries();

    const { consultaCEP, carregando: carregandoCep } = useConsultaCEP();
    const { getPessoas, carregando: carregandoPessoa } = useGetPessoas();
    const { getPessoaById, carregando: carregandoPessoaId } = useGetPessoaById()
    const { getEnderecoPessoa, carregando: carregandoEndereco } = useGetEnderecoPessoa();
    const { getMov } = useMovAtual()
    const { getConfigByCod } = useContratoAtual()
    const consumidorPadrao = getConfigByCod(EnumContratoConfig.ClientePadrao)
    const refInputNumero = useRef<HTMLInputElement>(null);

    const carregando = [props.loading, carregandoCep, carregandoEndereco, carregandoPessoa, carregandoPessoaId].includes(true)


    const { formAtendimentoDeliveryValidationYup } = useFormAtendimentoDeliveryValidation();

    const [query, setQuery] = useState('')
    const [uf, setUf] = useState<string>('')

    const [jaCadastrado, setJaCadastrado] = useState<boolean>(false);
    const pessoaBeforeModel = useRef<PessoaModel>(new PessoaModel())
    const enderecoBeforeModel = useRef<PessoaEnderecoModel>(new PessoaEnderecoModel())

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors, touchedFields }
    } = useForm<PedidoDadosModelPost>({
        defaultValues: { ...props.model },
        resolver: yupResolver(formAtendimentoDeliveryValidationYup),
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: PedidoDadosModelPost) => {
        let model = picker<PedidoDadosModelPost>(values, new PedidoDadosModelPost(), false)
        model = removeGuidEmpty(model, true)
        props.handleSubmit(model,
            enderecoBeforeModel.current && enderecoBeforeModel.current.id !== guidEmpty() ? enderecoBeforeModel.current : undefined,
            pessoaBeforeModel.current ? pessoaBeforeModel.current : undefined
        );
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: PedidoDadosModelPost) => {
            reset({ ...model, quantidadePessoas: 1 })
        },
    }));

    const searchCliente = useCallback(
        async (query: string, pessoaId: string = '') => {
            try {
                if (isEmpty(query) && isEmpty(pessoaId)) {
                    return;
                }

                let cliente = new PessoaModel()

                if (pessoaId) {
                    const res = await getPessoaById(pessoaId);
                    if (res.erro) throw res.erro;
                    if (!res.resultado) throw new Error('Erro ao pesquisar Cliente.')

                    cliente = res.resultado.data as PessoaModel

                } else {
                    query = query.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
                    const res = await getPessoas(`generico=${query}`, 0);
                    if (res.erro) throw res.erro;
                    if (!res.resultado) throw new Error('Erro ao pesquisar Cliente.')

                    if (res.resultado?.data.list.length < 1) {
                        showToast('error', 'Cliente não encontrado.')
                        return
                    }

                    cliente = res.resultado.data.list[0] as PessoaModel
                }

                pessoaBeforeModel.current = cliente

                setValue('cliente.cpfCnpj', cliente.cpfcnpj)
                setValue('cliente.nomeFantasia', cliente.nome)
                setValue('cliente.email', cliente.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.EMAIL)?.valor || '')
                setValue('cliente.telefone', cliente.contatos.find(contato => contato.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '')

                try {
                    const resEndereco = await getEnderecoPessoa(cliente.id)

                    if (resEndereco.erro) throw resEndereco.erro
                    if (!resEndereco.resultado) throw new Error()

                    if (resEndereco.resultado?.data.length < 1) {
                        return
                    }

                    const endereco = resEndereco.resultado.data[0] as PessoaEnderecoModel

                    enderecoBeforeModel.current = endereco

                    setValue('enderecoEntrega.cep', endereco.cep)
                    setValue('enderecoEntrega.codigoMunicipio', endereco.cMun)
                    setValue('enderecoEntrega.municipio', endereco.xMun)
                    setValue('enderecoEntrega.logradouro', endereco.logradouro)
                    setValue('enderecoEntrega.bairro', endereco.bairro)
                    setValue('enderecoEntrega.uf', endereco.uf)
                    setValue('enderecoEntrega.numero', endereco.numero)
                    setValue('enderecoEntrega.complemento', endereco.complemento)
                    setUf(endereco.uf)

                } catch (e: any) {

                }

                setJaCadastrado(true)
            } catch (e: any) {
                showToast('error', e.message);
            }
        },
        [getEnderecoPessoa, getPessoaById, getPessoas, setValue, showToast],
    );

    const removerCliente = useCallback(() => {
        pessoaBeforeModel.current = new PessoaModel()
        enderecoBeforeModel.current = new PessoaEnderecoModel()

        setJaCadastrado(false)
    }, [])

    useEffect(() => {
        const mov = getMov()
        if (pedidoState) {
            searchCliente('', pedidoState.cliente.referenceId)
        }
        if (!mov) return

        if (!isEmpty(mov.cliente?.id) && mov.cliente?.id !== consumidorPadrao) {
            searchCliente('', mov.cliente?.id || '')
        }

        if (mov.receitasMedicas.length > 0) {
            const receita = mov.receitasMedicas.find(receita => {
                if (receita.comprador) {
                    return true
                }
                return false;
            })

            if (receita) {
                const comprador = receita.comprador;

                if (comprador.pessoaId && comprador.pessoaId !== guidEmpty()) {
                    searchCliente('', comprador.pessoaId);
                    return
                }

                setValue('cliente.cpfCnpj', comprador.cpfcnpj);
                setValue('cliente.nomeFantasia', comprador.nome);
                setValue('cliente.telefone', comprador.telefone || '');
                setValue('cliente.dataNascimento', comprador.dataNascimento || '');

                setValue('enderecoEntrega.cep', comprador.cep)
                setValue('enderecoEntrega.codigoMunicipio', comprador.codMunicipio ? comprador.codMunicipio.toString() : '')
                setValue('enderecoEntrega.municipio', comprador.municipio)
                setValue('enderecoEntrega.logradouro', comprador.logradouro)
                setValue('enderecoEntrega.uf', comprador.uf)
                setValue('enderecoEntrega.numero', comprador.numero)
                setValue('enderecoEntrega.complemento', comprador.complemento)
                setUf(comprador.uf)

            }
        }

    }, [consumidorPadrao, getMov, pedidoState, searchCliente, setValue])

    const handleConsultaCep = async (value: string) => {
        try {
            let res = await consultaCEP(value);

            setValue('enderecoEntrega.cep', res.CEP)
            setValue('enderecoEntrega.municipio', res.Municipio)
            setValue('enderecoEntrega.logradouro', res.Logradouro)
            setValue('enderecoEntrega.bairro', res.Bairro)
            setValue('enderecoEntrega.uf', res.UF)
            setUf(res.UF)
            refInputNumero.current?.focus();
        }
        catch (err: any) {
            showToast('error', err.message)
        }
    }

    return (
        <>
            <div className={utilClasses.formContainer}>
                {carregando && props.showLoading ? (
                    <div className={utilClasses.controlLoading}>
                        <CircularLoading tipo="FULLSIZED" />
                    </div>
                ) : null}

                <Grid container spacing={2} justifyContent='center' className={classes.container}>
                    <Grid item xs={12} style={{ paddingBottom: 0, display: 'flex', gap: theme.spacing(2) }}>
                        <Typography color='primary' variant='h6'>Dados do Cliente</Typography>
                        {(jaCadastrado || pedidoState) && (
                            <Box className={classes.card}>
                                <div className={classes.cardContent}>
                                    <Typography className={classes.moduloNome} variant="body2">
                                        {pedidoState ? (
                                            <>
                                                Pedido #{pedidoState.codigoPedido}
                                            </>
                                        ) : (
                                            <>
                                                Cliente: {pessoaBeforeModel.current.nome}
                                            </>
                                        )}
                                    </Typography>
                                    {!pedidoState && (
                                        <FecharIcon tipo="BUTTON" style={{ width: 16, cursor: 'pointer' }} onClick={() => removerCliente()} />
                                    )}
                                </div>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldSaurus
                            label='Pesquisar Cliente'
                            placeholder='Telefone, Documento ou Nome'
                            searchable
                            allowSubmit={false}
                            onSearch={async (value: string) => searchCliente(value)}
                            value={query}
                            onChange={(e: any) => setQuery(e.target.value)}
                        />
                    </Grid>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={carregando ? utilClasses.controlLoading : ''}
                    >
                        <Grid container spacing={2} item xs={12} justifyContent='center' className={classNames(classes.containerForm)}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="cliente.telefone"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="TELEFONE"
                                            disabled={carregando}
                                            fullWidth
                                            variant="outlined"
                                            label="Telefone *"
                                            placeholder={'Ex: (11) 12345-6789'}
                                            error={Boolean(
                                                errors.cliente?.telefone && errors.cliente?.telefone.message
                                            )}
                                            helperText={
                                                touchedFields.cliente?.telefone || errors.cliente?.telefone
                                                    ? errors.cliente?.telefone?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="cliente.nomeFantasia"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="TEXTO"
                                            disabled={carregando}
                                            fullWidth
                                            variant="outlined"
                                            label="Nome *"
                                            placeholder={'EX: Miguel Rodrigues da Silva'}
                                            error={Boolean(
                                                errors.cliente?.nomeFantasia && errors.cliente?.nomeFantasia.message
                                            )}
                                            helperText={
                                                touchedFields.cliente?.nomeFantasia || errors.cliente?.nomeFantasia
                                                    ? errors.cliente?.nomeFantasia?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="cliente.cpfCnpj"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="CNPJ_CPF"
                                            disabled={carregando}
                                            fullWidth
                                            variant="outlined"
                                            label="Documento *"
                                            placeholder={'CPF ou CNPJ'}
                                            error={Boolean(
                                                errors.cliente?.cpfCnpj && errors.cliente?.cpfCnpj.message
                                            )}
                                            helperText={
                                                touchedFields.cliente?.cpfCnpj || errors.cliente?.cpfCnpj
                                                    ? errors.cliente?.cpfCnpj?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name="cliente.email"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            tipo="EMAIL"
                                            disabled={carregando}
                                            fullWidth
                                            variant="outlined"
                                            label="E-mail"
                                            placeholder={'seunome@email.com'}
                                            error={Boolean(
                                                errors.cliente?.email && errors.cliente?.email.message
                                            )}
                                            helperText={
                                                touchedFields.cliente?.email || errors.cliente?.email
                                                    ? errors.cliente?.email?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                <Typography color='primary' variant='h6'>Dados do Endereço</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant='fullWidth' />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Controller
                                    name="enderecoEntrega.cep"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            allowSubmit={false}
                                            tipo="CEP"
                                            label="CEP *"
                                            placeholder='Ex: 99999-999'
                                            searchable
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.cep && errors.enderecoEntrega?.cep.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.cep || errors.enderecoEntrega?.cep
                                                    ? errors.enderecoEntrega?.cep?.message
                                                    : undefined
                                            }
                                            {...field}
                                            onSearch={handleConsultaCep}
                                            onChange={e => {
                                                field.onChange(e)
                                                const { value } = e.target

                                                if (stringNumeros(value).length === 8) {
                                                    setTimeout(() => handleConsultaCep(value), 400)
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Controller
                                    name="enderecoEntrega.logradouro"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            placeholder='Ex: Rua Ipiranga'
                                            allowSubmit={false}
                                            label="Endereço *"
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.logradouro && errors.enderecoEntrega?.logradouro.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.logradouro || errors.enderecoEntrega?.logradouro
                                                    ? errors.enderecoEntrega?.logradouro?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Controller
                                    name="enderecoEntrega.numero"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            allowSubmit={false}
                                            inputRef={refInputNumero}
                                            tipo="NUMERO"
                                            label="Número *"
                                            placeholder='Ex: 10'
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.numero && errors.enderecoEntrega?.numero.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.numero || errors.enderecoEntrega?.numero
                                                    ? errors.enderecoEntrega?.numero?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="enderecoEntrega.complemento"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            allowSubmit={false}
                                            label="Complemento"
                                            placeholder='Ex: Bloco 3, apartamento 05'
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.complemento && errors.enderecoEntrega?.complemento.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.complemento || errors.enderecoEntrega?.complemento
                                                    ? errors.enderecoEntrega?.complemento?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Controller
                                    name="enderecoEntrega.bairro"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            allowSubmit={false}
                                            label="Bairro *"
                                            placeholder='Ex: Tatuapé'
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.bairro && errors.enderecoEntrega?.bairro.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.bairro || errors.enderecoEntrega?.bairro
                                                    ? errors.enderecoEntrega?.bairro?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <Controller
                                    name="enderecoEntrega.municipio"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldSaurus
                                            readOnly={carregando}
                                            allowSubmit={false}
                                            tipo="TEXTO"
                                            label="Município *"
                                            placeholder='Ex: São Paulo'
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.municipio && errors.enderecoEntrega?.municipio.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.municipio || errors.enderecoEntrega?.municipio
                                                    ? errors.enderecoEntrega?.municipio?.message
                                                    : undefined
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <Controller
                                    name="enderecoEntrega.uf"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectSaurus
                                            conteudo={UFMock}
                                            disabled={carregando}
                                            allowSubmit={false}
                                            label="UF *"
                                            fullWidth
                                            autoComplete={'off'}
                                            error={Boolean(
                                                errors.enderecoEntrega?.uf && errors.enderecoEntrega?.uf.message
                                            )}
                                            helperText={
                                                touchedFields.enderecoEntrega?.uf || errors.enderecoEntrega?.uf
                                                    ? errors.enderecoEntrega?.uf?.message
                                                    : undefined
                                            }
                                            {...field}
                                            value={UFMock.find(ufItem => ufItem.Value === uf)?.Key}
                                            onChange={(event) => {
                                                if (event) {
                                                    const item = UFMock.filter(
                                                        (item) => item.Key === event.target.value,
                                                    )[0];
                                                    if (item) {
                                                        setValue('enderecoEntrega.uf', item.Value);
                                                        setUf(item.Value)
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Button style={{ display: 'none' }} type="submit"></Button>
                    </form>
                </Grid>
            </div>
        </>
    );
});
