import { DefaultCard, useDefaultCardStyles } from '../../../components';
import { CardOperadoresProps } from './card-operadores-props';
import { useCallback, useEffect } from 'react';
import { useStyles } from './card-operadores-styles';
import { toDateString } from 'utils/to-date';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useSessaoAtual,
  useToastSaurus
} from 'services/app';
import { isEmpty } from 'lodash';
import { useThemeQueries } from 'views/theme';
import { usePDV } from 'services/app/hooks/pdv';
import { useGetSessaoImpressao } from 'data/api/gestao/sessao/get-impressao';
import { useSessaoPDV } from 'services/app/hooks/sessao-pdv';
import { CircularLoading } from 'views/components/utils';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { ModuloResumoSessaoProps } from 'views/components/dialog/dialog-config-resumo-sessao/dialog-config-resumo-sessao';
import { AppEventEnum } from 'model/enums/enum-app-event';
import classNames from 'classnames';
import { Tooltip } from 'views/design-system';
import { CardOperadoresDesktop } from './components/card-operadores-desktop/card-operadores-desktop';
import { CardOperadoresMobile } from './components/card-operadores-mobile/card-operadores-mobile';


export interface CardOperadoresSituacaoProps extends CardOperadoresProps {
  dataAbertura: string | undefined
  dataFechamento: string
  dataConferencia: string | undefined
  handleImpressaoClick: (event: any) => void
}

const dataComOuSemData = (model: string, comData: boolean = true) => {
  let mascara = "HH:mm"
  if (comData) {
    mascara = 'DD/MM/yyyy HH:mm'
  }

  return toDateString(model, mascara)
}

function formatarDataFechamento(dataAbertura: string, dataFechamento: string): string {
  if (!dataFechamento) {
    return ''
  }

  // Extrai apenas as partes da data para comparar
  const dataAberturaDia = toDateString(dataAbertura);
  const dataFechamentoDia = toDateString(dataFechamento);

  const isMesmaData = dataAberturaDia === dataFechamentoDia
  // Se as datas forem iguais, retorna apenas o horário
  if (isMesmaData) {
    return dataComOuSemData(dataFechamento, false) ?? '';
  }

  return dataComOuSemData(dataFechamento) ?? '';
}

export const CardOperadores = ({
  ...props
}: CardOperadoresProps) => {

  const { model } = props

  //AUX
  const classes = useDefaultCardStyles();
  const classesOperador = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const { addHandler, removeHandler } = useEventTools();

  //PROVIDERS
  const { getPDV, getImpressoraPdv } = usePDV();
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { imprimirResumo } = useSessaoPDV();
  const { getRegistro } = useGestaoStorage();
  const { abrirDialogEdicaoGerenciamentoSessao, abrirConfigResumoSessao } =
    useCadastros();

  //CHAMADAS DA API
  const { getSessaoImpressao, carregando } = useGetSessaoImpressao();

  const getImpressaoSessaoWrapper = useCallback(async () => {
    try {
      const query = getRegistro(
        GestaoStorageKeys.ResumoSessao,
        false
      ) as ModuloResumoSessaoProps[];
      const filtros = query.map((filtro) => {
        return `&${filtro.query}=${filtro.selecionado}`.toString();
      });
      const queryTratada = filtros.join('');

      const qtdColuna = getPDV()?.configuracoes.find((p) => p.cod === 57);

      const res = await getSessaoImpressao(
        getEmpresaSelecionada()!.Id,
        model.caixaId,
        model.id,
        qtdColuna?.vConfig ?? 62,
        queryTratada
      );

      if (res.erro) {
        throw new Error(res.erro);
      }

      if (getImpressoraPdv()) {
        imprimirResumo(
          res?.resultado?.data?.texto ?? '',
          getPDV()?.numCaixa.toString() ?? ''
        );
      }


    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [
    getEmpresaSelecionada,
    getImpressoraPdv,
    getPDV,
    getSessaoImpressao,
    imprimirResumo,
    showToast,
    getRegistro,
    model.caixaId,
    model.id
  ]);

  const retornarDescricaoStatus = useCallback(() => {
    if (isEmpty(model.dFechamento)) {
      return 'Sessão Aberta';
    } else if (isEmpty(model.dConferencia)) {
      return 'Sessão Fechada';
    } else {
      return 'Sessão Conferida';
    }
  }, [model.dConferencia, model.dFechamento]);

  const handleImpressaoClick = useCallback(
    (event: any) => {
      event.stopPropagation();
      abrirConfigResumoSessao(model.id);
    },
    [abrirConfigResumoSessao, model.id]
  );

  const chamarResumo = useCallback(
    ({ aberto, sessaoId }: any) => {
      if (!aberto) {
        if (sessaoId === model.id) {
          const resumo = async () => {
            await getImpressaoSessaoWrapper();
          };
          resumo();
        }
      }
    },
    [getImpressaoSessaoWrapper, model.id]
  );

  useEffect(() => {
    addHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);

    return () => {
      removeHandler(AppEventEnum.DialogConfigResumoSessao, chamarResumo);
    };
  }, [addHandler, chamarResumo, removeHandler]);

  const dataAbertura = dataComOuSemData(model?.dAbertura)
  const dataFechamento = formatarDataFechamento(model?.dAbertura, model?.dFechamento)
  const dataConferencia = dataComOuSemData(model?.dConferencia)

  const componentsProps: CardOperadoresSituacaoProps = {
    ...props,
    dataAbertura,
    dataFechamento,
    dataConferencia,
    handleImpressaoClick,
  }

  return (
    <DefaultCard onClick={async () => abrirDialogEdicaoGerenciamentoSessao(model)}>
      {carregando && <CircularLoading tipo="FULLSIZED" />}
      <Tooltip arrow title={retornarDescricaoStatus()} placement="right">
        <div
          className={classNames(
            classes.tagStatus,
            isEmpty(model.dFechamento)
              ? classesOperador.statusPendente
              : isEmpty(model.dConferencia)
                ? classesOperador.statusFechada
                : classesOperador.statusConferida
          )}
        ></div>
      </Tooltip>

      {isMobile ? (
        <CardOperadoresMobile {...componentsProps} />
      ) : (
        <CardOperadoresDesktop {...componentsProps} />
      )}
    </DefaultCard>
  );
};
