import { DisabledTable, DisabledTableModel } from "./interface-disabled.table";

export interface TabelaPromocoesDePor extends DisabledTable {
    idIndexed?: number;
    promocaoId: string;
    promocao: string;
    dataInicial: number;
    dataFinal: number;
    horaInicial: number;
    horaFinal: number;
    diasSemana: number;
    valorPromocao: number;
    ativo: boolean;
    variacaoId: string;
    codigo: string | null;
    id: string | null;
}

export class TabelaPromocoesDePorModel extends DisabledTableModel {
    constructor(
        public promocaoId: string = '',
        public promocao: string = '',
        public dataInicial: number = 0,
        public dataFinal: number = 0,
        public horaInicial: number = 0,
        public horaFinal: number = 0,
        public diasSemana: number = 0,
        public valorPromocao: number = 0,
        public ativo: boolean = false,
        public variacaoId: string = '',
        public codigo: string | null = '',
        public id: string | null = '',
        public idIndexed?: number,
    ) { super() }
}