import { VariaveisAmbiente } from "config";
import { useGetConfigEmissor } from "data/api/gestao/empresa/get-config-emissor";
import { useGetConfiguracoesPontosVenda } from "data/api/gestao/pontos-venda/get-configuracoes-pontos-venda";
import { usePostAdicionarVersao } from "data/api/gestao/pontos-venda/post-adicionar-versao";
import { isEmpty } from "lodash";
import { TokenGestaoEmpresaModel } from "model";
import { EmissorModel } from "model/api/gestao/master/emissor";
import { PontosVendaModel } from "model/api/gestao/pontos-venda";
import { PontosVendaVersaoModel } from "model/api/gestao/pontos-venda/ponto-venda-versao-model";
import { PontosVendaConfiguracoesModel } from "model/api/gestao/pontos-venda/pontos-venda-configuracoes-model";
import { PontosVendaCompletoVendaModel } from "model/app/ponto-venda/ponto-venda-completo-model";
import { EnumDeviceType } from "model/enums/enum-device-type";
import { EnumIndstatusStatus } from "model/enums/enum-indstatus-status";
import moment from "moment";
import { useCallback, useRef } from "react";
import { picker } from "utils/picker";
import { stringNumeros } from "utils/string-numeros";
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { PutConfiguracoesPontosVendaProps, usePatchConfiguracoesPontosVenda } from 'data/api/gestao/pontos-venda/put-configuracoes-pontos-venda';
import { EnumPDVConfigCod } from "model/enums/enum-pdv-config";
import { isPlanoControleMesasComandas } from "utils/plano-utils";
import { validaGuid } from 'utils/valida-guid';
import { ImpressoraModel } from 'model/api/gestao/impressora/impressora-model';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useDevice } from './device';
import impHomologadas from './utils/impHomologadas.json'
import { useSessaoAtual } from "../providers/sessao-atual";
import { usePostPontosVenda } from "data/api/gestao/pontos-venda/post-pontos-venda";
import { usePutPontosVenda } from "data/api/gestao/pontos-venda/put-pontos-venda";
import { useGetPontosVenda } from "data/api/gestao/pontos-venda/get-pontos-venda";
import { useGetEquipamentoById } from "data/api/gestao/equipamento/get-equipamento-by-id";
import { useEmpresaAtual } from "./empresa-atual";
import { chaveCaracteresParaSubstituir, serializarGuids } from "utils/serialize-guid";

export function usePDV() {
  const getImp = useRef<boolean>(false);
  const { getRegistro, setRegistro, delRegistro } = useGestaoStorage();
  const { plano, getPessoa, usuario, getEmpresaSelecionada } = useSessaoAtual();
  const { getEmpresaAtual } = useEmpresaAtual()
  const { callEvent } = useEventTools();
  const { getConfiguracoesPontosVenda, carregando: carregandoGetConfiguracoes } = useGetConfiguracoesPontosVenda();
  const { patchConfigPontosVenda, carregando: carregandoPatchConfiguracoes } = usePatchConfiguracoesPontosVenda()
  const { postAdicionarVersao, carregando: carregandoPostVersao } = usePostAdicionarVersao();
  const { postPontosVenda, carregando: carregandoPostPDV } = usePostPontosVenda();
  const { putPontosVenda, carregando: carregandoPutPDV } = usePutPontosVenda();
  const { getPontosVenda, carregando: carregandoGetPdvs } = useGetPontosVenda();
  const { getConfigEmissor, carregando: carregandoGetConfigNfce } = useGetConfigEmissor();
  const { getEquipamentoById, carregando: carregandoEquipamento } = useGetEquipamentoById();
  const { getCompatibleDevice, getSerialNumber, carregando: carregandoSerial } = useDevice();

  const carregando =
    carregandoGetConfigNfce ||
    carregandoGetConfiguracoes ||
    carregandoGetPdvs ||
    carregandoPostVersao ||
    carregandoPostPDV ||
    carregandoPutPDV ||
    carregandoPatchConfiguracoes ||
    carregandoEquipamento ||
    carregandoSerial;

  const getPDV = useCallback(() => {
    const pdv = getRegistro(GestaoStorageKeys.PDV, false) as PontosVendaCompletoVendaModel;

    if (Object.entries(pdv).length === 0) {
      return undefined;
    }

    return pdv;
  }, [getRegistro]);

  const getConfigByCod = useCallback((codigo: EnumPDVConfigCod):
    string | undefined => {

    const pdv = getPDV()

    if (isEmpty(pdv))
      return undefined;
    if (isEmpty(pdv?.configuracoes))
      return undefined;

    const configCod = pdv?.configuracoes.find(config => config.cod === codigo)

    if (isEmpty(configCod))
      return undefined

    return configCod?.vConfig
  }, [getPDV])

  const deviceType = useCallback(() => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'Tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'Mobile';
    }
    return 'Desktop';
  }, []);

  const getImpressoraPdv = useCallback(() => {
    const impressora = getRegistro(
      GestaoStorageKeys.Impressora,
      false
    ) as ImpressoraModel;

    if (isEmpty(impressora)) {
      return undefined;
    }

    return impressora;
  }, [getRegistro]);

  const searchImpressoraPDV = useCallback(async () => {
    try {
      const pdv = getPDV();

      if (getImp.current) {
        return
      }

      if (pdv && pdv.tpCaixa === EnumPDVTpCaixa.WEBPDV) {
        const impressoraPdv = getImpressoraPdv();

        if (!impressoraPdv) {
          const configPortaDeComunicacao = getConfigByCod(
            EnumPDVConfigCod.PortaComunicacao
          );

          if (!configPortaDeComunicacao) {
            setRegistro(GestaoStorageKeys.Impressora, {}, false);
            return;
          }

          if (
            configPortaDeComunicacao &&
            !validaGuid(configPortaDeComunicacao)
          )
            return;

          const empresaId = getEmpresaAtual()?.id || '';
          getImp.current = true
          const res = await getEquipamentoById(
            empresaId,
            configPortaDeComunicacao!
          );

          if (res.erro) throw res.erro;

          const impressora = res?.resultado?.data as ImpressoraModel;
          if (impressora.caminho.length <= 0) {
            return
          }

          setRegistro(GestaoStorageKeys.Impressora, impressora, false);
          return impressora;
        }


        return impressoraPdv;
      }
      return;
    } catch (e: any) {
      delRegistro(GestaoStorageKeys.Impressora, false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPDV = useCallback(
    async (
      pdv: PontosVendaCompletoVendaModel,
      forcarAtualizacao: Boolean,
      mesmoSerial: boolean = false
    ) => {
      pdv.abertura = new Date();

      if (!forcarAtualizacao) {
        var pdvAntigo = getPDV();
        if (pdvAntigo) {
          if (
            pdvAntigo.numCaixa === pdv.numCaixa ||
            pdvAntigo.chave === pdv.chave
          ) {
            pdv.abertura = pdvAntigo.abertura || new Date();
            pdv.ultConsulta = pdvAntigo.ultConsulta;
            pdv.configuracoes = pdvAntigo.configuracoes;
            pdv.versao = pdvAntigo.versao;
            pdv.emissor = pdvAntigo.emissor;
          }
        }
      }

      const dataAtualizacao = moment(pdv.ultConsulta || '2001-01-01')
        .add(5, 'm')
        .toDate();
      const dataAgora = moment().toDate();
      if (dataAtualizacao.getTime() < dataAgora.getTime()) {
        forcarAtualizacao = true;
      }

      if (forcarAtualizacao) {
        await postAdicionarVersao(
          pdv?.empresaId || '',
          pdv?.id || '',
          `${VariaveisAmbiente.environment}|${VariaveisAmbiente.version
          }|${deviceType()}`
        );

        pdv!.versao = new PontosVendaVersaoModel();
        pdv!.versao!.versao = `${VariaveisAmbiente.environment}|${VariaveisAmbiente.version
          }|${deviceType()}`;
        pdv!.versao!.dAtualizacao = dataAgora;
        pdv.ultConsulta = new Date();
      }

      if (!pdv.emissor || forcarAtualizacao) {
        const res = await getConfigEmissor(getEmpresaAtual()?.cpfcnpj || '');
        if (res.erro) {
          throw new Error(
            'Erro ao carregar as configurações de NFC-e. Detalhe: ' +
            res.erro.message
          );
        }
        pdv.emissor = res.resultado?.data as EmissorModel;
        pdv.ultConsulta = new Date();
      }
      if (!pdv.configuracoes || forcarAtualizacao) {
        const retConfigs = await getConfiguracoesPontosVenda(
          pdv.empresaId,
          pdv.id
        );
        if (retConfigs.erro)
          throw new Error(
            'Erro ao carregar as configurações de venda. Detalhe: ' +
            retConfigs.erro
          );
        pdv.configuracoes = retConfigs.resultado
          ?.data as Array<PontosVendaConfiguracoesModel>;

        if (!mesmoSerial) {
          const quantColunas = pdv.configuracoes.find(
            (item) => item.cod === EnumPDVConfigCod.QuantidadeColunas
          );
          const modTrabalho = pdv.configuracoes.find(
            (item) => item.cod === EnumPDVConfigCod.ModeloTrabalho
          );
          const modelo = pdv.configuracoes.find(
            (item) => item.cod === EnumPDVConfigCod.ModeloEquipamento
          );

          quantColunas!.vConfig =
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS ||
              VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA
              ? '32'
              : '64';
          quantColunas!.editado = 1;

          if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
            VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) {
            const nameDevice = await getCompatibleDevice();
            if (nameDevice) {
              const equipamentoCompativel = impHomologadas.modelos.find(
                (modelo) =>
                  modelo?.equipamentos &&
                  modelo.equipamentos.length > 0 &&
                  modelo.equipamentos.find((eq) => eq === nameDevice)
              );

              if (equipamentoCompativel) {
                quantColunas!.vConfig = String(
                  equipamentoCompativel.colunas ?? 32
                );
                quantColunas!.editado = 1;

                modelo!.vConfig = equipamentoCompativel.modelo[0];
                modelo!.editado = 1;
              }
            }
          }

          if (!isPlanoControleMesasComandas(plano?.plano)) {
            modTrabalho!.vConfig = '0';
            modTrabalho!.editado = 1;
          }

          pdv.configuracoes = [
            ...pdv.configuracoes.filter(
              (x) => x.cod !== modTrabalho!.cod && x.cod !== quantColunas!.cod && x.cod !== modelo!.cod
            ),
            modTrabalho!,
            quantColunas!,
            modelo!
          ];

          const res = await patchConfigPontosVenda(
            pdv.configuracoes.filter(
              (x) =>
                new PutConfiguracoesPontosVendaProps(x.id, x.cod, x.vConfig)
            ),
            pdv.empresaId,
            pdv.id
          );

          if (res.erro) throw res.erro;
        }

        pdv.ultConsulta = new Date();
      }

      setRegistro(GestaoStorageKeys.PDV, pdv, false);
    },
    [setRegistro, getPDV, postAdicionarVersao, deviceType, getConfigEmissor, getEmpresaAtual, getConfiguracoesPontosVenda, plano?.plano, patchConfigPontosVenda, getCompatibleDevice]
  );


  const vincularPDVExistente = useCallback(async (pdv: PontosVendaModel) => {
    const isCordova = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS;
    let chavePDV = ''

    if (isCordova) {
      chavePDV = await getSerialNumber();
    } else if (validaGuid(pdv.chave)) {
      chavePDV = getPessoa().pessoa.id;
    } else {
      chavePDV = serializarGuids([getPessoa().pessoa!.id, getEmpresaAtual()!.id]);
    }
    if (chavePDV === pdv.chave) {
      let model = picker<PontosVendaCompletoVendaModel>(pdv, new PontosVendaCompletoVendaModel());
      model.abertura = new Date();
      await setPDV(model, true, true);
    } else {
      if (pdv.tpCaixa === 0) {
        throw new Error('Não é possível se atrelar a um WebPDV')
      }
      pdv.indStatus = EnumIndstatusStatus.ATIVADO;
      pdv.chave = isCordova ? await getSerialNumber() : serializarGuids([getPessoa().pessoa!.id, getEmpresaAtual()!.id]);
      pdv.tpCaixa = (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
        VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) ? 3 : 0;
      const ret = await putPontosVenda(pdv);
      if (ret.erro) {
        throw ret.erro;
      }

      let model2 = picker<PontosVendaCompletoVendaModel>(pdv, new PontosVendaCompletoVendaModel());
      model2.abertura = new Date();
      await setPDV(model2, true);
    }
  }, [getEmpresaAtual, getPessoa, getSerialNumber, putPontosVenda, setPDV]);

  const vincularNovoPDV = useCallback(async (numero: Number, descricao: string, empresa: TokenGestaoEmpresaModel, chavePDv: string) => {
    const novoPDV = new PontosVendaModel();
    novoPDV.chave = chavePDv
    novoPDV.numCaixa = Number(numero);
    novoPDV.descricao = descricao;
    novoPDV.dominio = stringNumeros(empresa?.Documento || '');
    novoPDV.contratoId = empresa.ContratoId || '';
    novoPDV.empresaId = empresa.Id || '';
    novoPDV.depositoId = empresa?.Id || '';
    novoPDV.tpCaixa = (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
      VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) ? 3 : 0;

    const ret = await postPontosVenda(novoPDV);
    if (ret.erro) {
      throw ret.erro;
    }

    let model = picker<PontosVendaCompletoVendaModel>(ret.resultado?.data, new PontosVendaCompletoVendaModel());
    await setPDV(model, true);

  }, [postPontosVenda, setPDV]);

  const searchPDVPorSerial = useCallback(
    async (key: string) => {

      const query = '&ChaveTerminal=' + key + '&TemFiltro=true';

      const res = await getPontosVenda(
        getEmpresaAtual()?.id || '',
        query,
      );
      if (res.erro) throw res.erro;

      return res.resultado?.data.list || [];
    },
    [getEmpresaAtual, getPontosVenda],
  );

  const searchPDVs = useCallback(async (): Promise<
    Array<PontosVendaModel>
  > => {
    const res = await getPontosVenda(getEmpresaAtual()?.id || '', "PageSize=100");
    if (res.erro) throw res.erro;

    return (
      (res.resultado?.data.list as Array<PontosVendaModel>) ||
      new Array<PontosVendaModel>()
    );
  }, [getEmpresaAtual, getPontosVenda]);

  const numeracaoDisponivel = useCallback(
    (pdvs: PontosVendaModel[]) => {
      if (!Array.isArray(pdvs) || pdvs.length === 0) {
        return 1;
      }

      if (pdvs.length > 10 && (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA ||
        VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS)) {
        return undefined;
      }

      pdvs.sort((a, b) => a.numCaixa - b.numCaixa);

      let nextNumber = 1;

      for (let i = 0; i < pdvs.length; i++) {
        if (pdvs[i].numCaixa === nextNumber) {
          nextNumber++;
        } else if (pdvs[i].numCaixa > nextNumber) {
          break;
        }
      }
      return nextNumber;
    },
    []
  );

  const procurarMeuPDV = useCallback(
    async (recarregarConfigs: boolean) => {
      let pdvNoStorage = true;
      var pdvAtual = getPDV();
      const isCordova = VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA || VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS;
      let serial = ''
      let serialAntigo = '';
      let serialSemReplace = '';
      //FUNÇÃO PRA ATUALIZAR PRO NOVO PADRÃO DE CHAVE COMPOSTA
      const atualizarChavePDV = async (pdvAtual: PontosVendaCompletoVendaModel, forceAtt: boolean) => {
        const chave = serializarGuids([getPessoa().pessoa!.id, getEmpresaAtual()!.id])
        const pdvAtualizado = {
          ...pdvAtual,
          chave
        }

        await putPontosVenda(pdvAtualizado);
        await setPDV(pdvAtualizado, forceAtt);

        return chave;
      }
      //


      if (isCordova) {
        serial = await getSerialNumber();
      } else {
        /*
          EXPLICANDO:
          Estamos serializando a guid da empresa e usuario para criar uma chave do PDV, já que
          usuarios podem ter empresas diferentes e isso quebra pra usar um PDV na outra empresa.
          Como tem muitos PDV's Web com id da pessoa como chave, eu precisei ter
          certeza de não validar somente no modelo novo mas como no antigo também pra não dar problema.
        */
        serial = serializarGuids([getPessoa().pessoa!.id, getEmpresaAtual()!.id]);
        serialAntigo = getPessoa().pessoa.id;
        serialSemReplace = serializarGuids([getPessoa().pessoa!.id, getEmpresaAtual()!.id], 60, false);
      }
      if (!serial) throw new Error('Número de Série do PDV não Identificado');

      localStorage.setItem('deviceId', serial);
      const caracteresProibidos = chaveCaracteresParaSubstituir.map<string>(x => x.Key);
      const possuiCaracteresProibidos = pdvAtual?.chave.split('').reduce((prev, curr) => prev || caracteresProibidos.includes(curr), false);

      if (pdvAtual && !recarregarConfigs && pdvAtual.chave === serial) {
        pdvNoStorage = pdvAtual.chave === serial;
      } else if (pdvAtual &&
        (
          (!recarregarConfigs &&
            pdvAtual.chave === serialAntigo) ||
          possuiCaracteresProibidos
        )
      ) {
        serial = await atualizarChavePDV(pdvAtual, recarregarConfigs);
        pdvNoStorage = pdvAtual.chave === serial;
      } else {
        //BUSCO OS PDVS POR SERIAL
        let pdvKey = await searchPDVPorSerial(serial);

        if ((!pdvKey || pdvKey.length === 0) && !isCordova) {
          pdvKey = await searchPDVPorSerial(serialSemReplace);
          if ((!pdvKey || pdvKey.length === 0) && !isCordova) {
            pdvKey = await searchPDVPorSerial(serialAntigo);
            if (pdvKey && pdvKey.length > 0) {
              serial = serialAntigo;

              var pdvTemp = picker<PontosVendaCompletoVendaModel>(
                pdvKey[0],
                new PontosVendaCompletoVendaModel()
              );

              serial = await atualizarChavePDV(pdvTemp, recarregarConfigs);
              localStorage.setItem('deviceId', serial);
            }
          }
        }

        //SE ACHOU O SERIAL JA CADASTRADO
        if (pdvKey.length > 0) {
          var model = picker<PontosVendaCompletoVendaModel>(
            pdvKey[0],
            new PontosVendaCompletoVendaModel()
          );
          const caracteresProibidos = chaveCaracteresParaSubstituir.map<string>(x => x.Key);
          const possuiCaracteresProibidos = model.chave.split('').reduce((prev, curr) => prev || caracteresProibidos.includes(curr), false);
          if (possuiCaracteresProibidos) {
            model.chave = await atualizarChavePDV(model, recarregarConfigs);
          }
          await setPDV(model, recarregarConfigs, true);
          callEvent(AppEventEnum.PDV, model)
          callEvent(AppEventEnum.SessaoPDV, true)
        } else {
          try {
            delRegistro(GestaoStorageKeys.PDV, false);
            pdvNoStorage = false;
            const empresa = getEmpresaSelecionada();
            const retAllPdvs = await searchPDVs();

            const numPdvDisponivel = numeracaoDisponivel(retAllPdvs);

            if (!numPdvDisponivel) {
              delRegistro(GestaoStorageKeys.PDV, false);
              return false;
            }
            // todo: pdv de nome da pessoa caso seja web
            await vincularNovoPDV(
              numPdvDisponivel,
              VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR ? 'PDV do ' + (usuario?.apelido ?? usuario?.saudacao) : 'PDV ' + numPdvDisponivel,
              empresa || new TokenGestaoEmpresaModel(),
              serial
            );
            pdvNoStorage = true;
          } catch (e) {
            delRegistro(GestaoStorageKeys.PDV, false);
            pdvNoStorage = false;
          }
        }
      }

      if (pdvNoStorage) {
        await searchImpressoraPDV();
      }

      return pdvNoStorage;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const invalidarConfiguracoes = useCallback(() => {
    const pdv = getPDV();
    if (isEmpty(pdv)) {
      return
    }
    if (pdv) {
      pdv.ultConsulta = new Date(2000, 1, 1);
    }
    setPDV(pdv, true, true);
  }, [getPDV, setPDV]);

  const getInicializacaoVendaRealizada = useCallback(() => {
    const ret = getRegistro(GestaoStorageKeys.PDVInicializado, false);
    return ret === 'true';
  }, [getRegistro])

  const setInicializacaoVendaRealizada = useCallback((value: boolean) => {
    setRegistro(GestaoStorageKeys.PDVInicializado, value.toString(), false);
  }, [setRegistro]);

  return {
    carregando,
    invalidarConfiguracoes,
    vincularNovoPDV,
    vincularPDVExistente,
    procurarMeuPDV,
    getPDV,
    getConfigByCod,
    getImpressoraPdv,
    getInicializacaoVendaRealizada,
    setInicializacaoVendaRealizada
  }
}
