import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { TextFieldSaurus } from '../../../controles/inputs';
import { makeUtilClasses } from '../../../../theme';
import { CircularLoading } from '../../../utils/circular-loading/circular-loading';
import {
    DefaultFormRefs,
} from '../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormDadosEntradaValidation } from './form-dados-emitente-validation';
import { FormDadosEmitenteProps } from './form-dados-emitente-props';
import { EmitFormModel } from 'model/app/forms/entrada/emit-form-model';
import { AutocompletePessoas, EnumNomeCnpj } from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { PessoaEnderecoModel, PessoaModel } from 'model/api/gestao/pessoa';
import { EnumTipoPessoaContato } from 'model/enums/enum-tipo-pessoa-contato';
import { useGetEnderecoPessoa } from 'data/api/gestao/pessoa';
import { useToastSaurus } from 'services/app';
import { formatarCPFCNPJ } from 'utils/cpfcnpj-format';
import { useConsultaCEP } from 'data/api/wsmaster';
import { stringNumeros } from 'utils/string-numeros';
import { isEmpty } from 'lodash';
import { useStyles } from './form-dados-emitente-styles';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';

export const FormDadosEmitente = forwardRef<
    DefaultFormRefs<EmitFormModel>,
    FormDadosEmitenteProps
>(({ ...props }, ref) => {
    const utilClasses = makeUtilClasses();
    const { formDadosEntradaValidationYup } = useFormDadosEntradaValidation();
    const [model, setModel] = useState<EmitFormModel>(new EmitFormModel())
    const modelFormRef = useRef<EmitFormModel>(new EmitFormModel())
    const { getEnderecoPessoa, carregando: carregandoGet } = useGetEnderecoPessoa()
    const { consultaCEP, carregando: carregandoCEP } = useConsultaCEP();
    const { showToast } = useToastSaurus()
    const [, setAtt] = useState<boolean>(false)
    const classes = useStyles()

    const carregando = [carregandoCEP, carregandoGet].includes(true)

    const {
        handleSubmit,
        control,
        formState: { errors, touchedFields },
        reset,
        setValue,
        getValues,
    } = useForm<EmitFormModel>({
        resolver: yupResolver(formDadosEntradaValidationYup),
        defaultValues: {
            ...model
        },
        criteriaMode: 'all',
        mode: 'onChange',
    });

    const onSubmit = (values: EmitFormModel) => {
        props.onSubmit(values, model);
    };

    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            await handleSubmit(onSubmit)();
        },
        resetForm: () => {
            reset();
        },
        fillForm: (model: EmitFormModel) => {
            reset({ ...model })
            modelFormRef.current = { ...model }
            setModel({ ...model })
        },
    }));

    const getEnderecoPessoaWrapper = useCallback(async (pessoaId: string) => {
        const res = await getEnderecoPessoa(pessoaId)
        if (res.erro) throw res.erro

        const resultado = res.resultado?.data as PessoaEnderecoModel[]
        if (resultado.length <= 0) {
            return new PessoaEnderecoModel()
        }

        return resultado[0]
    }, [getEnderecoPessoa])

    const handleConsultaCep = async (value: string) => {
        try {
            let res = await consultaCEP(value);

            setValue('cep', res.CEP);
            setValue('xBairro', res.Bairro);
            setValue('xLgr', res.Logradouro);
            setValue('uf', res.UF);
            setValue('cMun', res.CMun ? Number(res.CMun) : 0);
            setValue('xMun', res.Municipio)
        } catch (e: any) {
            showToast('error', e.message);
        }
    }

    return (
        <Box my={2}>
            <div className={utilClasses.formContainer}>
                {carregando ? (
                    <CircularLoading tipo="FULLSIZED" />
                ) : null}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props.loading ? utilClasses.controlLoading : ''}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="doc"
                                control={control}
                                render={({ field }) => (
                                    <AutocompletePessoas
                                        disabled={props.loading}
                                        label="CPF/CNPJ"
                                        allowSubmit
                                        mensagemNaoEncontrado='Nenhum registro encontrado'
                                        error={Boolean(errors.doc && errors.doc.message)}
                                        helperText={
                                            touchedFields.doc || errors.doc
                                                ? errors.doc?.message
                                                : undefined
                                        }
                                        {...field}
                                        nomeCnpj={EnumNomeCnpj.Cnpj}
                                        onChange={async (retorno) => {
                                            try {
                                                if (!retorno.isString) {
                                                    const pessoa = retorno.value as PessoaModel
                                                    setValue('xNome', pessoa.nome)
                                                    setValue('doc', pessoa.cpfcnpj)
                                                    setValue('fone', pessoa.contatos.find(x => x.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '')
                                                    setValue('xFant', pessoa.fantasia)
                                                    const enderecoPessoa = await getEnderecoPessoaWrapper(pessoa.id)
                                                    setValue('cMun', enderecoPessoa.cMun ? Number(enderecoPessoa.cMun) : 0)
                                                    setValue('cep', stringNumeros(enderecoPessoa.cep))
                                                    setValue('nro', enderecoPessoa.numero)
                                                    setValue('xBairro', enderecoPessoa.bairro)
                                                    setValue('uf', enderecoPessoa.uf)
                                                    setValue('xCpl', enderecoPessoa.complemento)
                                                    setValue('xLgr', enderecoPessoa.logradouro)
                                                    setValue('xMun', enderecoPessoa.xMun)
                                                    setValue('email', pessoa.contatos.find(x => x.tipo === EnumTipoPessoaContato.EMAIL)?.valor || '')
                                                }
                                            } catch (error: any) {
                                                showToast('error', error.message)
                                            }
                                        }}
                                        value={formatarCPFCNPJ(field.value)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xNome"
                                control={control}
                                render={({ field }) => (
                                    <AutocompletePessoas
                                        disabled={props.loading}
                                        label="Razão Social"
                                        allowSubmit
                                        permiteNovo={!isEmpty(getValues('xNome'))}
                                        mensagemNaoEncontrado='Nenhum registro encontrado'
                                        textoNovoItem='Editar para:'
                                        error={Boolean(errors.xNome && errors.xNome.message)}
                                        helperText={
                                            touchedFields.xNome || errors.xNome
                                                ? errors.xNome?.message
                                                : undefined
                                        }
                                        {...field}
                                        nomeCnpj={EnumNomeCnpj.Nome}
                                        onChange={async (retorno) => {
                                            try {
                                                if (retorno.isString) {
                                                    setValue('xNome', retorno.value)
                                                    return
                                                }
                                                const pessoa = retorno.value as PessoaModel
                                                setValue('xNome', pessoa.nome)
                                                setValue('doc', pessoa.cpfcnpj)
                                                setValue('fone', pessoa.contatos.find(x => x.tipo === EnumTipoPessoaContato.TELEFONE)?.valor || '')
                                                setValue('xFant', pessoa.fantasia)
                                                const enderecoPessoa = await getEnderecoPessoaWrapper(pessoa.id)
                                                setValue('cMun', enderecoPessoa.cMun ? Number(enderecoPessoa.cMun) : 0)
                                                setValue('cep', stringNumeros(enderecoPessoa.cep))
                                                setValue('nro', enderecoPessoa.numero)
                                                setValue('xBairro', enderecoPessoa.bairro)
                                                setValue('uf', enderecoPessoa.uf)
                                                setValue('xCpl', enderecoPessoa.complemento)
                                                setValue('xLgr', enderecoPessoa.logradouro)
                                                setValue('xMun', enderecoPessoa.xMun)
                                                setAtt(prev => !prev)
                                            } catch (error: any) {
                                                showToast('error', error.message)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="ie"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Inscrição Estadual"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.ie && errors.ie.message)}
                                        helperText={
                                            touchedFields.ie || errors.ie
                                                ? errors.ie?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="im"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Inscrição Municipal"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.im && errors.im.message)}
                                        helperText={
                                            touchedFields.im || errors.im
                                                ? errors.im?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'EMAIL'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="E-mail"
                                        allowSubmit
                                        placeholder='Ex: usuario@saurus.com.br'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.email && errors.email.message)}
                                        helperText={
                                            touchedFields.email || errors.email
                                                ? errors.email?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="fone"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TELEFONE'}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Telefone"
                                        allowSubmit
                                        placeholder='Ex: (11) 99999-9999'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.fone && errors.fone.message)}
                                        helperText={
                                            touchedFields.fone || errors.fone
                                                ? errors.fone?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box pb={1}>
                                <Typography variant="h6">
                                    Endereço
                                </Typography>
                                <Divider className={classes.divider} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo="CEP"
                                        disabled={props.loading}
                                        fullWidth
                                        searchable
                                        autoComplete={'off'}
                                        label="CEP"
                                        placeholder="Ex: 01309-030"
                                        onSearch={handleConsultaCep}
                                        error={Boolean(errors.cep && errors.cep.message)}
                                        helperText={
                                            errors.cep
                                                ? errors.cep?.message
                                                : undefined
                                        }
                                        {...field}
                                        onChange={e => {
                                            field.onChange(e)
                                            const { value } = e.target

                                            if (stringNumeros(value).length === 8) {
                                                setTimeout(() => handleConsultaCep(value), 400)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xLgr"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Endereço"
                                        allowSubmit
                                        placeholder='Ex: Rua Fernando de Albuquerque'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xLgr && errors.xLgr.message)}
                                        helperText={
                                            touchedFields.xLgr || errors.xLgr
                                                ? errors.xLgr?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="nro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Número"
                                        allowSubmit
                                        placeholder='Ex: 1.111'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.nro && errors.nro.message)}
                                        helperText={
                                            touchedFields.nro || errors.nro
                                                ? errors.nro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="xCpl"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Complemento"
                                        allowSubmit
                                        placeholder='Ex: Casa 5'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xCpl && errors.xCpl.message)}
                                        helperText={
                                            touchedFields.xCpl || errors.xCpl
                                                ? errors.xCpl?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="xBairro"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Bairro"
                                        allowSubmit
                                        placeholder='Ex: Consolação'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xBairro && errors.xBairro.message)}
                                        helperText={
                                            touchedFields.xBairro || errors.xBairro
                                                ? errors.xBairro?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="xMun"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="Município"
                                        allowSubmit
                                        placeholder='Ex: São Paulo'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.xMun && errors.xMun.message)}
                                        helperText={
                                            touchedFields.xMun || errors.xMun
                                                ? errors.xMun?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="uf"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldSaurus
                                        tipo={'TEXTO'}
                                        disabled={props.loading}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        label="UF"
                                        allowSubmit
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={Boolean(errors.uf && errors.uf.message)}
                                        helperText={
                                            touchedFields.uf || errors.uf
                                                ? errors.uf?.message
                                                : undefined
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button style={{ display: 'none' }} type="submit"></Button>
                </form>
            </div>
        </Box>
    );
});
