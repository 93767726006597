import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: 0,
        position: "relative",
        padding: theme.spacing(1),
        background: theme.palette.common.white,
    },
    container: {
        display: "flex",
        flexDirection: "row"
    },
    title: {
        fontWeight: 800,
        marginRight: 4
    },
    containerMedalPlan: {
        width: 70,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    containerLimitPlan: {
        background: theme.palette.grey[100]
    },
    subTitle: {
        fontWeight: 600
    },
    containerCardItemsLimit: {
        width: "100%",
        overflowY: "auto",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxHeight: "220px"
    },
    containerInfo: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        width: "100%",
        background: theme.palette.primary.main,
        height: "2px!important"
    },
    buttonCancelar: {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.light
        },
        "& svg": {
            fill: theme.palette.error.main
        }
    },


}));
