import { Menu, MenuItem } from '@material-ui/core';
import { DialogSaurus } from '../dialog-saurus/dialog-saurus';
import { useStyles } from './dialog-acoes-pedido-styles';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ArrowRigthIcon } from 'views/components/icons/arrow-rigth-icon';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router-dom';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { CircularLoading } from 'views/components/utils';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useThemeQueries } from 'views/theme';
import { isEmpty } from 'lodash';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { guidEmpty } from 'utils/guid-empty';
import { StatusPedidoMock } from 'data/mocks/status-pedido-mock';
import { MenuOptionsModel } from 'views/components/menu-options/model/menu-options-model';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { Divider, Grid, Typography } from 'views/design-system';
import { usePedidoPagAntecipado } from 'views/components/modals/pedido-pagamento-antecipado/components/pedido-pag-antecipado-conteudo/use-pedido-pag-antecipado';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { usePutComanda } from 'data/api/gestao/comandas/put-comanda';
import { EnumComandas } from 'model/enums/enum-comandas';
import { usePutPedidoAlterarComanda } from 'data/api/gestao/pedido-dados/put-pedido-alterar-comanda';
import { EnumTpPermissaoUsuario } from 'model/enums/enum-tp-permissao-usuario';
import { useBancoLocal } from 'services/app/hooks/banco-local';
import { TabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { EnumTableNames } from 'database/touchone-carga-database';
import { usePutPedidoAlterarStatus } from 'data/api/gestao/pedido-dados/put-pedido-alterar-status';

interface DialogAcoesPedidoProps {
  aberto: boolean;
  pedido: PedidoModel;
  carregarInfoPedido: () => Promise<void>;
  titulo?: string;
}

interface ListOptionProps {
  label: string
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  menu?: ReactNode
  isValid?: boolean
}

export const DialogAcoesPedido = ({
  aberto,
  pedido,
  carregarInfoPedido,
  titulo
}: DialogAcoesPedidoProps) => {
  // HOOKS
  const { fecharDialogAcoesPedidos, abrirDialogAlterarMesaPedido, abrirPedidoPagamentoAntecipado } = useCadastros();
  const { handleValorPagamento } = usePedidoPagAntecipado(pedido, fecharDialogAcoesPedidos)
  const { getFirst } = useBancoLocal();

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedido } = useGetPedidoSalao()
  const { putComanda, carregando: carregandoPutComanda } = usePutComanda()
  const { putPedidoAlterarComanda, carregando: carregandoPutAlterarComanda } = usePutPedidoAlterarComanda()
  const { putPedidoAlterarStatus, carregando: carregandoPutPedidoAlterarStatus } =
    usePutPedidoAlterarStatus();

  // AUX
  const { getEmpresaSelecionada, getPermissaoBoolean, plano } = useSessaoAtual();
  const { removerItensPedidoVendaSimples } = useMovAtual();
  const classesComponent = useStyles();
  const history = useHistory();
  const confirm = useConfirm();
  const { showToast } = useToastSaurus();
  const carregando = carregandoPutPedidoAlterarStatus || carregandoGetPedido || carregandoPutComanda || carregandoPutAlterarComanda;
  const { theme } = useThemeQueries()
  const { abrirSolicitarPermissao, abrirIdentificarComanda } = useCadastros();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { getEmpresaAtual } = useEmpresaAtual()
  const isFarma = isPlanoFarmaceutico(plano?.plano);
  const { redirectTransferirItensPedido } = useMovRota();

  const onCloseClick = useCallback(() => {
    fecharDialogAcoesPedidos();
  }, [fecharDialogAcoesPedidos]);

  const cancelarPedido = useCallback(async () => {
    try {
      await removerItensPedidoVendaSimples(pedido.id);
      const respostaPutStatus = await putPedidoAlterarStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        EnumStatusPedido.CANCELADO,
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao alterar status do pedido para Cancelado. Detalhe: ${respostaPutStatus.erro}`,
        );
      }
      showToast('success', 'Pedido cancelado com sucesso.');
      fecharDialogAcoesPedidos();
      return history.push(MovRotasMock.pedidosRota)
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [fecharDialogAcoesPedidos, getEmpresaSelecionada, history, pedido.id, putPedidoAlterarStatus, removerItensPedidoVendaSimples, showToast]);

  const handleClickCancelarPedido = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Cancelar Pedido',
      description:
        'Deseja mesmo cancelar o pedido? Essa alteração não poderá ser desfeita.',
    }).then(cancelarPedido);
  }, [cancelarPedido, confirm]);

  const checarPermissaoCancelarPedido = useCallback(() => {
    const permitido = getPermissaoBoolean(EnumCodigosPermissoes.CANCELAMENTO_CUPOM)

    //caso não tenha permissão
    if (!permitido) {
      abrirSolicitarPermissao(
        async () => {
          handleClickCancelarPedido();
        }, EnumCodigosPermissoes.CANCELAMENTO_CUPOM, 'cancelar o pedido')
      return
    }

    handleClickCancelarPedido();
  }, [getPermissaoBoolean, handleClickCancelarPedido, abrirSolicitarPermissao])

  const reabrirPedido = useCallback(async () => {
    try {
      await removerItensPedidoVendaSimples(pedido.id);

      if (pedido.statusPedido.codigo === EnumStatusPedido.CONFIRMADO) {
        throw new Error(`O pedido já está aberto.`);
      }

      const respostaPutStatus = await putPedidoAlterarStatus(
        getEmpresaSelecionada()?.Id ?? '',
        pedido.id,
        EnumStatusPedido.CONFIRMADO,
      );

      if (respostaPutStatus.erro) {
        throw new Error(
          `Erro ao tentar reabrir o pedido. Detalhe: ${respostaPutStatus.erro}`,
        );
      }
      showToast('success', 'O pedido foi reaberto com sucesso.');
      await carregarInfoPedido()
      onCloseClick()
    } catch (err: any) {
      showToast('error', err.message);
    }
  }, [carregarInfoPedido, getEmpresaSelecionada, onCloseClick, pedido.id, pedido.statusPedido.codigo, putPedidoAlterarStatus, removerItensPedidoVendaSimples, showToast]);

  const handleClickReabrirPedido = useCallback(() => {
    confirm({
      confirmationText: 'Confirmar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
        className: 'round'
      },
      cancellationButtonProps: {
        className: 'round'
      },
      title: 'Reabrir Pedido',
      description: 'Deseja reabrir o pedido atual?',
    }).then(reabrirPedido);
  }, [confirm, reabrirPedido]);

  const handleAlterarMesa = useCallback(() => {
    abrirDialogAlterarMesaPedido(pedido, carregarInfoPedido);
  }, [abrirDialogAlterarMesaPedido, carregarInfoPedido, pedido]);

  const handleTransferirItens = useCallback(() => {
    fecharDialogAcoesPedidos();
    return redirectTransferirItensPedido({
      pedido,
      transferirPedido: true,
      alterarVendedor: false,
    });
  }, [fecharDialogAcoesPedidos, pedido, redirectTransferirItensPedido]);

  const handleAlterarVendedorItem = useCallback(() => {
    fecharDialogAcoesPedidos();
    return history.push({
      pathname: MovRotasMock.pedidoAlterarVendedor,
      search: history.location.search,
      state: { pedido },
    });
  }, [fecharDialogAcoesPedidos, history, pedido]);

  const handleFinalizacaoParcialComanda = useCallback(() => {
    fecharDialogAcoesPedidos();
    return history.push({
      pathname: MovRotasMock.finalizacaoParcialRota,
      search: history.location.search,
      state: { pedido },
    });
  }, [fecharDialogAcoesPedidos, history, pedido]);

  const isDelivery = useMemo(() => {
    const hasComandaId = !isEmpty(pedido.comandaId) && pedido.comandaId !== guidEmpty()
    const hasMesaId = !isEmpty(pedido.mesaId) && pedido.mesaId !== guidEmpty()
    if (hasMesaId || hasComandaId) {
      return false
    }

    return true
  }, [pedido.comandaId, pedido.mesaId])


  const handleStatusClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const wrapperClick = useCallback((click: () => any) => {
    click();
    handleClose();
  }, []);

  const alterarStatus = useCallback(async (status: EnumStatusPedido) => {
    try {

      const res = await putPedidoAlterarStatus(getEmpresaAtual()?.id ?? '', pedido.id, status)
      if (res.erro) throw res.erro
      showToast('success', `Pedido #${pedido.codigoPedido} alterado para o status ${StatusPedidoMock.find(x => x.Key === status)?.Value ?? ''}`)
    } catch (err: any) {
      showToast('error', "Problema ao alterar o status.Detalhe: " + err.message)
    }
  }, [getEmpresaAtual, pedido.codigoPedido, pedido.id, putPedidoAlterarStatus, showToast])

  const statusOptions = useMemo(() => {
    let options: MenuOptionsModel[] = StatusPedidoMock.filter(x => pedido.statusPedido.codigo !== x.Key).map((x) => {
      return new MenuOptionsModel(
        x.Value,
        <ArrowRigthIcon tipo="BUTTON" class={classesComponent.editIcon} />,
        () => alterarStatus(x.Key),
      )
    })

    return options
  }, [alterarStatus, classesComponent.editIcon, pedido.statusPedido.codigo])

  const handleAlterarEndereco = useCallback(() => {
    history.push({
      pathname: '/venda-simples/identificar-cliente-delivery',
      state: {
        pedido: pedido
      }
    })
    fecharDialogAcoesPedidos()
  }, [fecharDialogAcoesPedidos, history, pedido])

  const handlePagAntecipado = useCallback(() => {
    if (pedido.pagamentos.length <= 0) {
      handleValorPagamento()
      return
    }
    abrirPedidoPagamentoAntecipado(pedido)
    fecharDialogAcoesPedidos()
  }, [abrirPedidoPagamentoAntecipado, fecharDialogAcoesPedidos, handleValorPagamento, pedido])

  const handleReportarPerdaComanda = useCallback(() => {
    const permissaoTransferenciaComanda = getPermissaoBoolean(EnumCodigosPermissoes.TRANSFERENCIA_COMANDA)

    const reportarPerda = async () => {
      const empresaIdAtual = getEmpresaAtual()?.id ?? ''
      fecharDialogAcoesPedidos()
      abrirIdentificarComanda(
        async (novaComanda: ComandasModel) => {
          try {
            const resPedidoComanda = await getPedidoSalao(empresaIdAtual, `?comandaId=${novaComanda?.id ?? ''}`)
            if (resPedidoComanda.erro) throw resPedidoComanda.erro

            const resTransfer = await putPedidoAlterarComanda(novaComanda.id, pedido.id, empresaIdAtual)
            if (resTransfer.erro) throw resTransfer.erro
            let comandaAtual = null
            comandaAtual = await getFirst<TabelaComandas>({
              nomeTabela: EnumTableNames.COMANDAS,
              where: 'id',
              value: pedido.comandaId,
            })
            const resComanda = await putComanda(
              {
                ...comandaAtual,
                status: EnumComandas.BLOQUEADO
              } as unknown as ComandasModel, empresaIdAtual
            )
            if (resComanda.erro) throw resComanda.erro
            showToast('success', `Comanda alterada para o código "${novaComanda.codigoComanda}" e comanda com o código "${pedido.codigoComanda}" foi bloqueada.`)
            history.push(`${MovRotasMock.visualizarPedidoRota}/${novaComanda.id}?modo=comanda`)
            return true
          } catch (err: any) {
            showToast('error', err.message)
            return false
          }
        }
      )
    }
    if (permissaoTransferenciaComanda) {
      reportarPerda()
      return
    }
    abrirSolicitarPermissao(
      reportarPerda,
      EnumCodigosPermissoes.TRANSFERENCIA_COMANDA,
      'perda da comanda',
      EnumTpPermissaoUsuario.SimNao
    )

  }, [abrirIdentificarComanda, abrirSolicitarPermissao, fecharDialogAcoesPedidos, getEmpresaAtual, getFirst, getPedidoSalao, getPermissaoBoolean, history, pedido.codigoComanda, pedido.comandaId, pedido.id, putComanda, putPedidoAlterarComanda, showToast])

  const listOptions: ListOptionProps[] = useMemo(() => ([
    {
      label: 'Alterar Mesa',
      onClick: handleAlterarMesa,
      isValid: !isFarma && !isDelivery,
    },
    {
      label: 'Transferir Itens',
      onClick: handleTransferirItens,
      isValid: true,
    },
    {
      label: 'Cancelar Pedido',
      onClick: checarPermissaoCancelarPedido,
    },
    {
      label: 'Reabrir Pedido',
      onClick: handleClickReabrirPedido,
    },
    {
      label: 'Alterar Vendedor',
      onClick: handleAlterarVendedorItem,
    },
    {
      label: 'Finalização Parcial',
      onClick: handleFinalizacaoParcialComanda,
      isValid: !isEmpty(pedido.produtos) && !isDelivery,
    },
    {
      label: 'Alterar Status',
      onClick: handleStatusClick,
      isValid: isDelivery,
      menu: (
        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {statusOptions.map((option, index) => option ? (
            <MenuItem key={index} onClick={() => wrapperClick(option.click!)}>
              {option.icon}
              {option.label}
            </MenuItem>
          ) : null)}
        </Menu>
      )
    },
    {
      label: 'Alterar Dados do Cliente',
      onClick: handleAlterarEndereco,
      isValid: false // TODO: voltar com opção de alterar os dados do cliente.
    },
    {
      label: 'Pagamento Antecipado',
      onClick: handlePagAntecipado,
      isValid: !isDelivery
    },
    {
      label: 'Reportar Perda da Comanda',
      onClick: handleReportarPerdaComanda,
      isValid: Boolean(pedido.comandaId && pedido.comandaId !== guidEmpty())
    },
  ]), [anchorEl, checarPermissaoCancelarPedido, handleAlterarEndereco, handleAlterarMesa, handleAlterarVendedorItem, handleClickReabrirPedido, handleFinalizacaoParcialComanda, handlePagAntecipado, handleReportarPerdaComanda, handleTransferirItens, isDelivery, isFarma, open, pedido.comandaId, pedido.produtos, statusOptions, wrapperClick])

  const listOptionsComponent = useMemo(() => {
    return listOptions
      .filter(x => x.isValid === undefined ? x : x.isValid)
      .map((option, i, row) => {
        const index = `option-list-${option.label}-index`
        return (
          <>
            <Grid
              key={index}
              className={classesComponent.acoes}
              onClick={option.onClick}
            >
              <Typography>{option.label}</Typography>
              <ArrowRigthIcon tipo="BUTTON" style={{ width: '20px' }} />
            </Grid>
            {i < (row.length - 1) && (
              <Divider className={classesComponent.divider} />
            )}
            {option.menu}
          </>
        )
      })
  }, [classesComponent.acoes, classesComponent.divider, listOptions])

  return (
    <>
      <DialogSaurus
        aberto={aberto || false}
        isButtonTitleClose
        handleClickClose={onCloseClick}
        titulo={titulo ? titulo : "Opções"}
        colorTitulo={theme.palette.primary.main}
        tamanho="xs"
      >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Grid className={classesComponent.cardContainer}>
          <Grid className={classesComponent.cardContent}>
            {listOptionsComponent}
          </Grid>
        </Grid>
      </DialogSaurus>
    </>
  );
};
