export enum EnumMenu {
  // ------------------------------------------------------------------------------------------ PDV
  PDV = 10,

  //VENDA
  OPERACIONAL_FORAVENDA_INICIAR_VENDA = 44444,

  // VENDA PRODUTOS 
  OPERACIONAL_DENTROVENDA_GROUP_VENDA_PRODUTOS = 31000,
  OPERACIONAL_DENTROVENDA_CATALOGO = 31001,
  OPERACIONAL_DENTROVENDA_AVULSO = 31002,
  OPERACIONAL_DENTROVENDA_LEITOR = 31003,
  OPERACIONAL_DENTROVENDA_CARRINHO = 31004,

  // VENDA FINALIZACAO
  OPERACIONAL_DENTROVENDA_GROUP_FINALIZAR_VENDA = 32000,
  OPERACIONAL_DENTROVENDA_PAGAMENTO = 32001,
  OPERACIONAL_DENTROVENDA_FINALIZAR_VENDA = 32002,

  // OUTRAS OPERACOES DE VENDA
  OPERACIONAL_DENTROVENDA_GROUP_OUTRAS_OPERACOES_VENDA = 33000,
  OPERACIONAL_DENTROVENDA_CANCELAR = 33001,
  OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA = 33002,
  OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE = 33003,
  OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR = 33004,

  // OPERACOES SALOES DENTRO DA VENDA
  OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES = 34000,
  OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS = 34001,
  OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA = 34002,
  OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA = 34003,
  OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_BALCAO = 34004,
  OPERACIONAL_CONTROLESALAO_GERAR_COMANDA = 34005,
  OPERACIONAL_DENTROVENDA_IDENTIFICADOR_PAGER = 34006,


  // RELATORIOS DENTRO DA VENDA
  OPERACIONAL_DENTROVENDA_GROUP_RELATORIOS = 35000,
  OPERACIONAL_DENTROVENDA_DASHBOARD = 35001,
  OPERACIONAL_DENTROVENDA_HISTORICO_VENDA = 35002,

  // OPERACIONAL E ADMINISTRATIVO
  OPERACIONAL_DENTROVENDA_GROUP_RETAGUARDA_E_ADMINISTRATIVO = 36000,
  OPERACIONAL_DENTROVENDA_ABRIR_RETAGUARDA = 36001,
  OPERACIONAL_DENTROVENDA_GERENCIAR_SESSOES = 36002,
  OPERACIONAL_DENTROVENDA_SINCRONIZAR_CADASTROS = 36003,
  OPERACIONAL_DENTROVENDA_CONFIGURAR_PDV = 36004,
  OPERACIONAL_DENTROVENDA_CONSULTAR_CONVENIO = 36005,
  OPERACIONAL_DENTROVENDA_SINCRONIZAR_VENDAS = 36006,
  OPERACIONAL_DENTROVENDA_FORCAR_SINCRONIZACAO = 36007,

  // SUPORTE E GERENCIAMENTO DE SESSÃO
  OPERACIONAL_DENTROVENDA_SUPORTE = 37000,
  OPERACIONAL_DENTROVENDA_GERENCIAR_SESSAO = 37001,

  // DELIVERY
  OPERACIONAL_DENTROVENDA_GROUP_DELIVERY = 38000,
  OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY = 38001,
  OPERACIONAL_DENTROVENDA_DELIVERY = 38002,

  // ------------------------------------------------------------------------------------------ RETAGUARDA
  RETAGUARDA = 11,

  //CADASTROS
  CADASTRO_GROUP = 20000,
  CADASTRO_PRODUTOS = 20001,
  CADASTRO_PESSOAS = 20002,
  CADASTRO_FINALIZADORA = 20003,
  CADASTRO_COMISSAO = 20004,
  CADASTRO_PROMOCAO = 20005,
  CADASTRO_OUTROS = 20007,

  PESSOAS_GROUP = 21000,
  CADASTRO_CONVENIO = 21001,
  RELATORIOS_CONVENIO_FATURAS = 21002,

  FARMA_GROUP = 55000,
  FARMA_SNGPC = 55001,

  //RELATORIOS
  RELATORIOS_GROUP = 60000,
  RELATORIOS_DASHBOARD = 60001,
  RELATORIOS_AVANCADOS = 60002,
  RELATORIOS_GERAL = 60003,
  RELATORIOS_WIZARD = 60004,
  RELATORIOS_HISTORICO_VENDAS = 60005,
  RELATORIOS_PERFORMANCE = 60006,
  RELATORIOS_ENTRADA_MERCADORIA = 60007,
  RELATORIOS_MANIFESTOS = 60008,
  RELATORIOS_PENDENCIAS_FISCAIS = 60009,


  SALAO_GROUP = 30000,
  ENTRADA_GROUP = 40000,

  //ADMINISTRATIVO
  ADMINISTRATIVO_GROUP = 10000,
  ADMINISTRATIVO_DADOS_EMPRESA = 10001,
  ADMINISTRATIVO_IMPRESSORAS = 10002,
  ADMINISTRATIVO_PONTOS_VENDAS = 10003,

  ADMINISTRATIVO_ACESSOS_PERMISSOES = 10005,
  ADMINISTRATIVO_CONFIGURACOES = 10006,

  ADMINISTRATIVO_MESAS_COMANDAS = 10007,
  ADMINISTRATIVO_SALOES = 10008,
  ADMINISTRATIVO_SETOR_PROCESSOS = 10009,
  ADMINISTRATIVO_AUTENTICACAO = 10010,
  ADMINISTRATIVO_MEU_CARDAPIO = 10011,

  ADMINISTRATIVO_GERENCIAMENTO_DE_SESSOES = 1012,

  // FISCAL
  FISCAL_GROUP = 50000,
  FISCAL_ATIVAR_REVISAR_DADOS_NFCE = 50001,
  FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS = 50002,

  // SUPORTE
  SUPORTE_GROUP = 98000,
  SUPORTE_CHAT = 98001,
  SUPORTE_BASE_CONHECIMENTO = 98002,

  //SAIR
  MASTER_SAIR = 99999,
}
