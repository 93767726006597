import { useGetRelatoriosAvancados } from "data/api/gestao/relatorios/relatorios-avancados/get-relatorios-avancados"
import { GestaoStorageKeys, useGestaoStorage } from "./gestao-storage";
import { useCallback } from "react";
import { useToastSaurus } from "./toast-saurus";
import { EnumCodRelatorios, RelatoriosAvancadosModel } from "model/api/gestao/relatorios-avancados/relatorios-avancados-model";
import { isEmpty } from "lodash";

export const useConfiguracoesRelatorios = () => {

    const { getRelatoriosAvancados, carregando } = useGetRelatoriosAvancados();
    const { getRegistro, setRegistro } = useGestaoStorage();
    const { showToast } = useToastSaurus();

    const atualizarRelatorios = useCallback(async () => {
        try {
            const res = await getRelatoriosAvancados('ativo=true');

            if (res.erro) throw res.erro;

            const configs = res.resultado?.data as Array<RelatoriosAvancadosModel>;
            const empresaId = getRegistro(GestaoStorageKeys.EmpresaAtual, false)?.id as string;

            if (!empresaId) throw new Error('Empresa não encontrada');

            const model = {
                empresaId,
                configuracoes: configs,
            }

            setRegistro(GestaoStorageKeys.RelatoriosConfiguracoes, model, false);
        } catch (e: any) {
            showToast('error', e.message)
        }
    }, [getRegistro, getRelatoriosAvancados, setRegistro, showToast])

    const possuiRelatorios = useCallback((): boolean => {
        const obj = getRegistro(GestaoStorageKeys.RelatoriosConfiguracoes, false);

        if (isEmpty(obj)) return false;

        const empresaId = getRegistro(GestaoStorageKeys.EmpresaAtual, false)?.id as string;

        if (empresaId !== obj.empresaId) {
            return false;
        }

        if (typeof obj.configuracoes !== 'object') return false

        return Array.from(obj.configuracoes).length > 0;
    }, [getRegistro])

    const getConfigRelatorios = useCallback((): Array<RelatoriosAvancadosModel> => getRegistro(GestaoStorageKeys.RelatoriosConfiguracoes, false), [getRegistro])

    const getConfigRelatorioByCod = useCallback((cod: EnumCodRelatorios): RelatoriosAvancadosModel | undefined => {
        const obj = getRegistro(GestaoStorageKeys.RelatoriosConfiguracoes, false);
        if (isEmpty(obj)) throw new Error('Relatórios não encontrados.');

        return (obj.configuracoes as Array<RelatoriosAvancadosModel>).find(x => x.codigo === cod);
    }, [getRegistro])
    return {
        atualizarRelatorios,
        possuiRelatorios,
        getConfigRelatorios,
        getConfigRelatorioByCod,
        carregando,
    }
}